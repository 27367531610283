import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Loader from '../../../components/loader';
import Button from '../../../components/button';
import { GetBooksByStatus_4 } from '../../../api';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import debounce from '../../../utils/debounce';

import './style.css';

class KomplentFond extends Component {
 state = {};

 handleInputTitle = async (e) => {
  if (e.target.value != '') {
   var value = '';
   e.target.value == 'Отобразить все книги' ? (value = '') : (value = e.target.value);
   this.setState({ inputType: value });

   const obj3 = {
    value: value,
   };
   const res = await GetBooksByStatus_4.GetBooksByStatus_4(obj3);
   await this.props.setDate(res, 'spisan_book', 'spisan_book');
  } else {
   await this.props.setDate([], 'spisan_book', 'spisan_book');
  }
 };

 async componentDidMount() {
  await this.props.setDate([], 'spisan_book', 'spisan_book');
 }

 render() {
  const headerItemsContent = [
   { title: 'Дата списания', data: 'date' },
   { title: 'Акт списания', data: 'act' },
   { title: 'Инвентарный номер', data: 'invN' },

   { title: 'Автор', data: 'author' },
   { title: 'Название книги', data: 'title' },
   { title: 'Год издания', data: 'publication_year' },
   { title: 'Издатель', data: 'publisher' },
   { title: 'По отраслям знаний', data: 'by_branches_of_knowledge' },
   { title: 'Цена', data: 'price' },

   { title: 'ISBN', data: 'ISBN' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Списанные книги</h1>
     </div>
     <div className='smart-search' style={{ marginBottom: '20px' }}>
      <input type='text' placeholder='Введите заглавие' onChange={debounce(this.handleInputTitle, 900)} />
     </div>
     <div className='book-items-content'>
      <CommonWrapperTable mainArr={this.props.spisan_book} headersArr={headerItemsContent} />

      {/* <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} /> */}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(KomplentFond);
