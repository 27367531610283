import React, { Component } from 'react';

import { NavLink, Link } from 'react-router-dom';

import './style.css';

import menu from '../../assets/icon/menu.png';
import arrow_menu from '../../assets/icon/arrow-menu.png';
import logo from '../../assets/icon/logo.png';

import out_book from '../../assets/icon/out-book.png';
import add_book from '../../assets/icon/add-book.png';
import recrut from '../../assets/icon/recrut.png';
import metodist from '../../assets/icon/metodist.png';
import readers from '../../assets/icon/readers.png';
import education from '../../assets/icon/education.png';
import { setData } from '../../api';

class Menu extends Component {
  state = {
    visibleMenu: true,
    // --------------------
    admin: false,
    katalog: false,
    registr: false,
    registr2: false,

    static_read: false,
    serv: false,
    suscr: false,
    total_stat: false,
    svod_otchet: false,
    staff_info: false,
    komplektov: false,
    store: false,
    dolg: false,
    documents: false,

    analit: false,

    // Состояние для показа уведомлений
    zabronLength: 0,
    roles: localStorage.getItem('roles').split(','),
  };

  menuVisRazdel = (e) => {
    this.setState({
      [e.target.dataset.type]: !this.state[e.target.dataset.type],
    });

    if (e.target.dataset.type !== 'suscr') {
      let arrLinks = Object.keys(this.state).filter(
        (elem) => elem !== e.target.dataset.type && elem !== 'visibleMenu',
      );
      arrLinks.map((elem) => (elem !== 'roles' ? this.setState({ [elem]: false }) : null));
    }
  };

  autoNext = async () => {
    const arr = await setData.setData('zabron_book', localStorage.getItem('lib_name'));
    if (!localStorage.getItem('zabron_length')) {
      localStorage.setItem('zabron_length', arr.length);
    }
    if (+localStorage.zabron_length !== arr.length && !isNaN(+localStorage.zabron_length)) {
      localStorage.setItem('zabron_notification', '1');
      this.setState({ zabronLength: arr.length });
    }
    setTimeout(this.autoNext, 3000);
  };

  //  componentDidUpdate(props, state) {
  //   if (this.state.serv !== state.serv) {
  //    // this.autoNext();
  //   }
  //  }
  //  componentDidMount() {
  //   // this.autoNext();
  //  }

  //  componentDidUpdate(props, state) {
  //   if (this.state.serv !== state.serv) {
  //    this.autoNext();
  //   }
  //  }

  FindRole = (elem) => {
    return this.state.roles
      ? this.state.roles.length !== 0
        ? this.state.roles.includes(elem)
        : 0
      : 0;
  };

  render() {
    const visMenu = this.state.visibleMenu ? 'menu' : 'menu notMenu';

    const admin = this.state.admin ? 'adm-pod' : 'adm-pod unviz';
    const katalog = this.state.katalog ? 'adm-pod' : 'adm-pod unviz';
    const registr = this.state.registr ? 'adm-pod' : 'adm-pod unviz';
    const registr2 = this.state.registr2 ? 'adm-pod' : 'adm-pod unviz';

    const static_read = this.state.static_read ? 'adm-pod' : 'adm-pod unviz';
    const serv = this.state.serv ? 'adm-pod' : 'adm-pod unviz';
    const suscr = this.state.suscr ? 'adm-pod' : 'adm-pod unviz';
    const total_stat = this.state.total_stat ? 'adm-pod' : 'adm-pod unviz';
    const svod_otchet = this.state.svod_otchet ? 'adm-pod' : 'adm-pod unviz';
    const staff_info = this.state.staff_info ? 'adm-pod' : 'adm-pod unviz';
    const komplektov = this.state.komplektov ? 'adm-pod' : 'adm-pod unviz';
    const store = this.state.store ? 'adm-pod' : 'adm-pod unviz';
    const dolg = this.state.dolg ? 'adm-pod' : 'adm-pod unviz';
    const documents = this.state.documents ? 'adm-pod' : 'adm-pod unviz';

    const analit = this.state.analit ? 'adm-pod' : 'adm-pod unviz';

    return (
      <div className={visMenu}>
        <div className='menu-wrapper'>
          <div className='menu-up-content'>
            <Link
              to='/'
              className='menu-logo'
              onClick={() => this.setState({ active_link_id: '' })}
            >
              <img src={logo} alt='logo' />
            </Link>
          </div>

          <div className='menu-items'>
            <div className='menu-items-wrapper'>
              {this.FindRole('Администрирование') && (
                <div className='administr'>
                  <p className='adm-main' data-type='admin' onClick={this.menuVisRazdel}>
                    Администрирование
                  </p>

                  <div className={admin}>
                    <NavLink to='/obrabotka' className='link'>
                      Ведение и обработка системных журналов
                    </NavLink>
                    <NavLink to='/recrut' className='link'>
                      Создание учетных записей пользователей / Назначение прав доступу для
                      пользователей
                    </NavLink>
                    {/* <NavLink to='/book_add' className='link'>
           Электронные ресурсы с указанием доступа к ним
          </NavLink> */}
                    {/* {['15', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/book_add'
           onClick={() => this.setState({ active_link_id: 2 })}
           className={this.state.active_link_id === 2 ? 'active_menu' : null}
          >
           <p>Управление хранением электронным ресурсам</p>
          </Link>
         )} */}
                    {/* {['15', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/ebook_add'
           onClick={() => this.setState({ active_link_id: 3 })}
           className={this.state.active_link_id === 3 ? 'active_menu' : null}
          >
           <p>Управление и доступ к электронным ресурсам</p>
          </Link>
         )} */}
                    {/* <Link to='/index_doc'><p>Индексация электронных документов</p></Link> */}
                    <NavLink to='/create_srav' className='link'>
                      Создание и пополнение справочников
                    </NavLink>
                    {/* <Link to='/history'><p>Просмотр и выгрузка истории</p></Link> */}
                    <div className='link'>
                      <a href='https://user.smart-pvl.kz' target='_blank'>
                        Электронная библиотека для читателей
                      </a>
                    </div>
                    <NavLink to='/el_res' className='link'>
                      Электронные ресурсы
                    </NavLink>
                  </div>
                </div>
              )}
              {this.FindRole('Комплектование') && (
                <div className='administr'>
                  <p className='adm-main' data-type='komplektov' onClick={this.menuVisRazdel}>
                    Комплектование
                  </p>
                  <div className={komplektov}>
                    <NavLink to='/komplekt_vvod' className='link'>
                      Ввод данных о заказе в регистрационную форму
                    </NavLink>
                    <NavLink to='/komplekt_red' className='link'>
                      Редактирование данных о заказе в регистрационной форме
                    </NavLink>
                    {/* {['15', 'Отдел комплектования', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/komplekt_popular'
           onClick={() => this.setState({ active_link_id: 12 })}
           className={this.state.active_link_id === 12 ? 'active_menu' : null}
          >
           <p>Формирование документов для заказа литературы</p>
          </Link>
         )} */}
                    {/* {['15', 'Отдел комплектования', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/komplekt_otchet'
           onClick={() => this.setState({ active_link_id: 13 })}
           className={this.state.active_link_id === 13 ? 'active_menu' : null}
          >
           <p>Формирование и получение отчетных форм</p>
          </Link>
         )} */}
                    <NavLink to='/komplekt_inventar' className='link'>
                      Ведение электронной инвентарной книги
                    </NavLink>
                    <NavLink to='/stat_komplekt' className='link'>
                      Формирование отчетно-статистических данных
                    </NavLink>
                    {/* {['15', 'Отдел комплектования', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/komplekt_act'
           onClick={() => this.setState({ active_link_id: 15 })}
           className={this.state.active_link_id === 15 ? 'active_menu' : null}
          >
           <p>Ввод данных о сопроводительных докуметах</p>
          </Link>
         )} */}
                    {/* {['15', 'Отдел комплектования', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <NavLink to='/katalog_print' className='link'>
           Формирование и печать стандартных выходных форм
          </NavLink>
         )} */}
                    <NavLink
                      to='/komplekt_ksu'
                      onClick={() => this.setState({ active_link_id: 16 })}
                      className='link'
                    >
                      <p>Ведение книги суммарного учета (КСУ)</p>
                    </NavLink>
                    <NavLink to='/spisan_book' className='link'>
                      Списанные книги
                    </NavLink>

                    <div className='administr'>
                      <p className='adm-main' data-type='suscr' onClick={this.menuVisRazdel}>
                        Подписка
                      </p>
                      <div className={suscr}>
                        <NavLink to='/subcr_base' className='link'>
                          Периодические издания
                        </NavLink>
                        <NavLink to='/subcr_base_parts' className='link'>
                          Сбор заказов на периодические издания
                        </NavLink>
                        <NavLink to='/subcr_base_parts_control' className='link'>
                          Контроль поступления периодических изданий
                        </NavLink>
                        <NavLink to='/subcr_zakaz' className='link'>
                          База данных заказчиков и поставщиков
                        </NavLink>
                        {/* <NavLink to='/subcr_postav' className='link'>
             База данных поставщиков
            </NavLink> */}
                        {/* {['15', 'Библиотекарь', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
            <Link
             to='/subcr_otchet'
             onClick={() => this.setState({ active_link_id: 34 })}
             className={this.state.active_link_id === 34 ? 'active_menu' : null}
            >
             <p>Форма отчетности</p>{' '}
            </Link>
           )} */}
                        {/* {['15', 'Библиотекарь', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
            <Link
             to='/subcr_calendar'
             onClick={() => this.setState({ active_link_id: 35 })}
             className={this.state.active_link_id === 35 ? 'active_menu' : null}
            >
             <p>Контроль поступления периодических изданий</p>{' '}
            </Link>
           )} */}
                      </div>
                    </div>
                    {/* {['15', 'Отдел комплектования', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/ElStat'
           onClick={() => this.setState({ active_link_id: 18 })}
           className={this.state.active_link_id === 18 ? 'active_menu' : null}
          >
           <p>Статьи</p>
          </Link>
         )} */}
                  </div>
                </div>
              )}
              {this.FindRole('Каталогизация') && (
                <div className='administr'>
                  <p className='adm-main' data-type='katalog' onClick={this.menuVisRazdel}>
                    Каталогизация
                  </p>
                  <div className={katalog}>
                    <NavLink to='/book_add' className='link'>
                      Электронный каталог библиотеки
                    </NavLink>

                    <NavLink to='/marc' className='link'>
                      Загрузка/выгрузка библиографических записей
                    </NavLink>
                    <NavLink to='/lib_adress' className='link'>
                      Информация о пунктах книговыдачи
                    </NavLink>
                    <NavLink to='/static_sved_cat' className='link'>
                      Отчетно-статистические данные
                    </NavLink>
                    <NavLink to='/analitic_sved_cat' className='link'>
                      Формирование аналитической информации
                    </NavLink>
                    <NavLink to='/static_sved' className='link'>
                      Статистические сведения по состоянию, движению и использованию фонда
                      библиотеки
                    </NavLink>

                    {/* {['15', 'Отдел комплектования', 'Областная библиотека'].includes(localStorage.getItem('role')) && <Link to='/katalog_ip'><p>Обеспечение взаимодействия с внешними поставщиками информационных ресурсов</p></Link>} */}
                  </div>
                </div>
              )}
              {this.FindRole('Регистрация') && (
                <div className='administr'>
                  <p className='adm-main' data-type='registr' onClick={this.menuVisRazdel}>
                    Регистрация
                  </p>
                  <div className={registr}>
                    <NavLink to='/readers' className='link'>
                      Читатели
                    </NavLink>
                    <NavLink to='/sved_user' className='link'>
                      Отчетные формы
                    </NavLink>
                    <NavLink to='/reg_rules' className='link'>
                      Правила
                    </NavLink>
                    {/* {['15', 'Библиотекарь', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/pravila'
           onClick={() => this.setState({ active_link_id: 21 })}
           className={this.state.active_link_id === 21 ? 'active_menu' : null}
          >
           <p>Формирование правил автоматизированного обслуживания</p>
          </Link>
         )} */}
                    {/* {['15', 'Библиотекарь', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/reg_ip'
           onClick={() => this.setState({ active_link_id: 22 })}
           className={this.state.active_link_id === 22 ? 'active_menu' : null}
          >
           <p>Взаимодействие с другими информационными системами</p>
          </Link>
         )} */}
                  </div>
                </div>
              )}
              {this.FindRole('Статистика читателей') && (
                <div className='administr'>
                  <p
                    className='adm-main'
                    data-type='registr2'
                    onClick={this.menuVisRazdel}
                    style={{ fontSize: '17px' }}
                  >
                    Статистика читателей
                  </p>
                  <div className={registr2}>
                    <NavLink to='/readers_stat' className='link'>
                      Отчет по читателям
                    </NavLink>
                  </div>
                </div>
              )}
              {this.FindRole('Общая статистика') && (
                <div className='administr'>
                  <p className='adm-main' data-type='static_read' onClick={this.menuVisRazdel}>
                    Общая статистика
                  </p>
                  <div className={static_read}>
                    <NavLink to='/static_read' className='link'>
                      Формирование общей статистики
                    </NavLink>
                    {/* <NavLink to='/otrasl_read' className='link'>
           Книговыдача по параметрам отраслей литературы
          </NavLink>
          <NavLink to='/zhanr_read' className='link'>
           Книговыдача по жанрам литературы
          </NavLink>
          <NavLink to='/docvid_read' className='link'>
           Книговыдача по виду документа
          </NavLink> */}
                    <NavLink to='/analitik_stat' className='link'>
                      Формирование аналитической информации
                    </NavLink>
                  </div>
                </div>
              )}
              {this.FindRole('Обслуживание') && (
                <div className='administr'>
                  <p
                    className={
                      (+localStorage.zabron_notification ? 'notification ' : '') + 'adm-main'
                    }
                    data-type='serv'
                    onClick={this.menuVisRazdel}
                  >
                    Обслуживание
                  </p>
                  <div className={serv}>
                    <NavLink to='/book_out' className='link'>
                      Выдача
                    </NavLink>
                    <NavLink to='/store_bron_serv' className='link'>
                      Возврат и резервирование необходимой литературы
                    </NavLink>
                    <NavLink to='/serv_popular' className='link'>
                      Статистика по использованию и востребованности фондов
                    </NavLink>
                    {/* {['15', 'Библиотекарь', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/serv_dolg'
           onClick={() => this.setState({ active_link_id: 28 })}
           className={this.state.active_link_id === 28 ? 'active_menu' : null}
          >
           {' '}
           <p>Должники</p>{' '}
          </Link>
         )} */}
                    {/* {['15', 'Библиотекарь', 'Областная библиотека'].includes(
                    localStorage.getItem('role'),
                  ) && (
                    <Link
                      to="/serv_pravila"
                      onClick={() => this.setState({ active_link_id: 29 })}
                      className={this.state.active_link_id === 29 ? 'active_menu' : null}>
                      <p>
                        Информация, определяющая правила автоматизированного заказа и выдачи книг
                      </p>
                    </Link>
                  )} */}
                    <NavLink to='/zabron_book' className='link'>
                      Выдача забронированных книг
                    </NavLink>
                    <NavLink to='/service_rules' className='link'>
                      Правила
                    </NavLink>
                    <NavLink to='/books_extension' className='link'>
                      Список заявок на продление даты сдачи
                    </NavLink>
                  </div>
                </div>
              )}

              {/* <div className='administr'>
        <p className='adm-main' data-type='total_stat' onClick={this.menuVisRazdel}>
         Общая статистика
        </p>
        <div className={total_stat}>
         {['15', 'Методист', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/total_stat'
           onClick={() => this.setState({ active_link_id: 36 })}
           className={this.state.active_link_id === 36 ? 'active_menu' : null}
          >
           <p>Общая статистика</p>{' '}
          </Link>
         )}
         {['15', 'Методист', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <Link
           to='/otrasl_read'
           onClick={() => this.setState({ active_link_id: 37 })}
           className={this.state.active_link_id === 37 ? 'active_menu' : null}
          >
           <p>Формирование статистики по параметрам отраслей литературы</p>
          </Link>
         )}
        </div>
       </div> */}
              {this.FindRole('Отчетность') && (
                <div className='administr'>
                  <p className='adm-main' data-type='svod_otchet' onClick={this.menuVisRazdel}>
                    Отчетность
                  </p>
                  <div className={svod_otchet}>
                    {/* {['15', 'Методист', 'Областная библиотека'].includes(localStorage.getItem('role')) && (
          <NavLink to='/svod_otchet' className='link'>
           Сводная отчетность
          </NavLink>
         )} */}
                    {/* <NavLink to='/otchet_lib' className='link'>
           Общие сведения по библиотекам
          </NavLink> */}
                    <NavLink to='/general_given_books' className='link'>
                      Сводная отчетность по общей выдаче
                    </NavLink>
                    <NavLink to='/given_multibooks_p' className='link'>
                      Сводная отчетность по выдаче периодических изданий
                    </NavLink>
                    <NavLink to='/given_multibooks_g' className='link'>
                      Сводная отчетность по выдаче газет
                    </NavLink>
                    <NavLink to='/given_multibooks_m' className='link'>
                      Сводная отчетность по выдаче журналов
                    </NavLink>
                    <NavLink to='/given_books' className='link'>
                      Сводная отчетность по выдаче литературы
                    </NavLink>
                    <NavLink to='/return_books' className='link'>
                      Сводная отчетность по возврату литературы
                    </NavLink>
                    <NavLink to='/fond_books' className='link'>
                      Сводная отчетность по фонду литературы
                    </NavLink>
                    <NavLink to='/fond_books_branches' className='link'>
                      Сводная отчетность по фонду литературы по отраслям
                    </NavLink>
                    <NavLink to='/fond_books_langs' className='link'>
                      Сводная отчетность по фонду литературы по языкам
                    </NavLink>
                    <NavLink to='/fond_books_popularity' className='link'>
                      Сводная отчетность по популярности литературы
                    </NavLink>
                    <NavLink to='/readers_svod' className='link'>
                      Сводная отчетность по читателям
                    </NavLink>
                    <NavLink to='/visits_svod' className='link'>
                      Сводная отчетность по посещениям
                    </NavLink>
                    <NavLink to='/readers_age' className='link'>
                      Сводная отчетность по возрасту читателей
                    </NavLink>
                    <NavLink to='/readers_gender' className='link'>
                      Сводная отчетность по половому признаку читателей
                    </NavLink>
                    <NavLink to='/registration_svod' className='link'>
                      Сводная отчетность по регистрации читателей
                    </NavLink>
                    <NavLink to='/genre_svod' className='link'>
                      Сводная отчетность по жанрам
                    </NavLink>
                    {/* <NavLink to='/title_svod' className='link'>
           Сводная отчетность по названиям литературы
          </NavLink> */}
                    <NavLink to='/registration_user_svod' className='link'>
                      Сводная отчетность по регистрации пользователей
                    </NavLink>
                    {/* <NavLink to='/users_settings' className='link'>
           Сводная отчетность по настройкам пользователей
          </NavLink> */}
                  </div>
                </div>
              )}
              {this.FindRole('Сотрудники') && (
                <div className='administr'>
                  <p className='adm-main' data-type='staff_info' onClick={this.menuVisRazdel}>
                    Сотрудники
                  </p>
                  <div className={staff_info}>
                    <Link
                      to='/staff_info'
                      onClick={() => this.setState({ active_link_id: 42 })}
                      className='link'
                    >
                      <p>Выдача и возврат литературы</p>{' '}
                    </Link>

                    <NavLink to='/staff_info_roles' className='link'>
                      Права доступа/Роли сотрудников
                    </NavLink>
                    <NavLink to='/staff_info_system' className='link'>
                      Работа в системе и с литературой
                    </NavLink>
                  </div>
                </div>
              )}
              {this.FindRole('Хранилище') && (
                <div className='administr'>
                  <p className='adm-main' data-type='store' onClick={this.menuVisRazdel}>
                    Хранилище
                  </p>
                  <div className={store}>
                    <NavLink
                      to='/store_bron'
                      onClick={() => this.setState({ active_link_id: 43 })}
                      className='link'
                    >
                      <p>Подборка книг и отправка их на бронеполку для выдачи читателям</p>{' '}
                    </NavLink>
                    <NavLink to='/book-add-from-part' className='link'>
                      Прием заказов от пунктов книговыдачи
                    </NavLink>
                  </div>
                </div>
              )}
              {this.FindRole('Должники') && (
                <div className='administr'>
                  <p className='adm-main' data-type='dolg' onClick={this.menuVisRazdel}>
                    Должник
                  </p>
                  <div className={dolg}>
                    <NavLink to='/dolg' className='link'>
                      Информация по должникам
                    </NavLink>
                  </div>
                </div>
              )}
              {this.FindRole('Аналитика') && (
                <div className='administr'>
                  <p className='adm-main' data-type='analit' onClick={this.menuVisRazdel}>
                    Аналитика
                  </p>
                  <div className={analit}>
                    <NavLink to='/analit_readers' className='link'>
                      Аналитические данные "Статистика читателей"
                    </NavLink>
                    <NavLink to='/analit_general' className='link'>
                      Аналитические данные "Общая статистика"
                    </NavLink>
                    <NavLink to='/analit_staff' className='link'>
                      Аналитические данные "Сотрудники"
                    </NavLink>
                    <NavLink to='/analit_svod' className='link'>
                      Аналитические данные "Отчетность"
                    </NavLink>
                  </div>
                </div>
              )}
              {/* <Link to='/book_out' className='item'>
                                <img className='item-img' src={out_book} alt='menu-icon' />
                                <p className='item-text'>Книгавыдача</p>
                            </Link>

                            <Link to='/book_add' className='item'>
                                <img className='item-img' src={add_book} alt='menu-icon' />
                                <p className='item-text'>Книгокомплектование</p>
                            </Link>

                            <Link to='/recrut' className='item'>
                                <img className='item-img' src={recrut} alt='menu-icon' />
                                <p className='item-text'>Отдел кадров</p>
                            </Link>

                            <Link to='/metodist' className='item'>
                                <img className='item-img' src={metodist} alt='menu-icon' />
                                <p className='item-text'>Методист</p>
                            </Link>

                            <Link to='/readers' className='item'>
                                <img className='item-img' src={readers} alt='menu-icon' />
                                <p className='item-text'>Читатели</p>
                            </Link>

                            <Link to='/library' className='item'>
                                <img className='item-img' src={education} alt='menu-icon' />
                                <p className='item-text'>Библиотеки и мироприятия</p>
                            </Link> */}
              {/* <div className='administr'>
        <p className='adm-main' data-type='documents' onClick={this.menuVisRazdel}>
         Документы
        </p>
        <div className={documents}>
         <NavLink to='/documents' className='link'>
          Документы
         </NavLink>
        </div>
       </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
