import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../../redux/action';

import moment from 'moment';
import { periodical_publications_add } from '../../../../api';

import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import toast from 'react-hot-toast';

class FormBookAdd extends Component {
  state = {
    showModal5: false,
    publishersGetMassive: [],
    publisherSelectedN: -1,
    publisherSelectedItem: '',
    addbook: {
      type: '',
      name: '',
      num_chasti: '',
      sved_otvets: '',
      sved_zaglov: '',
      tema: '',
      place_izdn: '',
      place_izdn2: '',

      sved_izdn: '',
      izdatel: '',
      izdatelInput: '',

      god: '',
      seria: '',
      prim: '',
      price: '',
      kol: '',

      osn_language: '',
      ost_language1: '',
      ost_language2: '',
    },
  };

  handleChange = async ({ target }) => await this.setState({ [target.name]: target.value });

  handleChangeModal = async ({ target }) => {
    await this.setState({
      ...this.state,
      addbook: { ...this.state.addbook, [target.name]: target.value },
    });
  };

  PublisherChangeHandle = async () => {
    if (this.state.addbook.izdatelInput.trim().length !== 0) {
      const objForm = { keyword: this.state.addbook.izdatelInput };
      const formData = new FormData();
      formData.append(`keyword`, this.state.addbook.izdatelInput);

      const res = await fetch('https://smart-pvl.kz/Api/api/publisher/get.php', {
        method: 'POST',
        headers: {
          AUTH: localStorage.getItem('tokenLibrary'),
        },
        body: JSON.stringify(objForm),
      });
      const json = await res.json();
      if (json.length === 0) {
        this.setState({ showModal5: true, publishersGetMassive: [] });
      } else {
        this.setState({ publishersGetMassive: json, showModal5: true });
      }
    }
  };

  closeModalEvent = (e, state) => {
    if (e === 'modal') this.setState({ [state]: false });
  };

  PublisherSelectHandle = (e, i) => {
    this.setState({ publisherSelectedN: i, publisherSelectedItem: e });
  };

  closeModalWithSelect3 = () => {
    this.setState({
      ...this.state,
      addbook: { ...this.state.addbook, izdatel: this.state.publisherSelectedItem.id },
      showModal5: false,
    });
  };

  closeModalWithoutSelect = () =>
    this.setState({
      showModal5: false,
      publisherSelectedItem: '',
      publisherSelectedN: -1,
    });

  handleSubmit = async (e) => {
    e.preventDefault();

    const data = this.state.addbook;

    const res = await periodical_publications_add.periodical_publications_add(data);
    if (res) {
      toast.success('Успешно добавлено!');
      this.props.returnPage();
    } else {
      toast.error('Произошла ошибка');
    }
  };

  render() {
    const typeItem = [
      { title: 'Книга', data: 'book' },
      { title: 'Электронная статья', data: 'article' },
    ];

    const atrFormAddBook = [
      {
        title: 'Тип',
        name: 'type',
        type: 'text',
        option: [
          { type: 0, name: 'Газета' },
          { type: 1, name: 'Журнал' },
          { type: 2, name: 'Периодическое издание' },
        ],
      },
      { title: 'Название', name: 'name', type: 'text', placeholder: 'Обязательное поле' },
      {
        title: 'Номер части',
        name: 'num_chasti',
        type: 'number',
        placeholder: 'Обязательное поле',
      },
      {
        title: 'Сведения к заглавлению',
        name: 'sved_zaglov',
        type: 'text',
        placeholder: 'Обязательное поле',
      },
      {
        title: 'Сведения об ответственности',
        name: 'sved_otvets',
        type: 'text',
        placeholder: 'Обязательное поле',
      },
      { title: 'Тема', name: 'tema', option: this.props.SoderjSelectData.map((el) => el) },
      {
        title: 'Место издания',
        name: 'place_izdn',
        option: this.props.PublPlaceDataSelectsData.map((el) => el),
      },
      {
        title: 'Область места издания',
        name: 'place_izdn2',
        type: 'text',
        placeholder: 'Обязательное поле',
      },

      { title: 'Сведение об издании', name: 'sved_izdn', type: 'text', placeholder: '-' },
      {
        title: 'Издательство',
        name: 'izdatelInput',
        type: 'text',
        placeholder: 'Введите название издательства',
      },
      { title: 'Год издания', name: 'god', type: 'number', placeholder: 'Обязательное поле' },

      { title: 'Серия', name: 'seria', type: 'text', placeholder: 'Обязательное поле' },
      { title: 'Примечание', name: 'prim', type: 'text', placeholder: '-' },
      { title: 'Цена номера', name: 'price', type: 'number', placeholder: 'Обязательное поле' },
      { title: 'Количество № в год', name: 'kol', type: 'number', placeholder: '-' },
      {
        title: 'Язык осн. текста',
        name: 'osn_language',
        type: 'language',
        option: this.props.LanguageSelectData.map((el) => el),
      },
      {
        title: 'Другие языки издания',
        name: 'ost_language1',
        type: 'language',
        option: this.props.LanguageSelectData.map((el) => el),
      },
      {
        title: 'Другие языки издания',
        name: 'ost_language2',
        type: 'language',
        option: this.props.LanguageSelectData.map((el) => el),
      },
    ];

    // const atrFormAddArticle = [
    //  { title: 'Автор', name: 'author_article' },
    //  { title: 'Даты жизни 1 автора', name: 'author_date_article' },
    //  { title: 'Другой автор', name: 'author_other_article' },
    //  { title: 'Даты жизни др. автора', name: 'author_other_date_article' },
    //  { title: 'Постоянный коллективный автор', name: 'author_kol_article' },
    //  { title: 'Место постоянного автора', name: 'author_place_article' },
    //  { title: 'Подчиненное звено', name: 'author_part_article' },
    //  { title: 'Место временного автора', name: 'author_time_place_article' },
    //  { title: 'Подчиненное звено временного автора', name: 'author_part_time_article' },
    //  { title: 'Заглавие', name: 'title_article' },
    //  { title: 'Количество незначащих символов', name: 'kol_symbol_article' },
    //  { title: 'Номер части', name: 'num_part_article' },
    //  { title: 'Название части', name: 'name_part_article' },
    //  { title: 'Сведения, относящиеся к заглавию', name: 'title_other_article' },
    //  { title: 'Сведения об ответственности', name: 'liability_article' },
    //  { title: 'Параллельное название', name: 'parallel_name_article' },
    //  { title: 'Заглавие источника', name: 'title_source_article' },
    //  { title: 'Сведения, относящиеся к источнику', name: 'sved_source_article' },
    //  { title: 'Сведения об ответственности источника', name: 'sved_liability_article' },
    //  { title: 'Авторы источника', name: 'author_liability_article' },
    //  { title: 'Сведения об издании источника', name: 'sved_publish_source_article' },
    //  { title: 'Выходные данные источника', name: 'source_output_article' },
    //  { title: 'Смешанная информация', name: 'mixed_info_article' },
    //  { title: 'ISSN источника', name: 'issn_article' },
    //  { title: 'Примечание', name: 'prim_article' },
    //  { title: 'Тип примечания', name: 'type_prim_article' },
    //  { title: 'Реферат', name: 'ref_article' },
    //  { title: 'Ссылка на добавочную карточку', name: 'link_card_article' },
    //  { title: 'Тематическая рубрика', name: 'theme_rubric_article' },
    //  { title: 'Источник каталогизатора', name: 'source_katalog_article' },
    //  { title: 'Общее подразделение рубрики', name: 'gen_subdiv_rubric_article' },
    //  { title: 'Источник рубрики', name: 'source_rubric_article' },
    //  { title: 'Незначащие символы тем', name: 'theme_symbol_article' },
    //  { title: 'Ключевые слова', name: 'tags_article' },
    //  { title: 'Незначащие символы ключевых слов', name: 'tags_symbol_article' },
    //  { title: 'Индекс УДК', name: 'udk_article' },
    //  { title: 'Индекс ББК научных библиотек', name: 'bbk_science_article' },
    //  { title: 'Индекс ББК массовых библиотек', name: 'bbk_mass_article' },
    //  { title: 'Авторский знак', name: 'author_mark_article' },
    //  { title: 'Расстановка', name: 'placement_article' },
    //  { title: 'Дополнительные ссылки', name: 'other_link_article' },
    //  { title: 'Тип ссылки', name: 'type_link_article' },
    //  { title: 'Язык основного текста', name: 'main_lang_article' },
    //  { title: 'Другой язык издания', name: 'other_lang_article' },
    //  { title: 'Служебные отметки', name: 'serv_marks_article' },
    //  { title: 'Текст', name: 'text_article' },
    //  { title: 'Примечание к тексту', name: 'prim_text_article' },
    //  { title: 'Сигла', name: 'sigla_article' },
    //  { title: 'Количество экземпляров', name: 'num_copies_article' },
    // ];

    return (
      <div className='form-book-add'>
        <div className='form-add'>
          <Modal
            show={this.state.showModal5}
            closeModalEvent={(e) => this.closeModalEvent(e, 'showModal5')}
          >
            <div className='st12'>
              Количество совпадений: {this.state.publishersGetMassive.length}
              <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {this.state.publishersGetMassive.length !== 0
                  ? this.state.publishersGetMassive.map((i, j) => (
                      <div
                        className={j === this.state.publisherSelectedN ? 'fmba2' : 'fmba1'}
                        onClick={() => this.PublisherSelectHandle(i, j)}
                      >
                        {i.name}
                      </div>
                    ))
                  : null}
              </div>
              <div
                style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}
              >
                <Button
                  className='st3'
                  text='Выбрать'
                  onClick={() => this.closeModalWithSelect3()}
                />

                <Button
                  className='st3'
                  text='Закрыть'
                  onClick={() => this.closeModalWithoutSelect()}
                />
              </div>
            </div>
          </Modal>

          <form
            style={{ flexDirection: 'column' }}
            className='form-add-wrapper'
            onSubmit={this.handleSubmit}
          >
            <div className='form-add-book'>
              {atrFormAddBook.map((el, i) => {
                const data = el.hasOwnProperty('option') ? (
                  el.type == 'language' ? (
                    <div className='search-filter-book' key={i}>
                      <p>{el.title}</p>
                      <div className='search-filter-book-rubrik'>
                        <select name={el.name} onChange={this.handleChangeModal}>
                          <option>Выберите язык</option>
                          {el?.option.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : el.name == 'tema' ? (
                    <div className='search-filter-book' key={i}>
                      <p>{el.title}</p>
                      <div className='search-filter-book-rubrik'>
                        <select name={el.name} onChange={this.handleChangeModal}>
                          <option>Выберите тему</option>
                          {el?.option.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name_ru}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : el.name == 'place_izdn' ? (
                    <div className='search-filter-book' key={i}>
                      <p>{el.title}</p>
                      <div className='search-filter-book-rubrik'>
                        <select name={el.name} onChange={this.handleChangeModal}>
                          <option>Выберите тему</option>
                          {el?.option.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name_ru}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : el.name == 'type' ? (
                    <div className='search-filter-book' key={i}>
                      <p>{el.title}</p>
                      <div className='search-filter-book-rubrik'>
                        <select name={el.name} onChange={this.handleChangeModal}>
                          <option>Тип издания</option>
                          {el?.option.map((item, i) => {
                            return (
                              <option key={i} value={item.type}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : null
                ) : el.name == 'izdatelInput' ? (
                  <div className='search-filter-book__book' key={i}>
                    <p>{el.title}</p>

                    <input
                      type={el.type}
                      name={el.name}
                      value={this.state.addbook[el.name]}
                      placeholder={
                        el.placeholder === '-' ? '' : el.placeholder || 'Обязательные поля ввода'
                      }
                      onChange={this.handleChangeModal}
                      onBlur={this.PublisherChangeHandle}
                    />

                    <div style={{ marginTop: '10px' }}></div>

                    <input
                      placeholder={
                        this.state.publisherSelectedN === -1
                          ? 'Издательство не выбрано'
                          : this.state.publisherSelectedItem.name
                      }
                      disabled
                    />
                  </div>
                ) : el.name == 'izdatelInput' ? (
                  <div className='search-filter-book__book' key={i}>
                    <p>{el.title}</p>

                    <input
                      type={el.type}
                      name={el.name}
                      value={this.state.addbook[el.name]}
                      placeholder={
                        el.placeholder === '-' ? '' : el.placeholder || 'Обязательные поля ввода'
                      }
                      onChange={this.handleChangeModal}
                      onBlur={this.PublisherChangeHandle}
                    />

                    <div style={{ marginTop: '10px' }}></div>

                    <input
                      placeholder={
                        this.state.publisherSelectedN === -1
                          ? 'Издательство не выбрано'
                          : this.state.publisherSelectedItem.name
                      }
                      disabled
                    />
                  </div>
                ) : (
                  <div className='search-filter-book' key={i}>
                    <p>{el.title}</p>

                    <input
                      type={el.type}
                      name={el.name}
                      autoComplete='off'
                      value={this.state.addbook[el.name]}
                      placeholder='Обязательные поля ввода'
                      onChange={this.handleChangeModal}
                    />
                  </div>
                );
                return data;
              })}
            </div>

            <div>
              <Button className='btn_add_book' text='Добавить' />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
