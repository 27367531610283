import React, { Component } from 'react';

import './style.css'

class ErrprPage extends Component {
    render() {
        return (
            <div>
                <div className='wrapper-err'>
                    <div className='conten-err'>
                        <div className='dino'></div>
                        <div className='text-title-err'>
                            <h1>Нет подключение к Интернету</h1>
                            <div className='text-err'>
                                <p>Попробуйте сделать следующее:</p>
                                <ul>
                                    <li>Проверьте сетевые кабели, модем и маршрутизатор.</li>
                                    <li>Подключитесь к сети Wi-Fi ещё раз.</li>
                                    <li>
                                        <a href="*">Выполните диагностику сети в Windows</a>
                                    </li>
                                </ul>
                                <p>ERR_INTERNET_DISCONNECTED</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrprPage;