import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { logOut } from '../../redux/action';

const LibEditModal = ({}) => {
 const SiglsDate = useSelector((state) => state.SiglsDate);
 const [SiglID, setSiglID] = useState('-');
 const [SiglName, setSiglName] = useState('-');

 useEffect(() => {
  console.log('LibEditModal');
 }, []);

 const handleChangeModal = (e) => {
  console.log(e.target.value);
  let res = SiglsDate.find((i) => i.name === e.target.value);
  console.log(res.id);
  setSiglID(res.id);
  setSiglName(res.name);
 };

 const save = async () => {
  const idUser = localStorage.getItem('idUser');
  localStorage.setItem('libcheck', 'false');
  localStorage.setItem('lib_ID', SiglID);
  const res = await fetch(`https://smart-pvl.kz/Api/api/staff/overwriting.php?idUser=${idUser}&libID=${SiglID}`, {
   method: 'GET',
   headers: {
    AUTH: localStorage.getItem('tokenLibrary'),
   },
   //    body: JSON.stringify({
   //     idUser: localStorage.getItem('idUser'),
   //     libID: SiglID,
   //     libName: SiglName,
   //     token: localStorage.getItem('tokenLibrary'),
   //    })
  });
  out();
 };

 const out = async () => {
  delete localStorage.tokenLibrary;
  delete localStorage.idUser;

  delete localStorage.lib_name;
  delete localStorage.role;
  delete localStorage.libcheck;

  await logOut();

  window.location.reload();
 };

 return (
  <div
   style={{
    height: '100%',
    width: '100%',
    background: '#b8575791',
    position: 'fixed',
    zIndex: '10000',
    display: 'flex',
   }}
  >
   <div
    style={{
     height: '400px',
     //      width: 'min-content',
     background: '#322e2e',
     margin: 'auto',
     border: '7px solid rgb(32 31 31)',
     borderRadius: '30px',
     textAlign: 'center',
     padding: '20px',
    }}
   >
    <div
     style={{
      width: '90%',
      margin: 'auto',
      marginTop: '10px',
      color: '#ab6237',
      fontWeight: '700',
      textAlign: 'center',
     }}
    >
     Ваша учетная запись была некорректно привязана к вашей библиотеке.
    </div>
    <div
     style={{
      width: '90%',
      margin: 'auto',
      marginTop: '10px',
      color: '#ab6237',
      fontWeight: '700',
      textAlign: 'center',
      marginBottom: '40px',
     }}
    >
     Для продолжения работы в Smart Кітапхана, пожалуйста, выберите свою библиотеку из списка ниже.
    </div>
    <div className='search-filter-book_book'>
     <div style={{ margin: '10px 10px', fontWeight: 'bold' }}></div>
     <div className='search-filter-book-rubrik'>
      <select
       onChange={(e) => {
        handleChangeModal(e);
       }}
      >
       <option hidden>Выбрать библиотеку</option>
       {SiglsDate.map((item, i) => {
        // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
        return (
         <option key={i} value={item.name}>
          {item.name}
         </option>
        );
       })}
      </select>
      {/* <span className='plus' onClick={() => this.modalAddItem(el)}>
              +
             </span> */}
     </div>
    </div>

    <div
     style={{
      width: 'max-content',
      height: 'max-content',
      margin: 'auto',
      marginTop: '80px',
      border: '2px solid #ab6237',
      padding: '10px',
      borderRadius: '10px',
      color: '#976b52',
      cursor: 'pointer',
      display: SiglID == '-' ? 'none' : 'block',
     }}
     onClick={save}
    >
     Ок
    </div>
    <div
     style={{
      width: '90%',
      margin: 'auto',
      marginTop: '15px',
      color: '#ab6237',
      fontWeight: '700',
      textAlign: 'center',
      display: SiglID == '-' ? 'none' : 'block',
     }}
    >
     Вам потребуется перезайти в систему.
    </div>
   </div>
  </div>
 );
};

export default LibEditModal;
