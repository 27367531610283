import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import '../sved_user/style.css';
import {
  getStatByAge,
  getStatByMW,
  getStatByGenre,
  getStatByTitle,
  getStatyOtrasli,
  getStatyStatuses,
  getStatByLangs,
} from '../../../api';
import toast from 'react-hot-toast';

class ReadersStat extends Component {
  state = {
    arr_stat_age: [],
    arr_stat_gender: [],
    arr_stat_genre: [],
    arr_stat_title: [],

    open_1: false,
    open_2: false,
    open_3: false,
    open_4: false,
    open_5: false,
    open_6: false,
    open_7: false,
  };

  handleAge = async () => {
    const res = await getStatByAge.getStatByAge();

    var to_5 = 0;
    var from_5_to_15 = 0;
    var from_15_to_21 = 0;
    var from_21_to_35 = 0;
    var from_35_to_60 = 0;
    var from_60 = 0;

    res.map((data) => {
      var date = new Date(data.birthday);
      const q = Date.now();
      var dateMilli = q - date.getTime();
      var year = Math.floor(dateMilli / 31536000000);

      year > 60
        ? (from_60 = from_60 + 1)
        : year > 35
        ? (from_35_to_60 = from_35_to_60 + 1)
        : year > 21
        ? (from_21_to_35 = from_21_to_35 + 1)
        : year > 15
        ? (from_15_to_21 = from_15_to_21 + 1)
        : year > 5
        ? (from_5_to_15 = from_5_to_15 + 1)
        : (to_5 = to_5 + 1);
    });

    const obj_to_5 = { age: to_5, title: 'до 5 лет' };
    const obj_from_5_to_15 = { age: from_5_to_15, title: 'от 5 до 15' };
    const obj_from_15_to_21 = { age: from_15_to_21, title: 'от 15 до 21' };
    const obj_from_21_to_35 = { age: from_21_to_35, title: 'от 21 до 35' };
    const obj_from_35_to_60 = { age: from_35_to_60, title: 'от 35 до 60' };
    const obj_from_60 = { age: from_60, title: 'более 60 лет' };

    var yearsArr = [];

    yearsArr.push(obj_to_5);
    yearsArr.push(obj_from_5_to_15);
    yearsArr.push(obj_from_15_to_21);
    yearsArr.push(obj_from_21_to_35);
    yearsArr.push(obj_from_35_to_60);
    yearsArr.push(obj_from_60);

    this.setState({
      arr_stat_age: yearsArr,
      open_1: true,
      open_2: false,
      open_3: false,
      open_4: false,
      open_5: false,
      open_6: false,
      open_7: false,
    });
  };

  handlePol = async () => {
    const res = await getStatByMW.getStatByMW();

    var men = 0;
    var women = 0;

    res.map((data) => {
      data.gender == 0 ? (men = men + 1) : (women = women + 1);
    });

    const data = { men: men, women: women };

    var genderArr = [];

    genderArr.push(data);

    this.setState({
      arr_stat_gender: genderArr,
      open_1: false,
      open_2: true,
      open_3: false,
      open_4: false,
      open_5: false,
      open_6: false,
      open_7: false,
    });
  };

  handleGenre = async () => {
    const res = await getStatByGenre.getStatByGenre();
    this.setState({
      arr_stat_genre: res,
      open_1: false,
      open_2: false,
      open_3: true,
      open_4: false,
      open_5: false,
      open_6: false,
      open_7: false,
    });
  };

  handleTitle = async () => {
    const res = await getStatByTitle.getStatByTitle();
    this.setState({
      arr_stat_title: res,
      open_1: false,
      open_2: false,
      open_3: false,
      open_4: true,
      open_5: false,
      open_6: false,
      open_7: false,
    });
  };

  handleOtrasli = async () => {
    const res = await getStatyOtrasli.getStatyOtrasli();
    this.setState({
      arr_stat_title: res,
      open_1: false,
      open_2: false,
      open_3: false,
      open_4: false,
      open_5: true,
      open_6: false,
      open_7: false,
    });
  };

  handleStatuses = async () => {
    const res = await getStatyStatuses.getStatyStatuses();
    this.setState({
      arr_stat_title: res,
      open_1: false,
      open_2: false,
      open_3: false,
      open_4: false,
      open_5: false,
      open_6: true,
      open_7: false,
    });
  };

  handleLangs = async () => {
    const res = await getStatByLangs.getStatByLangs();
    this.setState({
      arr_stat_title: res,
      open_1: false,
      open_2: false,
      open_3: false,
      open_4: false,
      open_5: false,
      open_6: false,
      open_7: true,
    });
  };
  render() {
    const headerItemsContent_1 = [
      { title: 'Возраст', data: 'title' },
      { title: 'Количество', data: 'age' },
    ];

    const headerItemsContent_2 = [
      { title: 'Мужчины', data: 'men' },
      { title: 'Женщины', data: 'women' },
    ];

    const headerItemsContent_3 = [
      { title: 'Жанр', data: 'title' },
      { title: 'Количество', data: 'by_g_number' },
    ];

    const headerItemsContent_4 = [
      { title: 'Заглавие', data: 'title' },
      { title: 'Количество', data: 'by_t_number' },
    ];

    const headerItemsContent_5 = [
      { title: 'Отрасль', data: 'title' },
      { title: 'Количество', data: 'by_g_number' },
    ];
    const headerItemsContent_6 = [
      { title: 'Статус', data: 'title' },
      { title: 'Количество', data: 'by_g_number' },
    ];

    const headerItemsContent_7 = [
      { title: 'Язык', data: 'title' },
      { title: 'Количество', data: 'by_g_number' },
    ];
    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Статистика читателей</h1>
          </div>

          <div className='searchSved'>
            <div className='buttons-container'>
              <Button
                text='Отчет по возрасту'
                className='btn_library_del'
                onClick={this.handleAge}
              />
              <Button
                text='Отчет по половому признаку'
                className='btn_library_del'
                onClick={this.handlePol}
              />
              <Button
                text='Отчет по жанрам литературы'
                className='btn_library_del'
                onClick={this.handleGenre}
              />
              <Button
                text='Отчет по названиям литературы'
                className='btn_library_del'
                onClick={this.handleTitle}
              />
              <Button
                text='Отчет по отраслям литературы'
                className='btn_library_del'
                onClick={this.handleOtrasli}
              />
              <Button
                text='Отчет по социальному статусу читателей'
                className='btn_library_del'
                onClick={this.handleStatuses}
              />

              <Button
                text='Отчет по языкам литературы'
                className='btn_library_del'
                onClick={this.handleLangs}
              />
            </div>
          </div>

          <div className='monitor' style={{ marginTop: '40px' }}>
            {this.state.open_1 && (
              <CommonWrapperTable
                mainArr={this.state.arr_stat_age}
                headersArr={headerItemsContent_1}
              />
            )}
            {this.state.open_2 && (
              <CommonWrapperTable
                mainArr={this.state.arr_stat_gender}
                headersArr={headerItemsContent_2}
              />
            )}
            {this.state.open_3 && (
              <CommonWrapperTable
                mainArr={this.state.arr_stat_genre}
                headersArr={headerItemsContent_3}
              />
            )}
            {this.state.open_4 && (
              <CommonWrapperTable
                mainArr={this.state.arr_stat_title}
                headersArr={headerItemsContent_4}
              />
            )}
            {this.state.open_5 && (
              <CommonWrapperTable
                mainArr={this.state.arr_stat_title}
                headersArr={headerItemsContent_5}
              />
            )}

            {this.state.open_6 && (
              <CommonWrapperTable
                mainArr={this.state.arr_stat_title}
                headersArr={headerItemsContent_6}
              />
            )}

            {this.state.open_7 && (
              <CommonWrapperTable
                mainArr={this.state.arr_stat_title}
                headersArr={headerItemsContent_7}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(ReadersStat);
