import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import { Link } from 'react-router-dom';

// import history from '../components/history'

// import FilterPanel from '../../components/filter-panel';
import Pagination from '../../../components/pagination';
import FormBookAdd from './formBookAdd'
import Loader from '../../../components/loader'

import './style.css'

import arrow_sort from '../../../assets/icon/arrow-sort.png'

class Pravila extends Component {

    state = {
        choiceItemPage: 'ebook',
        currentPagePag: '',
    }

    getData = async (e) => {
        await this.setState({ choiceItemPage: e.target.dataset.set })
        await this.props.getData('prav', 'prav')
    }

    pagination = async (pageNum) => {
        await this.props.paginationPages({ item: "prav", skip: pageNum, page: 'prav' });
        await this.setState({ currentPagePag: pageNum });
    }

    sortItemBook = async (type) => {
        await this.props.sortItem('База книг', type, this.state.sort, 'bookAdd');
        await this.setState({ sort: !this.state.sort, sortPag: true, sortType: type })
    }

    selectItem = async (tab, id) => await this.props.selectItem(tab, id)

    async componentDidMount() {
        await this.props.getData('prav', 'prav')
    }

    render() {
        const choiceItem = [{ title: 'Правила', dataset: 'еbook' }, { title: 'Добавить', dataset: 'add' }];

        const headerItemsContent = [{ title: 'Правила', data: 'name' }];

        const url = this.props.match.path.slice(1)

        let filterPravila = url === 'pravila' ? '1' : '2'

        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        {url === 'pravila'
                            ?
                            <h1>Формирование правил автоматизированного обслуживания</h1>
                            :
                            <h1>Информация, определяющая правила автоматизированного заказа и выдачи книг</h1>}
                    </div>

                    <div className='choice-type'>
                        {choiceItem.map((el, i) =>
                            el === 'Добавить'
                                ?
                                <h3 data-set={el.dataset} key={i} onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}>{el.title}</h3>
                                :
                                <h3 data-set={el.dataset} key={i} onClick={this.getData}>{el.title}</h3>)}
                    </div>

                    {this.state.choiceItemPage === 'add'
                        ?
                        <FormBookAdd match={this.props.match} />
                        :
                        <>

                            {/* <FilterPanel /> */}

                            <div className='book-items-content'>
                                <div className='book-items-header'>
                                    {headerItemsContent.map((el, i) => {
                                        return (
                                            <div key={i}>
                                                <p>{el.title}</p>
                                                {/* <img src={arrow_sort} alt="profile" data-type={el.data} onClick={(e) => this.sortItemBook(e.target.dataset.type)} /> */}
                                            </div>
                                        )
                                    })}
                                </div>

                                {this.props.loader ?
                                    <Loader />
                                    :
                                    this.props.prav.length === 0
                                        ?
                                        <div className='search_output_null'>
                                            <h3 >Раздел пуст</h3>
                                        </div>
                                        :
                                        this.props.prav.filter(el => el.prav === filterPravila).map((el, i) => {
                                            let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
                                            return (
                                                <div className={oddEven} key={el.id}>
                                                    <div><p>{el.text}</p></div>
                                                </div>
                                            )
                                        })
                                }
                            </div>

                            {/* <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} /> */}

                        </>}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(Pravila);