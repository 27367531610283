import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../../redux/action';

// import { SmartSreach } from '../../api';

import Button from '../../../../components/button';
// import Modal from '../modal'

// import arrow_add from '../../assets/icon/arrow-menu.png'
// import close from '../../assets/icon/modal-genre-close.png'

class FormBookAdd extends Component {
 state = {
  typeItem: 'book',
  selectedItem: true,
  showModal: false,

  zhanr: false,
  zharnInput: '',
  newZhanr: '',

  addbook: {
   avtor: '',
   titul: '',
   dates_life: '',
   avtor_other: '',
   name: '',
   kol_sim: '',
   num_chasti: '',
   name_chasti: '',
   sved_zaglov: '',
   sved_otvets: '',
   par_name_book: '',
   place_izdn: '',
   sved_izdn: '',
   izdatel: '',
   god: '',
   val: '',
   fiz_har: '',
   detskoe_izd: '',
   seria: '',
   podseria: '',
   prim: '',
   ISBN: '',
   price: '',
   kol: '',
   stoit: '',
   tirazh: '',
   zhanr: '',
   kraeved: '',
   kl_slova: '',
   index_bbk: '',
   index_udk: '',
   avtor_znak: '',
   invert_num: '',
   sigla: '',
   date_vvoda: '',
   osn_language: '',
   ost_language: '',
   part_post: '',
   istok_post: '',
   vid_dok: '',
   sluzh_otm: '',
   image: '',
   lib_name: '',
   krat_sod: '',
  },
 };

 showModal = () => this.setState({ showModal: true });

 closeModal = () => this.setState({ showModal: false });

 handleChange = async ({ target }) => await this.setState({ [target.name]: target.value });

 handleChangeModal = async ({ target }) => {
  if (target.name === 'image') {
   this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.files[0] } });
  } else {
   this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.value } });
  }
 };

 handleSubmit = async (e) => {
  await e.preventDefault();

  // for (let key in this.state.addbook) {
  //     this.state.addbook[key] = this.state.addbook[key];
  // }

  const formData = await new FormData();
  for (const key in this.state.addbook) {
   await formData.append(
    `${key}`,
    this.state.addbook[key] === '' || this.state.addbook[key] === null ? '' : this.state.addbook[key]
   );
  }
  const res = await fetch('https://smart-pvl.kz/Api/RedBook2.php', { method: 'POST', body: formData });
  await res.json();

  this.setState({
   addbook: Object.keys(this.state.addbook).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
  });
 };

 componentDidMount() {
  this.setState({ addbook: { ...this.props.item } });
 }

 render() {
  const atrFormAddBook = [
   { title: 'Автор', name: 'avtor', type: 'text' },
   { title: 'Титул 1 автора', name: 'titul', type: 'text' },
   { title: 'Даты жизни 1 автора', name: 'dates_life', type: 'text' },
   // { title: 'Другой автор', name: 'avtor_other' },
   { title: 'Заглавие', name: 'name', type: 'text' },
   { title: 'Количество незначащих символов заглавления', name: 'kol_sim', type: 'text' },
   { title: 'Номер части', name: 'num_chasti', type: 'text' },
   { title: 'Название части', name: 'name_chasti', type: 'text' },
   { title: 'Сведение, относящиеся к заглавию', name: 'sved_zaglov', type: 'text' },
   { title: 'Сведение об ответственности', name: 'sved_otvets', type: 'text' },
   { title: 'Параллельное название', name: 'par_name_book', type: 'text' },
   { title: 'Место издания', name: 'place_izdn', type: 'text' },
   { title: 'Сведение об издании', name: 'sved_izdn', type: 'text' },
   { title: 'Издательство', name: 'izdatel', type: 'text' },
   { title: 'Год издания', name: 'god', type: 'text' },
   { title: 'Объем', name: 'val', type: 'text' },
   { title: 'Физические характеристики, иллюстрации, переплет и т.п.', name: 'fiz_har', type: 'text' },
   { title: 'Детское издание до 15 лет', name: 'detskoe_izd', type: 'checkbox' },
   { title: 'Серия', name: 'seria', type: 'text' },
   { title: 'Подсерия', name: 'podseria', type: 'text' },
   { title: 'Примечание', name: 'prim', type: 'text' },
   { title: 'ISBN', name: 'ISBN', type: 'text' },
   { title: 'Цена', name: 'price', type: 'text' },
   { title: 'Количество', name: 'kol', type: 'text' },
   { title: 'Стоимость', name: `${+this.state.addbook.price * +this.state.addbook.kol}`, type: 'other' },
   { title: 'Тираж', name: 'tirazh', type: 'text' },
   { title: 'Тематическая рубрика', name: 'zhanr', type: 'text' },
   { title: 'Отрасль', name: 'otrasli', type: 'select', option: this.props.other_data1 },
   { title: 'Краеведение', name: 'kraeved', type: 'text' },
   { title: 'Ключевые слова', name: 'kl_slova', type: 'text' },
   { title: 'Индекс ББК', name: this.state.addbook.index_bbk, type: 'other' },
   { title: 'Индекс УДК', name: this.state.addbook.index_udk, type: 'other' },
   { title: 'Авторский знак', name: 'avtor_znak', type: 'text' },
   { title: 'Инвентарный номер', name: 'invert_num', type: 'text' },
   { title: 'Сигла', name: 'sigla', type: 'text' },
   { title: 'Дата ввода', name: 'date_vvoda', type: 'text' },
   { title: 'Основной язык ввода', name: 'osn_language', type: 'text' },
   { title: 'Другие языки издания', name: 'ost_language', type: 'text' },
   { title: 'Партия поступления', name: 'part_post', type: 'text' },
   { title: 'Источник поступления', name: 'istok_post', type: 'text' },
   { title: 'Вид документа', name: 'vid_dok', type: 'text' },
   { title: 'Служебные отметки', name: 'sluzh_otm', type: 'text' },
   // { title: 'Выбор изображения', name: 'image', type: 'text' },
   { title: 'Библиотека', name: 'lib_name', type: 'select', option: this.props.library_id.map((el) => el.name) },
   { title: 'Краткое содержание', name: 'krat_sod', type: 'textarea' },
   // { title: 'Отрасли', name: 'otrasli', type: 'zhanr', plus: true },
   { title: 'Полка', name: 'shelf', type: 'text' },
   { title: 'Ряд', name: 'row', type: 'text' },
   { title: 'Изображение', name: 'image', type: 'file' },
  ];
  return (
   <div div className='form-add'>
    <form className='form-add-wrapper' onSubmit={this.handleSubmit}>
     <div className='form-add-book__book'>
      {atrFormAddBook.map((el, i) => {
       switch (el.type) {
        case 'select': {
         return (
          <div className='search-filter-book_book' key={i}>
           <p>{el.title}</p>
           <div className='search-filter-book-rubrik'>
            <select name={el.name} onChange={this.handleChangeModal} value={this.state.addbook[el.name]}>
             <option hidden>Выберите данные</option>
             {el.option.map((item, i) => {
              // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
              return <option key={i}>{item}</option>;
             })}
            </select>
            {/* <span className='plus' onClick={() => this.modalAddItem(el)}>+</span> */}
           </div>
          </div>
         );
        }
        case 'zhanr': {
         return (
          <div className='search-filter-book__book' key={i}>
           <p>{el.title}</p>
           <div className='rubrika'>
            <input
             type='text'
             name={el.name}
             className='input-rubrika'
             autoComplete='off'
             value={this.state.addItems[el.name]}
             placeholder='Обязательные поля ввода'
             onChange={(e) => this.searchItem(e, el.plus)}
            />
            <div className='searchZhanr'>
             {this.state.addItems[el.name + 'Items']?.slice(0, 5).map((el, i) => {
              return (
               <div>
                <p key={i} onClick={() => this.selectedItem(el)}>
                 {el.сolumn}
                </p>
               </div>
              );
             })}
             {/* {this.state.zharnInput.length >= 3 && this.state.zhanr.length > 5 ? <p>.....</p> : null} */}
            </div>
            {el.plus ? (
             <p title={`Добавить ${el.title}`} data-type='book' onClick={() => this.showModal(el)}>
              +
             </p>
            ) : null}
           </div>
          </div>
         );
        }
        case 'other': {
         return (
          <div className='search-filter-book__book' key={i}>
           <p>{el.title}</p>
           <p value={this.state.addbook[el.name]}>{el.name}</p>
          </div>
         );
        }
        case 'checkbox': {
         return (
          <div className='search-filter-book__book' key={i}>
           <p>{el.title}</p>
           <input
            type={el.type}
            name={el.name}
            checked={this.state.addbook[el.name]}
            onChange={this.handleChangeModal}
           />
          </div>
         );
        }
        case 'file': {
         return (
          <div className='search-filter-book__book' key={i}>
           <p>{el.title}</p>
           <input
            type={el.type}
            name={el.name}
            checked={this.state.addbook[el.name]}
            onChange={this.handleChangeModal}
           />
          </div>
         );
        }
        case 'textarea': {
         return (
          <div className='search-filter-book__book-textarea' key={i}>
           <p>{el.title}</p>
           <textarea
            name={el.name}
            onChange={this.handleChangeModal}
            autoComplete='off'
            value={this.state.addbook[el.name]}
            placeholder='Обязательные поля ввода'
           />
          </div>
         );
        }
        default: {
         return (
          <div className='search-filter-book__book' key={i}>
           <p>{el.title}</p>
           <input
            type='text'
            name={el.name}
            autoComplete='off'
            value={this.state.addbook[el.name]}
            placeholder='Обязательные поля ввода'
            onChange={this.handleChangeModal}
           />
          </div>
         );
        }
       }
      })}
     </div>

     <div className='form-add-img-btn'>
      <Button className='btn_add_book' text='Редактировать' />
     </div>
    </form>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
