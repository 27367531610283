import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, cleanAnyState } from '../redux/action';

import { SmartSreach, getAllBooksByPageAndSort, getAllBooksByAuthorNameInvenNumber } from '../api';

import history from '../components/history';

import CommonWrapperTable from '../components/tables/CommonWrapperTable/CommonWrapperTable';
import Pagination from '../components/pagination';
import FormBookAdd from '../components/formBookAdd';
import Loader from '../components/loader';
import debounce from '../utils/debounce';

import './style.css';

import Button from '../components/button';

class BookOut extends Component {
 state = {
  choiceItemPage: 'book',

  currentPagePag: '',

  sort: false,
  sortType: '',
  sortPag: false,

  btnType: 'все',

  scanerCode: null,

  lib_name: '',

  tempSelectAvtor: '',

  books: [],
  totalPages: '',
  searchInput: '',
  searchType: '',
 };

 pagination = (pageNum) => {
  if (this.state.searchType == 'by_title') {
   this.scanerRequest2(this.state.searchInput, pageNum);
  } else if (this.state.searchType == 'by_author') {
   this.scanerRequest1(this.state.searchInput, pageNum);
  } else if (this.state.searchType == 'by_invent_number') {
   this.scanerRequest3(this.state.searchInput, pageNum);
  }
  this.setState({ currentPagePag: pageNum });
 };

 sortItemBook = async (type) => {
  await this.props.sortItem('База книг', type, this.state.sort, 'bookAdd');
  this.setState({ sort: !this.state.sort, sortPag: true, sortType: type });
 };

 selectItem = async (item) => {
  await this.props.selectItem(item.id);
  history.push(`/book_add/${item.id}`);
 };

 EditItem = async (item) => {
  await this.props.selectItem(item.id);
  history.push(`/book_edit/${item.id}`);
 };

 selectedActor = async (avtor) => {
  this.setState({ tempSelectAvtor: avtor });
  const formData = new FormData();
  formData.append('avtor', avtor);
  // formData.append('lib_id', '');
  const res = await fetch(`https://smart-pvl.kz/Api/search/getAvtor.php`, {
   method: 'POST',
   headers: {
    AUTH: localStorage.getItem('tokenLibrary'),
   },
   body: formData,
  });
  const jsonres = await res.json();
  console.log(jsonres);
  // const json = await SmartSreach.SmartSreach({
  //  col: '*',
  //  tab: 'books',
  //  id_name: 'token',
  //  id_val: this.state.tempSelectAvtor,
  //  shtrih: true,
  //  searchAll: this.state.lib_name,
  //  lang: this.state.btnType,
  // });
  await this.props.setDate(jsonres, 'bookAdd', '');
  // await this.props.cleanAnyState('searchAvtorsAddBook');
  // await localStorage.setItem('avtorS', avtor);
 };

 separator_1 = (e) => this.scanerRequest1(e.target.value, 1);
 scanerRequest1 = async (e, page) => {
  if (e !== '') {
   const books = await getAllBooksByAuthorNameInvenNumber.getAllBooksByAuthorNameInvenNumber(
    page,
    'by_title',
    'asc',
    'by_author',
    e
   );
   this.props.setDate(books.books, 'bookAdd', '');

   this.setState({
    books: books.books,
    totalPages: Math.round(books.total_pages),
    searchInput: e,
    searchType: 'by_author',
   });
  } else {
   const books = await getAllBooksByPageAndSort.getAllBooksByPageAndSort(1, 'by_title', 'asc');
   this.props.setDate(books.books, 'bookAdd', '');
   this.setState({ books: books.books, totalPages: 0 });
  }
 };

 separator_2 = (e) => this.scanerRequest2(e.target.value, 1);
 scanerRequest2 = async (e, page) => {
  if (e !== '') {
   const books = await getAllBooksByAuthorNameInvenNumber.getAllBooksByAuthorNameInvenNumber(
    page,
    'by_title',
    'asc',
    'by_title',
    e
   );
   await this.props.setDate(books.books, 'bookAdd', '');
   this.setState({
    books: books.books,
    totalPages: Math.round(books.total_pages),
    searchInput: e,
    searchType: 'by_title',
   });
  } else {
   const books = await getAllBooksByPageAndSort.getAllBooksByPageAndSort(1, 'by_title', 'asc');
   await this.props.setDate(books.books, 'bookAdd', '');
   this.setState({
    books: books.books,
    totalPages: Math.round(books.total_pages),
    searchInput: e,
    searchType: 'by_title',
   });
  }
 };

 separator_3 = (e) => this.scanerRequest3(e.target.value, 1);
 scanerRequest3 = async (e, page) => {
  if (e !== '') {
   const books = await getAllBooksByAuthorNameInvenNumber.getAllBooksByAuthorNameInvenNumber(
    page,
    'by_title',
    'asc',
    'by_invent_number',
    e
   );
   await this.props.setDate(books.books, 'bookAdd', '');
   this.setState({
    books: books.books,
    totalPages: Math.round(books.total_pages),
    searchInput: e,
    searchType: 'by_invent_number',
   });
  } else {
   const books = await getAllBooksByPageAndSort.getAllBooksByPageAndSort(1, 'by_title', 'asc');
   await this.props.setDate(books.books, 'bookAdd', '');
   this.setState({
    books: books.books,
    totalPages: Math.round(books.total_pages),
    searchInput: e,
    searchType: 'by_invent_number',
   });
   this.setState({
    books: books.books,
    totalPages: Math.round(books.total_pages),
    searchInput: e,
    searchType: 'by_invent_number',
   });
  }
 };

 handleChange = ({ target }) => {
  this.setState({ [target.name]: target.value });
 };

 InvNumSplit = (target) => {
  console.log(target);

  if (target !== null) {
   if (typeof target !== 'undefined') {
    const splited = target.split(',');
    console.log(splited);
    return splited;
   }
  } else {
   return null;
  }
 };
 //смена каталогов книг
 typeCatalogBook = async (e) => {
  const btnType = e.target.textContent;
  this.setState({ btnType: e.target.textContent });
  const json = await SmartSreach.SmartSreach({
   col: '*',
   tab: 'books',
   id_name: 'name',
   id_val: this.state.tempSelectAvtor,
   shtrih: true,
   lang: btnType,
   skip: 0,
   searchAll: this.state.lib_name,
  });
  await this.props.setDate(json, 'bookAdd', 'bookWithSeacrch');
  await localStorage.setItem('catalogS', btnType);
 };

 //отображение книг выбранной библиотеки и отображение книг всех библиотек
 choiceLibrary = async (searchAll) => {
  if (searchAll === 'All') {
   const json = await SmartSreach.SmartSreach({
    col: '*',
    tab: 'books',
    id_name: 'name',
    id_val: this.state.tempSelectAvtor,
    searchAll: 'All',
    shtrih: true,
    lang: this.state.btnType,
   });
   await this.props.setDate(json, 'bookAdd', 'bookWithSeacrch');
   this.setState({ lib_name: 'All' });
   await localStorage.setItem('catalogS', searchAll);
  } else {
   const json = await SmartSreach.SmartSreach({
    col: '*',
    tab: 'books',
    id_name: 'name',
    id_val: this.state.tempSelectAvtor,
    searchAll,
    shtrih: true,
    lang: this.state.btnType,
   });
   await this.props.setDate(json, 'bookAdd', 'bookWithSeacrch');
   await localStorage.setItem('libNameS', searchAll);
  }
 };

 async componentDidMount() {
  // await this.props.getData('other_data', 'other_data');
  const books = await getAllBooksByPageAndSort.getAllBooksByPageAndSort(1, 'by_title', 'asc');
  console.log(books);
  console.log(books.books);
  await this.props.setDate(books.books, 'bookAdd', '');

  this.setState({
   books: books.books,
   totalPages: Math.round(books.total_pages),
   searchType: 'by_title',
  });
 }

 render() {
  const url = this.props.match.path.slice(1);

  const choiceItem = [
   { title: 'Книги', dataset: 'book' },
   { title: 'Добавить', dataset: 'add' },
  ];

  const buttons = [
   { title: 'Перейти к ресурсу', event: (e) => this.selectItem(e) },
   { title: 'Редактировать', event: (e) => this.EditItem(e) },
  ];

  const headerItemsContent = [
   { title: 'Автор', data: 'authors' },
   { title: 'Заглавие', data: 'title' },
   { title: 'Номер части', data: 'part_number' },
   { title: 'Инвентарные номера вашей библиотеки', data: 'inventory_numbers_bookAdd' },
   { title: 'Библиотеки', data: 'libraries' },
   { title: 'Доступ', data: 'buttons_by_accept_serv' },
  ];

  const btnTypeCatalog = [{ title: 'қазақша' }, { title: 'русский' }, { title: 'другие' }, { title: 'все' }];
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>
       {url === 'komplekt_vvod' ? 'Ввод данных о заказе в регистрационную форму' : 'ЭЛЕКТРОННЫЙ КАТАЛОГ БИБЛИОТЕКИ'}
      </h1>
     </div>
     {this.state.choiceItemPage === 'book' && (
      <div className='filter-serach'>
       <div className='smart-search'>
        <input
         type='text'
         placeholder='Искать по автору'
         onChange={debounce(this.separator_1, 900)}
         //  value={this.state.tempSelectAvtor}
        />
        <div style={{ marginTop: '15px' }}></div>
        <input
         type='text'
         placeholder='Искать по названию'
         onChange={debounce(this.separator_2, 900)}
         //  value={this.state.tempSelectAvtor}
        />
        <div style={{ marginTop: '15px' }}></div>

        {/* <div className='searchAvtor-wrapper'>
{this.props.searchAvtorsAddBook.length
? this.props.searchAvtorsAddBook.map((el, i) => (
<span key={i} className='searchAvtor' onClick={() => this.selectedActor(el)}>
{el}
</span>
))
: null}
</div> */}
       </div>
       {this.props.lib_name === 'Областная библиотека' && (
        <>
         <div className='search-filter-book-rubrik'>
          <select name='lib_name' onChange={this.handleChange} value={this.state.lib_name}>
           <option hidden>Выберите библиотеку</option>
           {this.props.library_id.map((item) => {
            return <option key={item.id}>{item.name}</option>;
           })}
          </select>
          <Button
           text='Отобразить книги данной библиотеки'
           className='btn_library_del wm'
           onClick={() => this.choiceLibrary(this.state.lib_name)}
          />
          <Button
           text='Отобразить книги всех библиотек'
           className='btn_library_edit'
           onClick={() => this.choiceLibrary(this.state.lib_name)}
          />
         </div>
         <div className='type-catalog-books'>
          <p>Выбор каталога:</p>
          {btnTypeCatalog.map((el, i) => (
           <Button text={el.title} key={i} className='type-category-book mr' onClick={this.typeCatalogBook} />
          ))}
         </div>
        </>
       )}
      </div>
     )}

     {this.state.choiceItemPage === 'add' ? (
      <FormBookAdd />
     ) : (
      <>
       <div className='book-items-content'>
        <CommonWrapperTable mainArr={this.state.books} headersArr={headerItemsContent} buttons={buttons} />
       </div>
       {this.state.totalPages > 1 && (
        <Pagination pageCount={this.state.totalPages} paginationPages={(pageNum) => this.pagination(pageNum)} />
       )}
      </>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, cleanAnyState };

export default connect(mapStateToProps, mapDispatchToProps)(BookOut);
