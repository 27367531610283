import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import Chart from '../../../components/charts/horizontal_bar';

import {
 getGeneralByGivenBooks_Staff,
 getGeneralByReturnBooks_Staff,
 getGeneralByGenre,
 getStatByRoles,
} from '../../../api';
import toast from 'react-hot-toast';

class AnalitStaff extends Component {
 state = {
  arr_stat: [],

  titlesArr: [],
  numbersArr: [],

  open_1: false,
  open_2: false,
  open_2: false,
  open_2: false,

  date1: '',
  date2: '',
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  if (this.state.date1 && this.state.date2) {
   toast.success('Можете выбрать тип отчета');
  } else {
   toast.error('Некорректно выбранный период');
  }
 };

 handleGiven = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByGivenBooks_Staff.getGeneralByGivenBooks_Staff({
    start: this.state.date1,
    end: this.state.date2,
   });

   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.userName);
    numbersArr.push(data.by_bh_number);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: true,
    open_2: false,
    open_3: false,
    open_4: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleReturn = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByReturnBooks_Staff.getGeneralByReturnBooks_Staff({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.userName);
    numbersArr.push(data.by_bh_number);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: true,
    open_3: false,
    open_4: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleRoles = async () => {
  const res = await getStatByRoles.getStatByRoles({});
  var titlesArr = [];
  var numbersArr = [];
  res.map((data) => {
   titlesArr.push(data.title);
   numbersArr.push(data.by_dt_number);
  });

  this.setState({
   titlesArr: titlesArr,
   numbersArr: numbersArr,
   open_1: false,
   open_2: false,
   open_3: true,
   open_4: false,
  });
 };

 render() {
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Сотрудники</h1>
     </div>
     <div>
      <SincetoCalendar
       selectDate={this.selectDate}
       state1={this.state.date1}
       state2={this.state.date2}
       handleDate={this.handleDate}
       type='date'
      />
     </div>
     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Отчет по выдаче литературы' className='btn_library_del' onClick={this.handleGiven} />
       <Button text='Отчет по возвратам литературы' className='btn_library_del' onClick={this.handleReturn} />
       <Button text='Отчет по Ролям и Доступу сотрудников' className='btn_library_del' onClick={this.handleRoles} />
      </div>
     </div>
     <div className='monitor' style={{ marginTop: '40px' }}>
      {this.state.open_1 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_2 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_3 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_4 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(AnalitStaff);
