import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';
import { getDataStat4 } from '../../../api';

import Loader from '../../../components/loader';

import '../otrasl_read/style.css';

class DocVidRead extends Component {
 state = {
  statData: [],
 };

 async componentDidMount() {
  // const stat = await getDataStat4.getDataStat4('qwe');
  // this.setState({ statData: stat });
 }

 checkData = (arr, name) => {
  //   console.log(arr, name);
  let res = arr.find((i) => i.name_ru === name);
  typeof res == 'undefined' ? (res = '0') : console.info();
  return res;
 };

 render() {
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Формирование статистики по виду документа</h1>
     </div>
     {this.state.statData ? (
      <div className='monitor'>
       <div className='colunm'>
        <div>
         <div className='headerRead'>
          <p>Виды документов</p>
         </div>
         <div className='type'>
          <p>
           Книга -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Книга').amount
              ? this.checkData(this.state.statData, 'Книга').amount
              : '0'
            : '0'}
          </p>
          <p>
           Брошюра -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Брошюра').amount
              ? this.checkData(this.state.statData, 'Брошюра').amount
              : '0'
            : '0'}
          </p>
          <p>
           Редкий фонд -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Редкий фонд').amount
              ? this.checkData(this.state.statData, 'Редкий фонд').amount
              : '0'
            : '0'}
          </p>
          <p>
           Электронное издание -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Электронное издание').amount
              ? this.checkData(this.state.statData, 'Электронное издание').amount
              : '0'
            : '0'}{' '}
          </p>
          <p>
           Аудио/видео издание -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Аудио/видео издание').amount
              ? this.checkData(this.state.statData, 'Аудио/видео издание').amount
              : '0'
            : '0'}
          </p>
          <p>
           Автореферат -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Автореферат').amount
              ? this.checkData(this.state.statData, 'Автореферат').amount
              : '0'
            : '0'}
          </p>
          <p>
           Газета -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Газета').amount
              ? this.checkData(this.state.statData, 'Газета').amount
              : '0'
            : '0'}
          </p>
          <p>
           Журнал -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Журнал').amount
              ? this.checkData(this.state.statData, 'Журнал').amount
              : '0'
            : '0'}
          </p>
          <p>
           Др.литература -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Др.литература').amount
              ? this.checkData(this.state.statData, 'Др.литература').amount
              : '0'
            : '0'}
          </p>
         </div>
        </div>
        <div></div>
       </div>
      </div>
     ) : (
      <Loader />
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(DocVidRead);
