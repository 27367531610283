import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../components/modal';
import toast from 'react-hot-toast';
import Button from '../components/button';

const PartAddedPage = () => {
 const url = window.location.pathname.slice(20);
 const [PartiaGetData, setPartiaGetData] = useState();
 const [ShowModal, setShowModal] = useState(false);
 const [onBoardClick, setonBoardClick] = useState(false);

 const closeModal = () => setShowModal(false);

 const GetData = async () => {
  const formData = new FormData();
  formData.append('BO', url);
  formData.append('method', 'get');

  const res = await fetch('https://smart-pvl.kz/Api/BO.php', {
   method: 'POST',
   headers: {
    AUTH: localStorage.getItem('tokenLibrary'),
   },
   body: formData,
  });
  const json = await res.json();
  console.log(json);
  setPartiaGetData(json);
 };

 useEffect(() => {
  GetData();
 }, []);
 return (
  <div>
   <Modal show={ShowModal} closeModalEvent={() => setonBoardClick(true)}>
    <div className='st12'>
     <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
      Окно заполнения полей Библиографического описания
     </div>
     <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
      {/* <Button className='st3' text='Сохранить' onClick={() => closeModal()} /> */}
      <Button className='st3' text='Закрыть' onClick={() => closeModal()} />
     </div>
    </div>
   </Modal>

   {!PartiaGetData
    ? null
    : PartiaGetData.map((i, j) => (
       <div style={{ textAlign: 'center', marginTop: '15px' }} onClick={() => setShowModal(true)}>
        Библиографическое описание № {j + 1}
        <div
         style={{ cursor: 'pointer', padding: '5px', height: '75px', background: '#a5c3f5', marginTop: '0px' }}
         className='st2'
        >
         <div className='st14'>
          <div className='st15'>{i.avtor}</div>
          <div className='st15'>{i.name}</div>
          <div className='st15'>{i.god}</div>
         </div>
        </div>
       </div>
      ))}
  </div>
 );
};

export default PartAddedPage;
