import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

// import history from '../components/history'
import { SearchMarkBook, GetDataMarc } from '../../../api';
import debounce from '../../../utils/debounce';

import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import BookFilesMarc from '../BookFilesMarc/BookFilesMarc';
import toast from 'react-hot-toast';

class Marc extends Component {
  state = {
    choiceItem: 'download',

    currentPagePag: '',

    sort: false,
    sortType: '',
    sortPag: false,
  };

  choiseItem = async (item) => {
    // await this.props.setDate([], 'obrabotka', 'obrabotka');
    //   if (item !== 'upload') {
    this.setState({ choiceItem: item });
    console.log(item);
    //   }
  };
  GetAndFilter = async (item) => {
    const data = await GetDataMarc.GetDataMarc(item.id);
    const names = [
      'Metka_001',
      'Metka_010',
      'Metka_011',
      'Metka_013',
      'Metka_014',
      'Metka_020',
      'Metka_021',
      'Metka_101',
      'Metka_102',
      'Metka_200',
      'Metka_205',
      'Metka_300',
      'Metka_324',
      'Metka_325',
      'Metka_509',
      'Metka_700',
      'Metka_701',
      'Metka_702',
    ];
    var tempArr = [];
    if (data.length !== 0) {
      if (data[0]) {
        for (var i = 0; i < names.length; i++) {
          if (Object.keys(data[0]).includes(names[i])) {
            const tempPart = data[0][names[i]].substring(1, data[0][names[i]].length - 1);
            i == 0
              ? tempPart.length >= 4
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 1
              ? tempPart.length >= 1
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 2
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 3
              ? tempPart.length >= 14
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 4
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 5
              ? tempPart.length >= 10
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 6
              ? tempPart.length >= 10
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 7
              ? tempPart.length >= 15
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 8
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 9
              ? tempPart.length >= 1
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 10
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 11
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 12
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 13
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 14
              ? tempPart.length >= 18
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 15
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 16
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
            i == 17
              ? tempPart.length >= 8
                ? tempArr.push(tempPart)
                : console.info()
              : console.info();
          }
        }
      }
      tempArr.push('_________________________________________________________________________');
      tempArr.push('start');

      tempArr.push('_________________________________________________________________________');
      tempArr.push('001 ## $a (---Identifier---) ## $a' + data.id);
      tempArr.push('010 ## $a (---ISBN---) ## $a' + data.ISBN);
      tempArr.push('010 ## $d (---Price---) ## $d' + data.price);
      tempArr.push('010 ## $9 (---Book_circulation---) ## $9' + data.book_circulation);
      tempArr.push('011 ## $a (---ISSN_number---) no_data');
      tempArr.push('013 ## $a (---ISMN_number---) no_data');
      tempArr.push('013 ## $b (---ISMN_dop---) no_data');
      tempArr.push('013 ## $d (---ISMN_price---) no_data');
      tempArr.push('013 ## $z (---ISMN_data---) no_data');
      tempArr.push('014 ## $a (---Article_ID---) no_data');
      tempArr.push(
        '020 ## $a (---Document_number_in_the_national_bibliographies_Country code---) no_data',
      );
      tempArr.push(
        '020 ## $b (---Document_number_in_the_national_bibliographies_Number---) no_data',
      );
      tempArr.push('021 ## $a (---State_registration_number_Country code---) no_data');
      tempArr.push('021 ## $b (---State_registration_number_Number---) no_data');
      tempArr.push('100 ## $a (---General_processing_data---) no_data');
      tempArr.push(
        '101 # $a1 (---Resource_language_Text_language---) # $a1' +
          data.lngname +
          ' ||| (code:' +
          data.lngcode +
          ') (lngcode_digital:' +
          data.lngcode_digital +
          ')',
      );
      tempArr.push('101 # $a2 (---Resource_language_Intermediate_translation_language---) no_data');
      tempArr.push('101 # $a3 (---Resource_language_Original_language---) no_data');
      tempArr.push('101 # $a4 (---Resource_language_Resume_language---) no_data');
      tempArr.push(
        '101 # $a5 (---Resource_language_Table_of_contents_language---) # $a5' +
          data.lngname +
          ' ||| (code:' +
          data.lngcode +
          ') (lngcode_digital:' +
          data.lngcode_digital +
          ')',
      );
      tempArr.push(
        '102 ## $a (---Country_of_publication_or_production---) ## $a' + data.place_of_publication,
      );
      tempArr.push(
        '200 1# $a (---Title_and_information_about_responsibility_Main_title---) 1# $a' +
          data.title,
      );
      tempArr.push(
        '200 1# $d (---Title_and_information_about_responsibility_Parallel_title---) 1# $d' +
          data.parallel_title,
      );
      tempArr.push(
        '200 1# $e (---Title_and_information_about_responsibility_Information_related_to_the_title---) 1# $e' +
          data.title_information,
      );
      tempArr.push(
        '200 1# $h (---Title_and_information_about_responsibility_Part_designation_and_number---) 1# $h' +
          data.part_number,
      );
      tempArr.push(
        '200 1# $i (---Title_and_information_about_responsibility_Name_of_the_part---) 1# $i' +
          data.part_name,
      );
      tempArr.push(
        '200 1# $v (---Title_and_information_about_responsibility_Volume_designation---) no_data',
      );
      tempArr.push(
        '200 1# $z (---Title_and_information_about_responsibility_The_language_of_the_parallel_title---) no_data',
      );
      tempArr.push(
        '205 ## $a (---Information_about_the_publication_Information_about_the_publication---) ## $a' +
          data.publisher,
      );
      tempArr.push(
        '205 ## $b (---Information_about_the_publication_Additional_information_about_the_publication---) no_data',
      );
      tempArr.push('300 ## $a (---General_notes---) no_data');
      tempArr.push('324 ## $a (---Note_about_the_original_(original)_version---) no_data');
      tempArr.push('325 ## $a (---ISSN---) no_data');
      tempArr.push('011 ## $a (---Note_about_the_copy---) no_data');
      tempArr.push(
        '509 01 #a (---Title-Structured_geographical_or_thematic_name_Geographical/thematic_name_-_the_initial_entry_element---) no_data',
      );
      tempArr.push(
        '509 01 #b (---Title-Structured_geographical_or_thematic_name_Geographical/thematic_name_-_structural_division---) no_data',
      );
      tempArr.push(
        '509 01 #c (---Title-Structured_geographical_or_thematic_name_Identifying_feature---) no_data',
      );
      tempArr.push(
        '509 01 #h (---Title-Structured_geographical_or_thematic_name_Dates---) no_data',
      );
      tempArr.push(
        '509 01 #f (---Title-Structured_geographical_or_thematic_name_Geographical_term---) no_data',
      );
      tempArr.push(
        '509 01 #n (---Title-Structured_geographical_or_thematic_name_Scale---) no_data',
      );
      tempArr.push('700 #1 $a (---Name_of_the_person_-_primary_responsibility---) no_data');
      tempArr.push('701 #1 $a (---Name_of_the_person_-_alternative_responsibility---) no_data');
      tempArr.push('702 #1 $a (---Name_of_the_person_-_secondary_responsibility---) no_data');
      tempArr.push('_________________________________________________________________________');
      tempArr.push('end');
      tempArr.push('_________________________________________________________________________');

      var text = '';
      for (var i = 0; i < tempArr.length; i++) {
        text = text + tempArr[i] + '\r\n';
      }

      console.log(text);
      this.Download('marcdata', text);
    } else {
      toast.error('Данные по книге недостаточны для формирования MARC-данных');
    }
  };

  Download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handleInputTitle = async (e, page = 1) => {
    if (e.target.value.length !== '') {
      const books = await SearchMarkBook.SearchMarkBook(
        page,
        'by_title',
        'asc',
        'by_title',
        e.target.value,
      );
      if (books) {
        await this.props.setDate(books.books, 'marc', '');
        this.setState({
          books: books.books,
          totalPages: Math.round(books.total_pages),
          searchInput: e.target.value,
          searchType: 'by_title',
        });
      }
    } else {
      await this.props.setDate([], 'marc', '');
      this.setState({
        books: [],
        totalPages: 0,
        searchInput: e.target.value,
        searchType: 'by_title',
      });
    }
  };

  async componentDidMount() {
    await this.props.setDate([], 'marc', 'marc');
  }

  render() {
    const choiceItem = [
      { title: 'Поиск в каталоге (выгрузка)', data: 'download' },
      { title: 'Загрузка', data: 'upload' },
    ];

    const buttons = [{ title: 'Выгрузить в MARC формате', event: (e) => this.GetAndFilter(e) }];

    const headerItemsContent = [
      { title: 'Автор', data: 'author' },
      { title: 'Название книги', data: 'title' },
      { title: 'По отраслям знаний', data: 'by_branches_of_knowledge' },
      { title: 'Год издания', data: 'publication_year' },
      { title: 'Цена', data: 'price' },
      { title: 'Издатель', data: 'publisher' },
      { title: 'Объем', data: 'number_of_pages' },
      { title: '', data: 'button' },
    ];

    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Загрузка / Выгрузка библиографических записей в MARC формате</h1>
          </div>
          <div className='choice-type'>
            {choiceItem.map((el, i) => (
              <h3
                style={{ background: el.data == this.state.choiceItem ? '#5b7fbb' : '' }}
                data-set={el.data}
                key={i}
                onClick={(e) => this.choiseItem(e.target.dataset.set)}
              >
                {el.title}
              </h3>
            ))}
          </div>
          {this.state.choiceItem == 'upload' ? (
            <BookFilesMarc />
          ) : (
            <>
              <div className='smart-search' style={{ marginBottom: '20px' }}>
                <input
                  type='text'
                  placeholder='Введите заглавие'
                  onChange={debounce(this.handleInputTitle, 900)}
                />
              </div>
              <div className='book-items-content'>
                <CommonWrapperTable
                  mainArr={this.props.marc}
                  headersArr={headerItemsContent}
                  buttons={buttons}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(Marc);
