import React, { Component } from 'react';

import { connect } from 'react-redux';

import MaskedInput from 'react-maskedinput';

import Button from '../button';
import history from '../history';

import { SmartSreach } from '../../api';
import '../FormPartAdd/style.css';

import toast from 'react-hot-toast';

class FormStaffEdit extends Component {
 state = {
  staff: {
   id: '',

   first_name: '',
   last_name: '',
   middle_name: '',
   date_of_birth: '',
   nation_id: '',
   education_id: '',
   position_id: '',
   general_experience: '',
   library_experience: '',
   gender_id: '',
   iin: '',
   family_status: '',
   passport_number: '',
   address: '',
   phone_number: '',
   lib_name: '',
   password: '',
   roles: [],
   library_id: '',
  },

  specialMassive: [],
  specialNumber: 0,
  specialDeleteMassive: [],

  // data: [{ title: 'Выберите статус', name: 'status', type: 'checkbox', option: [{ item: 'Студент', num: 1 }, { item: 'Школьник', num: 2 }] }]
 };

 ojbectGenerate = (name, value) => {
  const key = name;
  var obj = {};
  obj[key] = value;

  return obj;
 };

 handleChange = async ({ target }) => {
  if (target.type === 'number')
   await this.setState({ staff: { ...this.state.staff, [target.name]: Number(target.value) } });
  else {
   await this.setState({ staff: { ...this.state.staff, [target.name]: target.value.trim() } });
  }
 };
 handleChangeRole = async ({ target }) => {
  var timelessCheck = false;
  this.state.staff.roles.map((item) => (Object.keys(item)[0] == target.id ? (timelessCheck = true) : null));
  if (timelessCheck == true) {
   var timelessArray = this.state.staff.roles;
   timelessArray.map((item) => (Object.keys(item)[0] == target.id ? (item[target.id] = target.value) : console.info()));
   this.setState({ staff: { ...this.state.staff, roles: timelessArray } });
  } else {
   const minorObj = this.ojbectGenerate(target.id, target.value);
   var joined = this.state.staff.roles.concat(minorObj);
   this.setState({ staff: { ...this.state.staff, roles: joined } });
  }
 };

 deleteRoleMassive = (e) => {
  //   console.log(e);
  //   console.log(e.target.id);
  const deleteRoleID = e.target.id;
  var deleteRoleIndex = '';

  var timelessCheck = false;

  for (var i = 0; i < this.state.specialDeleteMassive.length; i++) {
   if (this.state.specialDeleteMassive[i] == deleteRoleID) {
    timelessCheck = true;
    deleteRoleIndex = i;
   }
  }
  if (timelessCheck == true) {
   var timelessArray = this.state.specialDeleteMassive;
   timelessArray.splice(deleteRoleIndex, 1);
   console.log(timelessArray);

   this.setState({ specialDeleteMassive: timelessArray });
  } else {
   var joined = this.state.specialDeleteMassive.concat(deleteRoleID);
   console.log(joined);
   this.setState({ specialDeleteMassive: joined });
  }
  var timelessArray2 = this.state.staff.roles;

  console.log(this.state.staff.roles);
  timelessArray2.splice(deleteRoleID - 1, 1);
  console.log(timelessArray2);
  this.setState({ staff: { ...this.state.staff, roles: timelessArray2 } });
  this.setState({ specialNumber: this.state.specialNumber - 1 });
  var foo = [];
  for (var i = 1; i <= this.state.specialNumber - 1; i++) {
   foo.push(i);
  }
  this.setState({ specialMassive: foo });
  //   console.log(deleteRoleID - 1);
  //   var timelessArray = this.state.staff.roles;
  //    console.log(e);
  //    console.log(timelessArray);
  //    console.log(timelessArray[e - 1]);
  //    console.log(timelessArray[e - 1][e]);
  //    const vas = e - 1;
  //    console.log('test', { vas: timelessArray[e - 1][e] });

  //    let index = timelessArray.findIndex((el) => el.name == timelessArray[e - 1][e]);
  //    console.log(index);
  //    //    timelessArray.splice(e, 1);
 };

 deleteRole = (e) => {
  var timelessArray = this.state.staff.roles;
  var DeleteIndex = '';
  console.log(timelessArray);
  timelessArray.map(
   (item) => (Object.keys(item)[0] == e ? console.log(e, Object.keys(item)[0], item) : console.info())
   //    Object.keys(item)[0] == e ? (DeleteIndex = e - 1) : console.info()
  );
  timelessArray.splice(e - 1, 1);
  console.log(e - 1);

  console.log(timelessArray);
  this.setState({ staff: { ...this.state.staff, roles: timelessArray } });
 };

 changeLibName = (lib) => {
  this.setState({ staff: { ...this.state.staff, library_id: Number(lib.target.value) } });
 };

 checkRoleDelete = (e) => {
  var check = false;
  this.state.specialDeleteMassive.map((k) => (k == e ? (check = true) : null));

  //     check == true ? this.deleteRole(e) : console.info();

  //   if (check == true) {
  //    var timelessArray = this.state.staff.roles;
  //    console.log(e);
  //    console.log(timelessArray);
  //    console.log(timelessArray[e - 1]);
  //    console.log(timelessArray[e - 1][e]);
  //    const vas = e - 1;
  //    console.log('test', { vas: timelessArray[e - 1][e] });

  //    let index = timelessArray.findIndex((el) => el.name == timelessArray[e - 1][e]);
  //    console.log(index);
  //    //    timelessArray.splice(e, 1);
  //    //    this.setState({ staff: { ...this.state.staff, roles: timelessArray } });
  //   }
  return check;
 };

 changeNationName = (e) => {
  this.setState({ staff: { ...this.state.staff, nation_id: Number(e.target.value) } });
 };
 changeEducationName = (e) => {
  this.setState({ staff: { ...this.state.staff, education_id: Number(e.target.value) } });
 };
 changePositionName = (e) => {
  this.setState({ staff: { ...this.state.staff, position_id: Number(e.target.value) } });
 };
 changeFamilynName = (e) => {
  this.setState({ staff: { ...this.state.staff, family_status: Number(e.target.value) } });
 };
 specialMassiveGenerate = () => {
  //   console.log(this.state.specialNumber);
  this.setState({ specialNumber: this.state.specialNumber + 1 });
  var foo = [];
  for (var i = 1; i <= this.state.specialNumber + 1; i++) {
   foo.push(i);
  }
  this.setState({ specialMassive: foo });
  //   console.log(foo);
  //   console.log(this.state.specialMassive);
 };

 handleSubmit = async (e) => {
  e.preventDefault();
  //   console.log(this.state);
  // console.log(this.props.library_id);
  // await this.setState({ phone_number: this.state.phone_number.replace(/\D/g, "") })

  // if (this.state.iin.length !== 12) {
  //     alert('Вы ввели не корректный номер ИИН')
  //     return
  // };
  if (!this.state.staff.password.trim().length) {
   toast.error('Необходимо заполнить поле "Пароль".');
  } else if (!this.state.staff.first_name.trim().length) {
   toast.error('Необходимо заполнить поле "Имя".');
  } else if (!this.state.staff.last_name.trim().length) {
   toast.error('Необходимо заполнить поле "Фамилия".');
  } else if (!this.state.staff.middle_name.trim().length) {
   toast.error('Необходимо заполнить поле "Очество".');
  } else if (!this.state.staff.date_of_birth.trim().length) {
   toast.error('Необходимо заполнить поле "Дата рождения".');
  } else if (!this.state.staff.address.trim().length) {
   toast.error('Необходимо заполнить поле "Адрес".');
  } else if (typeof this.state.staff.nation_id !== 'number') {
   toast.error('Необходимо заполнить поле "Национальность".');
  } else if (typeof this.state.staff.education_id !== 'number') {
   toast.error('Необходимо заполнить поле "Образование".');
  } else if (typeof this.state.staff.position_id !== 'number') {
   toast.error('Необходимо заполнить поле "Должность".');
  } else if (typeof this.state.staff.general_experience !== 'number') {
   toast.error('Необходимо заполнить поле "Общий стаж".');
  } else if (typeof this.state.staff.library_experience !== 'number') {
   toast.error('Необходимо заполнить поле "Библиотечный стаж".');
  } else if (typeof this.state.staff.family_status !== 'number') {
   toast.error('Необходимо заполнить поле "Семейное положение".');
  } else if (!this.state.staff.phone_number.trim().length) {
   toast.error('Необходимо заполнить поле "Номер телефона".');
  } else if (typeof this.state.staff.library_id !== 'number') {
   toast.error('Необходимо заполнить поле "Библиотека".');
  } else if (this.state.staff.roles.length == 0) {
   toast.error('Необходимо добавить поле "Роль".');
  } else {
   const res = await fetch('https://smart-pvl.kz/Api/api/staff/edit.php', {
    method: 'POST',
    headers: {
     AUTH: localStorage.getItem('tokenLibrary'),
    },
    body: JSON.stringify({
     ...this.state.staff,
     staff: localStorage.getItem('idUser'),
    }),
   });
   console.log(res);
   toast.success('Отредактировано');
   this.props.handleClose();
   //    const json = await res.json();

   //    if (json == 0) {
   //     await toast.success('Данный номер телефона уже имеется в системе');
   //    } else {
   //     await toast.success('Сотрудник успешно зарегистрирован');
   //     // history.push('/');
   //     // return true;
   //    }
  }
 };

 findInMassive1 = (e) => {
  //   console.log(e);

  let res = this.props.RolesData.find((i) => i.title === e);
  typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.id);
  //   console.log(res);
  return res;
 };

 handleChangeLib = async (e) => {
  this.setState({ lib_name_str: e.target.value });
  // if (e.target.value.length > 3) {
  //   const json = await SmartSreach.SmartSreach({
  //     tab: 'library_id',
  //     id_name: 'name',
  //     id_val: e.target.value,
  //     col: '*',
  //   });
  //   this.setState({ ...this.state, lib_name_temp: json.slice(0, json.length - 3) });
  // }
  // if (!e.target.value.length) {
  //  this.setState({ lib_name_temp: [] });
  // }
 };

 componentDidUpdate() {
  var massTerm = [];

  this.props.fullInfoPerson.roles.length !== 0
   ? this.props.fullInfoPerson.roles.map((i, j) => {
      const minorObj = this.ojbectGenerate(j + 1, this.findInMassive1(i.title));
      massTerm = massTerm.concat(minorObj);
     })
   : console.info();

  this.state.staff.phone_number == this.props.fullInfoPerson.phone_number
   ? console.info()
   : this.setState({
      staff: {
       ...this.state.staff,
       id: this.props.fullInfoPerson.id,
       first_name: this.props.fullInfoPerson.first_name,
       last_name: this.props.fullInfoPerson.last_name,
       middle_name: this.props.fullInfoPerson.middle_name,
       date_of_birth: this.props.fullInfoPerson.date_of_birth,
       nation_id: this.props.fullInfoPerson.nation_id,
       education_id: this.props.fullInfoPerson.education_id,
       position_id: this.props.fullInfoPerson.position_id,
       general_experience: this.props.fullInfoPerson.general_experience,
       library_experience: this.props.fullInfoPerson.library_experience,
       gender_id: this.props.fullInfoPerson.gender_id,
       iin: this.props.fullInfoPerson.iin,
       family_status: this.props.fullInfoPerson.family_status,
       passport_number: this.props.fullInfoPerson.passport_number,
       address: this.props.fullInfoPerson.address,
       phone_number: this.props.fullInfoPerson.phone_number,
       lib_name: this.props.fullInfoPerson.lib_name,
       password: this.props.fullInfoPerson.password,
       roles: massTerm,
       library_id: this.props.fullInfoPerson.library_id,
      },
      specialMassive: massTerm,
      specialNumber: massTerm.length,
     });
 }
 async componentDidMount() {
  var massTerm = [];

  this.props.fullInfoPerson.roles.length !== 0
   ? this.props.fullInfoPerson.roles.map((i, j) => {
      const minorObj = this.ojbectGenerate(j + 1, this.findInMassive1(i.title).toString());
      massTerm = massTerm.concat(minorObj);
     })
   : console.info();

  this.setState({
   staff: {
    ...this.state.staff,
    id: this.props.fullInfoPerson.id,

    first_name: this.props.fullInfoPerson.first_name,
    last_name: this.props.fullInfoPerson.last_name,
    middle_name: this.props.fullInfoPerson.middle_name,
    date_of_birth: this.props.fullInfoPerson.date_of_birth,
    nation_id: this.props.fullInfoPerson.nation_id,
    education_id: this.props.fullInfoPerson.education_id,
    position_id: this.props.fullInfoPerson.position_id,
    general_experience: this.props.fullInfoPerson.general_experience,
    library_experience: this.props.fullInfoPerson.library_experience,
    gender_id: this.props.fullInfoPerson.gender_id,
    iin: this.props.fullInfoPerson.iin,
    family_status: this.props.fullInfoPerson.family_status,
    passport_number: this.props.fullInfoPerson.passport_number,
    address: this.props.fullInfoPerson.address,
    phone_number: this.props.fullInfoPerson.phone_number,
    lib_name: this.props.fullInfoPerson.lib_name,
    password: this.props.fullInfoPerson.password,
    roles: massTerm,
    library_id: this.props.fullInfoPerson.library_id,
   },
   specialMassive: massTerm,
   specialNumber: massTerm.length,
  });
 }

 render() {
  const atrFormAddReader = [
   {
    title: 'Номер телефона',
    name: 'phone_number',
    type: 'tel',
   },
   {
    title: 'Библиотека',
    name: 'lib_name_str',
    type: 'select',
   },
   {
    title: 'Имя',
    name: 'first_name',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Фамилия',
    name: 'last_name',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Очество',
    name: 'middle_name',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Дата рождения',
    name: 'date_of_birth',
    type: 'date',
    placeholder: 'Обязательный',
   },
   //    { title: 'Место рождения', name: 'place_age', type: 'text' },
   {
    title: 'Национальность',
    name: 'nation_id',
    type: 'text',
    placeholder: 'Обязательный',
   },
   // {
   //   title: 'Состоите ли в профсоюзе',
   //  name: 'profsouz',
   //   type: 'text',
   //   placeholder: 'Обязательный',
   // },
   {
    title: 'Образование',
    name: 'education_id',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Должность',
    name: 'position_id',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Пол',
    name: 'gender_id',
    type: 'select',
    placeholder: 'Обязательный',
    options: [
     { title: 'Мужской', id: 0 },
     { title: 'Женский', id: 1 },
    ],
   },
   {
    title: 'Общий стаж',
    name: 'general_experience',
    type: 'number',
    placeholder: 'Обязательный',
   },
   {
    title: 'Библиотечный стаж',
    name: 'library_experience',
    type: 'number',
    placeholder: 'Обязательный',
   },

   //    { title: 'Непрерывный стаж', name: 'neprer_stazh', type: 'text' },
   //    { title: 'Последнее место работы', name: 'last_work', type: 'text' },
   {
    title: 'ИИН',
    name: 'iin',
    type: 'number',
    placeholder: 'Необязательный',
   },
   {
    title: 'Семейное положение',
    name: 'family_status',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Номер паспорта',
    name: 'passport_number',
    type: 'number',
    placeholder: 'Необязательный',
   },
   {
    title: 'Адрес',
    name: 'address',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Пароль',
    name: 'password',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Права доступа',
    name: 'role',
    type: 'radio',
    option: this.props.RolesData,
    // option: [
    //  'Директор',
    //  'Инспектор по кадрам',
    //  'Отдел комплектования',
    //  'Методист',
    //  'Библиотекарь',
    //  // 'Областная библиотека'
    // ],
   },
  ];

  return (
   <form onSubmit={this.handleSubmit}>
    <div style={{ marginTop: '-20px', marginBottom: '35px', textAlign: 'center' }}>
     <Button className='btn_add_book' text='Редактировать' />
    </div>
    <div className='form-wrapper'>
     {atrFormAddReader.map((el, i) => {
      if (el?.options) {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select name={el.name} onChange={this.handleChange} value={this.state.staff[el.name]}>
          <option hidden>{el.placeholder}</option>
          {el.options.map((option, i) => {
           return (
            <option key={option.id} value={option.id}>
             {option.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'role') {
       return (
        <div>
         <span>{el.title}</span>
         {/* {console.log(this.state.specialMassive)}
         {console.log(this.state.staff.roles)} */}

         {this.state.specialMassive.map((i, j) => {
          //   var check = this.checkRoleDelete(j + 1);
          //   console.log(this.state.staff.roles);
          return (
           <label key={j} className='form-input'>
            {/* {}
            {console.log('----------')} */}

            <div style={{ display: 'flex' }}>
             <select
              //   disabled={check}
              name={el.name}
              id={j + 1}
              onChange={this.handleChangeRole}
              value={
               typeof this.state.staff.roles[j] !== 'undefined'
                ? this.state.staff.roles[j][Object.keys(this.state.staff.roles[j])[0]]
                : console.info()
              }
              // value='1'
             >
              <option hidden>{el.placeholder}</option>
              {el.option.map((option, i) => {
               return (
                <option key={option.id} value={option.id}>
                 {option.title}
                </option>
               );
              })}
             </select>
             <div
              style={{ width: '50%', height: '30px', border: '1px solid', margin: '5px', textAlign: 'center' }}
              id={j + 1}
              onClick={this.deleteRoleMassive}
             >
              удалить
             </div>
            </div>
           </label>
          );
         })}

         <div className='btn_add_book' style={{ marginBottom: '20px' }} onClick={() => this.specialMassiveGenerate()}>
          Добавить роль
         </div>
        </div>
       );
      } else if (el.type == 'tel') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>

         <MaskedInput
          mask='+7(111) 111 - 1111'
          //   onChange={(e) => console.log(e.target.value)}
          onChange={(e) => this.setState({ staff: { ...this.state.staff, phone_number: e.target.value } })}
          value={this.state.staff.phone_number}
         />
        </label>
       );
      } else if (el.name == 'lib_name_str') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeLibName(e)} value={this.state.staff.library_id}>
          <option hidden>Выберите данные</option>
          {this.props.SiglsDate.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.name}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'nation_id') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeNationName(e)} value={this.state.staff[el.name]}>
          <option hidden>Выберите данные</option>
          {this.props.NationalitySelectData.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'education_id') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeEducationName(e)} value={this.state.staff[el.name]}>
          <option hidden>Выберите данные</option>
          {this.props.StaffEducation.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'position_id') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changePositionName(e)} value={this.state.staff[el.name]}>
          <option hidden>Выберите данные</option>
          {this.props.PositionsData.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'family_status') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeFamilynName(e)} value={this.state.staff[el.name]}>
          <option hidden>Выберите данные</option>
          {this.props.MaritalStatusData.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else {
       return (
        <label className='form-input' key={i}>
         <span>{el.title}</span>
         <input
          value={this.state.staff[el.name]}
          type={el.type}
          name={el.name}
          placeholder={el.placeholder}
          onChange={this.handleChange}
         />
        </label>
       );
      }
     })}
    </div>
   </form>
  );
 }
}

const mapStateToProps = (data) => data;
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FormStaffEdit);
