import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import { getDolg } from '../../../api';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import './style.css';

class ServDolg extends Component {
 state = {
  choiceItemPage: 'dolzhen',

  priceAll: 0,
 };

 async componentDidMount() {
  const res = await getDolg.getDolg();

  var priceAll = 0;

  res.map((book) => (priceAll = priceAll + Number(book.price)));

  this.setState({ priceAll: priceAll });
  await this.props.setDate(res, 'dolzhen', 'dolzhen');
 }

 render() {
  const headerItemsContent = [
   { title: 'Инвентарный номер', data: 'inventory_number' },

   { title: 'Книга', data: 'title' },
   { title: 'Автор', data: 'author' },
   { title: 'Цена', data: 'price' },

   { title: 'Количество предоставленных дней', data: 'day_number' },
   { title: 'Дата выдачи', data: 'date_vydachi' },
   { title: 'Дата сдачи', data: 'date_sdachi' },

   { title: 'Читатель', data: 'reader' },
   { title: 'Номер телефона', data: 'phone_number' },
  ];

  let url = this.props.match.url.slice(1);

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      {url === 'dolg' ? (
       <h1>Отчетность по должникам</h1>
      ) : (
       <h1>Отслеживание задолженностей и должников по возврату литературы</h1>
      )}
      <div className='total-content'>
       <div>
        <span>Книг на руках</span>
        <p>{this.props.dolzhen.length}</p>
       </div>
       <div>
        <span>Сумма книг на руках</span>
        <p>{this.state.priceAll} тнг.</p>
       </div>
      </div>
     </div>

     <div className='book-items-content'>
      <CommonWrapperTable mainArr={this.props.dolzhen} headersArr={headerItemsContent} />
     </div>
     <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} />
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(ServDolg);
