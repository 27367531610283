import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../redux/action';

import { libraryPage } from '../../api';

import Loader from '../../components/loader';
import Modal from '../../components/modal';

import './style.css';
import arrow_sort from '../../assets/icon/arrow-sort.png';
import close from '../../assets/icon/modal-genre-close.png';
import Button from '../../components/button';

class Library extends Component {
 state = {
  choiceItemPage: 'library',

  typeBtn: '',

  newItem: {
   name: '',
   tel: '',
   supervisor: '',
   location: '',
   year: '',
   fill: '',
   work: '',
   target: '',
  },

  showModal: false,
 };

 //переключение по категориям
 choiceItem = async (e) => {
  this.setState({ choiceItemPage: +e.target.dataset.set });

  const json = await libraryPage.libraryPageSetData(+e.target.dataset.set);
  await this.props.setDate(json, +e.target.dataset.set);
 };

 //модалка на создание и редактирование
 showModal = (el, typeBtn) => {
  this.setState({ showModal: true, typeBtn, newItem: Object.assign(this.state.newItem, el) });
 };
 closeModalEvent = (e) => {
  if (e === 'modal') this.closeModal();
 };
 closeModal = () => {
  this.setState({
   newItem: Object.keys(this.state.newItem).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
   showModal: false,
  });
 };

 handleChange = ({ target }) => {
  this.setState({ ...this.state, newItem: { ...this.state.newItem, [target.name]: target.value } });
 };

 async componentDidMount() {
  const json = await libraryPage.libraryPageSetData(this.state.choiceItemPage);
 }

 render() {
  const headerItemsContentLibrary = [
   { title: 'Название', data: 'name' },
   { title: 'Телефон', data: 'tel' },
   { title: 'Руководитель', data: 'supervisor' },
  ];
  const headerItemsContentClubs = [
   { title: 'Наименование библиотеки', data: 'location' },
   { title: 'Название клуба', data: 'name' },
   { title: 'Год создани', data: 'year' },
   { title: 'Цели и задачи', data: 'target' },
   { title: 'Кол-во участников', data: 'fill' },
  ];
  const headerItemsContentCircles = [
   { title: 'Наименование библиотеки', data: 'location' },
   { title: 'Название кружка', data: 'name' },
   { title: 'Год создани', data: 'year' },
   { title: 'График работы', data: 'work' },
   { title: 'Кол-во участников', data: 'fill' },
  ];

  const choiceItem = [
   { title: 'Библиотеки', data: 'library' },
   { title: 'Клубы', data: 'clubs' },
   { title: 'Кружки', data: 'circles' },
   { title: 'Выставки', data: 'exibitions' },
  ];

  const library = [
   {
    name: 'АКТОГАЙСКАЯ ЦБС',
    tel: '8 (71841) 21-1-86,  8 (71841) 21-3-13, 8 (71841) 21-3-91',
    supervisor: 'Машрикова Бибигуль Болатовна 87776442072',
   },
   {
    name: 'ЦБС РАЙОНА АҚҚУЛЫ',
    tel: '8 (71839) 21-8-55, 8 (71839) 21-8-32, 8 (71839) 21-1-60',
    supervisor: 'Табулова Дарико Абаковна 87056135505',
   },
   {
    name: 'БАЯНАУЛЬСКАЯ ЦБС',
    tel: '8 (71840) 9-17-04, 8 (71840) 9-13-31',
    supervisor: 'Рахимова Сауле Файзуллаевна 87766795333',
   },
   {
    name: 'ЖЕЛЕЗИНСКАЯ ЦБС',
    tel: '8 (71831) 21298, 8 (71831) 2-13-43',
    supervisor: 'Каримова Алтын Танжарыковна 87059897373',
   },
   {
    name: 'ИРТЫШСКАЯ ЦБС',
    tel: '8 (71832) 2-11-53, 8(71832) 2-27-64',
    supervisor: 'Байтемирова Асем Балгиновна, 87054095533',
   },
   { name: 'МАЙСКАЯ ЦБС', tel: '8 (71838) 92-1-47, 8(71838) 91-9-87', supervisor: 'Зилькенов Саниял Оразбаевич' },
   {
    name: 'ЦБС ПАВЛОДАРСКОГО РАЙОНА',
    tel: '8 (7182) 61-92-78; 8(7182) 61-94-56',
    supervisor: 'Каужапарова Гульнар Толеуовна 87779336037',
   },
   {
    name: 'ЦБС РАЙОНА ТЕРЕҢКӨЛ',
    tel: '8 (71833) 2-18-93, 8 (71833) 2-26-40',
    supervisor: 'Каббасова Зауреш Сапарбековна 87773328444',
   },
   {
    name: 'УСПЕНСКАЯ ЦБС',
    tel: '8 (71834) 91-5-42,  8 (71834) 91-6-10',
    supervisor: 'Скакова Асем Темирбулатовна 87051252350',
   },
   {
    name: 'ЩЕРБАКТИНСКАЯ ЦБС',
    tel: '8 (71836) 2-20-93, 8 (71836) 22-6-82',
    supervisor: 'Естаева Гульжан Ахатовна 87052666900',
   },
   {
    name: 'ЦБС г.АКСУ',
    tel: '8 (71837) 5-00-59, 8 (71837)5-06-28',
    supervisor: 'Шинагулова Умут Нурлановна 877779356474',
   },
   {
    name: 'ЦБС г. ПАВЛОДАРА',
    tel: '8 (7182) 53-44-05, 8(7182) 53-09-04',
    supervisor: 'Оралхан Айбек Тлеубекович 87777676812',
   },
   {
    name: 'ЦБС г. ЭКИБАСТУЗА',
    tel: '8 (7187) 77-55-65, 8(7187) 34-36-87',
    supervisor: 'Жанахметова Анар Жатаевна 87016675460',
   },
   {
    name: 'ОБЛАСТНАЯ СПЕЦИАЛЬНАЯ БИБЛОТЕКА ДЛЯ СЛЕПЫХ И НЕЗРЯЧИХ ГРАЖДАН',
    tel: '8 (7182)34-89-98, 8 (7182)34-89-82',
    supervisor: 'Кенжебекова Жамал Киянкиновна 87015393340',
   },
   {
    name: 'ОБЛАСТНАЯ УНИВЕРСАЛЬНАЯ НАУЧНАЯ БИБЛИОТЕКА ИМЕНИ С.ТОРАЙГЫРОВА',
    tel: '8 (7182)32-08-02, 8 (7182)32-01-65',
    supervisor: 'Шахметова Шолпан Баяновна 87479880545',
   },
  ];

  const clubs = [
   {
    location: 'Массовая библиотека № 3',
    name: 'Клуб «Огородник» - семейный клуб',
    year: '2016',
    target: 'Организация досуга сельчан. Привлечение новых читателей',
    fill: '12 человек',
   },
   {
    location: 'ЦДБ',
    name:
     'Клуб ветеранов культуры «Нам есть ещё что вам сказать…» - клуб для ветеранов, инвалидов и людей пожилого возраста',
    year: '2007',
    target: 'Организация досуга пенсионеров',
    fill: '20 человек',
   },
   {
    location: 'Массовая библиотека села Кенжеколь',
    name: 'Клуб «Ауылдастар», «Сельчане»- семейный клуб',
    year: '2011',
    target: 'Организация досуга сельчан',
    fill: '14 человек',
   },
   {
    location: 'Массовая библиотека села Павлодарское',
    name: 'Клуб «на Огонек» - клуб для ветеранов и людей пожилого возраста',
    year: '2005',
    target: 'Организация досуга пенсионеров',
    fill: '23 человек',
   },
   {
    location: 'Массовая библиотека села Мойылды',
    name: 'Клуб «Даналык» - клуб для ветеранов, инвалидов и людей пожилого возраста',
    year: '2005',
    target: 'Организация досуга пенсионеров',
    fill: '15 человек',
   },
   {
    location: 'ЦГБ',
    name: 'Клуб «Дачник» - клуб для ветеранов, инвалидов и людей пожилого возраста',
    year: '2000',
    target: 'Организация досуга пенсионеров и привлечение новых читателей',
    fill: '25 человек',
   },
  ];

  const circles = [
   {
    location: 'Массовая библиотека села Павлодарское',
    name: 'Домбровой кружок «Еркем-ай»',
    year: '2019',
    work: 'Сарсенбі, бейсенбі - среда, четверг',
    fill: '7 человек',
   },
   {
    location: 'Детская библиотека №2',
    name: '«Ертегілер әлемінде» - «В мире сказок»',
    year: '2017',
    work: 'жексенбі-воскресенье сағ./час 11.00',
    fill: '10 человек',
   },
   {
    location: 'Массовая библиотека №10',
    name: 'Кружок английского языка',
    year: '2018',
    work: 'Сенбi- суббота',
    fill: '15 человек',
   },
   { location: 'Массовая библиотека №10', name: '«Карамелька»', year: '2017', work: 'Суббота', fill: '5 человек' },
   {
    location: 'Массовая библиотека №10',
    name: '«Искусства слова» - кружок казахского языка',
    year: '2019',
    work: 'Среда',
    fill: '5 человек',
   },
   { location: 'МБ с.Кенжеколь', name: '«Қиял»-«Фантазия»', year: '2021', work: '', fill: '12 человек' },
   { location: 'ЦДБ', name: '«Мой рукодельный мир»', year: '2021', work: '', fill: '15 человек' },
  ];

  const modal = [
   { title: 'Название', name: 'name', type: 'library' },
   { title: 'Телефон', name: 'tel', type: 'library' },
   { title: 'Руководитель', name: 'supervisor', type: 'library' },

   { title: 'Наименование библиотеки', name: 'location', type: ['clubs', 'circles'] },
   { title: 'Название клуба', name: 'name', type: 'clubs' },
   { title: 'Год создани', name: 'year', type: ['clubs', 'circles'] },
   { title: 'Цели и задачи', name: 'target', type: 'clubs' },
   { title: 'Кол-во участников', name: 'fill', type: ['clubs', 'circles'] },

   { title: 'Название кружка', data: 'name', type: 'circles' },
   { title: 'График работы', data: 'work', type: 'circles' },
  ];
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <Modal
      show={this.state.showModal}
      handleClose={this.hideModal}
      btn={this.state.typeBtn}
      classBtn='btn_add_genre'
      closeModalEvent={this.closeModalEvent}
     >
      <div className='modal-content-regReader'>
       <div className='modal-title-icon'>
        <h1>{this.state.typeBtn}</h1>
        <img className='modal-close-icon' onClick={this.closeModal} alt='close' src={close} />
       </div>

       <form onSubmit={(e) => e.preventDefault()} className='product-modal-inner'>
        <div className='form-add-book-regReader'>
         {modal
          .filter((el) => el.type.includes(this.state.choiceItemPage))
          .map((el, i) => {
           return (
            <div className='search-filter-book' key={i}>
             <p>{el.title}</p>
             <input
              type='text'
              name={el.name}
              autoComplete='off'
              placeholder='Обязательные поля'
              value={this.state.newItem[el.name]}
              onChange={this.handleChange}
             />
            </div>
           );
          })}
        </div>
       </form>
      </div>
     </Modal>

     <div className='title-total'>
      <h1>БИБЛИОТЕКИ И МИРОПРИЯТИЯ</h1>
     </div>

     <div className='choice-type'>
      {choiceItem.map((el, i) => (
       <h3 data-set={el.data} key={i} onClick={this.choiceItem}>
        {el.title}
       </h3>
      ))}
     </div>

     {/* Библиотеки */}
     {this.state.choiceItemPage === 'library' ? (
      <div className='book-items-content'>
       <div className='book-items-header'>
        <div className='item-lib'>
         {headerItemsContentLibrary.map((el, i) => {
          return (
           <div key={i}>
            <p>{el.title}</p>
            <img
             src={arrow_sort}
             alt='profile'
             data-type={el.data}
             onClick={(e) => this.sortItemBook(e.target.dataset.type)}
            />
           </div>
          );
         })}
        </div>
        <div className='del-edit'>
         <Button text='Добавить' className='btn_library_create' onClick={() => this.showModal(null, 'Добавление')} />
        </div>
       </div>

       {
        // this.props.loader
        //     ? <Loader />
        //     : library.length === 0
        //         ?
        //         <div className='search_output_null'>
        //             <h1>:</h1>
        //             <h3 >По данному поиску нет совпадений</h3>
        //         </div>
        //         :
        library.map((el, i) => {
         let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
         return (
          <div className={oddEven} key={el.id}>
           <div className='item-lib'>
            <div>
             <p>{el.name}</p>
            </div>
            <div>
             <p>{el.tel}</p>
            </div>
            <div>
             <p>{el.supervisor}</p>
            </div>
           </div>
           <div className='del-edit'>
            <Button
             text='Редакфывтировать'
             className='btn_library_edit'
             onClick={() => this.showModal(el, 'Редактирование')}
            />
            <Button text='Удалить' className='btn_library_del' onClick={() => this.showModal(el, 'Удаление')} />
           </div>
          </div>
         );
        })
       }
      </div>
     ) : null}

     {/* Клубы */}
     {this.state.choiceItemPage === 'clubs' ? (
      <div className='book-items-content'>
       <div className='book-items-header'>
        <div className='item-lib'>
         {headerItemsContentClubs.map((el, i) => {
          return (
           <div key={i}>
            <p>{el.title}</p>
            <img
             src={arrow_sort}
             alt='profile'
             data-type={el.data}
             onClick={(e) => this.sortItemBook(e.target.dataset.type)}
            />
           </div>
          );
         })}
        </div>
        <div className='del-edit'>
         <Button text='Добавить' className='btn_library_create' onClick={() => this.showModal(null, 'Добавление')} />
        </div>
       </div>

       {
        // this.props.loader
        //     ? <Loader />
        //     : clubs.length === 0
        //         ?
        //         <div className='search_output_null'>
        //             <h1>:</h1>
        //             <h3 >По данному поиску нет совпадений</h3>
        //         </div>
        //         :
        clubs.map((el, i) => {
         let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
         return (
          <div className={oddEven} key={el.id}>
           <div className='item-lib'>
            <div>
             <p>{el.location}</p>
            </div>
            <div>
             <p>{el.name}</p>
            </div>
            <div>
             <p>{el.year}</p>
            </div>
            <div>
             <p>{el.target}</p>
            </div>
            <div>
             <p>{el.fill}</p>
            </div>
           </div>
           <div className='del-edit'>
            <Button
             text='Редактировать'
             className='btn_library_edit'
             onClick={() => this.showModal(el, 'Редактирование')}
            />
            <Button text='Удалить' className='btn_library_del' onClick={() => this.showModal(el, 'Удаление')} />
           </div>
          </div>
         );
        })
       }
      </div>
     ) : null}

     {/* Кружки */}
     {this.state.choiceItemPage === 'circles' ? (
      <div className='book-items-content'>
       <div className='book-items-header'>
        <div className='item-lib'>
         {headerItemsContentCircles.map((el, i) => {
          return (
           <div key={i}>
            <p>{el.title}</p>
            <img
             src={arrow_sort}
             alt='profile'
             data-type={el.data}
             onClick={(e) => this.sortItemBook(e.target.dataset.type)}
            />
           </div>
          );
         })}
        </div>
        <div className='del-edit'>
         <Button text='Добавить' className='btn_library_create' onClick={() => this.showModal(null, 'Добавление')} />
        </div>
       </div>

       {
        // this.props.loader
        //     ? <Loader />
        //     : circles.length === 0
        //         ?
        //         <div className='search_output_null'>
        //             <h1>:</h1>
        //             <h3 >По данному поиску нет совпадений</h3>
        //         </div>
        //         :
        circles.map((el, i) => {
         let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
         return (
          <div className={oddEven} key={el.id}>
           <div className='item-lib'>
            <div>
             <p>{el.location}</p>
            </div>
            <div>
             <p>{el.name}</p>
            </div>
            <div>
             <p>{el.year}</p>
            </div>
            <div>
             <p>{el.work}</p>
            </div>
            <div>
             <p>{el.fill}</p>
            </div>
           </div>
           <div className='del-edit'>
            <Button
             text='Редактировать'
             className='btn_library_edit'
             onClick={() => this.showModal(el, 'Редактирование')}
            />
            <Button text='Удалить' className='btn_library_del' onClick={() => this.showModal(el, 'Удаление')} />
           </div>
          </div>
         );
        })
       }
      </div>
     ) : null}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(Library);
