import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Toaster } from 'react-hot-toast';

import { setDate, returnBook } from './redux/action';

import { setData, selectedItem, sortItem, getBooksForBronForFirstPage } from './api';

import LibEditModal from './components/LibEditModal/LibEditModal';
import Menu from './components/menu';
import Header from './components/header';

import MainPage from './pages/MainPage';
import BookAdd from './pages/BookAdd';
import PartAdd from './pages/PartAdd';
import PartAdd_G from './pages/PartAdd_G';

import BookAddFromPart from './pages/BookAddFromPart';
import BookAddFromPart_g from './pages/BookAddFromPart_g';

import BookOut from './pages/BookOut';
import BookItem from './pages/bookItem';
import MultiBookItem from './pages/MultiBookItem/MultiBookItem';

import Reader from './pages/reader';
import Recrut from './pages/recrut';
import LogIn from './pages/log-in';
import Library from './pages/library';
import ErrorPage from './pages/error-page';

import './App.css';

// import IndexDoc from './pages/trash/index_doc';
import Obrabotka from './pages/trash/obrabotka';
import ElectronRes from './pages/trash/electron_res';
import EBookAdd from './pages/trash/ebook_add';
import CreateSrav from './pages/trash/create_srav';
import History from './pages/trash/history';
import Log from './pages/trash/log';
import LibraryAdr from './pages/trash/lib_adress';
import StaticSved from './pages/trash/static_sved';
import SvedCustomers from './pages/trash/SvedCustomers/SvedCustomers';
import ReadersStat from './pages/trash/ReadersStat/ReadersStat';
import StaticRead2 from './pages/trash/StaticRead2/StaticRead2';
import StaffStatGivRet from './pages/trash/StaffStatGivRet/StaffStatGivRet';
import UsersRoles from './pages/trash/UsersRoles/UsersRoles';

import StaticRead from './pages/trash/static_read';
import SubcrBase from './pages/trash/subcr_base';
import SubcrPostav from './pages/trash/subcr_postav';
import SubcrOtchet from './pages/trash/subcr_otchet';
import TotlaStat from './pages/trash/total_stat';
import SvodOtchet from './pages/trash/svod_otchet';
import KomplektRed from './pages/trash/komplekt_red';
import KomplektPopular from './pages/trash/komplekt_popular';
import KomplektInventar from './pages/trash/komplekt_inventar';
import KomplektFond from './pages/trash/komplekt_fond';
import StoreBron from './pages/trash/store_bron';
import ServDolg from './pages/trash/serv_dolg';
import Documents from './pages/trash/Documents/Documents';

import OtchetLib from './pages/trash/otchet_lib';
import Marc from './pages/trash/marc';
import Pravila from './pages/trash/pravila';
import OtraslRead from './pages/trash/otrasl_read';
import SubcrCalendar from './pages/trash/subcr_calendar';
import StadSpad from './pages/trash/stad_spad';
import StadSpadCat from './pages/trash/StadSpadCat/StadSpadCat';
import ServiceRules from './pages/trash/ServiceRules/ServiceRules';
import ElResources from './pages/trash/ElResources/ElResources';

import AnalitReaders from './pages/trash/AnalitReaders/AnalitReaders';
import AnalitGeneral from './pages/trash/AnalitGeneral/AnalitGeneral';
import AnalitStaff from './pages/trash/AnalitStaff/AnalitStaff';
import AnalitSvod from './pages/trash/AnalitSvod/AnalitSvod';

import OtchetSet from './pages/trash/otchet_set';
import OtchetSet2 from './pages/trash/OtchetSet2/OtchetSet2';
import OtchetSet3 from './pages/trash/OtchetSet3/OtchetSet3';
import OtchetSet4 from './pages/trash/OtchetSet4/OtchetSet4';
import OtchetSet5 from './pages/trash/OtchetSet5/OtchetSet5';
import OtchetSet6 from './pages/trash/OtchetSet6/OtchetSet6';
import OtchetSet7 from './pages/trash/OtchetSet7/OtchetSet7';
import OtchetSet8 from './pages/trash/OtchetSet8/OtchetSet8';
import OtchetSet9 from './pages/trash/OtchetSet9/OtchetSet9';
import OtchetSet10 from './pages/trash/OtchetSet10/OtchetSet10';

import OtchetSetDop1 from './pages/trash/OtchetSetDop1/OtchetSetDop1';
import OtchetSetDop2 from './pages/trash/OtchetSetDop2/OtchetSetDop2';
import OtchetSetDop3 from './pages/trash/OtchetSetDop3/OtchetSetDop3';
import OtchetSetDop4 from './pages/trash/OtchetSetDop4/OtchetSetDop4';
import OtchetSetDop5 from './pages/trash/OtchetSetDop5/OtchetSetDop5';
import OtchetSetDop6 from './pages/trash/OtchetSetDop6/OtchetSetDop6';
import OtchetSetDop7 from './pages/trash/OtchetSetDop7/OtchetSetDop7';

import Analit from './pages/trash/analit';
import KatalogPrint from './pages/trash/katalog_print';
import KomplektAkt from './pages/trash/komplekt_act';
import KomplektKSU from './pages/trash/komplekt_ksu';
// import KatalogIp from './pages/trash/katalog_ip';
import KomplektOtchet from './pages/trash/komplekt_otchet';
import SpisanBook from './pages/trash/spisan_book';
import ZabronBook from './pages/trash/zabron_book';
import RegRules from './pages/trash/RegRules/RegRules';

import Stati from './pages/trash/stati';
import ZhanrRead from './pages/trash/ZhanrRead/ZhanrRead';
import DocVidRead from './pages/trash/DocVidRead/DocVidRead';
import PartAddedPage from './pages/PartAddedPage';
import StelaRed from './pages/trash/red_stela';
import ErrorModal from './components/ErrorModal/ErrorModal';
import FormBookRed from './components/FormBookRed/FormBookRed';

import commonTryCatch from './helpers/commonTryCatch';
import BooksExtension from './pages/trash/BooksExtension/BooksExtension';
class App extends React.PureComponent {
  state = {
    onLine: true,

    successBook: false,

    // libcheck: false,
  };

  //получение данных
  getData = async (item, page, lib_name) => {
    if (item !== 'other_data') {
      await localStorage.removeItem('avtorS');
      await localStorage.removeItem('libNameS');
      await localStorage.removeItem('catalogS');
    }
    try {
      if (page == 'store_bron1') {
        const json = await getBooksForBronForFirstPage.getBooksForBronForFirstPage();
        await this.props.setDate(json);
      } else {
        const json = await setData.setData(item, lib_name);
        await this.props.setDate(json, page, item);
      }
    } catch (error) {
      await this.setState({ onLine: false });
    }
  };

  //Выдать книгу
  giveBook = async (data) => {
    this.setState({ successBook: false });
    const res = await commonTryCatch(
      'https://smart-pvl.kz/Api/api/books_history/add.php',
      'POST',
      JSON.stringify(data),
    );
    if (res) this.setState({ successBook: true });
  };

  //Вернуть книгу
  returnBookItem = async (data) => {
    this.setState({ successBook: false });
    const res = await commonTryCatch(
      'http://smart-pvl.kz/Api/ReturnBook.php',
      'POST',
      JSON.stringify(data),
    );
    if (res) {
      await this.props.returnBook(data.id);
      this.setState({ successBook: true });
    }
  };

  //Пагинация для добавлении книг (пагинация поселе поиска)
  paginationPages = async (data) => {
    if (data.page) {
      if (data.page === 'staff') {
        const res = await commonTryCatch(
          'https://smart-pvl.kz/Api/Display.php',
          'POST',
          JSON.stringify({ ...data, token: localStorage.getItem('tokenLibrary') }),
        );
        await this.props.setDate(res, data.page, data.item);
      } else {
        const res = await commonTryCatch(
          'https://smart-pvl.kz/Api/Display.php',
          'POST',
          JSON.stringify({ ...data, token: localStorage.getItem('tokenLibrary') }),
        );
        await this.props.setDate(res, data.page, data.item);
      }
    }
  };

  //Сортировка
  sortItem = async (item, sort_name, sort, page) => {
    const json = await sortItem.sortBook({ skip: 1, item, sort, sort_name });
    await this.props.setDate(json, page, item);
  };

  // Выбор книги
  selectItem = async (id) => {
    const json = await selectedItem.selectedBook({ id: id });
    await this.props.setDate(json, 'bookItem');
  };
  async componentDidMount() {
    const res = await fetch(`https://smart-pvl.kz/Api/api/customer/get.php`, {
      method: 'POST',
      body: JSON.stringify({ page: 1 }),
      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
    });
    if (res.status === 401) {
      localStorage.clear();
      await this.props.logOut;
    }
  }
  render() {
    if (localStorage.getItem('modal401')) {
      localStorage.clear();
      return <ErrorModal auth={false} />;
    }
    if (localStorage.getItem('tryCatch')) {
      localStorage.clear();
      return <ErrorModal button={true} />;
    }
    return (
      <>
        {this.state.onLine ? (
          <div className='app'>
            <Toaster position='top-right' />
            {this.props.logIn ? (
              <>
                {localStorage.getItem('libcheck') == 'true' ? <LibEditModal /> : null}

                <Menu />
                <div className='app-content'>
                  <Header />

                  <Switch>
                    <Route path='/' render={({ match }) => <MainPage match={match} />} exact />

                    <Route
                      path='/book_add'
                      render={({ match }) => (
                        <BookAdd
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          sortItem={this.sortItem}
                          selectItem={this.selectItem}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/book_edit/:id'
                      render={({ match }) => (
                        <FormBookRed
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          sortItem={this.sortItem}
                          selectItem={this.selectItem}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/book_out'
                      render={({ match }) => (
                        <BookOut
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          sortItem={this.sortItem}
                          selectItem={this.selectItem}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/books_extension'
                      render={({ match }) => <BooksExtension match={match} />}
                      exact
                    />

                    <Route
                      path='/book_out/:id'
                      render={({ match }) => (
                        <BookItem
                          giveBook={this.giveBook}
                          returnBookItem={this.returnBookItem}
                          successBook={this.state.successBook}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/multibook/:id'
                      render={({ match }) => <MultiBookItem match={match} />}
                      exact
                    />

                    <Route
                      path='/book-add-from-part/:id'
                      render={({ match }) => <PartAddedPage match={match} />}
                      exact
                    />

                    <Route
                      path='/book_add/:id'
                      render={({ match }) => (
                        <BookItem
                          giveBook={this.giveBook}
                          returnBookItem={this.returnBookItem}
                          successBook={this.state.successBook}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/recrut'
                      render={({ match }) => (
                        <Recrut
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          sortItem={this.sortItem}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/book-add-from-part'
                      render={({ match }) => (
                        <BookAddFromPart
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/subcr_base_parts_control'
                      render={({ match }) => (
                        <BookAddFromPart_g
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/readers'
                      render={({ match }) => (
                        <Reader
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/library'
                      render={({ match }) => <Library getData={this.getData} match={match} />}
                      exact
                    />

                    {/* ------------------------ */}

                    <Route
                      path='/obrabotka'
                      render={({ match }) => (
                        <Obrabotka
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/staff_info_system'
                      render={({ match }) => (
                        <Obrabotka
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/marc'
                      render={({ match }) => (
                        <Marc
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/ebook_add'
                      render={({ match }) => (
                        <EBookAdd
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/create_srav'
                      render={({ match }) => (
                        <CreateSrav
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/history'
                      render={({ match }) => <History getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/log'
                      render={({ match }) => <Log getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/lib_adress'
                      render={({ match }) => <SubcrPostav getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/static_sved'
                      render={({ match }) => <StaticSved getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/stat_komplekt'
                      render={({ match }) => <StaticSved getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/sved_user'
                      render={({ match }) => <SvedCustomers getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/readers_stat'
                      render={({ match }) => <ReadersStat getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/static_read'
                      render={({ match }) => <StaticRead getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/static_sved_cat'
                      render={({ match }) => <StaticRead2 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/subcr_base'
                      render={({ match }) => (
                        <SubcrBase
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/subcr_zakaz'
                      render={({ match }) => <LibraryAdr getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/subcr_postav'
                      render={({ match }) => <LibraryAdr getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/subcr_otchet'
                      render={({ match }) => <SubcrOtchet getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/total_stat'
                      render={({ match }) => <TotlaStat getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/svod_otchet'
                      render={({ match }) => <SvodOtchet getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/otchet_lib'
                      render={({ match }) => <OtchetLib getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/staff_info'
                      render={({ match }) => (
                        <StaffStatGivRet
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/staff_info_roles'
                      render={({ match }) => (
                        <UsersRoles
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/komplekt_vvod'
                      render={({ match }) => (
                        <PartAdd
                          paginationPages={this.paginationPages}
                          selectItem={this.selectItem}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/subcr_base_parts'
                      render={({ match }) => (
                        <PartAdd_G
                          paginationPages={this.paginationPages}
                          selectItem={this.selectItem}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/komplekt_red'
                      render={({ match }) => (
                        <KomplektRed
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/komplekt_popular'
                      render={({ match }) => (
                        <KomplektPopular getData={this.getData} match={match} />
                      )}
                      exact
                    />
                    <Route
                      path='/komplekt_inventar'
                      render={({ match }) => (
                        <KomplektInventar
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/komplekt_fond'
                      render={({ match }) => (
                        <KomplektFond
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/store_bron'
                      render={({ match }) => (
                        <StoreBron
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/store_bron_serv'
                      render={({ match }) => (
                        <StoreBron
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/el_res'
                      render={({ match }) => (
                        <ElResources
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/analit_readers'
                      render={({ match }) => (
                        <AnalitReaders
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/analit_general'
                      render={({ match }) => (
                        <AnalitGeneral
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/analit_staff'
                      render={({ match }) => (
                        <AnalitStaff
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/analit_svod'
                      render={({ match }) => (
                        <AnalitSvod
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/serv_otbor'
                      render={({ match }) => (
                        <StoreBron
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/serv_popular'
                      render={({ match }) => (
                        <StaticSved
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/serv_dolg'
                      render={({ match }) => (
                        <ServDolg
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/documents'
                      render={({ match }) => (
                        <Documents
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/dolg'
                      render={({ match }) => (
                        <ServDolg
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/pravila'
                      render={({ match }) => (
                        <Pravila
                          paginationPages={this.paginationPages}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/serv_pravila'
                      render={({ match }) => (
                        <Pravila
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/otrasl_read'
                      render={({ match }) => <OtraslRead getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/zhanr_read'
                      render={({ match }) => <ZhanrRead getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/docvid_read'
                      render={({ match }) => <DocVidRead getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/subcr_calendar'
                      render={({ match }) => <SubcrCalendar getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/analitik_stat'
                      render={({ match }) => <StadSpad getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/analitic_sved_cat'
                      render={({ match }) => <StadSpadCat getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/given_books'
                      render={({ match }) => <OtchetSet getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/return_books'
                      render={({ match }) => <OtchetSet2 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/fond_books'
                      render={({ match }) => <OtchetSet3 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/readers_svod'
                      render={({ match }) => <OtchetSet4 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/visits_svod'
                      render={({ match }) => <OtchetSet5 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/readers_age'
                      render={({ match }) => <OtchetSet6 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/readers_gender'
                      render={({ match }) => <OtchetSet7 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/registration_svod'
                      render={({ match }) => <OtchetSet8 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/genre_svod'
                      render={({ match }) => <OtchetSet9 getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/registration_user_svod'
                      render={({ match }) => <OtchetSet10 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/general_given_books'
                      render={({ match }) => <OtchetSetDop1 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/given_multibooks_p'
                      render={({ match }) => <OtchetSetDop2 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/given_multibooks_g'
                      render={({ match }) => <OtchetSetDop3 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/given_multibooks_m'
                      render={({ match }) => <OtchetSetDop4 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/fond_books_branches'
                      render={({ match }) => <OtchetSetDop5 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/fond_books_langs'
                      render={({ match }) => <OtchetSetDop6 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/fond_books_popularity'
                      render={({ match }) => <OtchetSetDop7 getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/analit'
                      render={({ match }) => <Analit getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/komplekt_otchet'
                      render={({ match }) => (
                        <KomplektOtchet
                          selectItem={this.selectItem}
                          getData={this.getData}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/katalog_print'
                      render={({ match }) => <KatalogPrint getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/komplekt_act'
                      render={({ match }) => (
                        <KomplektAkt
                          getData={this.getData}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/komplekt_ksu'
                      render={({ match }) => <KomplektKSU getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/spisan_book'
                      render={({ match }) => <SpisanBook getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/reg_rules'
                      render={({ match }) => <RegRules getData={this.getData} match={match} />}
                      exact
                    />
                    <Route
                      path='/service_rules'
                      render={({ match }) => <ServiceRules getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path='/zabron_book'
                      render={({ match }) => (
                        <ZabronBook
                          getData={this.getData}
                          giveBook={this.giveBook}
                          successBook={this.state.successBook}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />
                    <Route
                      path='/ElStat'
                      render={({ match }) => (
                        <Stati
                          getData={this.getData}
                          giveBook={this.giveBook}
                          successBook={this.state.successBook}
                          paginationPages={this.paginationPages}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path='/red_stela'
                      render={({ match }) => <StelaRed getData={this.getData} match={match} />}
                      exact
                    />
                  </Switch>
                </div>
              </>
            ) : (
              <LogIn />
            )}
          </div>
        ) : (
          <ErrorPage />
        )}
      </>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = {
  setDate,
  returnBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
