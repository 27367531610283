import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import Loader from '../../../components/loader';

import './style.css'

class SubcrOtchet extends Component {

    async componentDidMount() {
        await this.props.getData('subcr_otchet', 'subcr_otchet')
    }

    render() {
        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>Форма отчетности</h1>
                    </div>

                    {this.props.subcr_otchet.length
                        ?
                        <div className='monitor'>
                            <div className='colunm'>
                                <div>
                                    <p>Количество периодических изданий в фонде: {this.props.subcr_otchet[0]}</p>
                                </div>
                                <div>
                                    <p>Количество заказчиков: 16</p>
                                </div>
                                <div>
                                    <p>Количество поставщиков: 40</p>
                                </div>
                            </div>
                        </div>
                        :
                        <Loader />}



                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(SubcrOtchet);