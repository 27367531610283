import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';
import { sortItem } from '../../../api'

import Loader from '../../../components/loader';

import './style.css'

class TotlaStat extends Component {

    state = {
        abc: '',
        kol_book: 0
    }

    abcChange = async (e) => {

        const res = await sortItem.sortBook({ item: 'abc', letter: e.target.value })
        await this.setState({ abc: e.target.value, kol_book: res })
    }

    async componentDidMount() {
        await this.props.getData('svod_otchet', 'svod_otchet')
    }

    render() {

        const url = this.props.match.path.slice(1);
        console.log(url);

        const abc_ru = ["А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "О", "П", "Р", "С", "Т", "У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ы", "Ь", "Э", "Ю", "Я"];

        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        {url === 'svod_otchet' ? <h1>Сводная отчетность</h1> : <h1>Формирование и получение отчетных форм</h1>}
                    </div>

                    {this.props.svod_otchet.length
                        ?
                        <div className='monitor'>
                            <div className='colunm'>
                                <div>
                                    <p>Книг выдали - {this.props.svod_otchet[0]} раз</p>
                                </div>
                                <div>
                                    <p>Книг возвратили - {this.props.svod_otchet[1]} раз</p>
                                </div>
                                <div>
                                    <p>Количество книг: {this.props.svod_otchet[2]}</p>
                                </div>
                                <div>
                                    <p>Количество читателей: {this.props.svod_otchet[3]}</p>
                                </div>
                                <div>
                                    <p>Посещаемость: {this.props.svod_otchet[4]}</p>
                                </div>
                                <div>
                                    <p>Количество жанров: {this.props.svod_otchet[5]}</p>
                                </div>
                                <div>
                                    <p>Популярные жанры: {this.props.svod_otchet[6].map(el => <p className='pop-zhanrs'>{el.zhanr}</p>)}</p>
                                </div>
                                <div>
                                    <p>По возрасту:</p>
                                    <div className='type'>
                                        <p>от  до 5 лет - {this.props.svod_otchet[7]}</p>
                                        <p>от 5 до 13 лет - {this.props.svod_otchet[8]}</p>
                                        <p>от 14 до 21 лет - {this.props.svod_otchet[9]}</p>
                                        <p>от 22 до 40 лет - {this.props.svod_otchet[10]}</p>
                                        <p>от 41 до 63 лет - {this.props.svod_otchet[11]}</p>
                                        <p>старше 63 лет - {this.props.svod_otchet[12]}</p>
                                    </div>
                                </div>
                                <div>
                                    <p>По полу:</p>
                                    <div className='type'>
                                        <p>Женский - {this.props.svod_otchet[13]}</p>
                                        <p>Мужской - {this.props.svod_otchet[14]}</p>
                                    </div>
                                </div>
                                <div>
                                    <p>Количество сотрудников: {this.props.svod_otchet[15]}</p>
                                </div>
                            </div>

                            <div className='colunm'>
                                <div>
                                    <p>Статистика по названию литературы:</p>
                                    <div className='word'>
                                        <p>Выберите букву:</p>
                                        <select onChange={this.abcChange} name='abc' className='select_abc'>
                                            <option hidden>Выберите букву</option>
                                            {abc_ru.map((el, i) => <option key={i}>{el}</option>)}
                                        </select>
                                    </div>
                                    <div className='type'>
                                        <p>Количество книг начинающихся на букву {this.state.abc} - {this.state.kol_book}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loader />}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(TotlaStat);