import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, bronPolka } from '../../../redux/action';

import { GetBroned, GetBronedRow, editInventNumStatus, ForBroneSearch } from '../../../api';

import ModalForBrone from '../../../components/modalForBrone/modalForBrone';
import Button from '../../../components/button';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import moment from 'moment';
import toast from 'react-hot-toast';
import debounce from '../../../utils/debounce';

import './style.css';

class StoreBron extends Component {
 state = {
  choiceItem: 'broned',
  date1: '',
  date2: '',
  show: false,
  show2: false,

  selected_book: '',
  inputType: '',
 };

 choiseItem = async (item) => {
  await this.props.setDate([], 'store_bron', 'store_bron');
  this.setState({ choiceItem: item });
  this.getDataBroned();
 };

 SendToBrone = async (item) => {
  this.setState({ show: true, selected_book: item });
 };

 handleInputTitle = async (e) => {
  if (e.target.value != '') {
   var value = '';
   e.target.value == 'Отобразить все книги' ? (value = '') : (value = e.target.value);
   this.setState({ inputType: value });

   const obj3 = {
    value: value,
   };
   const res = await ForBroneSearch.ForBroneSearch(obj3);
   const data = Object.values(res);
   await this.props.setDate(data, 'store_bron', 'store_bron');
  }
 };

 SendBron = async (item) => {
  const res = await editInventNumStatus.editInventNumStatus({
   status: 2,
   id: item.id,
  });

  if (res) {
   toast.success('Книга успешно отправлена на бронеполку!');
   const obj3 = {
    value: this.state.inputType,
   };
   const res = await ForBroneSearch.ForBroneSearch(obj3);
   const data = Object.values(res);
   await this.props.setDate(data, 'store_bron', 'store_bron');
  } else {
   toast.error('Возникла ошибка');
  }
 };

 ReturnFromBrone = async (item) => {
  // this.setState({ show2: true, selected_book: item });
  const res = await editInventNumStatus.editInventNumStatus({
   status: 1,
   id: item.id,
  });

  if (res) {
   toast.success('Книга успешно возвращена!');
   this.getDataBroned();
  } else {
   toast.error('Возникла ошибка');
  }
 };

 CloseModalSendToBrone = async () => {
  this.setState({ show: false });
 };

 getDataBroned = async () => {
  //   const obj = {
  //    value: 'Загл',
  //   };
  //   const res = await GetBooksByTitleTest.GetBooksByTitleTest(obj);
  //   console.log(res);

  //   const obj2 = {
  //    phone_number: '+7(222) 222 - 2222',
  //    password: '222',
  //   };
  //   const res2 = await InsertDataTest.InsertDataTest(obj2);
  //   console.log(res2);

  //   const obj3 = {
  //    date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  //   };
  //   const res3 = await GetDataTest.GetDataTest(obj3);
  //   console.log(res3);

  const type = this.state.choiceItem;
  if (type == 'broned') {
   const obj3 = {
    date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
   };
   const res = await GetBroned.GetBroned(obj3);
   const data = Object.values(res);
   await this.props.setDate(data, 'store_bron', 'store_bron');
  } else if (type == 'bronedRow') {
   const res = await GetBronedRow.GetBronedRow();
   const data = Object.values(res);
   await this.props.setDate(data, 'store_bron', 'store_bron');
  }
 };

 async componentDidMount() {
  this.getDataBroned();
 }

 render() {
  const buttons = [{ title: 'Отправить на бронеполку', event: (e) => this.SendToBrone(e) }];
  const buttons2 = [{ title: 'Вернуть', event: (e) => this.ReturnFromBrone(e) }];
  const buttons3 = [{ title: 'Отправить на бронеполку', event: (e) => this.SendBron(e) }];

  const choiceItem = [
   { title: 'Забронированные книги', data: 'broned' },
   { title: 'Книги на бронеполке', data: 'bronedRow' },

   { title: 'Резервирование', data: 'tobron' },
  ];

  const headerItemsContent_broned = [
   { title: 'Название', data: 'title' },
   { title: 'Автор', data: 'author' },
   { title: 'Дата бронирования', data: 'date' },
   { title: 'Читатель', data: 'reader' },
   { title: 'Действие', data: 'button' },
  ];

  const headerItemsContent_bronedRow = [
   { title: 'Название', data: 'book_name' },
   { title: 'Автор', data: 'author' },
   { title: 'Инвентарный номер', data: 'inventory_number' },
   { title: 'Действие', data: 'button' },
  ];

  const headerItemsContent_tobron = [
   { title: 'Название', data: 'title' },
   { title: 'Автор', data: 'author' },
   { title: 'Инвентарный номер', data: 'inventory_number' },
   { title: 'Действие', data: 'button' },
  ];

  let url = this.props.match.url.slice(1);

  return (
   <div className='page-main'>
    <ModalForBrone
     getDataBroned={this.getDataBroned}
     show={this.state.show}
     closeModalProp={this.CloseModalSendToBrone}
     book={this.state.selected_book}
    />
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <div>
       <div>
        {url === 'store_bron' ? (
         <h1>Подборка книг и отправка их на бронеполку для выдачи читателям</h1>
        ) : url === 'store_bron_adm' ? (
         <h1>Управление хранением электронных ресурсов</h1>
        ) : (
         <h1>Возврат и резервирование необходимой литературы</h1>
        )}
        {/* <div>
         <div className='del-edit'>
          <Button text='Прием заказов от пунктов книговыдачи' className='btn_library_create' />
         </div>
         <div className='del-edit'>
          <Button text='Прием книг от пунктов книговыдачи' className='btn_library_create' />
         </div>
        </div> */}
       </div>
       <div className='choice-type'>
        {choiceItem.map((el, i) => (
         <h3
          style={{ background: el.data == this.state.choiceItem ? '#5b7fbb' : '' }}
          data-set={el.data}
          key={i}
          onClick={(e) => this.choiseItem(e.target.dataset.set)}
         >
          {el.title}
         </h3>
        ))}
       </div>
      </div>
     </div>

     {this.state.choiceItem == 'tobron' ? (
      <div className='smart-search' style={{ marginBottom: '20px' }}>
       <input type='text' placeholder='Введите заглавие' onChange={debounce(this.handleInputTitle, 900)} />
      </div>
     ) : null}
     <div className='book-items-content'>
      {this.props.store_bron.length === 0 ? (
       <div className='search_output_null'>
        <h3>Раздел пуст</h3>
       </div>
      ) : this.state.choiceItem == 'broned' ? (
       <CommonWrapperTable mainArr={this.props.store_bron} headersArr={headerItemsContent_broned} buttons={buttons} />
      ) : this.state.choiceItem == 'bronedRow' ? (
       <CommonWrapperTable
        mainArr={this.props.store_bron}
        headersArr={headerItemsContent_bronedRow}
        buttons={buttons2}
       />
      ) : this.state.choiceItem == 'tobron' ? (
       <CommonWrapperTable mainArr={this.props.store_bron} headersArr={headerItemsContent_tobron} buttons={buttons3} />
      ) : null}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, bronPolka };

export default connect(mapStateToProps, mapDispatchToProps)(StoreBron);
