export default async function commonTryCatch(url, method = 'GET', body) {
 try {
  const res = await fetch(url, {
   method,
   body,
   headers: {
    AUTH: localStorage.getItem('tokenLibrary'),
   },
  });
  if (res.status === 401) {
   localStorage.clear();
   localStorage.setItem('modal401', true);
  }
  const json = await res.json();
  return json;
 } catch (error) {
  console.log(error);
 }
}
