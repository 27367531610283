const initialState = {
 bookOut: [],

 bookAdd: [],
 searchAvtorsAddBook: [],

 bookItem: [],

 readers: [],

 staff: [],

 role: [],
 book_files_el: [],
 book_files_marc: [],
 libraryLib: [],
 libraryClub: [],
 libraryCircle: [],

 library_id: [],
 reg_rules: [],
 srvc_rules: [],

 numbersBtnPag: 0,

 maxNumberBtnPage: 0,

 numbersItem: 0,

 totalAllItem: 0,

 booksOnHand: 0,

 logIn: false,

 loader: true,

 roleUser: '',

 name: '',

 other_data1: [],

 // -----
 obrabotka: [],
 ebook_add: [],
 create_srav: [],
 static_sved: [],
 sved_user: [],
 static_read: [],
 subcr_base: [],
 subcr_otchet: [],
 total_stat: [],
 svod_otchet: [],
 komplekt_popular: [],
 komplekt_invent: [],

 store_bron: [],
 dolzhen: [],
 kol_book: 0,
 marc: [],
 prav: [],
 otrasl_read: [],
 subcr_calendar: [],
 otchet_set: [],
 subcr_calendar: [],
 komplekt_act: [],
 komplekt_otchet: [],
 spisan_book: [],
 komplekt_ksu: [],
 zabron_book: [],
 ElStat: [],
 SiglsDate: [],
 RolesData: [],
 StaffEducation: [],
 MaritalStatusData: [],
 PositionsData: [],

 LanguageSelectData: [],

 EducationSelectData: [],
 NationalitySelectData: [],
 SchoolSelectData: [],
 StatusSelectData: [],
 IstPosSelectData: [],
 VidDokSelectData: [],
 SoderjSelectData: [],
 OtrasliZnaniiSelectData: [],
 GenresSelectData: [],
 BOPartiaPart: [],

 PublPlaceDataSelectsData: [],
 SupliersDataSelectsData: [],
 Education2DataSelectsData: [],
 StaffingSelectsDataData: [],
 LibLevelSelectsDataData: [],

 UDKSelectsByGeneral: [],
 UDKSelectsByKazakstanika: [],
 UDKSelectsByKraeved: [],
 UDKSelectsByBBK: [],
 // temp
 red_stela: [],
};

const reducer = (state = initialState, action) => {
 switch (action.type) {
  case 'LOG_IN': {
   return {
    ...state,
    logIn: action.payload,
    // roleUser: action.role,
    // name: action.user,
    // lib_name: action.libname,
    // totalAllItem: action.numAllBook,
   };
  }

  case 'LOG_OUT': {
   return {
    ...state,
    logIn: false,
   };
  }

  case 'SET_PublPlaceDataSelects_SELECT_DATA': {
   return {
    ...state,
    PublPlaceDataSelectsData: action.payload,
   };
  }
  case 'SET_SupliersDataSelects_SELECT_DATA': {
   return {
    ...state,
    SupliersDataSelectsData: action.payload,
   };
  }
  case 'SET_Education2DataSelects_SELECT_DATA': {
   return {
    ...state,
    Education2DataSelectsData: action.payload,
   };
  }
  case 'SET_StaffingSelectsData_SELECT_DATA': {
   return {
    ...state,
    StaffingSelectsDataData: action.payload,
   };
  }
  case 'SET_LibLevelSelectsData_SELECT_DATA': {
   return {
    ...state,
    LibLevelSelectsDataData: action.payload,
   };
  }

  case 'SET_UDKSelectsByGeneral_SELECT_DATA': {
   return {
    ...state,
    UDKSelectsByGeneral: action.payload,
   };
  }

  case 'SET_UDKSelectsByKazakstanika_SELECT_DATA': {
   return {
    ...state,
    UDKSelectsByKazakstanika: action.payload,
   };
  }

  case 'SET_UDKSelectsByKraeved_SELECT_DATA': {
   return {
    ...state,
    UDKSelectsByKraeved: action.payload,
   };
  }

  case 'SET_UDKSelectsByBBK_SELECT_DATA': {
   return {
    ...state,
    UDKSelectsByBBK: action.payload,
   };
  }

  case 'SET_LanguageSelectData_DATA': {
   return {
    ...state,
    LanguageSelectData: action.payload,
   };
  }

  case 'SET_SIGLS_DATE': {
   return {
    ...state,
    SiglsDate: action.payload,
   };
  }

  case 'SET_ROLES_DATA': {
   return {
    ...state,
    RolesData: action.payload,
   };
  }

  case 'SET_STAFF_EDUCATION_DATA': {
   return {
    ...state,
    StaffEducation: action.payload,
   };
  }
  case 'SET_MARITAL_DATA': {
   return {
    ...state,
    MaritalStatusData: action.payload,
   };
  }
  case 'SET_POSITIONS_DATA': {
   return {
    ...state,
    PositionsData: action.payload,
   };
  }

  case 'SET_BOPartiaPart_SELECT_DATA': {
   return {
    ...state,
    BOPartiaPart: action.payload,
   };
  }

  case 'SET_Genres_SELECT_DATA': {
   return {
    ...state,
    GenresSelectData: action.payload,
   };
  }

  case 'SET_OtrasliZnanii_SELECT_DATA': {
   return {
    ...state,
    OtrasliZnaniiSelectData: action.payload,
   };
  }

  case 'SET_Soderj_SELECT_DATA': {
   return {
    ...state,
    SoderjSelectData: action.payload,
   };
  }

  case 'SET_VidDok_SELECT_DATA': {
   return {
    ...state,
    VidDokSelectData: action.payload,
   };
  }

  case 'SET_IstPos_SELECT_DATA': {
   return {
    ...state,
    IstPosSelectData: action.payload,
   };
  }

  case 'SET_Status_SELECT_DATA': {
   return {
    ...state,
    StatusSelectData: action.payload,
   };
  }

  case 'SET_School_SELECT_DATA': {
   return {
    ...state,
    SchoolSelectData: action.payload,
   };
  }

  case 'SET_Education_SELECT_DATA': {
   return {
    ...state,
    EducationSelectData: action.payload,
   };
  }

  case 'SET_Nationality_SELECT_DATA': {
   return {
    ...state,
    NationalitySelectData: action.payload,
   };
  }
  case 'SET_DATE': {
   if (action.page === 'other_data') {
    const role = action.payload.slice(action.payload.length - 1, action.payload.length).flat();
    return {
     ...state,
     role,
     searchAvtorsAddBook: [],
    };
   } else if (
    // action.item === 'База книг' ||
    action.item === 'marc' ||
    action.item === 'ebook_add' ||
    action.item === 'create_srav' ||
    action.item === 'komplekt_act' ||
    action.item === 'ElStat'
   ) {
    // if (action.page === 'bookAdd') {
    return {
     ...state,
     [action.page]: action.payload.slice(0, action.payload.length - 3),
     numbersBtnPag: +action.payload.slice(action.payload.length - 3, action.payload.length - 2),
     totalAllItem: +action.payload.slice(action.payload.length - 2, action.payload.length - 1),
     booksOnHand: +action.payload.slice(action.payload.length - 1, action.payload.length),
     loader: false,
    };
    // }
    // return {
    //     ...state,
    //     [action.page]: action.payload.slice(0, action.payload.length - 1),
    //     numbersBtnPag: +action.payload.slice(action.payload.length - 1, action.payload.length),
    //     maxNumberBtnPage: +action.payload.slice(action.payload.length - 1, action.payload.length),
    // }
   } else if (action.item === 'Читатели') {
    return {
     ...state,
     [action.page]: action.payload.slice(0, action.payload.length - 2),
     numbersBtnPag: +action.payload.slice(action.payload.length - 2, action.payload.length - 1),
     totalAllItem: +action.payload.slice(action.payload.length - 1, action.payload.length),
    };
   } else if (action.item === 'bookWithSeacrch') {
    let [numbersBtnPag] = action.payload.slice(action.payload.length - 4, action.payload.length - 3);
    let [numbersItem] = action.payload.slice(action.payload.length - 3, action.payload.length - 2);
    let [booksOnHand] = action.payload.slice(action.payload.length - 2, action.payload.length - 1);
    let [searchAvtorsAddBook] = action.payload.slice(action.payload.length - 1, action.payload.length);
    return {
     ...state,
     [action.page]: action.payload.slice(0, action.payload.length - 4),
     numbersItem,
     numbersBtnPag,
     booksOnHand,
     searchAvtorsAddBook,
    };
   } else if (action.item === 'Сотрудники') {
    return {
     ...state,
     [action.page]: action.payload,
     //  numbersBtnPag: +action.payload.slice(action.payload.length - 1, action.payload.length),
    };
   } else if (action.item === 'obrabotka') {
    return {
     ...state,
     [action.page]: action.payload,
     //  numbersBtnPag: +action.payload.slice(action.payload.length - 1, action.payload.length),
    };
   } else if (action.item === 'library_id') {
    return {
     ...state,
     [action.page]: action.payload.sort((a, b) => (a.name > b.name ? 1 : -1)),
     loader: false,
    };
   } else {
    return {
     ...state,
     [action.page]: action.payload,
     loader: false,
    };
   }
  }

  case 'DEL_ITEM': {
   return {
    ...state,
    [action.state]: state[action.state].filter((el) => +el.id !== +[action.id]),
   };
  }

  case 'EDIT_USER': {
   return {
    ...state,
    staff: state.staff.map((el) => (el.id === action.user.id ? action.user : el)),
   };
  }

  // -----------
  case 'BRONE_POLKA': {
   return {
    ...state,
    store_bron: state.store_bron.map((el) => (el.id === action.id ? { ...el, busy: action.value } : el)),
   };
  }
  case 'RETURN_BOOK': {
   return {
    ...state,
    bookAdd: state.bookAdd.map((el) => (el.id === action.id ? { ...el, busy: '0' } : el)),
   };
  }
  case 'ADD_PERIOD_CALENDAR': {
   return { ...state, subcr_calendar: [...state.subcr_calendar, action.item] };
  }

  case 'CLEAN_ANY_STATE': {
   return { ...state, [action.payload]: [] };
  }

  default:
   return state;
 }
};

export default reducer;
