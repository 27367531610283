import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import TypeLibrarys from '../../../components/tables/type_librarys';
import CountApp from '../../../components/tables/count_app';
import OsnovCharacterLib from '../../../components/tables/osnov_charcter_lib';
import DvizLibFond from '../../../components/tables/dvizh_lib_fond';
import Postupilo from '../../../components/tables/postupilo';
import Vubulo from '../../../components/tables/vubulo';
import Sostoit from '../../../components/tables/sostoit';
import VubuloVtechGoda from '../../../components/tables/vubulo_v_tech_goda';
import SostoitNaKonGoda from '../../../components/tables/sostoit_na_kon_goda';
import VudanoVtechGoda from '../../../components/tables/vubulo_v_tech_goda';
import InfoTech from '../../../components/tables/info-tech';

import Loader from '../../../components/loader';

import './style.css'

class OtchetLib extends Component {

    state = {
        tableChoice: ''
    }

    tableChoice = (e) => {
        this.setState({ tableChoice: e.target.dataset.set })
    }

    async componentDidMount() {
        await this.props.getData('svod_otchet', 'svod_otchet')
    }

    render() {

        const table = [{ key: 'TypeLibrarys', table: <TypeLibrarys /> }, { key: 'CountApp', table: <CountApp /> },
        { key: 'OsnovCharacterLib', table: <OsnovCharacterLib /> }, { key: 'DvizLibFond', table: <DvizLibFond /> },
        { key: 'Postupilo', table: <Postupilo /> }, { key: 'Vubulo', table: <Vubulo /> }, { key: 'Sostoit', table: <Sostoit /> },
        { key: 'VubuloVtechGoda', table: <VubuloVtechGoda /> }, { key: 'SostoitNaKonGoda', table: <SostoitNaKonGoda /> },
        { key: 'VudanoVtechGoda', table: <VudanoVtechGoda /> }, { key: 'InfoTech', table: <InfoTech /> }]

        const tableChoice = [{ title: 'Типы библиотек Павлодара', key: 'TypeLibrarys' }, { title: 'Количество зданий(помещений)', key: 'CountApp' },
        { title: 'Основные характеристики  деятельности библиотек', key: 'OsnovCharacterLib' }, { title: 'Сосотоит на начало 2022г.', key: 'DvizLibFond' },
        { title: 'Поступило в течении года', key: 'Postupilo' }, { title: 'Выбыло в течении года', key: 'Vubulo' },
        { title: 'Состоит на конец года', key: 'Sostoit' }, { title: 'Выбытие в течении года', key: 'VubuloVtechGoda' },
        { title: 'Состоит', key: 'SostoitNaKonGoda' }, { title: 'Выдача литературы. Выдано в течении года', key: 'VudanoVtechGoda' },
        { title: 'Информационно-коммуникационные технологии в библиотеки', key: 'InfoTech' }]

        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>Общие сведения по библиотекам</h1>
                    </div>

                    {this.props.svod_otchet.length
                        ?
                        <div className='table'>

                            <div className='table-choice'>
                                {tableChoice.map((el, i) => {
                                    return (
                                        <p key={i} data-set={el.key} onClick={this.tableChoice}>{el.title}</p>
                                    )
                                })}
                            </div>

                            {table.filter(el => el.key === this.state.tableChoice).map(el => el.table)}

                        </div>
                        :
                        <Loader />}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(OtchetLib);