import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../../redux/action';

import { SmartSreach } from '../../../../api';

import Button from '../../../../components/button';
import Modal from '../../../../components/modal';

import arrow_add from '../../../../assets/icon/arrow-menu.png';
import close from '../../../../assets/icon/modal-genre-close.png';

class FormBookAdd extends Component {
 state = {
  typeItem: 'article',

  addbook: {
   name: '',
   file: '',
  },
 };

 handleChangeModal = async ({ target }) => {
  if (target.name === 'file') {
   await this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.files[0] } });
  } else {
   await this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.value } });
  }
 };

 handleSubmit = async (e) => {
  e.preventDefault();

  if (this.state.addbook.name.trim().length) {
   const formData = await new FormData();
   for (const key in this.state.addbook) {
    await formData.append(`${key}`, this.state.addbook[key]);
   }
   formData.append('staff', localStorage.getItem('idUser'));
   const res = await fetch('https://smart-pvl.kz/Api/AddDoc.php', {
    method: 'POST',
    body: formData,
    staff: localStorage.getItem('idUser'),
   });
   await res.json();

   this.setState({
    addbook: Object.keys(this.state.addbook).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
   });
  } else {
   alert('Заполните поля');
  }
 };

 //живой поиск Жанра
 searchZhanr = async ({ target }) => {
  await this.setState({ zharnInput: target.value });
  if (this.state.zharnInput.length >= 3) {
   const json = await SmartSreach.SmartSreach({
    col: 'zhanr',
    tab: 'other_data',
    id_name: 'zhanr',
    id_val: target.value,
   });
   const zhanr = await json.slice(0, json.length - 2);
   await this.setState({ zhanr });
  }
  if (this.state.zharnInput.length === 0) {
   await this.setState({ zhanr: [] });
  }
 };

 render() {
  console.log(this.state.addbook);
  const atrFormAddArticle = [
   { title: 'Название', name: 'name', type: 'text' },
   { title: 'Выберите файл', name: 'file', type: 'file' },
  ];

  return (
   <div className='form-book-add'>
    <div className='form-add'>
     <form className='form-add-wrapper' onSubmit={this.handleSubmit}>
      {
       <div className='form-add-sprav'>
        {atrFormAddArticle.map((el, i) => {
         return (
          <div className='search-filter-article' key={i}>
           <p>{el.title}</p>
           {el.type === 'file' ? (
            <input
             type={el.type}
             autoComplete='off'
             name={el.name}
             placeholder='Обязательные поля ввода'
             onChange={this.handleChangeModal}
            />
           ) : (
            <input
             type={el.type}
             autoComplete='off'
             name={el.name}
             placeholder='Обязательные поля ввода'
             value={this.state.addbook[el.name]}
             onChange={this.handleChangeModal}
            />
           )}
          </div>
         );
        })}
       </div>
      }

      <div className='form-add-img-btn'>
       <Button className='btn_add_book' text='Добавить' />
      </div>
     </form>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
