import React from 'react';
import './styles.css';
import { Chart } from 'react-charts';

export default function ChartF(data) {
 const series = React.useMemo(
  () => ({
   type: 'line',
  }),
  []
 );
 const axes = React.useMemo(
  () => [
   { primary: true, position: 'bottom', type: 'utc' },
   { position: 'left', type: 'linear' },
  ],
  []
 );
 //  const data = React.useMemo(
 //   () => [
 //    {
 //     label: 'Количество человек',
 //     datums: [
 //      { x: new Date('2020-01-01'), y: 16 },
 //      { x: new Date('2020-02-01'), y: 6 },
 //      { x: new Date('2020-03-01'), y: 10 },
 //      { x: new Date('2020-04-01'), y: 2 },
 //      { x: new Date('2020-05-01'), y: 8 },
 //      { x: new Date('2020-06-01'), y: 3 },
 //      { x: new Date('2020-07-01'), y: 22 },
 //      { x: new Date('2020-08-01'), y: 15 },
 //      { x: new Date('2020-09-01'), y: 17 },
 //      { x: new Date('2020-10-01'), y: 4 },
 //      { x: new Date('2020-11-01'), y: 12 },
 //      { x: new Date('2020-12-01'), y: 20 },
 //     ],
 //    },
 //   ],
 //   []
 //  );
 return (
  <div className='chart'>
   {console.log(data.data)}
   <div style={{ width: '800px', height: '500px' }}>
    <Chart data={data.data} series={series} axes={axes} tooltip />
   </div>
  </div>
 );
}
