import commonTryCatch from './commonTryCatch';
import toast from 'react-hot-toast';

export default async function tryCatchAddAfterEvent(url, data, event, name, item) {
 let adding = '';
 if (name === 'Образование' && item === 'reader') {
  adding = '_fin';
 }
 try {
  const res = await commonTryCatch(
   `https://smart-pvl.kz/Api/${url}/${event}${adding}.php`,
   'POST',
   JSON.stringify(data)
  );
  const isDel = await res;

  if (isDel) {
   let res;
   if (url === 'api/classifiers') {
    res = await commonTryCatch(`https://smart-pvl.kz/Api/${url}/get.php`, 'POST', JSON.stringify({ type: data.type }));
   } else {
    res = await commonTryCatch(`https://smart-pvl.kz/Api/${url}/get${adding}.php`);
   }
   toast('Успешно!');
   return res;
  }
 } catch (error) {
  toast('Что-то пошло не так..');
 }
}
