import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';
import { tableKSU } from '../../../api';

import Loader from '../../../components/loader';

import './style.css';

import Button from '../../../components/button';
import KsuTable from '../../../components/tables/KsuTable/KsuTable';

class CreateSrav extends Component {
 state = {
  lib: '',
  start: '',
  end: '',
 };

 handleChange = ({ target }) => {
  this.setState({ [target.name]: target.value });
 };

 filter = async () => {
  const json = await tableKSU.tableKSU(this.state);
  await this.props.setDate(json, 'komplekt_ksu');
 };

 setData = async (e) => {
  await this.setState({ lib: e.target.dataset.set });
  const json = await tableKSU.tableKSU(this.state);
  await this.props.setDate(json, 'komplekt_ksu');
 };

 render() {
  console.log('ksu', this.props.komplekt_ksu);
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Ведение книги суммарного учета (КСУ)</h1>
     </div>
     <KsuTable />
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(CreateSrav);
