import React from 'react';

import './modal.css';

const Modal = ({ handleClose, show, children, btn, classBtn, closeModalEvent }) => {
 //   if (show) {
 //     document.body.style.overflow = 'hidden'
 //     document.body.style.paddingRight = '20px'
 // } else {
 //     document.body.style.overflow = 'visible'
 //     document.body.style.paddingRight = '0'
 // }

 let showHideClassName = show ? 'modal1 display-block' : 'modal1 display-none';
 return (
  <div className={showHideClassName} data-type='modal' onClick={(e) => closeModalEvent(e.target.dataset.type)}>
   <section className='modal-main1'>
    {children}
    <button type='button' className={classBtn} onClick={handleClose}>
     {btn}
    </button>
   </section>
  </div>
 );
};

export default Modal;
