import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import moment from 'moment';
import 'moment/locale/ru';
import styled from 'styled-components';

import FormBookAdd from './formBookAdd';

import './style.css';
import Button from '../../../components/button';

const GridWrapper = styled.div`
 display: grid;
 grid-template-columns: repeat(7, 1fr);
 //   grid-template-rows: repeat(6, 1fr);
 grid-gap: 1px;
 background-color: ${(props) => (props.isHeader ? '#1E1F21' : '#4D4C4D')};
 ${(props) => props.isHeader && 'border-bottom: 1px solid #4D4C4D'}
`;
const CellWrapper = styled.div`
 min-height: ${(props) => (props.isHeader ? 24 : 80)}px;
 min-width: 140px;
 background-color: ${(props) => (props.isWeekday ? '#27282A' : '#1E1F21')};
 color: ${(props) => (props.isSelectedMonth ? '#DDDDDD' : '#555759')};
`;
const RowInCell = styled.div`
 display: flex;
 justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
 ${(props) => props.pr && `padding-right: ${props.pr * 8}px`}
`;
const DayWrapper = styled.div`
 height: 31px;
 width: 31px;
 display: flex;
 align-items: center;
 justify-content: center;
 margin: 2px;
`;
const CurrentDay = styled('div')`
 height: 100%;
 width: 100%;
 background: #f00;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
`;
const WorkoutItem = styled('div')`
 width: 100%;
 background: rgb(255 228 196);
 border-radius: 5px;
 color: #000;
 text-align: center;
 border: 1px solid #000;
 cursor: pointer;
`;

class LibraryAdr extends Component {
 state = {
  today: moment(),

  choiceItemPage: 'book',
 };

 getData = async (e) => {
  await this.setState({ choiceItemPage: e.target.dataset.set });
  await this.props.getData('subcr_calendar', 'subcr_calendar');
 };

 delPeriod = async (id) => {
  const res = await fetch('https://smart-pvl.kz/Api/DelPeriodDate.php', {
   method: 'POST',
   body: JSON.stringify({ id, staff: localStorage.getItem('idUser') }),
  });
  const json = await res.json();
  await this.props.setDate(json, 'subcr_calendar');
 };

 prevHandler = async () => {
  await this.setState({ today: this.state.today.clone().subtract(1, 'month') });
  // const month = this.state.today.clone().format('MM');
  // const year = await this.state.today.clone().subtract(1, "month").format('MMMM') === 'декабрь' ? this.state.today.clone().subtract(1, "year").format("YYYY") : this.state.today.clone().format("YYYY");
  // const item = await this.props.page.slice(1, this.props.page.length)
 };

 todayHandler = async () => {
  await this.setState({ today: moment() });
  // const item = await this.props.page.slice(1, this.props.page.length)
  // const date = await new Date().toLocaleDateString().split('.').reverse().slice(0, 2).join('-')
 };

 nextHandler = async () => {
  await this.setState({ today: this.state.today.clone().add(1, 'month') });
  // const month = this.state.today.clone().format('MM');
  // const year = await this.state.today.clone().add(1, "month").format('MMMM') === 'декабрь' ? this.state.today.clone().add(1, "year").format("YYYY") : this.state.today.clone().format("YYYY");
  // const item = await this.props.page.slice(1, this.props.page.length)
 };

 async componentDidMount() {
  await this.props.getData('subcr_calendar', 'subcr_calendar');
 }

 render() {
  const startDay = this.state.today.clone().startOf('month').startOf('week');

  const totalDays = 42;
  const day = startDay.clone().subtract(1, 'day');
  const daysMap = [...Array(totalDays)].map(() => day.add(1, 'day').clone());

  const isCurrentDay = (day) => moment().isSame(day, 'day');
  const isSelectedMonth = (day) => this.state.today.isSame(day, 'month');

  const choiceItem = [
   { title: 'Календарь', dataset: 'book' },
   { title: 'Добавить', dataset: 'add' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Контроль поступления периодических изданий</h1>
     </div>

     <div className='choice-type'>
      {choiceItem.map((el, i) =>
       el === 'Добавить' ? (
        <h3 data-set={el.dataset} key={i} onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}>
         {el.title}
        </h3>
       ) : (
        <h3 data-set={el.dataset} key={i} onClick={this.getData}>
         {el.title}
        </h3>
       )
      )}
     </div>

     {this.state.choiceItemPage === 'add' ? (
      <FormBookAdd />
     ) : (
      <div className='ShadowWrapper'>
       <div className='DivWrapperTitle'></div>

       <div className='DivWrapperMonitor'>
        <div>
         <span className='TitleWrapper'>{this.state.today.format('MMMM')}</span>
         <span className='TextWrapper'>{this.state.today.format('YYYY')}</span>
        </div>

        <div className='ButtonsWrapper'>
         <button className='ButtonWrapper' onClick={this.prevHandler}>
          &lt;
         </button>
         <button className='TodayButton' onClick={this.todayHandler}>
          Сегодня
         </button>
         <button className='ButtonWrapper' onClick={this.nextHandler}>
          &gt;
         </button>
        </div>
       </div>

       <>
        <GridWrapper isHeader>
         {[...Array(7)].map((el, i) => (
          <CellWrapper isHeader isSelectedMonth key={i}>
           <RowInCell justifyContent={'flex-end'} pr={1}>
            {moment()
             .day(i + 1)
             .format('ddd')}
           </RowInCell>
          </CellWrapper>
         ))}
        </GridWrapper>

        <GridWrapper>
         {daysMap.map((dayItem) => (
          <CellWrapper
           isWeekday={dayItem.day() === 6 || dayItem.day() === 0}
           key={dayItem.unix()}
           isSelectedMonth={isSelectedMonth(dayItem)}
          >
           <RowInCell justifyContent={'flex-end'}>
            <DayWrapper>
             {!isCurrentDay(dayItem) && dayItem.format('D')}
             {isCurrentDay(dayItem) && <CurrentDay>{dayItem.format('D')}</CurrentDay>}
            </DayWrapper>
           </RowInCell>
           {this.props.subcr_calendar.map((el, i) => {
            if (el.date == dayItem.format('YYYY-MM-DD')) {
             return (
              <WorkoutItem key={i}>
               <div className='itemRasp'>
                <p>{el.name}</p>
                <Button text='X' className='circle_btn_red' onClick={() => this.delPeriod(el.id)} />
               </div>
              </WorkoutItem>
             );
            }
           })}
          </CellWrapper>
         ))}
        </GridWrapper>
       </>
      </div>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(LibraryAdr);
