import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../redux/action';

import { accept_batch_g, getPartsByID_g } from '../../api';

import Button from '../button';
import Modal from '../modal';

import moment from 'moment';

import toast from 'react-hot-toast';

class FormPartView_g extends Component {
 state = {
  batchID: '',
  datePostuplenia: '',
  documentNumber: '',
  dateByDoc: '',
  postavshik: '',
  postavshikText: '',
  typeOfSource: '',
  typeOfSourceText: '',
  primechanie: '',
  number: 0,
  kolExempl: 0,
  GeneralSumma: 0,
  SoprovDoc: '',
  avtorInput: '',
  izdatelInput: '',
  avtorsGetMassive: [],
  avtorSelectedN: -1,
  avtorSelectedItem: '',
  publishersGetMassive: [],
  publisherSelectedN: -1,
  publisherSelectedItem: '',
  specialMassive: [],
  currentBO: '',
  minorCheck: false,
  majorMassive: [],
  addAvtor: {
   name: '',
  },
  showModal: false,
  showModal2: false,
  showModal3: false,
  showModal4: false,
  showModal5: false,
  showModal6: false,
  addbook: {
   part_id: '',
   avtor: '1',
   name: '',
   place_izdn: 0,
   izdatel: 0,
   ISBN: '',
   po_soderj: 0,
   num_chasti: '',
   name_chasti: '',
   god: 0,
   price: 0,
   otrasli: 0,
   summa: 0,
   kolichestvo: 0,
   libLevel: 0,
   author_name: '',
   publisher_name: '',
  },
 };

 specialMassiveGenerate = (e) => {
  this.setState({ number: e });
  var foo = [];
  for (var i = 1; i <= Number(e); i++) {
   foo.push(i);
  }
  this.setState({ specialMassive: foo });
 };

 AvtorAddSend = async () => {
  const SendObj = {
   name: this.state.addAvtor.name,
  };
  const res = await fetch('https://smart-pvl.kz/Api/api/author/add.php', {
   method: 'POST',
   headers: {
    AUTH: localStorage.getItem('tokenLibrary'),
   },
   body: JSON.stringify(SendObj),
  });
  const json = await res.json();
  console.log(json);
  if (json) {
   this.setState({ showModal6: false, showModal2: true, avtorSelectedItem: json, avtorSelectedN: 0 });
   toast.success('Автор успешно создан!');
  }
 };
 ojbectGenerate = (name, value) => {
  const key = name;
  var obj = {};
  obj[key] = value;

  return obj;
 };

 getNameAndValue2 = (select) => {
  const foo = select.target.selectedOptions[0].getAttribute('name');
  this.setState({ typeOfSource: select.target.value, typeOfSourceText: foo });
 };

 getNameAndValue3 = (select) => {
  this.setState({ addbook: { ...this.state.addbook, otrasli: select.target.value } });
 };

 getNameAndValue4 = (select) => {
  const foo = select.target.selectedOptions[0].getAttribute('name');
  this.setState({ postavshik: select.target.value, postavshikText: foo });
 };

 getNameAndValue5 = (select) => {
  this.setState({ SoprovDoc: select.target.value });
 };

 getNameAndValue6 = (select) => {
  this.setState({ addbook: { ...this.state.addbook, place_izdn: select.target.value } });
 };

 getNameAndValue7 = (select) => {
  this.setState({ addbook: { ...this.state.addbook, libLevel: select.target.value } });
 };

 getNameAndValue8 = (select) => {
  this.setState({ addbook: { ...this.state.addbook, po_soderj: select.target.value } });
 };

 showModal = async (el, type = null) => {
  this.setState({
   showModal: true,
  });
 };

 closeModalWithSelect3 = () => {
  this.setState({ showModal5: false, showModal2: true });
  this.setState({
   addbook: {
    ...this.state.addbook,
    izdatel: this.state.publisherSelectedItem.id,
    publisher_name: this.state.publisherSelectedItem.name,
   },
  });
 };

 PublisherSelectHandle = (e, i) => {
  this.setState({ publisherSelectedN: i, publisherSelectedItem: e });
 };

 openModal2 = (i) => {
  var timelessArray = {};
  this.state.majorMassive.map((item) => (Object.keys(item)[0] == i ? (timelessArray = item) : null));

  var tempAuthorObj = {
   id: '',
   name: '',
  };

  var tempPublisherObj = {
   id: '',
   name: '',
  };

  if (typeof timelessArray[i] !== 'undefined') {
   tempAuthorObj.id = timelessArray[i].avtor;
   tempAuthorObj.name = timelessArray[i].author_name;
   tempPublisherObj.id = timelessArray[i].izdatel;
   tempPublisherObj.name = timelessArray[i].publisher_name;
  }

  typeof timelessArray[i] == 'undefined'
   ? console.info()
   : this.setState({
      addbook: {
       avtor: timelessArray[i].avtor,
       name: timelessArray[i].name,
       place_izdn: timelessArray[i].place_izdn,
       izdatel: timelessArray[i].izdatel,
       ISBN: timelessArray[i].ISBN,
       libLevel: timelessArray[i].libLevel,
       po_soderj: timelessArray[i].po_soderj,
       num_chasti: timelessArray[i].num_chasti,
       name_chasti: timelessArray[i].name_chasti,
       part_id: timelessArray[i].part_id,
       god: timelessArray[i].god,
       price: timelessArray[i].price,
       otrasli: timelessArray[i].otrasli,
       summa: timelessArray[i].summa,
       kolichestvo: timelessArray[i].kolichestvo,
      },
      avtorSelectedN: timelessArray[i].avtor,
      avtorSelectedItem: tempAuthorObj,
      publisherSelectedN: timelessArray[i].izdatel,
      publisherSelectedItem: tempPublisherObj,
     });
  this.setState({ currentBO: i, showModal2: true });
 };

 closeModalWithSelect = () => {
  this.setState({ showModal3: false });
  this.setState({
   addbook: {
    ...this.state.addbook,
    avtor: this.state.avtorSelectedItem.id,
    author_name: this.state.avtorSelectedItem.name,
   },
  });
 };

 PublisherChangeHandle = async () => {
  if (this.state.izdatelInput.trim().length !== 0) {
   const objForm = { keyword: this.state.izdatelInput };
   const formData = new FormData();
   formData.append(`keyword`, this.state.izdatelInput);

   const res = await fetch('https://smart-pvl.kz/Api/api/publisher/get.php', {
    method: 'POST',
    headers: {
     AUTH: localStorage.getItem('tokenLibrary'),
    },
    body: JSON.stringify(objForm),
   });
   const json = await res.json();
   if (json.length === 0) {
    this.setState({ showModal2: false, showModal5: true, publishersGetMassive: [] });
   } else {
    this.setState({ showModal2: false, publishersGetMassive: json, showModal5: true });
   }
  }
 };

 openAddAvtorModal = () => {
  this.setState({ showModal3: false, avtorSelectedItem: '', avtorSelectedN: -1, showModal6: true });
 };

 AvtorSelectHandle = (e, i) => {
  this.setState({ avtorSelectedN: i, avtorSelectedItem: e });
 };

 closeModal2 = () => {
  this.setState({ currentBO: '', showModal2: false });
  this.setState({
   addbook: {
    avtor: '1',
    name: '',
    place_izdn: 0,
    izdatel: 0,
    ISBN: '',
    po_soderj: 0,
    num_chasti: '',
    name_chasti: '',
    god: 0,
    price: 0,
    otrasli: 0,
    summa: 0,
    kolichestvo: 0,
   },
  });
 };

 AvtorChangeHandle = async () => {
  if (this.state.avtorInput.trim().length !== 0) {
   const objForm = { keyword: this.state.avtorInput };
   const formData = new FormData();
   formData.append(`keyword`, this.state.avtorInput);

   const res = await fetch('https://smart-pvl.kz/Api/api/author/get.php', {
    method: 'POST',
    headers: {
     AUTH: localStorage.getItem('tokenLibrary'),
    },
    body: JSON.stringify(objForm),
   });
   const json = await res.json();
   if (json.length === 0) {
    this.setState({ showModal2: false, showModal3: true, avtorsGetMassive: [] });
   } else {
    this.setState({ showModal2: false, avtorsGetMassive: json, showModal3: true });
   }
  }
 };

 handleChangeBOinputs = (targetName) => {
  console.log(this.state.addbook);
  if (this.state.addbook.name.trim().length) {
   if (this.state.addbook.place_izdn !== 0) {
    if (this.state.addbook.god !== 0) {
     if (this.state.addbook.price !== 0) {
      if (this.state.addbook.otrasli !== 0) {
       if (this.state.addbook.summa !== 0) {
        if (this.state.addbook.kolichestvo !== 0) {
         if (this.state.addbook.kolichestvo > 0) {
          if (this.state.addbook.price !== 0) {
           if (this.state.addbook.price > 0) {
            if (this.state.addbook.po_soderj !== 0) {
             if (this.state.addbook.izdatel !== 0) {
              if (this.state.addbook.ISBN.trim().length) {
               if (this.state.addbook.libLevel !== 0) {
                var timelessCheck = false;
                this.state.majorMassive.map((item) =>
                 Object.keys(item)[0] == targetName ? (timelessCheck = true) : null
                );
                if (timelessCheck == true) {
                 var timelessArray = this.state.majorMassive;
                 //  console.log(timelessArray);

                 timelessArray.map((item) =>
                  Object.keys(item)[0] == targetName
                   ? ((item[targetName].avtor = this.state.addbook.avtor),
                     (item[targetName].author_name = this.state.addbook.author_name),
                     (item[targetName].part_id = this.state.addbook.part_id),
                     (item[targetName].name = this.state.addbook.name),
                     (item[targetName].place_izdn = this.state.addbook.place_izdn),
                     (item[targetName].publisher_name = this.state.addbook.publisher_name),
                     (item[targetName].god = this.state.addbook.god),
                     (item[targetName].price = this.state.addbook.price),
                     (item[targetName].otrasli = this.state.addbook.otrasli),
                     (item[targetName].summa = this.state.addbook.summa),
                     (item[targetName].kolichestvo = this.state.addbook.kolichestvo),
                     (item[targetName].name_chasti = this.state.addbook.name_chasti),
                     (item[targetName].num_chasti = this.state.addbook.num_chasti),
                     (item[targetName].po_soderj = this.state.addbook.po_soderj),
                     (item[targetName].ISBN = this.state.addbook.ISBN),
                     (item[targetName].izdatel = this.state.addbook.izdatel))
                   : console.info()
                 );
                 this.setState({ majorMassive: timelessArray, currentBO: '', showModal2: false });
                } else {
                 const minorObj = this.ojbectGenerate(targetName, this.state.addbook);
                 var joined = this.state.majorMassive.concat(minorObj);
                 this.setState({ majorMassive: joined, currentBO: '', showModal2: false });
                }
                this.setState({
                 addbook: {
                  avtor: '1',
                  name: '',
                  place_izdn: 0,
                  izdatel: 0,
                  ISBN: '',
                  po_soderj: 0,
                  num_chasti: '',
                  name_chasti: '',
                  god: 0,
                  price: 0,
                  otrasli: 0,
                  summa: 0,
                  kolichestvo: 0,
                  libLevel: 0,
                  author_name: '',
                  publisher_name: '',
                 },
                });
               } else {
                toast.error('Заполните поле "Библиографический уровень".');
               }
              } else {
               toast.error('Заполните поле "ISBN".');
              }
             } else {
              toast.error('Заполните поле "Издатель".');
             }
            } else {
             toast.error('Заполните поле "По содержанию".');
            }
           } else {
            toast.error('"Цена" не может быть отрицательным значением.');
           }
          } else {
           toast.error('Заполните поле "Цена".');
          }
         } else {
          toast.error('"Количество экземляров" не может быть отрицательным значением.');
         }
        } else {
         toast.error('Заполните поле "Количество экземляров".');
        }
       } else {
        toast.error('Заполните поля "Количество экземляров" и "Цена".');
       }
      } else {
       toast.error('Заполните поле "Отрасль".');
      }
     } else {
      toast.error('Заполните поле "Цена".');
     }
    } else {
     toast.error('Заполните поле "Год".');
    }
   } else {
    toast.error('Заполните поле "Место издания".');
   }
  } else {
   toast.error('Заполните поле "Заглавие".');
  }
 };

 closeModal = () => this.setState({ showModal: false });

 closeModal3 = () => {
  // this.setState({ showModal2: true, showModal3: false });
 };
 closeModal5 = () => {
  // this.setState({ showModal5: false });
 };
 closeModal6 = () => {
  //  this.setState({ showModal6: false });
 };
 closeModalWithCheck = () => {
  if (this.state.datePostuplenia.trim().length) {
   if (this.state.documentNumber.trim().length) {
    if (this.state.dateByDoc.trim().length) {
     //  if (this.state.typeOfDoc.trim().length) {
     if (this.state.postavshik !== 0) {
      if (this.state.typeOfSource !== 0) {
       if (this.state.number !== 0) {
        if (this.state.number > 0) {
         if (this.state.kolExempl !== 0) {
          if (this.state.kolExempl > 0) {
           if (this.state.GeneralSumma !== 0) {
            if (this.state.GeneralSumma > 0) {
             if (this.state.SoprovDoc.trim().length) {
              this.setState({ showModal: false, minorCheck: true });
             } else {
              toast.error('Заполните поле "Сопроводительный документ".');
             }
            } else {
             toast.error('"Сумма всей партии" не может быть отрицательным числом.');
            }
           } else {
            toast.error('Заполните поле "Сумма всей партии".');
           }
          } else {
           toast.error('"Количество экземпляров в партии" не может быть отрицательным числом.');
          }
         } else {
          toast.error('Заполните поле "Количество экземпляров в партии".');
         }
        } else {
         toast.error('"Количество БО" не может быть отрицательным числом.');
        }
       } else {
        toast.error('Заполните поле "Количество БО".');
       }
      } else {
       toast.error('Заполните поле "Тип источника".');
      }
     } else {
      toast.error('Заполните поле "Поставщик".');
     }
     //  } else {
     //   toast.error('Заполните поле "Тип документа".');
     //  }
    } else {
     toast.error('Заполните поле "Дата по документу".');
    }
   } else {
    toast.error('Заполните поле "№ документа".');
   }
  } else {
   toast.error('Заполните поле "Дата поступления".');
  }
 };

 handleChange = ({ target }) => {
  this.setState({ [target.name]: target.value });
 };

 handleChangeSelect = ({ target }) => {
  this.setState({ temArrValue: target.value });
 };

 closeModalWithoutSelect = () =>
  this.setState({
   showModal3: false,
   // avtorSelectedItem: '',
   // avtorSelectedN: -1,
   showModal5: false,
   //  publisherSelectedItem: '',
   //  publisherSelectedN: -1,
  });

 handleTestSubmit = async (type) => {
  if (this.state.majorMassive.length == this.state.number) {
   const data = {
    batchID: this.state.batchID,
   };

   const json = await accept_batch_g.accept_batch_g(data);
   //  console.log(json);
   if (json) {
    toast.success('Заказ успешно принят!');
    await this.props.returnOnFirstPage();
   } else {
    toast.error('Возникла ошибка на стороне сервера');
   }
  } else {
   toast.error('Необходимо заполнить все БО, указанные в накладной');
  }
 };

 closeModalEvent = (e) => {
  this.closeModal();
 };

 PriceHandler = (e) => {
  //   this.setState({ addbook: { ...this.state.addbook} });
  var priceXkol = e.target.value * this.state.addbook.kolichestvo;
  this.setState({ addbook: { ...this.state.addbook, summa: priceXkol, price: e.target.value } });
 };

 KolHandler = (e) => {
  //   this.setState({ addbook: { ...this.state.addbook} });
  var priceXkol = e.target.value * this.state.addbook.price;
  this.setState({ addbook: { ...this.state.addbook, summa: priceXkol, kolichestvo: e.target.value } });
 };

 closeModalEvent2 = (e) => {
  this.closeModal2();
 };

 ReformGetData = (array) => {
  var majorObj = [];
  array.map((array_item, i) => {
   const minorObj = this.ojbectGenerate(i + 1, array_item);
   majorObj.push(minorObj);
  });
  this.setState({ majorMassive: majorObj });
  console.log(majorObj);
 };

 async componentDidMount() {
  this.setState({
   batchID: this.props.choiseItemPart.id,
   datePostuplenia: this.props.choiseItemPart.entrance_date,
   documentNumber: this.props.choiseItemPart.document_number,
   dateByDoc: this.props.choiseItemPart.document_date,
   postavshik: this.props.choiseItemPart.supplier_id,
   postavshikText: this.props.choiseItemPart.type_of_source,
   typeOfSource: this.props.choiseItemPart.type_of_source_id,
   typeOfSourceText: this.props.choiseItemPart.supplier,
   primechanie: this.props.choiseItemPart.note,
   number: this.props.choiseItemPart.bo_number,
   kolExempl: this.props.choiseItemPart.exemplar_number,
   GeneralSumma: this.props.choiseItemPart.summa,
   SoprovDoc: this.props.choiseItemPart.soprov_doc,
   minorCheck: true,
  });

  this.specialMassiveGenerate(this.props.choiseItemPart.bo_number);

  const obj = {
   id: this.props.choiseItemPart.id,
  };
  const json = await getPartsByID_g.getPartsByID_g(obj);
  this.ReformGetData(json);
 }

 render() {
  return (
   <div className='form-book-add'>
    {console.log(this.props.choiseItemPart)}
    <Modal show={this.state.showModal} closeModalEvent={() => this.closeModalEvent}>
     <div className='st12'>
      <div className='st9'>
       <div className='st10'>
        <div className='4'>Дата поступления:</div>
        <div className='st11'>
         <input
          type='date'
          value={this.state.datePostuplenia}
          onChange={(e) => this.setState({ datePostuplenia: e.target.value })}
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>№ документа:</div>
        <div className='st11'>
         <input value={this.state.documentNumber} onChange={(e) => this.setState({ documentNumber: e.target.value })} />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Дата по документу</div>
        <div className='st11'>
         <input
          type='date'
          value={this.state.dateByDoc}
          onChange={(e) => this.setState({ dateByDoc: e.target.value })}
         />
        </div>
       </div>

       <div className='st10'>
        <div className='4'>Поставщик</div>
        <div className='st11'>
         <select onChange={(e) => this.getNameAndValue4(e)} value={this.state.postavshik}>
          <option hidden>Выберите из списка</option>
          {this.props.SupliersDataSelectsData.map((item, i) => {
           return (
            <option key={i} name={item.name_ru} value={item.id}>
             {item.name_ru}
            </option>
           );
          })}
         </select>
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Тип источника</div>
        <div className='st11'>
         <select onChange={(e) => this.getNameAndValue2(e)} value={this.state.typeOfSource}>
          <option hidden>Выберите из списка</option>
          {this.props.IstPosSelectData.map((item, i) => {
           return (
            <option key={i} name={item.name_ru} value={item.id}>
             {item.name_ru}
            </option>
           );
          })}
         </select>
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Примечание</div>
        <div className='st11'>
         <input value={this.state.primechanie} onChange={(e) => this.setState({ primechanie: e.target.value })} />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Количество экземпляров в партии (шт.)</div>
        <div className='st11'>
         <input
          value={this.state.kolExempl}
          type='number'
          onChange={(e) => this.setState({ kolExempl: e.target.value })}
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Сумма всей партии (тнг.)</div>
        <div className='st11'>
         <input
          value={this.state.GeneralSumma}
          type='number'
          onChange={(e) => this.setState({ GeneralSumma: e.target.value })}
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Сопроводительный документ</div>
        <div className='st11'>
         {/* <input onChange={(e) => this.setState({ SoprovDoc: e.target.value })} /> */}
         <select onChange={(e) => this.getNameAndValue5(e)} value={this.state.SoprovDoc}>
          <option hidden>Выберите из списка</option>
          <option key='1'>Накладная (каз)</option>
          <option key='2'>Накладная (рус)</option>
          <option key='3'>Акт</option>
          <option key='4'>Счет-Фактура</option>
          <option key='5'>Подписка</option>
          <option key='6'>Дар</option>
          <option key='7'>По обмену</option>
          <option key='8'>Лист гос. регистрации</option>
         </select>
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Количество БО </div>
        <div className='st11'>
         <input
          disabled
          value={this.state.number}
          type='number'
          onChange={(e) => this.specialMassiveGenerate(e.target.value)}
         />
        </div>
       </div>
      </div>
      <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
       <Button className='st3' text='Сохранить' onClick={() => this.closeModalWithCheck()} />
       <Button className='st3' text='Закрыть' onClick={() => this.closeModal()} />
      </div>
     </div>
    </Modal>

    <Modal show={this.state.showModal3} closeModalEvent={(e) => this.closeModal3()}>
     <div className='st12'>
      Количество совпадений: {this.state.avtorsGetMassive.length}
      <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
       {this.state.avtorsGetMassive.length !== 0
        ? this.state.avtorsGetMassive.map((i, j) => (
           <div
            className={j === this.state.avtorSelectedN ? 'fmba2' : 'fmba1'}
            onClick={() => this.AvtorSelectHandle(i, j)}
           >
            {i.name}
           </div>
          ))
        : null}
      </div>
      <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
       <Button
        className='st3'
        text='Выбрать'
        onClick={() => {
         this.closeModalWithSelect();
         this.setState({ showModal2: true });
        }}
       />
       <Button className='st3' text='Добавить' onClick={() => this.openAddAvtorModal()} />

       <Button
        className='st3'
        text='Закрыть'
        onClick={() => {
         this.closeModalWithoutSelect();
         this.setState({ showModal2: true });
        }}
       />
      </div>
     </div>
    </Modal>

    <Modal show={this.state.showModal5} closeModalEvent={(e) => this.closeModal5()}>
     <div className='st12'>
      Количество совпадений: {this.state.publishersGetMassive.length}
      <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
       {this.state.publishersGetMassive.length !== 0
        ? this.state.publishersGetMassive.map((i, j) => (
           <div
            className={j === this.state.publisherSelectedN ? 'fmba2' : 'fmba1'}
            onClick={() => this.PublisherSelectHandle(i, j)}
           >
            {i.name}
           </div>
          ))
        : null}
      </div>
      <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
       <Button className='st3' text='Выбрать' onClick={() => this.closeModalWithSelect3()} />

       <Button
        className='st3'
        text='Закрыть'
        onClick={() => {
         this.closeModalWithoutSelect();
         this.setState({ showModal2: true });
        }}
       />
      </div>
     </div>
    </Modal>

    <Modal show={this.state.showModal6} closeModalEvent={(e) => this.closeModal6()}>
     <div className='st12'>
      Окно добавления автора
      <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
       <div className='search-filter-book__book'>
        <input
         onChange={(e) => this.setState({ ...this.state, addAvtor: { ...this.state.addAvtor, name: e.target.value } })}
         placeholder='Имя'
        />
       </div>
      </div>
      <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
       <Button className='st3' text='Добавить' onClick={() => this.AvtorAddSend()} />
       <Button className='st3' text='Закрыть' onClick={() => this.closeModal6()} />
      </div>
     </div>
    </Modal>

    <Modal show={this.state.showModal2} closeModalEvent={() => this.closeModalEvent2}>
     <div className='st12'>
      Периодическое издание № {this.state.currentBO}
      <div className='st9'>
       <div className='st10'>
        <div className='4'>Заглавие</div>
        <div className='st11'>
         <input
          disabled
          onChange={(e) => this.setState({ addbook: { ...this.state.addbook, name: e.target.value } })}
          name='name'
          value={this.state.addbook.name}
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Год </div>
        <div className='st11'>
         <input
          disabled
          onChange={(e) => this.setState({ addbook: { ...this.state.addbook, god: e.target.value } })}
          name='god'
          value={this.state.addbook.god}
          type='number'
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Место издания</div>
        <div className='st11'>
         {/* <input
          onChange={(e) => this.setState({ addbook: { ...this.state.addbook, place_izdn: e.target.value } })}
          name='place_izdn'
          value={this.state.addbook.place_izdn}
         /> */}
         <select disabled onChange={(e) => this.getNameAndValue6(e)} value={this.state.addbook.place_izdn}>
          <option hidden>Выберите из списка</option>
          {this.props.PublPlaceDataSelectsData.map((item, i) => {
           return (
            <option key={i} name={item.name_ru} value={item.id}>
             {item.name_ru}
            </option>
           );
          })}
         </select>
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Издательство</div>
        <div className='st11'>
         {/* <input
          onChange={(e) => this.setState({ addbook: { ...this.state.addbook, izdatel: e.target.value } })}
          name='izdatel'
          value={this.state.addbook.izdatel}
         /> */}
         {/* <input
          name='izdatel'
          value={this.state.izdatelInput}
          //  placeholder={el.placeholder === '-' ? '' : el.placeholder || 'Обязательные поля ввода'}
          onChange={(e) => this.setState({ izdatelInput: e.target.value })}
          onBlur={this.PublisherChangeHandle}
         /> */}
         <div style={{ marginTop: '10px' }}></div>

         <input
          placeholder={
           this.state.publisherSelectedN === -1 ? 'Издательство не выбрано' : this.state.publisherSelectedItem.name
          }
          disabled
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Тема</div>
        <div className='st11'>
         {/* <input
          onChange={(e) => this.setState({ addbook: { ...this.state.addbook, po_soderj: e.target.value } })}
          name='po_soderj'
          value={this.state.addbook.po_soderj}
         /> */}
         <select disabled onChange={(e) => this.getNameAndValue8(e)} value={this.state.addbook.po_soderj}>
          <option hidden>Выберите из списка</option>
          {this.props.SoderjSelectData.map((item, i) => {
           return (
            <option key={i} name={item.name_ru} value={item.id}>
             {item.name_ru}
            </option>
           );
          })}
         </select>
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Номер части</div>
        <div className='st11'>
         <input
          disabled
          onChange={(e) => this.setState({ addbook: { ...this.state.addbook, num_chasti: e.target.value } })}
          name='num_chasti'
          type='number'
          value={this.state.addbook.num_chasti}
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Количество экземляров (шт.)</div>
        <div className='st11'>
         <input
          disabled
          onChange={(e) => this.KolHandler(e)}
          name='kolichestvo'
          type='number'
          value={this.state.addbook.kolichestvo}
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Цена (тнг.)</div>
        <div className='st11'>
         <input
          disabled
          type='number'
          onChange={(e) => this.PriceHandler(e)}
          name='price'
          value={this.state.addbook.price}
         />
        </div>
       </div>
       <div className='st10'>
        <div className='4'>Сумма (тнг.)</div>
        <div className='st11'>
         <input disabled name='summa' value={this.state.addbook.summa} />
        </div>
       </div>
      </div>
      <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
       {/* <Button className='st3' text='Сохранить' onClick={() => this.handleChangeBOinputs(this.state.currentBO)} /> */}
       <Button className='st3' text='Закрыть' onClick={() => this.closeModal2()} />
      </div>
     </div>
    </Modal>

    <div className='form-add'>
     <Button className='st3' text='Назад' onClick={() => this.props.returnOnFirstPage()} />
     <div className='st2'>
      <div className='st1' style={{ marginTop: '-30px' }}>
       <Button className='st3_2' text='Данные по накладной' />
      </div>
      <div className='st5'>
       <div className='st4'>
        <div className='st8'>Дата поступления</div>
        <div>
         <p>: {this.state.datePostuplenia}</p>
        </div>
       </div>
       <div className='st4'>
        <div className='st8'>№ документа</div>
        <div>
         <p>: {this.state.documentNumber}</p>
        </div>
       </div>
       <div className='st4'>
        <div className='st8'>Дата по документу</div>
        <div>
         <p>: {this.state.dateByDoc}</p>
        </div>
       </div>
       {/* <div className='st4'>
        <div className='st8'>Тип документа</div>
        <div>
         <p>: {this.state.typeOfDocText}</p>
        </div>
       </div> */}
       <div className='st4'>
        <div className='st8'>Поставщик</div>
        <div>
         <p>: {this.state.postavshikText}</p>
        </div>
       </div>
       <div className='st4'>
        <div className='st8'>Сопроводительный документ</div>
        <div>
         <p>: {this.state.SoprovDoc}</p>
        </div>
       </div>
       <div className='st4'>
        <div className='st8'>Тип источника</div>
        <div>
         <p>: {this.state.typeOfSourceText}</p>
        </div>
       </div>
       <div className='st4'>
        <div className='st8'>Примечание</div>
        <div>
         <p>: {this.state.primechanie}</p>
        </div>
       </div>
       <div className='st4'>
        <div className='st8'>Количество БО</div>
        <div>
         <p>: {this.state.number}</p>
        </div>
       </div>{' '}
       <div className='st4'>
        <div className='st8'>Количество экземляров</div>
        <div>
         <p>: {this.state.kolExempl}</p>
        </div>
       </div>
      </div>
     </div>
    </div>
    {this.state.minorCheck &&
     this.state.specialMassive.map((i) => (
      <div style={{ textAlign: 'center', marginTop: '15px' }} onClick={() => this.openModal2(i)}>
       Периодическое издание № {i}
       <div
        style={{ cursor: 'pointer', padding: '5px', height: '75px', background: '#a5c3f5', marginTop: '0px' }}
        className='st2'
       >
        <div className='st14_g'>
         <div className='st15'>
          {this.state.majorMassive.map((item) => (Object.keys(item)[0] == i ? item[i].name : null))}
         </div>
         <div className='st15'>
          {this.state.majorMassive.map((item) => (Object.keys(item)[0] == i ? item[i].god + ' г.' : null))}
         </div>
        </div>
       </div>
      </div>
     ))}
    <div className='form-add-img-btn' style={{ display: this.state.minorCheck ? 'flex' : 'none' }}>
     <Button className='st3' text='Принять поступление' onClick={this.handleTestSubmit} />
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(FormPartView_g);
