import React, { Component } from 'react';

import './style.css'

class Button extends Component {
    render() {
        return (
            <button className={this.props.className} type={this.props.typeBtn} onClick={this.props.onClick}>{this.props.text}</button>
        );
    }
}

export default Button;