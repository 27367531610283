import React, { Component } from 'react';

import { connect } from 'react-redux';
import SincetoCalendar from '../../../../components/calendars/SincetoCalendar/SincetoCalendar';
import { setDate } from '../../../../redux/action';

import Chart from '../chart';
import ChartG from '../chart-g';

import './../style.css';

class Analit extends Component {
 state = {
  date1: '',
  date2: '',
  statArr: false,
 };

 // async componentDidMount() {
 //     const res = await getStatLibPeriod.getStatLibPeriod({
 //         id_sigl: localStorage.lib_ID,
 //         start: this.state.date1 || '2020-01',
 //         end: this.state.date2 || '2030-01',
 //         lib_name: localStorage.lib_name
 //     })
 //     this.setState({ statArr: res })

 // }
 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  // const res = await getStatLibPeriod.getStatLibPeriod({
  //     id_sigl: localStorage.lib_ID,
  //     start: this.state.date1,
  //     end: this.state.date2,
  //     lib_name: localStorage.lib_name
  // })
  // console.log(res);
  // this.setState({ statArr: res })
 };

 render() {
  const analitGender = [
   {
    label: 'Количество',
    data: [
     { x: 'Мужской', y: 150 },
     { x: 'Женский', y: 59 },
    ],
   },
  ];
  const analitAge = [
   {
    label: 'Количество',
    data: [
     { x: 'от 7 до 18 лет', y: 27 },
     { x: 'от 19 до 30 лет', y: 19 },
     { x: 'от 31 до 50 лет', y: 48 },
     { x: 'старше 50 лет', y: 33 },
    ],
   },
  ];
  const analitLang = [
   {
    label: 'Количество',
    data: [
     { x: 'Русский', y: 27 },
     { x: 'Казахский', y: 19 },
     { x: 'другие', y: 48 },
    ],
   },
  ];

  const pieOptions = {
   title: 'Аналитика по социальному статусу',
   pieHole: 0,
   slices: [{ color: '#2BB673' }, { color: '#d91e48' }, { color: '#007fad' }, { color: '#e9a227' }],
   legend: { position: 'bottom', alignment: 'center', textStyle: { color: '233238', fontSize: 14 } },
   tooltip: { showColorCode: true },
   chartArea: { left: 0, top: 0, width: '100%', height: '100%' },
   fontName: 'Roboto',
  };
  const dataPie = [
   ['Age', 'Weight'],
   ['Госслужащий', 7],
   ['Гражданский служащий', 0],
   ['Рабочий', 1],
   ['Студент', 1],
   ['Школьник', 1],
   ['Пенсионер ', 0],
   ['Инвалид ', 0],
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <SincetoCalendar
      selectDate={this.selectDate}
      state1={this.state.date1}
      state2={this.state.date2}
      handleDate={this.handleDate}
      type='month'
     />
     <div className='block-chart'>
      <div className='chart-container'>
       <Chart text={'Аналитика по полу'} data={analitGender} />
       <Chart text={'Аналитика по возрасту'} data={analitAge} />
      </div>
      <div className='chart-container'>
       <Chart text={'Аналитика по языку'} data={analitLang} />
       <ChartG pieOptions={pieOptions} text='Аналитика по социальному статусу' type='PieChart' dataPie={dataPie} />
      </div>
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(Analit);
