import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import Loader from '../../loader';

import './style.css'

class VudanoVtechGoda extends Component {

    async componentDidMount() {
        // await this.props.getData('svod_otchet', 'svod_otchet')
    }

    render() {

        const lib = [
            { name: 'Центральная городская библиотека г.Павлодара', book: 80038, square: '972,9', adr: 'Торайгырова 44/1 т.53-44-05' },
            { name: 'Массовая  библиотека  № 3', book: 9737, square: '160,0', adr: 'П.Ленинский Сарыарка 53А т. 62-52-64' },
            { name: 'Массовая  библиотека  № 4', book: 19792, square: '77,3', adr: 'Сибирская  56   т. 51-59-31' },
            { name: 'Массовая  библиотека  им. Дихана Абилева', book: 18766, square: '312,7', adr: 'Богенбай батыра 38/2 т.20-44-01' },
            { name: 'Массовая  библиотека с. Жетекши', book: 7640, square: '17,4', adr: 'Ауэзова 15   т.35-67-85  акимат' },
            { name: 'Массовая  библиотека  с.Кенжеколь', book: 19745, square: '80,0', adr: 'Кенжекольская  5 т.35-27-36' },
            { name: 'Массовая  библиотека  с.Мойылды', book: 7080, square: '37', adr: 'Улы -Дала 5/2 т.35-66-48' },
            { name: 'Массовая  библиотека  с.Павлодарское', book: 12294, square: '120', adr: 'Молодежная 15/1 т.31-87 - 07' },
            { name: 'Центральная детская библиотека им. Мубарака Жаманбалинова', book: 41540, square: '466', adr: 'Малайсары батыра 2 т. 54-93-04' },
            { name: 'Детская библиотека  № 2', book: 16721, square: '178,3', adr: 'Майры 47 т. 52-68-08' },
            { name: 'Детская библиотека  № 3', book: 12128, square: '61,3', adr: 'Пр.Назарбаева 285-121  т.57-75-06' },
            { name: 'Детская библиотека  № 4', book: 23372, square: '144,2', adr: 'Пр.Назарбаева 20-286 т.55-34-49' },
            { name: 'Детская библиотека  № 8', book: 14191, square: '184,5', adr: 'Катаева 36/1  т-21-58-00' },
        ]

        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    {this.props.svod_otchet.length
                        ?
                        <>
                            <h2>ВЫДАЧА ЛИТЕРАТУРЫ ВЫДАНО В ТЕЧЕНИЕ ГОДА  - ВСЕГО</h2>
                            <div className='table'>
                                <div className='row dviz_lib_fond'>
                                    <div className='row-item5'>
                                        <p>Наименование</p>
                                    </div>
                                    <div className='row-item5 flex border jcc'>
                                        <div className='row-subitem flex__sub'>
                                            <div>
                                                <p>Всего</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row-item5 flex border'>
                                        <p>В том числе</p>
                                        <div className='row-subitem flex__sub'>
                                            <div>
                                                <p>Книги</p>
                                            </div>
                                            <div className='border-l-r'>
                                                <p>Электронные издания</p>
                                            </div>
                                            <div>
                                                <p>Аудио/видео издания</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row-item5 flex border'>
                                        <p>Периодические издания</p>
                                        <div className='row-subitem flex__sub'>
                                            <div className='border-r'>
                                                <p>Газеты</p>
                                            </div>
                                            <div>
                                                <p>Журналы</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row-item5 flex jcc'>
                                        <div className='row-subitem flex__sub'>
                                            <div>
                                                <p>Другая литературы</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {lib.map((el, i) => {
                                    return (
                                        <div className='row h5' key={i}>
                                            <div className='row-item5'>
                                                <div className='row-subitem__data w'>
                                                    <p>{el.name}</p>
                                                </div>
                                            </div>
                                            <div className='row-item5'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                </div>
                                            </div>
                                            <div className='row-item5 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                    <p>1_3</p>
                                                </div>
                                            </div>
                                            <div className='row-item5 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                            <div className='row-item5 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <Loader />}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(VudanoVtechGoda);