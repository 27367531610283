// import './styles.css';
import ReactApexChart from 'react-apexcharts';
import React from 'react';

class Chart extends React.Component {
 render() {
  const series = this.props.numbersArr;
  const options = {
   chart: {
    type: 'polarArea',
    width: '380',
   },
   labels: this.props.labelsArr,
   fill: {
    opacity: 1,
   },
   stroke: {
    width: 1,
    colors: undefined,
   },
   legend: {
    position: 'bottom',
    fontSize: '18px',
   },
   plotOptions: {
    polarArea: {
     rings: {
      strokeWidth: 0,
     },
     spokes: {
      strokeWidth: 0,
     },
    },
   },
   //    theme: {
   //     monochrome: {
   //      enabled: true,
   //      shadeTo: 'light',
   //      shadeIntensity: 0.6,
   //     },
   //    },
   yaxis: {
    show: false,
   },
  };
  return (
   <div>
    {/* <div style={{ width: '800px', overflow: 'auto', height: 'auto', marginBottom: '0px' }}> */}
    <ReactApexChart options={options} series={series} type='polarArea' width={580} />
    {/* </div> */}
   </div>
  );
 }
}
export default Chart;
