import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../..//redux/action';

import { Link } from 'react-router-dom';
import { periodical_publications_getByLib } from '../../../api';

import FormBookAdd from './formBookAdd';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import history from '../../../components/history';

class BookAdd extends Component {
  state = {
    choiceItemPage: 'book',

    currentPagePag: '',

    sort: false,
    sortType: '',
    sortPag: false,

    scanerCode: null,
  };

  returnPage = async (e) => {
    this.setState({ choiceItemPage: 'book' });
  };

  pagination = async (pageNum) => {
    await this.props.paginationPages(
      pageNum,
      'База книг',
      'bookAdd',
      this.state.sortPag,
      this.state.sortType,
      this.state.scanerCode,
    );
    await this.setState({ currentPagePag: pageNum });
  };

  sortItemBook = async (type) => {
    await this.props.sortItem('База книг', type, this.state.sort, 'bookAdd');
    await this.setState({ sort: !this.state.sort, sortPag: true, sortType: type });
  };

  selectItem = async (item) => history.push(`/multibook/${item.id}`);

  async componentDidMount() {
    const res = await periodical_publications_getByLib.periodical_publications_getByLib();
    console.log(res);

    await this.props.setDate(res, 'subcr_base', 'subcr_base');
  }

  render() {
    const choiceItem = [
      { title: 'Список периодических изданий', dataset: 'book' },
      { title: 'Формирование списка периодических изданий', dataset: 'add' },
    ];

    const buttons = [
      { title: 'Перейти к ресурсу', event: (e) => this.selectItem(e) },
      // { title: 'Удалить', event: (e) => this.EditItem(e) },
    ];

    const headerItemsContent = [
      { title: 'Тип', data: 'type_of_gazet' },
      { title: 'Название', data: 'title' },
      { title: 'Год издания', data: 'god' },
      { title: 'Издатель', data: 'izdatel' },
      { title: 'Место издания', data: 'place_izdn' },
      { title: 'Серия', data: 'seria' },
      { title: 'Тема', data: 'tema' },
      { title: 'Доступ', data: 'button' },
    ];
    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Подписка</h1>
            {/* <div className='total-content'>
                            <div>
                                <span>Книг на руках</span>
                                <p>{this.props.booksOnHand}</p>
                            </div>
                            <div>
                                <span>Всего книг</span>
                                <p>{this.props.totalAllItem}</p>
                            </div>
                        </div> */}
          </div>

          <div className='choice-type'>
            {choiceItem.map((el, i) => (
              <h3
                data-set={el.dataset}
                key={i}
                onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}
              >
                {el.title}
              </h3>
            ))}
          </div>

          {this.state.choiceItemPage === 'add' ? (
            <FormBookAdd returnPage={this.returnPage} />
          ) : (
            <>
              <CommonWrapperTable
                mainArr={this.props.subcr_base}
                headersArr={headerItemsContent}
                buttons={buttons}
              />

              <div className='book-items-content'></div>

              {/* <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} /> */}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(BookAdd);
