import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, editUserAC, delItem } from '../../redux/action';
import { GetStaffFirstPage, GetStaffBySearch, GetStaffFullData, DeleteUserStaff } from '../../api';

import Pagination from '../../components/pagination';
import FormStaffAdd from '../../components/formStaffAdd';
import FormStaffEdit from '../../components/FormStaffEdit/FormStaffEdit';
import CommonWrapperTable from '../../components/tables/CommonWrapperTable/CommonWrapperTable';
import debounce from '../../utils/debounce';

import Modal from '../../components/modal';

import toast from 'react-hot-toast';

class Recrut extends Component {
 state = {
  choiceItem: 'staff',

  scanerCode: '',

  typeModal: '',

  lib_name: 'All',

  showModal: false,
  typeBtn: '',
  person: {},
  typedFIO: '',

  log: '',
 };

 showModal = async (el, typeBtn, typeModal) => {
  if (typeModal == 'edit') {
   const data = {
    id: el.id,
   };
   const json = await GetStaffFullData.GetStaffFullData(data);
   console.log(json);
   this.setState({ showModal: true, typeBtn, person: json, typeModal });
  } else {
   this.setState({ showModal: true, typeBtn, person: el, typeModal });
  }
 };

 closeModalEvent = (e) => {
  if (e === 'modal') this.setState({ showModal: false });
 };

 closeModal = async () => {
  if (this.state.typeModal === 'edit') {
   await this.setState({ showModal: false });
   this.searchStaff(this.state.typedFIO);
  } else if (this.state.typeModal === 'delete') {
   await this.setState({ showModal: false, log: '' });
  } else {
   this.setState({ showModal: false });
  }
 };

 pagination = async (pageNum) => {
  await this.props.paginationPages({
   item: 'Сотрудники',
   skip: pageNum,
   page: 'staff',
   searchAll: this.state.lib_name,
  });
  await this.setState({ currentPagePag: pageNum });
 };

 separator_1 = (e) => this.searchStaff(e.target.value);

 searchStaff = async (e) => {
  const data = {
   filter: {
    search: {
     keyword: e,
    },
   },
  };
  const json = await GetStaffBySearch.GetStaffBySearch(data, 1);
  const jsondata = Object.values(json);
  await this.props.setDate(jsondata, 'staff', 'Сотрудники');
  this.setState({ typedFIO: e });
 };

 handleChangeChoiceItem = async () => {
  this.setState({ choiceItem: 'staff' });
 };

 handleDeleteUser = async (e) => {
  const json = await DeleteUserStaff.DeleteUserStaff(e.id);
  if (json) {
   toast.success('Сотрудник успешно удален');
   this.searchStaff(this.state.typedFIO);
  }
 };

 async componentDidMount() {
  await this.setState({
   lib_name: this.props.lib_name === 'Областная библиотека' ? 'All' : this.props.lib_name,
  });

  const res = await GetStaffFirstPage.GetStaffFirstPage();
  console.log(res);
  const data = Object.values(res);
  console.log(data);

  await this.props.setDate(res, 'staff', 'Сотрудники');
 }
 render() {
  const choiceItem = [
   { title: 'Сотрудники', data: 'staff' },
   { title: 'Создать сотрудника', data: 'add' },
  ];

  const headerItemsContent = [
   { title: 'Ф.И.О.', data: 'StaffFIO' },
   { title: 'Номер телефона', data: 'phone_number' },
   { title: 'Библиотека', data: 'StaffLibName' },
   { title: 'Права', data: 'StaffRoles' },
   { title: '', data: 'button' },
  ];

  const buttons = [
   { title: 'Редактировать', event: (e) => this.showModal(e, 'Редактировать', 'edit') },
   { title: 'Удалить', event: (e) => this.handleDeleteUser(e) },
  ];

  return (
   <div className='page-main'>
    <Modal
     show={this.state.showModal}
     handleClose={this.closeModal}
     //  btn={this.state.typeBtn}
     //  classBtn='btn_add_genre'
     closeModalEvent={this.closeModalEvent}
    >
     {this.state.typeModal === 'edit' ? (
      <div style={{ overflow: 'auto', padding: '15px' }} className='modal-content-regReader'>
       <FormStaffEdit fullInfoPerson={this.state.person} handleClose={this.closeModal} />
      </div>
     ) : null}
    </Modal>

    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>ОТДЕЛ КАДРОВ</h1>
     </div>

     <div className='choice-type'>
      {choiceItem.map((el, i) => (
       <h3 data-set={el.data} key={i} onClick={(e) => this.setState({ choiceItem: e.target.dataset.set })}>
        {el.title}
       </h3>
      ))}
     </div>

     {this.state.choiceItem === 'add' ? (
      <FormStaffAdd handleChangeChoiceItem={this.handleChangeChoiceItem} />
     ) : (
      <>
       <div className='smart-search'>
        <input
         type='text'
         placeholder='Введите фамилию сотрудника'
         // ref={input => input && input.focus()}
         onChange={debounce(this.separator_1, 900)}
        />
       </div>

       <div className='book-items-content'>
        <CommonWrapperTable mainArr={this.props.staff} headersArr={headerItemsContent} buttons={buttons} />
        {this.props.numbersBtnPag > 1 && (
         <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} />
        )}
       </div>
      </>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, editUserAC, delItem };

export default connect(mapStateToProps, mapDispatchToProps)(Recrut);
