import React from 'react';

import { connect } from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            marginTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));

function PaginationOutlined({ pageCount, paginationPages }) {
    const classes = useStyles();
    // const countPages = Math.ceil(pageCount / 5);
    return (
        <div className={classes.root}>
            <Pagination count={pageCount} hidePrevButton hideNextButton onChange={(e) => paginationPages(e.target.textContent)} />
        </div>
    );
}

const mapStateToProps = (data) => data

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationOutlined);