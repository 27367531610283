import s from './BooksExtension.module.css'
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import { useEffect, useCallback, useState } from 'react';
import { editBooksExtension, getBooksExtensionInfo } from '../../../api';

const BooksExtension = () => {
    const [arr, setArr] = useState([])

    const BooksExtension = useCallback(async () => {
        const res = await getBooksExtensionInfo.getBooksExtensionInfo()
        setArr(res)
    }, [])

    const buttonExtend = async (e) => {
        const res = await editBooksExtension.editBooksExtension({ id_be: e.books_history_id, bool: true })
        if(res){
            alert('Успешно продлено')
            BooksExtension()
        } else{
            alert('Что-то пошло не так..')
        }
    }

    const buttonDiscard = async (e) => {
        const res = await editBooksExtension.editBooksExtension({ id_be: e.books_history_id, bool: true })
        if(res){
            alert('Отказано')
            BooksExtension()
        } else{
            alert('Что-то пошло не так..')
        }
    }

    useEffect(() => {
        BooksExtension()
    }, [BooksExtension])

    const buttons = [
        { title: 'Продлить', event: (e) => buttonExtend(e) },
        { title: 'Отказать', event: (e) => buttonDiscard(e) },
    ];

    const headersArr = [
        { title: 'Название', data: 'book_title' },
        { title: 'Автор', data: 'author' },
        { title: 'Старая дата сдачи', data: 'date_sdachi_old' },
        { title: 'Продлить дату сдачи до', data: 'date_sdachi' },
        { title: 'Читатель', data: 'FIO' },
        { title: 'Читательский билет', data: 'library_ticket' },
        { title: 'Номер телефона', data: 'phone_number' },
        { title: 'Действие', data: 'button' },
    ];

    return (
        <div className={s.pageWrapper}>
            <h1 className={s.pageTitle}>Список заявок на продление даты сдачи</h1>

            <CommonWrapperTable mainArr={arr} headersArr={headersArr} buttons={buttons} />
        </div>
    );
};

export default BooksExtension;