import React, { Component } from 'react';
import { setDate } from '../../redux/action';
import { connect } from 'react-redux';

import './style.css';

class BookCard extends Component {
 findInMassive1 = (e) => {
  //   console.log(e);

  let res = this.props.LanguageSelectData.find((i) => i.id == e);
  typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.code);
  //   console.log(res);
  return res;
 };

 findInMassive2 = (e) => {
  //   console.log(e);

  let res = this.props.GenresSelectData.find((i) => i.id == e);
  typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.name_ru);
  //   console.log(res);
  return res;
 };

 findInMassive22 = (e) => {
  let res = this.props.UDKSelectsByGeneral.find((i) => i.id == e);
  typeof res == 'undefined' ? this.findInMassive33(e) : (res = res.index_udk_bbk);
  return res;
 };

 findInMassive33 = (e) => {
  let res = this.props.UDKSelectsByKazakstanika.find((i) => i.id == e);
  typeof res == 'undefined' ? this.findInMassive44(e) : (res = res.index_udk_bbk);
  return res;
 };

 findInMassive44 = (e) => {
  let res = this.props.UDKSelectsByKraeved.find((i) => i.id == e);
  typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.index_udk_bbk);
  return res;
 };

 render() {
  let tags = this.props.book.kl_slova ? this.props.book.kl_slova.split(',') : [];
  const tagsarr = [];
  for (const i in tags) {
   //  console.log(tags[i]);
   const splited = tags[i].split('&');
   //  console.log(splited);
   for (const j in splited) {
    // console.log(splited[j]);
    tagsarr.push(splited[j]);
   }
  }

  return (
   <div className='book-card'>
    <div className='book-card-wrapper'>
     <div className='book-card-top'>
      <div className='book-card-left'>
       {/* <p>{this.props.book.index_bbk}</p> */}
       <p>{this.findInMassive22(this.props.book.classifier)}</p>
       <p>{this.props.book.author_sign}</p>
      </div>
      <div></div>
      <div>
       {' '}
       <div className='book-card-right'>
        <p>{this.findInMassive1(this.props.book.language)}</p>
       </div>
      </div>
     </div>
     <div className='book-card-bottom'>
      <div className='book-redline'>
       <p>{this.props.book.authors[0].name}</p>
      </div>

      <div className='book-redline'>
       <p>
        {this.props.book.title} {this.props.book.parallel_title ? ' = ' + this.props.book.parallel_title : ''} [Текст] /{' '}
        {this.props.book.authors[0].name == 'Без автора' ? '' : this.props.book.authors[0].name + '.-'}
        {/* {this.props.book.sved_otvets}- */}
        {this.props.book.place_of_publication}:{this.props.book.publisher}, {this.props.book.publication_year + ' г.'}.-
        {this.props.book.number_of_pages + ' стр.'}
       </p>
      </div>
      {/* <div className='book-redline'>
              <p>{this.props.book.prim}</p>
            </div> */}

      <div className='book-redline'>
       <p>
        {' '}
        ISBN: {this.props.book.ISBN}: {this.props.book.price + ' тнг.'}., {this.props.book.book_circulation + ' шт.'}
       </p>
      </div>

      <div className='book-redline'>
       <p>{this.findInMassive2(this.props.book.genre_id)}</p>
      </div>
      <div className='book-redline'>
       <p>{this.props.book.keywords.map((item, i) => i + 1 + '.' + item.keyword + '. ')}</p>
      </div>

      {/* <div style={{ textAlign: 'end' }}>
       {' '}
       <p>{'ББК(М) ' + this.props.book.index_bbk}</p>
      </div> */}
     </div>
     <div></div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(BookCard);
