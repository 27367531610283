export default function chooseHeadersTitle(value) {
    if (value.url === 'api/zakazchik') {
        return [
            { title: 'Название', data: 'name' },
            { title: 'Контакты', data: 'contact' },
            { title: 'Действие', data: 'button' }
        ]
    } else if (value.url === 'api/provider') {
        return [
            { title: 'Название', data: 'name' },
            { title: 'Контакты', data: 'contact' },
            { title: 'Статус', data: 'status' },
            { title: 'Действие', data: 'button' }
        ]
    } else if (value.url === 'api/classifiers') {
        return [
            { title: 'Индекс', data: 'index_udk_bbk' },
            { title: 'Название на рус', data: 'name_ru' },
            { title: 'Название на каз', data: 'name_kz' },
            { title: 'Действие', data: 'button' }
        ]
    } else if(value.url === 'api/genres') {
        return [
            { title: 'Название на рус', data: 'name_ru' },
            { title: 'Название на каз', data: 'name_kz' },
            { title: 'Действие', data: 'button' }
        ]
    } else if (value.list === 'library' || value.url === 'api/statuses' || value.url === 'api/supplier' || value.url === 'api/sources_of_income') {
        return [
            { title: 'Название', data: 'name_ru' },
            { title: 'Действие', data: 'button' }
        ]
    } else if (value.list === 'staff' || value.list === 'reader') {
        return [
            { title: 'Название', data: 'title' },
            { title: 'Действие', data: 'button' }
        ]
    } else if (value.url === 'api/libraries') {
        return [
            { title: 'Название на рус', data: 'name' },
            { title: 'Название на каз', data: 'name_kz' },
            { title: 'Телефон', data: 'phone_number' },
            { title: 'Действие', data: 'button' }
        ]
    } else if (value.url === 'api/publisher') {
        return [
            { title: 'Название на рус', data: 'name' },
            { title: 'Действие', data: 'button' }
        ]
    }
}