import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';
import { getDataStat2 } from '../../../api';

import Loader from '../../../components/loader';

import './style.css';

class OtraslRead extends Component {
 state = {
  statData: [],
 };

 async componentDidMount() {
  // const stat = await getDataStat2.getDataStat2('qwe');
  // this.setState({ statData: stat });
 }

 checkData = (arr, name) => {
  console.log(arr, name);
  let res = arr.find((i) => i.name_ru === name);
  typeof res == 'undefined' ? (res = '0') : console.info();
  return res;
 };

 render() {
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>ФОРМИРОВАНИЕ СТАТИСТИКИ ПО ПАРАМЕТРАМ ОТРАСЛЕЙ ЛИТЕРАТУРЫ</h1>
     </div>
     {this.state.statData ? (
      <div className='monitor'>
       <div className='colunm'>
        <div>
         <div className='headerRead'>
          <p>Отрасли</p>
         </div>
         <div className='type' style={{ marginLeft: '1px !important' }}>
          <p>
           ОПЛ -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'ОПЛ').amount
              ? this.checkData(this.state.statData, 'ОПЛ').amount
              : '0'
            : '0'}
          </p>
          <p>
           Естественные науки, здравоохранение и медицина -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Естественные науки, здравоохранение и медицина').amount
              ? this.checkData(this.state.statData, 'Естественные науки, здравоохранение и медицина').amount
              : '0'
            : '0'}
          </p>
          <p>
           Техника, сельское и лесное хозяйство -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Техника, сельское и лесное хозяйство').amount
              ? this.checkData(this.state.statData, 'Техника, сельское и лесное хозяйство').amount
              : '0'
            : '0'}
          </p>
          <p>
           Искусство, физкультура и спорт -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Искусство, физкультура и спорт').amount
              ? this.checkData(this.state.statData, 'Искусство, физкультура и спорт').amount
              : '0'
            : '0'}{' '}
          </p>
          <p>
           Филологические науки, художественная литература -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Филологические науки, художественная литература').amount
              ? this.checkData(this.state.statData, 'Филологические науки, художественная литература').amount
              : '0'
            : '0'}
          </p>
         </div>
        </div>
        <div></div>
       </div>
      </div>
     ) : (
      <Loader />
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(OtraslRead);
