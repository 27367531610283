import React, { Component } from 'react';

import { connect } from 'react-redux';

import MaskedInput from 'react-maskedinput';

import Button from '../button';
import history from '../history';

import { SmartSreach } from '../../api';
import './style.css';

import toast from 'react-hot-toast';

class FormBookAdd extends Component {
 state = {
  staff: {
   first_name: '',
   last_name: '',
   middle_name: '',
   date_of_birth: '',
   //    place_age: '',
   nation_id: '',
   //    profsouz: '',
   education_id: '',
   position_id: '',
   general_experience: '',
   library_experience: '',
   gender_id: '',
   //    neprer_stazh: '',
   //    last_work: '',
   iin: '',
   family_status: '',
   passport_number: '',
   address: '',
   phone_number: '',
   lib_name: '',
   //    role: '',
   password: '',
   roles: [],
   library_id: '',
  },

  specialMassive: [],
  specialNumber: 0,
  // data: [{ title: 'Выберите статус', name: 'status', type: 'checkbox', option: [{ item: 'Студент', num: 1 }, { item: 'Школьник', num: 2 }] }]
 };

 ojbectGenerate = (name, value) => {
  const key = name;
  var obj = {};
  obj[key] = value;

  return obj;
 };

 handleChange = async ({ target }) => {
  // if (target.name === 'status') await this.setState({ status: !this.state.status })
  await this.setState({ staff: { ...this.state.staff, [target.name]: target.value.trim() } });
 };
 handleChangeRole = async ({ target }) => {
  var timelessCheck = false;
  this.state.staff.roles.map((item) => (Object.keys(item)[0] == target.id ? (timelessCheck = true) : null));
  if (timelessCheck == true) {
   var timelessArray = this.state.staff.roles;
   timelessArray.map((item) => (Object.keys(item)[0] == target.id ? (item[target.id] = target.value) : console.info()));
  } else {
   const minorObj = this.ojbectGenerate(target.id, target.value);
   var joined = this.state.staff.roles.concat(minorObj);
   this.setState({ staff: { ...this.state.staff, roles: joined } });
  }
 };
 changeLibName = (lib) => {
  this.setState({ staff: { ...this.state.staff, library_id: lib.target.value } });
 };
 changeNationName = (e) => {
  this.setState({ staff: { ...this.state.staff, nation_id: e.target.value } });
 };
 changeEducationName = (e) => {
  this.setState({ staff: { ...this.state.staff, education_id: e.target.value } });
 };
 changePositionName = (e) => {
  this.setState({ staff: { ...this.state.staff, position_id: e.target.value } });
 };
 changeFamilynName = (e) => {
  this.setState({ staff: { ...this.state.staff, family_status: e.target.value } });
 };
 specialMassiveGenerate = () => {
  console.log(this.state.specialNumber);
  this.setState({ specialNumber: this.state.specialNumber + 1 });
  var foo = [];
  for (var i = 1; i <= this.state.specialNumber + 1; i++) {
   foo.push(i);
  }
  this.setState({ specialMassive: foo });
  console.log(foo);
  console.log(this.state.specialMassive);
 };

 handleSubmit = async (e) => {
  e.preventDefault();
  //   console.log(this.state);
  // console.log(this.props.library_id);
  // await this.setState({ phone_number: this.state.phone_number.replace(/\D/g, "") })

  // if (this.state.iin.length !== 12) {
  //     alert('Вы ввели не корректный номер ИИН')
  //     return
  // };

  if (!this.state.staff.password.trim().length) {
   toast.error('Необходимо заполнить поле "Пароль".');
  } else if (!this.state.staff.first_name.trim().length) {
   toast.error('Необходимо заполнить поле "Имя".');
  } else if (!this.state.staff.last_name.trim().length) {
   toast.error('Необходимо заполнить поле "Фамилия".');
  } else if (!this.state.staff.middle_name.trim().length) {
   toast.error('Необходимо заполнить поле "Очество".');
  } else if (!this.state.staff.date_of_birth.trim().length) {
   toast.error('Необходимо заполнить поле "Дата рождения".');
  } else if (!this.state.staff.address.trim().length) {
   toast.error('Необходимо заполнить поле "Адрес".');
  } else if (!this.state.staff.nation_id.trim().length) {
   toast.error('Необходимо заполнить поле "Национальность".');
  } else if (!this.state.staff.education_id.trim().length) {
   toast.error('Необходимо заполнить поле "Образование".');
  } else if (!this.state.staff.position_id.trim().length) {
   toast.error('Необходимо заполнить поле "Должность".');
  } else if (!this.state.staff.general_experience.trim().length) {
   toast.error('Необходимо заполнить поле "Общий стаж".');
  } else if (!this.state.staff.library_experience.trim().length) {
   toast.error('Необходимо заполнить поле "Библиотечный стаж".');
  } else if (!this.state.staff.family_status.trim().length) {
   toast.error('Необходимо заполнить поле "Семейное положение".');
  } else if (!this.state.staff.phone_number.trim().length) {
   toast.error('Необходимо заполнить поле "Номер телефона".');
  } else if (!this.state.staff.library_id.trim().length) {
   toast.error('Необходимо заполнить поле "Библиотека".');
  } else if (this.state.staff.roles.length == 0) {
   toast.error('Необходимо добавить поле "Роль".');
  } else {
   const res = await fetch('https://smart-pvl.kz/Api/api/staff/add.php', {
    method: 'POST',
    headers: {
     AUTH: localStorage.getItem('tokenLibrary'),
    },
    body: JSON.stringify({
     ...this.state.staff,
     staff: localStorage.getItem('idUser'),
    }),
   });
   const json = await res.json();

   if (json == 0) {
    await toast.success('Данный номер телефона уже имеется в системе');
   } else {
    await toast.success('Сотрудник успешно зарегистрирован');
    this.props.handleChangeChoiceItem();
    // return true;
   }
  }
 };

 handleChangeLib = async (e) => {
  this.setState({ lib_name_str: e.target.value });
  // if (e.target.value.length > 3) {
  //   const json = await SmartSreach.SmartSreach({
  //     tab: 'library_id',
  //     id_name: 'name',
  //     id_val: e.target.value,
  //     col: '*',
  //   });
  //   this.setState({ ...this.state, lib_name_temp: json.slice(0, json.length - 3) });
  // }
  // if (!e.target.value.length) {
  //  this.setState({ lib_name_temp: [] });
  // }
 };

 render() {
  const atrFormAddReader = [
   {
    title: 'Номер телефона',
    name: 'phone_number',
    type: 'tel',
   },
   {
    title: 'Библиотека',
    name: 'lib_name_str',
    type: 'select',
   },
   {
    title: 'Имя',
    name: 'first_name',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Фамилия',
    name: 'last_name',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Отчество',
    name: 'middle_name',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Дата рождения',
    name: 'date_of_birth',
    type: 'date',
    placeholder: 'Обязательный',
   },
   //    { title: 'Место рождения', name: 'place_age', type: 'text' },
   {
    title: 'Национальность',
    name: 'nation_id',
    type: 'text',
    placeholder: 'Обязательный',
   },
   // {
   //   title: 'Состоите ли в профсоюзе',
   //  name: 'profsouz',
   //   type: 'text',
   //   placeholder: 'Обязательный',
   // },
   {
    title: 'Образование',
    name: 'education_id',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Должность',
    name: 'position_id',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Пол',
    name: 'gender_id',
    type: 'select',
    placeholder: 'Обязательный',
    options: [
     { title: 'Мужской', id: 0 },
     { title: 'Женский', id: 1 },
    ],
   },
   {
    title: 'Общий стаж',
    name: 'general_experience',
    type: 'number',
    placeholder: 'Обязательный',
   },
   {
    title: 'Библиотечный стаж',
    name: 'library_experience',
    type: 'number',
    placeholder: 'Обязательный',
   },

   //    { title: 'Непрерывный стаж', name: 'neprer_stazh', type: 'text' },
   //    { title: 'Последнее место работы', name: 'last_work', type: 'text' },
   {
    title: 'ИИН',
    name: 'iin',
    type: 'number',
    placeholder: 'Необязательный',
   },
   {
    title: 'Семейное положение',
    name: 'family_status',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Номер паспорта',
    name: 'passport_number',
    type: 'number',
    placeholder: 'Необязательный',
   },
   {
    title: 'Адрес',
    name: 'address',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Пароль',
    name: 'password',
    type: 'text',
    placeholder: 'Обязательный',
   },
   {
    title: 'Должность/Права доступа',
    name: 'role',
    type: 'radio',
    option: this.props.RolesData,
    // option: [
    //  'Директор',
    //  'Инспектор по кадрам',
    //  'Отдел комплектования',
    //  'Методист',
    //  'Библиотекарь',
    //  // 'Областная библиотека'
    // ],
   },
  ];

  return (
   <form onSubmit={this.handleSubmit}>
    <div style={{ marginTop: '-20px', marginBottom: '35px', textAlign: 'center' }}>
     <Button className='btn_add_book' text='Добавить' />
    </div>
    <div className='form-wrapper'>
     {atrFormAddReader.map((el, i) => {
      if (el?.options) {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select name={el.name} onChange={this.handleChange}>
          <option hidden>{el.placeholder}</option>
          {el.options.map((option, i) => {
           return (
            <option key={option.id} value={option.id}>
             {option.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'role') {
       return (
        <div>
         <span>{el.title}</span>
         {this.state.specialMassive.map((i) => (
          <label key={i} className='form-input'>
           <select name={el.name} id={i} onChange={this.handleChangeRole}>
            <option hidden>{el.placeholder}</option>
            {el.option.map((option, i) => {
             return (
              <option key={option.id} value={option.id}>
               {option.title}
              </option>
             );
            })}
           </select>
          </label>
         ))}

         <div className='btn_add_book' style={{ marginBottom: '20px' }} onClick={() => this.specialMassiveGenerate()}>
          Добавить роль
         </div>
        </div>
       );
      } else if (el.type == 'tel') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>

         <MaskedInput
          mask='+7(***) *** - ****'
          onChange={(e) => this.setState({ staff: { ...this.state.staff, phone_number: e.target.value } })}
          value={this.state.staff.phone_number}
         />
        </label>
       );
      } else if (el.name == 'lib_name_str') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeLibName(e)}>
          <option hidden>Выберите данные</option>
          {this.props.SiglsDate.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.name}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'nation_id') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeNationName(e)}>
          <option hidden>Выберите данные</option>
          {this.props.NationalitySelectData.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'education_id') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeEducationName(e)}>
          <option hidden>Выберите данные</option>
          {this.props.StaffEducation.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'position_id') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changePositionName(e)}>
          <option hidden>Выберите данные</option>
          {this.props.PositionsData.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else if (el.name == 'family_status') {
       return (
        <label key={i} className='form-input'>
         <span>{el.title}</span>
         <select onChange={(e) => this.changeFamilynName(e)}>
          <option hidden>Выберите данные</option>
          {this.props.MaritalStatusData.map((item, i) => {
           // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
           return (
            <option value={item.id} key={i}>
             {item.title}
            </option>
           );
          })}
         </select>
        </label>
       );
      } else {
       return (
        <label className='form-input' key={i}>
         <span>{el.title}</span>
         <input type={el.type} name={el.name} placeholder={el.placeholder} onChange={this.handleChange} />
        </label>
       );
      }
     })}
    </div>
   </form>
  );
 }
}

const mapStateToProps = (data) => data;
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
