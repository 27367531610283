import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import Chart from '../../../components/charts/horizontal_bar';
import ChartArea from '../../../components/charts/area_chart';

import {
 getSvodStatByGiven,
 getSvodStatByReturn,
 getSvodStatByReaders,
 getSvodStatByFond,
 getSvodStatByVisits,
 getSvodStatByAge,
 getSvodStatByGemder,
 getSvodStatRegist,
 getSvodStatGenre,
 getSvodStatRegEmpl,
} from '../../../api';
import toast from 'react-hot-toast';

class AnalitSvod extends Component {
 state = {
  arr_stat: [],

  titlesArr: [],
  numbersArr: [],
  labelsArr: [],
  open_1: false,
  open_2: false,
  open_3: false,
  open_4: false,
  open_5: false,
  open_6: false,
  open_7: false,
  open_8: false,
  open_9: false,
  open_10: false,
  date1: '',
  date2: '',
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  if (this.state.date1 && this.state.date2) {
   toast.success('Можете выбрать тип отчета');
  } else {
   toast.error('Некорректно выбранный период');
  }
 };

 handleGiven = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getSvodStatByGiven.getSvodStatByGiven({
    start: this.state.date1,
    end: this.state.date2,
   });

   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.name);
    numbersArr.push(data.booksN);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: true,
    open_2: false,
    open_3: false,
    open_4: false,
    open_5: false,
    open_6: false,
    open_7: false,
    open_8: false,
    open_9: false,
    open_10: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleReturn = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getSvodStatByReturn.getSvodStatByReturn({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.name);
    numbersArr.push(data.booksN);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: true,
    open_3: false,
    open_4: false,
    open_5: false,
    open_6: false,
    open_7: false,
    open_8: false,
    open_9: false,
    open_10: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleFond = async () => {
  const res = await getSvodStatByFond.getSvodStatByFond({});
  var titlesArr = [];
  var numbersArr = [];
  res.map((data) => {
   titlesArr.push(data.name);
   numbersArr.push(data.booksN);
  });

  this.setState({
   titlesArr: titlesArr,
   numbersArr: numbersArr,
   open_1: false,
   open_2: false,
   open_3: true,
   open_4: false,
   open_5: false,
   open_6: false,
   open_7: false,
   open_8: false,
   open_9: false,
   open_10: false,
  });
 };

 handleReaders = async () => {
  const res = await getSvodStatByReaders.getSvodStatByReaders({});
  var titlesArr = [];
  var numbersArr = [];
  res.map((data) => {
   titlesArr.push(data.name);
   numbersArr.push(data.booksN);
  });

  this.setState({
   titlesArr: titlesArr,
   numbersArr: numbersArr,
   open_1: false,
   open_2: false,
   open_3: false,
   open_4: true,
   open_5: false,
   open_6: false,
   open_7: false,
   open_8: false,
   open_9: false,
   open_10: false,
  });
 };

 handleVisit = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getSvodStatByVisits.getSvodStatByVisits({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.name);
    numbersArr.push(data.booksN);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: false,
    open_3: false,
    open_4: false,
    open_5: true,
    open_6: false,
    open_7: false,
    open_8: false,
    open_9: false,
    open_10: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleAge = async () => {
  const res = await getSvodStatByAge.getSvodStatByAge({});

  const data = Object.values(res);

  var titlesArrMajor = [];
  var numbersArrMajor = [];
  var labelsArrMajor = [];

  data.map((data) => {
   var titlesArr = [];
   var numbersArr = [];
   var labelsArr = [];

   titlesArr.push(data.lib);
   numbersArr.push(data.to_5);
   numbersArr.push(data.f_5_15);
   numbersArr.push(data.f_15_21);
   numbersArr.push(data.f_21_35);
   numbersArr.push(data.f_35_60);
   numbersArr.push(data.f_60);
   labelsArr.push('до 5 лет');
   labelsArr.push('от 5 до 15');
   labelsArr.push('от 15 до 21');
   labelsArr.push('от 21 до 35');
   labelsArr.push('от 35 до 60');
   labelsArr.push('более 60 лет');

   titlesArrMajor.push(titlesArr);
   numbersArrMajor.push(numbersArr);
   labelsArrMajor.push(labelsArr);
  });

  console.log(titlesArrMajor);
  console.log(numbersArrMajor);
  console.log(labelsArrMajor);

  titlesArrMajor.map((lib, i) => console.log(titlesArrMajor[i]));
  this.setState({
   titlesArr: titlesArrMajor,
   numbersArr: numbersArrMajor,
   labelsArr: labelsArrMajor,

   open_1: false,
   open_2: false,
   open_3: false,
   open_4: false,
   open_5: false,
   open_6: true,
   open_7: false,
   open_8: false,
   open_9: false,
   open_10: false,
  });
 };

 handleGender = async () => {
  const res = await getSvodStatByGemder.getSvodStatByGemder({});

  const data = Object.values(res);

  var titlesArrMajor = [];
  var numbersArrMajor = [];
  var labelsArrMajor = [];

  data.map((data) => {
   var titlesArr = [];
   var numbersArr = [];
   var labelsArr = [];

   titlesArr.push(data.lib);

   numbersArr.push(data.man);
   numbersArr.push(data.woman);

   labelsArr.push('Мужчины');
   labelsArr.push('Женщины');

   titlesArrMajor.push(titlesArr);
   numbersArrMajor.push(numbersArr);
   labelsArrMajor.push(labelsArr);
  });

  console.log(titlesArrMajor);
  console.log(numbersArrMajor);
  console.log(labelsArrMajor);

  titlesArrMajor.map((lib, i) => console.log(titlesArrMajor[i]));
  this.setState({
   titlesArr: titlesArrMajor,
   numbersArr: numbersArrMajor,
   labelsArr: labelsArrMajor,

   open_1: false,
   open_2: false,
   open_3: false,
   open_4: false,
   open_5: false,
   open_6: false,
   open_7: true,
   open_8: false,
   open_9: false,
   open_10: false,
  });
 };

 handleRegReaders = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getSvodStatRegist.getSvodStatRegist({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.name);
    numbersArr.push(data.booksN);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: false,
    open_3: false,
    open_4: false,
    open_5: false,
    open_6: false,
    open_7: false,
    open_8: true,
    open_9: false,
    open_10: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleGenre = async () => {
  const res = await getSvodStatGenre.getSvodStatGenre({});

  const data = Object.values(res);

  var titlesArrMajor = [];
  var numbersArrMajor = [];
  var labelsArrMajor = [];

  data.map((data) => {
   var titlesArr = [];
   var numbersArr = [];
   var labelsArr = [];

   titlesArr.push(data.lib);

   numbersArr.push(data.g1);
   numbersArr.push(data.g2);
   numbersArr.push(data.g3);
   numbersArr.push(data.g4);
   numbersArr.push(data.g5);
   numbersArr.push(data.g6);
   numbersArr.push(data.g7);
   numbersArr.push(data.g8);
   numbersArr.push(data.g9);
   numbersArr.push(data.g10);
   numbersArr.push(data.g11);
   numbersArr.push(data.g12);
   numbersArr.push(data.g13);
   numbersArr.push(data.g14);

   labelsArr.push('Проза');
   labelsArr.push('Поэзия');
   labelsArr.push('Мемуары');
   labelsArr.push('Очерки');
   labelsArr.push('Детектив');
   labelsArr.push('Фантастика');
   labelsArr.push('Ужасы');
   labelsArr.push('Приключения');
   labelsArr.push('Женский роман');
   labelsArr.push('Комикс');
   labelsArr.push('Пьеса');
   labelsArr.push('Эссе');
   labelsArr.push('Фольклор');
   labelsArr.push('Другое');

   titlesArrMajor.push(titlesArr);
   numbersArrMajor.push(numbersArr);
   labelsArrMajor.push(labelsArr);
  });

  console.log(titlesArrMajor);
  console.log(numbersArrMajor);
  console.log(labelsArrMajor);

  titlesArrMajor.map((lib, i) => console.log(titlesArrMajor[i]));
  this.setState({
   titlesArr: titlesArrMajor,
   numbersArr: numbersArrMajor,
   labelsArr: labelsArrMajor,

   open_1: false,
   open_2: false,
   open_3: false,
   open_4: false,
   open_5: false,
   open_6: false,
   open_7: false,
   open_8: false,
   open_9: true,
   open_10: false,
  });
 };

 handleRegUsers = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getSvodStatRegEmpl.getSvodStatRegEmpl({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.name);
    numbersArr.push(data.booksN);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: false,
    open_3: false,
    open_4: false,
    open_5: false,
    open_6: false,
    open_7: false,
    open_8: false,
    open_9: false,
    open_10: true,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 render() {
  return (
   <div className='page-main'>
    {/* <div style={{ height: '5000px', overflow: 'auto', padding: '50px' }}>
    <div style={{ height: 'inherit', overflow: 'initial' }}> */}
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1> Отчетность</h1>
     </div>
     <div>
      <SincetoCalendar
       selectDate={this.selectDate}
       state1={this.state.date1}
       state2={this.state.date2}
       handleDate={this.handleDate}
       type='date'
      />
     </div>
     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Отчет по выдаче литературы' className='btn_library_del' onClick={this.handleGiven} />
       <Button text='Отчет по возврату литературы' className='btn_library_del' onClick={this.handleReturn} />
       <Button text='Отчет по фонду литературы' className='btn_library_del' onClick={this.handleFond} />
       <Button text='Отчет по читателям' className='btn_library_del' onClick={this.handleReaders} />
       <Button text='Отчет по посещениям' className='btn_library_del' onClick={this.handleVisit} />
      </div>
     </div>
     <div style={{ marginTop: '40px' }}></div>
     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Отчет по возрасту читателей' className='btn_library_del' onClick={this.handleAge} />
       <Button text='Отчет по половому признаку читателей' className='btn_library_del' onClick={this.handleGender} />
       <Button text='Отчет по регистрации читателей' className='btn_library_del' onClick={this.handleRegReaders} />
       <Button text='Отчет по жанрам' className='btn_library_del' onClick={this.handleGenre} />
       <Button text='Отчет по регистрации пользователей' className='btn_library_del' onClick={this.handleRegUsers} />
      </div>
     </div>
     {/* <div className='monitor' style={{ marginTop: '40px' }}> */}
     {this.state.open_1 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     {this.state.open_2 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     {this.state.open_3 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     {this.state.open_4 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     {this.state.open_5 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}

     <div className='areaChartsWrapper'>
      {this.state.open_6 &&
       this.state.titlesArr.map((lib, i) => (
        <div style={{ marginTop: '20px' }}>
         <h1>{this.state.titlesArr[i][0]}</h1>
         <div className='areaChartsColumn'>
          <ChartArea
           titlesArr={this.state.titlesArr[i]}
           labelsArr={this.state.labelsArr[i]}
           numbersArr={this.state.numbersArr[i]}
          />{' '}
         </div>
        </div>
       ))}
     </div>
     <div className='areaChartsWrapper'>
      {this.state.open_7 &&
       this.state.titlesArr.map((lib, i) => (
        <div style={{ marginTop: '20px' }}>
         <h1>{this.state.titlesArr[i][0]}</h1>
         <div className='areaChartsColumn'>
          <ChartArea
           titlesArr={this.state.titlesArr[i]}
           labelsArr={this.state.labelsArr[i]}
           numbersArr={this.state.numbersArr[i]}
          />{' '}
         </div>
        </div>
       ))}
     </div>
     {this.state.open_8 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     <div className='areaChartsWrapper'>
      {this.state.open_9 &&
       this.state.titlesArr.map((lib, i) => (
        <div style={{ marginTop: '20px' }}>
         <h1>{this.state.titlesArr[i][0]}</h1>
         <div className='areaChartsColumn'>
          <ChartArea
           titlesArr={this.state.titlesArr[i]}
           labelsArr={this.state.labelsArr[i]}
           numbersArr={this.state.numbersArr[i]}
          />{' '}
         </div>
        </div>
       ))}
     </div>
     {this.state.open_10 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}

     {/* </div> */}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(AnalitSvod);
