import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import toast from 'react-hot-toast';
import { getSvodByPopularity } from '../../../api';
import Button from '../../../components/button';

import '../otchet_set/style.css';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CSVDownload } from 'react-csv';

class OtchetSetDop7 extends Component {
  state = {
    arr_stat: [],

    open_1: false,
    open_2: false,
    date1: '',
    date2: '',
    SiglID: '-',
    SiglName: '-',
    csv: [],
  };

  handleDate = async () => {
    if (this.state.date1 && this.state.date2) {
      if (this.state.SiglID !== '-') {
        try {
          const res = await getSvodByPopularity.getSvodByPopularity({
            start: this.state.date1,
            end: this.state.date2,
            library_id: this.state.SiglID,
          });
          console.log(res);
          this.setState({ arr_stat: res, open_1: true });
        } catch {
          console.log('err');
        }
      } else {
        toast.error('Выберите библиотеку');
        this.setState({ arr_stat: [], open_1: false });
      }
    } else {
      toast.error('Некорректно выбранный период');
      this.setState({ arr_stat: [], open_1: false });
    }
  };

  selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

  handleTXT = () => {
    var text = '';
    this.state.arr_stat.map((data) => (text = text + data.title + '-' + data.by_t_number + '\r\n'));

    console.log(text);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'Отчет по популярности литературы');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handlePDF = () => {
    try {
      const input = document.getElementById('divDownload');
      html2canvas(input).then((canvas) => {
        const ImageData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(ImageData, 'JPEG', 0, 0);
        pdf.save('Отчет по популярности литературы.pdf');
      });
    } catch {
      console.log('err');
    }
  };

  handleCSV = () => {
    try {
      var majorArr = [];
      var headerArr = [];

      headerArr.push('Заглавие');
      headerArr.push('Количество');

      majorArr.push(headerArr);

      this.state.arr_stat.map((data) => {
        var minorArr = [];
        minorArr.push(data.title);
        minorArr.push(data.by_t_number);
        majorArr.push(minorArr);
      });

      console.log(majorArr);
      this.setState({ csv: majorArr, open_2: true });
    } catch {
      console.log('err');
    }
  };

  handleChangeModal = (e) => {
    let res = this.props.SiglsDate.find((i) => i.name === e.target.value);
    this.setState({ SiglID: res.id, SiglName: res.name });
  };

  render() {
    const headerItemsContent_1 = [
      { title: 'Заглавие', data: 'title' },
      { title: 'Количество', data: 'by_t_number' },
    ];

    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Формирование сводной отчетности по популярности литературы</h1>
          </div>

          <div className='search-filter-book_book'>
            <div style={{ margin: '10px', marginBottom: '10px', fontWeight: 'bold' }}></div>
            <div className='search-filter-book-rubrik' style={{ marginBottom: '20px' }}>
              <select
                onChange={(e) => {
                  this.handleChangeModal(e);
                }}
              >
                <option hidden>Выбрать библиотеку</option>
                {this.props.SiglsDate.map((item, i) => {
                  // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {/* <span className='plus' onClick={() => this.modalAddItem(el)}>
              +
             </span> */}
            </div>
          </div>

          <div>
            <SincetoCalendar
              selectDate={this.selectDate}
              state1={this.state.date1}
              state2={this.state.date2}
              handleDate={this.handleDate}
              type='date'
            />

            {this.state.open_1 && (
              <div className='searchSved'>
                <div className='buttons-container'>
                  <Button text='TXT' className='btn_library_del' onClick={this.handleTXT} />
                  <Button text='PDF' className='btn_library_del' onClick={this.handlePDF} />
                  <Button text='CSV' className='btn_library_del' onClick={this.handleCSV} />
                  {this.state.open_2 && <CSVDownload data={this.state.csv} target='_blank' />}
                </div>
              </div>
            )}

            {this.state.open_1 && (
              <div id='divDownload' style={{ width: '210mm', padding: '20px', margin: 'auto' }}>
                <CommonWrapperTable
                  mainArr={this.state.arr_stat}
                  headersArr={headerItemsContent_1}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(OtchetSetDop7);
