import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, cleanAnyState, setBOPartiaPart } from '../redux/action';

import { GetBatchesByDate_g } from '../api';

import CommonWrapperTable from '../components/tables/CommonWrapperTable/CommonWrapperTable';

import FormPartView_g from '../components/FormPartView_g/FormPartView_g';

import moment from 'moment';

class BookAddFromPart_g extends Component {
 state = {
  choiceItemPage: 'book',
  choiseItemPart: [],
 };

 returnOnFirstPage = async () => {
  this.setState({ choiceItemPage: 'book' });
  const obj = {
   date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  };
  const json = await GetBatchesByDate_g.GetBatchesByDate_g(obj);
  await this.props.setBOPartiaPart(json);
 };

 HandleEditClick = async (e) => {
  this.setState({ choiseItemPart: e, choiceItemPage: 'edit' });
 };

 async componentDidMount() {
  const obj = {
   date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  };
  const json = await GetBatchesByDate_g.GetBatchesByDate_g(obj);
  await this.props.setBOPartiaPart(json);
 }

 render() {
  const buttons = [{ title: 'Подробнее', event: (e) => this.HandleEditClick(e) }];

  const headerItemsContent = [
   { title: 'Дата поступления', data: 'entrance_date' },
   { title: 'Дата по документу', data: 'document_date' },

   { title: 'Номер документа', data: 'document_number' },
   { title: 'Примечание', data: 'note' },
   { title: 'Сопроводительный документ', data: 'soprov_doc' },

   { title: 'Поставщик', data: 'supplier' },
   { title: 'Тип источника', data: 'type_of_source' },
   { title: 'Количество БО', data: 'bo_number' },
   { title: 'Общая сумма', data: 'summa' },

   { title: 'Количество экземпляров', data: 'exemplar_number' },
   { title: 'Дата', data: 'dateCreate' },
   { title: 'Отметка о поступлении', data: 'status_type' },
   { title: '', data: 'button' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     {this.state.choiceItemPage !== 'book' ? (
      <FormPartView_g returnOnFirstPage={this.returnOnFirstPage} choiseItemPart={this.state.choiseItemPart} />
     ) : (
      <>
       <div className='title-total'>
        <h1>Контроль поступления периодических изданий</h1>
       </div>
       <div className='book-items-content' style={{ width: 'fit-content', paddingRight: '50px', marginRight: '-60px' }}>
        <CommonWrapperTable mainArr={this.props.BOPartiaPart} headersArr={headerItemsContent} buttons={buttons} />
       </div>
      </>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, cleanAnyState, setBOPartiaPart };

export default connect(mapStateToProps, mapDispatchToProps)(BookAddFromPart_g);
