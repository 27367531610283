import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import { Link } from 'react-router-dom';

// import history from '../components/history'

// import FilterPanel from '../../components/filter-panel';
import Pagination from '../../../components/pagination';
import FormBookAdd from './formBookAdd';
import Loader from '../../../components/loader';

import './style.css';

import arrow_sort from '../../../assets/icon/arrow-sort.png';

class BookAdd extends Component {
  state = {
    choiceItemPage: 'book',

    currentPagePag: '',

    sort: false,
    sortType: '',
    sortPag: false,

    scanerCode: null,
  };

  getData = async (e) => {
    await this.setState({ choiceItemPage: e.target.dataset.set });
    await this.props.getData('ebook_add', 'ebook_add');
  };

  pagination = async (pageNum) => {
    await this.props.paginationPages({ item: 'ebook_add', skip: pageNum, page: 'ebook_add' });
    await this.setState({ currentPagePag: pageNum });
  };

  sortItemBook = async (type) => {
    await this.props.sortItem('База книг', type, this.state.sort, 'bookAdd');
    await this.setState({ sort: !this.state.sort, sortPag: true, sortType: type });
  };

  selectItem = async (tab, id) => await this.props.selectItem(tab, id);

  async componentDidMount() {
    await this.props.getData('ebook_add', 'ebook_add');
    await this.props.getData('other_data', 'other_data');
  }

  render() {
    const choiceItem = [
      { title: 'Электронные ресурсы', dataset: 'еbook' },
      { title: 'Добавить', dataset: 'add' },
    ];

    const headerItemsContent = [
      { title: 'Автор', data: 'avtor' },
      { title: 'Название книги', data: 'name' },
      { title: 'Год издания', data: 'god' },
      { title: 'Издатель', data: 'izdatel' },
      { title: 'Тираж', data: 'tirazh' },
      { title: 'Место издания', data: 'place_izdn' },
      { title: 'Серия', data: 'seria' },
      { title: 'ISBN', data: 'ISBN' },
    ];
    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>УПРАВЛЕНИЕ И ДОСТУП К ЭЛЕКТРОННЫМ РЕСУРСАМ</h1>
          </div>

          <div className='choice-type'>
            {choiceItem.map((el, i) =>
              el === 'Добавить' ? (
                <h3
                  data-set={el.dataset}
                  key={i}
                  onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}
                >
                  {el.title}
                </h3>
              ) : (
                <h3 data-set={el.dataset} key={i} onClick={this.getData}>
                  {el.title}
                </h3>
              ),
            )}
          </div>

          {this.state.choiceItemPage === 'add' ? (
            <FormBookAdd />
          ) : (
            <>
              {/* <FilterPanel /> */}

              <div className='book-items-content'>
                <div className='book-items-header'>
                  {headerItemsContent.map((el, i) => {
                    return (
                      <div key={i}>
                        <p>{el.title}</p>
                        <img
                          src={arrow_sort}
                          alt='profile'
                          data-type={el.data}
                          onClick={(e) => this.sortItemBook(e.target.dataset.type)}
                        />
                      </div>
                    );
                  })}
                </div>

                {this.props.loader ? (
                  <Loader />
                ) : this.props.ebook_add.length === 0 ? (
                  <div className='search_output_null'>
                    <h1>:</h1>
                    <h3>Раздел пуст</h3>
                  </div>
                ) : (
                  this.props.ebook_add.map((el, i) => {
                    let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
                    let url = `${this.props.match.url}/${el.id}`;
                    // let newUrl = `/${el.id}`;
                    return (
                      <div className={oddEven} key={el.id}>
                        <div onClick={() => this.selectItem('books', el.id)}>
                          <p>{el.avtor}</p>
                        </div>
                        <div>
                          <p>{el.name}</p>
                        </div>
                        <div>
                          <p>{el.god}</p>
                        </div>
                        <div>
                          <p>{el.izdatel}</p>
                        </div>
                        <div>
                          <p>{el.tirazh}</p>
                        </div>
                        <div>
                          <p>{el.place_izdn}</p>
                        </div>
                        <div>
                          <p>{el.seria}</p>
                        </div>
                        <div>
                          <p>{el.ISBN}</p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>

              <Pagination
                pageCount={this.props.numbersBtnPag}
                paginationPages={(pageNum) => this.pagination(pageNum)}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(BookAdd);
