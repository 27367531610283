import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import Chart from '../../../components/charts/horizontal_bar';

import { getGeneralByGivenBooks, getGeneralByReturnBooks, getGeneralByGenre, getGeneralByPopular } from '../../../api';
import toast from 'react-hot-toast';

class AnalitGeneral extends Component {
 state = {
  arr_stat: [],

  titlesArr: [],
  numbersArr: [],

  open_1: false,
  open_2: false,
  open_2: false,
  open_2: false,

  date1: '',
  date2: '',
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  if (this.state.date1 && this.state.date2) {
   toast.success('Можете выбрать тип отчета');
  } else {
   toast.error('Некорректно выбранный период');
  }
 };

 handleGiven = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByGivenBooks.getGeneralByGivenBooks({
    start: this.state.date1,
    end: this.state.date2,
   });

   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.title);
    numbersArr.push(data.by_t_number);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: true,
    open_2: false,
    open_3: false,
    open_4: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleReturn = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByReturnBooks.getGeneralByReturnBooks({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.title);
    numbersArr.push(data.by_t_number);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: true,
    open_3: false,
    open_4: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handleGenre = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByGenre.getGeneralByGenre({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.title);
    numbersArr.push(data.by_t_number);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: false,
    open_3: true,
    open_4: false,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 handlePop = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByPopular.getGeneralByPopular({
    start: this.state.date1,
    end: this.state.date2,
   });
   var titlesArr = [];
   var numbersArr = [];
   res.map((data) => {
    titlesArr.push(data.title);
    numbersArr.push(data.by_t_number);
   });

   this.setState({
    titlesArr: titlesArr,
    numbersArr: numbersArr,
    open_1: false,
    open_2: false,
    open_3: false,
    open_4: true,
   });
  } else {
   toast.error('Выберите период');
  }
 };

 render() {
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Формирование общей статистики</h1>
     </div>
     <div>
      <SincetoCalendar
       selectDate={this.selectDate}
       state1={this.state.date1}
       state2={this.state.date2}
       handleDate={this.handleDate}
       type='date'
      />
     </div>
     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Отчет по выдаче литературы' className='btn_library_del' onClick={this.handleGiven} />
       <Button text='Отчет по возвратам литературы' className='btn_library_del' onClick={this.handleReturn} />
       <Button text='Отчет по жанрам литературы' className='btn_library_del' onClick={this.handleGenre} />
       <Button text='Отчет по популярности литературы' className='btn_library_del' onClick={this.handlePop} />
      </div>
     </div>
     <div className='monitor' style={{ marginTop: '40px' }}>
      {this.state.open_1 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_2 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_3 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_4 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(AnalitGeneral);
