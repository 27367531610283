import React from 'react';

import './modal.css'

const Alert = ({ handleClose, show, children, btn, classBtn, btnTwo = null, cancelClose = null }) => {

    let showHideClassName = show ? "modal1 display-block" : "modal1 display-none";

    return (
        <div className={showHideClassName} data-type='modal' >
            <section className="modal-main1">
                {children}
                <div className='btns'>
                    <button type="button" className={classBtn} onClick={handleClose}>
                        {btn}
                    </button>

                    {btnTwo
                        ? <button type="button" className={classBtn} onClick={cancelClose}>
                            {btnTwo}
                        </button>
                        : null}
                </div>
            </section>
        </div>
    );
};

export default Alert;