import React, { Component } from 'react';

import { connect } from 'react-redux'
import SincetoCalendar from '../../../../components/calendars/SincetoCalendar/SincetoCalendar';
import { setDate } from '../../../../redux/action';

import Chart from '../chart'
import ChartG from '../chart-g'

import './../style.css'

class StatTotal extends Component {
    state = {
        date1: '',
        date2: '',
        statArr: false
    };

    // async componentDidMount() {
    //     const res = await getStatLibPeriod.getStatLibPeriod({
    //         id_sigl: localStorage.lib_ID,
    //         start: this.state.date1 || '2020-01',
    //         end: this.state.date2 || '2030-01',
    //         lib_name: localStorage.lib_name
    //     })
    //     this.setState({ statArr: res })

    // }
    selectDate = (e) => this.setState({ [e.target.name]: e.target.value })

    handleDate = async () => {
        // const res = await getStatLibPeriod.getStatLibPeriod({
        //     id_sigl: localStorage.lib_ID,
        //     start: this.state.date1,
        //     end: this.state.date2,
        //     lib_name: localStorage.lib_name
        // })
        // console.log(res);
        // this.setState({ statArr: res })
    }

    render() {

        const analitBook = [{ label: "Количество", data: [{ x: 'Книг выдали', y: 50 }] }]

        const pieOptions = {
            title: "Аналитика по социальному статусу",
            pieHole: 0,
            slices: [{ color: "#2BB673" }, { color: "#d91e48" }, { color: "#007fad" }, { color: "#e9a227" }],
            legend: { position: "bottom", alignment: "center", textStyle: { color: "233238", fontSize: 14 } },
            tooltip: { showColorCode: true },
            chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
            fontName: "Roboto"
        };
        const dataPie = [
            ["Age", "Weight"], ["Общественно-политическая литература", 107], ["Естественнонаучная литература", 100],
            ["Медицина", 107], ["Техническая и сельскохозяйственная литература", 100], ["Культура, наука, искусство и спорт", 107], ["Детективы", 100],
            ["Приключения", 107], ["Женский роман", 100], ["Ужасы", 107], ["Комиксы", 100], ["Фантастика", 107], ["Лирика", 100],
            ["Пьесы", 107], ["Мемуары", 100], ["Фольклор", 107], ["Литература универсального содержания (энциклопедии, словари, справочники)", 100]
        ]

        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>
                    <SincetoCalendar
                        selectDate={this.selectDate}
                        state1={this.state.date1}
                        state2={this.state.date2}
                        handleDate={this.handleDate}
                    />
                    <div className='block-chart'>
                        <Chart text={'Аналитика выдачи книг'} data={analitBook} />
                        <ChartG pieOptions={pieOptions} text='Аналитика книг по отраслям' type="PieChart" dataPie={dataPie} />
                    </div>

                </div>

            </div >
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(StatTotal);