import commonTryCatch from '../helpers/commonTryCatch';

//Вход и автовход
export const Auth = {
  async Auth(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/staff/login.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//сиглы библиотек
export const LibSigls = {
  async LibSigls(item, lib_name) {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/libraries/get.php`, 'POST');
  },
};

//genres
export const SelectsAllByTypeGenres = {
  async SelectsAllByTypeGenres(item, lib_name) {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/genres/get.php`, 'POST');
  },
};

//otrasliZnani
export const SelectsAllByTypeOtrasliZnanii = {
  async SelectsAllByTypeOtrasliZnanii() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/branches_of_knowledge/get.php`,
      'POST',
    );
  },
};

// soderj
export const SelectsAllByTypeSoderj = {
  async SelectsAllByTypeSoderj() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/by_content/get.php`, 'POST');
  },
};

// vidDok
export const SelectsAllByTypeVidDok = {
  async SelectsAllByTypeVidDok() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/document_types/get.php`, 'POST');
  },
};

//istPos
export const SelectsAllByTypeIstPos = {
  async SelectsAllByTypeIstPos() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/sources_of_income/get.php`, 'POST');
  },
};

//status
export const SelectsAllByTypeStatus = {
  async SelectsAllByTypeStatus() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statuses/get.php`, 'POST');
  },
};

//school
export const SelectsAllByTypeSchool = {
  async SelectsAllByTypeSchool() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/customer_education_list/get.php`);
  },
};

//education
export const SelectsAllByTypeEducationReader = {
  async SelectsAllByTypeEducationReader() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/customer_education_list/get_fin.php`,
      'POST',
    );
  },
};

export const SelectsAllByTypeEducationStaff = {
  async SelectsAllByTypeEducationStaff() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/employees_education/get.php`, 'POST');
  },
};

//nationality
export const SelectsAllByTypeNationality = {
  async SelectsAllByTypeNationality() {
    return await commonTryCatch('https://smart-pvl.kz/Api/api/nation/get.php');
  },
};

//language
export const LanguageDataSelects = {
  async LanguageDataSelects() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/language/get.php`, 'POST');
  },
};

//staffing
export const StaffingSelectsData = {
  async StaffingSelectsData() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/cabinet/get.php`);
  },
};

//lib_level
export const LibLevelSelectsData = {
  async LibLevelSelectsData() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/bibliographic_level/get.php`);
  },
};

export const MaritalStatusData = {
  async MaritalStatusData() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/marital_status/get.php`);
  },
};

export const PositionsData = {
  async PositionsData() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/positions/get.php`);
  },
};

export const RolesGetData = {
  async RolesGetData() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/roles/get.php`);
  },
};

//education2
export const Education2DataSelects = {
  async Education2DataSelects() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/selects/select.php?type=education2`);
  },
};

//suppliers
export const SupliersDataSelects = {
  async SupliersDataSelects() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/supplier/get.php`);
  },
};

//place_publication
export const PublPlaceDataSelects = {
  async PublPlaceDataSelects() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/places_of_publication/get.php`,
      'POST',
    );
  },
};
export const UDKSelectsByGeneral = {
  async UDKSelectsByGeneral() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/selects/classifiers.php?type=general`);
  },
};

export const UDKSelectsByKazakstanika = {
  async UDKSelectsByKazakstanika() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/selects/classifiers.php?type=kazakhstanika`,
    );
  },
};

export const UDKSelectsByBBK = {
  async UDKSelectsByBBK() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/selects/classifiers.php?type=bbk`);
  },
};

export const UDKSelectsByKraeved = {
  async UDKSelectsByKraeved() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/selects/classifiers.php?type=kraeved`);
  },
};
//BO
export const GetBatchesByDate = {
  async GetBatchesByDate(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches/getByDate.php`,
      'POST',
      JSON.stringify({ date: data.date }),
    );
  },
};

export const GetBatchesByDate_g = {
  async GetBatchesByDate_g(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches_g/getByDate.php`,
      'POST',
      JSON.stringify({ date: data.date }),
    );
  },
};

export const getPartsByID = {
  async getPartsByID(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches_parts/getPartsByID.php`,
      'POST',
      JSON.stringify({ id: data.id }),
    );
  },
};

export const getPartsByID_g = {
  async getPartsByID_g(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches_parts_g/getPartsByID.php`,
      'POST',
      JSON.stringify({ id: data.id }),
    );
  },
};

export const edit_batch = {
  async edit_batch(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches/edit.php`,
      'POST',
      JSON.stringify({ ...data }),
    );
  },
};

export const accept_batch = {
  async accept_batch(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches/accept.php`,
      'POST',
      JSON.stringify({ ...data }),
    );
  },
};

export const accept_batch_g = {
  async accept_batch_g(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches_g/accept.php`,
      'POST',
      JSON.stringify({ ...data }),
    );
  },
};

export const periodical_publications_add = {
  async periodical_publications_add(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/periodical_publications/add.php`,
      'POST',
      JSON.stringify({ ...data }),
    );
  },
};

export const periodical_publications_getByLib = {
  async periodical_publications_getByLib() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/periodical_publications/getByLib.php`,
      'POST',
    );
  },
};
//получение данных
export const getDataStat1 = {
  async getDataStat1(lib_name) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/getStat.php`,
      'POST',
      JSON.stringify({
        item: 'static_read',
        token: localStorage.getItem('tokenLibrary'),
      }),
    );
  },
};

export const getDataStat2 = {
  async getDataStat2() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/getBooksCount.php?group_by=otrasl`);
  },
};

export const getDataStat3 = {
  async getDataStat3() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/getBooksCount.php?group_by=zhanr`);
  },
};

export const getDataStat4 = {
  async getDataStat4() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/getBooksCount.php?group_by=docVid`);
  },
};

export const getBooksForBronForFirstPage = {
  async getBooksForBronForFirstPage() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/library_books/search.php`, 'POST');
  },
};

//получение данных
export const setData = {
  async setData(item, lib_name) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/Display.php`,
      'POST',
      JSON.stringify({
        item,
        token: localStorage.getItem('tokenLibrary'),
        skip: 1,
        lib_name,
      }),
    );
  },
};

//выбор книги
export const selectedItem = {
  async selectedBook(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/bibliographic_descriptions/get.php`,
      'POST',
      JSON.stringify({ ...data }),
    );
  },
};

//сортировка книг
export const sortItem = {
  async sortBook(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/Display.php`,
      'POST',
      JSON.stringify({
        ...data,
        token: localStorage.getItem('tokenLibrary'),
      }),
    );
  },
};

//регистрация читателя
export const user = {
  async regUser(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/AddUser.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//редактирование читателя
export const editUser = {
  async editUser(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/RedStaff.php`,
      'POST',
      JSON.stringify({
        ...data,
        tokenSt: localStorage.getItem('tokenLibrary'),
      }),
    );
  },
};

export const getAllBooksByPageAndSort = {
  async getAllBooksByPageAndSort(page, sortBy, sortDir) {
    const data = {
      filter: {
        sort_column: sortBy,
        sort_direction: sortDir,
      },
    };
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/bibliographic_descriptions/get.php?page=${page}`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getAllBooksByAuthorNameInvenNumber = {
  async getAllBooksByAuthorNameInvenNumber(page, sortBy, sortDir, search_column, keyword) {
    const data = {
      filter: {
        sort_column: sortBy,
        sort_direction: sortDir,
        search: {
          search_column: search_column,
          keyword: keyword,
        },
      },
    };
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/bibliographic_descriptions/get.php?page=${page}`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//живой поиск
export const SmartSreach = {
  async SmartSreach(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/SmartSreach.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//сотрудники при открытии модуля
export const GetStaffFirstPage = {
  async GetStaffFirstPage() {
    const data = {
      filter: {
        search: {
          keyword: '',
        },
      },
    };
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/staff/get.php?page=1`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const GetLogsFirstPage = {
  async GetLogsFirstPage(type, date1, date2) {
    const data = {
      table: type,
      date1: date1,
      date2: date2,
    };
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/log/get.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const GetStaffBySearch = {
  async GetStaffBySearch(data, page) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/staff/get.php?page=${page}`,
      'POST',
      JSON.stringify(data),
    );
  },
};
export const DeleteUserStaff = {
  async DeleteUserStaff(id) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/staff/delete.php`,
      'POST',
      JSON.stringify({ id }),
    );
  },
};
export const GetStaffFullData = {
  async GetStaffFullData(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/staff/get.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};
export const GetStaffFullDataLog = {
  async GetStaffFullDataLog(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/log/getByPerson.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};
export const GetBookFiles = {
  async GetBookFiles(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/book_files/getByTitle.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};
export const GetBookFiles_marc = {
  async GetBookFiles_marc(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/book_files/getByTitle_marc.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};
//Библиотеки и мироприятия
export const libraryPage = {
  async libraryPageSetData(type) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/index.php?method=Sobr`,
      'POST',
      JSON.stringify({ type }),
    );
  },
  async libraryPageAddItem(page) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/SmartSreach.php`,
      'POST',
      JSON.stringify({ page }),
    );
  },
  async libraryPageEditItem(page) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/SmartSreach.php`,
      'POST',
      JSON.stringify({ page }),
    );
  },
  async libraryPageDelItem(page) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/SmartSreach.php`,
      'POST',
      JSON.stringify({ page }),
    );
  },
};

//Списание книг
export const delBookApi = {
  async delBookApi(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/books_write_off/add.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//Списание книг
export const delMultiBookApi = {
  async delMultiBookApi(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/delMultiBook.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//Добавление дополнительных параметров при добавлении книги (Тематическая рубрика, Основной язык, Вид документа, Источник поступления, Отрасли)
export const AddOther = {
  async AddOther(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/AddOther.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//Редактирование и удаление Тематической рубрики
export const editDelOther = {
  async editDelOther(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/RedTem.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//большой фильтр книг
export const SearchBooks = {
  async SearchBooks(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/SearchBooks.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

//таблица КСУ
export const tableKSU = {
  async tableKSU(data) {
    return await commonTryCatch(`https://smart-pvl.kz/Api/ksu.php`, 'POST', JSON.stringify(data));
  },
};

//тип каталога книг
export const typeCatalogBooks = {
  async typeCatalogBooks(data) {
    return await commonTryCatch(`https://smart-pvl.kz/Api/Books.php`, 'POST', JSON.stringify(data));
  },
};

//получение статистики зарегестрированных
export const fetchStatistic = {
  async fetchStatistic(dateFrom, dateTo) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/getStat.php`,
      'POST',
      JSON.stringify({ start: dateFrom, end: dateTo }),
    );
  },
};

export const GetUsersMonthReg = {
  async getUsersMonthReg(data) {
    const formData = new FormData();
    formData.append('month', data.month);
    formData.append('item', data.item);
    return await commonTryCatch(`https://smart-pvl.kz/Api/diary.php`, 'POST', formData);
  },
};

export const getInventNumberBook = {
  async getInventNumberBook(data) {
    //   const formData = new FormData();
    //   formData.append('book_id', data.book_id);
    //   formData.append('sigl', data.sigl);
    //   formData.append('method', 'getAll');
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/library_books/get.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getDolg = {
  async getDolg() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/books_history_dop/searchAll.php`,
      'POST',
    );
  },
};

export const getInventByTitle = {
  async getInventByTitle(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/library_books_dop/getByTitle.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const GetBooksByStatus_4 = {
  async GetBooksByStatus_4(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/books_write_off_dop/searchByTitle.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const SearchMarkBook = {
  async SearchMarkBook(page, sortBy, sortDir, search_column, keyword) {
    const data = {
      filter: {
        sort_column: sortBy,
        sort_direction: sortDir,
        search: {
          search_column: search_column,
          keyword: keyword,
        },
      },
    };

    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/bibliographic_descriptions_dop/get.php?page=${page}`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const GetDataMarc = {
  async GetDataMarc(id) {
    const data = {
      id: id,
    };

    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/bibliographic_descriptions_dop/getMarcData.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const addBatch = {
  async addBatch(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches/add.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const addBatch_g = {
  async addBatch_g(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/batches_g/add.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const editInventNumStatus = {
  async editInventNumStatus(data) {
    const res = await fetch(`https://smart-pvl.kz/Api/api/library_books/edit.php`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
    });
    const json = await res.json();
    return json;
  },
};

export const editBookedBookStatus = {
  async editBookedBookStatus(data) {
    const res = await fetch(`https://smart-pvl.kz/Api/api/booked_books/edit.php`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
    });
    const json = await res.json();
    return json;
  },
};

export const addInventNumber = {
  async addInventNumber(data) {
    const formData = new FormData();
    formData.append('book_id', data.book);
    formData.append('library_id', data.sigl);
    formData.append('inventory_number', data.invent_num);
    formData.append('status', data.status_vyd);

    const dataReq = {
      book_id: data.book,
      library_id: data.sigl,
      inventory_number: data.invent_num,
      status: data.status_vyd,
    };
    //   formData.append('method', 'add');
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/library_books/add.php`,
      'POST',
      JSON.stringify(dataReq),
    );
  },
};

export const delInventNumber = {
  async delInventNumber(data) {
    const dataReq = {
      id: data.id,
    };
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/library_books/delete.php`,
      'POST',
      JSON.stringify(dataReq),
    );
  },
};

export const getStaticFond = {
  async getStaticFond(data) {
    const formData = new FormData();
    formData.append('start', data.start);
    formData.append('end', data.end);
    return await commonTryCatch(`https://smart-pvl.kz/Api/getPeriodVydachi.php`, 'POST', formData);
  },
};
export const getUserInfo = {
  async getUserInfo(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/users/getUserInfo.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getCustomers = {
  async getCustomers() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/bd_customers.php`);
  },
};

export const GetBronedRow = {
  async GetBronedRow() {
    const res = await fetch(`https://smart-pvl.kz/Api/api/library_books/search3.php`, {
      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
    });
    const json = await res.json();
    return json;
  },
};

export const ForBroneSearch = {
  async ForBroneSearch(data) {
    const res = await fetch(`https://smart-pvl.kz/Api/api/library_books_dop/get.php`, {
      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  },
};

export const GetBooksByTitleTest = {
  async GetBooksByTitleTest(data) {
    const res = await fetch(
      `https://smart-pvl.kz/Api/api/bibliographic_descriptions/getbyTitleForReaders.php`,
      {
        headers: {
          AUTH: localStorage.getItem('tokenLibrary'),
        },
        method: 'POST',
        body: JSON.stringify(data),
      },
    );
    const json = await res.json();
    return json;
  },
};
export const InsertDataTest = {
  async InsertDataTest(data) {
    const res = await fetch(`https://smart-pvl.kz/Api/api/reader/login.php`, {
      //    headers: {
      //     AUTH: localStorage.getItem('tokenLibrary'),
      //    },
      method: 'POST',
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  },
};

export const GetBroned = {
  async GetBroned(data) {
    const res = await fetch(`https://smart-pvl.kz/Api/api/booked_books/get.php`, {
      method: 'POST',
      body: JSON.stringify(data),

      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
    });
    const json = await res.json();
    return json;
  },
};

export const getMultiBookData = {
  async getMultiBookData(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/getbyID.php`,
      'POST',
      JSON.stringify({ id: data }),
    );
  },
};

export const getInventNumberMultiBook = {
  async getInventNumberMultiBook(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/getInventNumber.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const addInventNumberMultiBook = {
  async addInventNumberMultiBook(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/addInventNumber.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getMultiBookInventByType = {
  async getMultiBookInventByType(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/getMultiBookInventByType.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getStatLibPeriod = {
  async getStatLibPeriod(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/static_sved.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getStatLibPeriodReg = {
  async getStatLibPeriodReg(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/reg.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getStatLibPeriodVisit = {
  async getStatLibPeriodVisit(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/visit.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getStatLibPeriodBooks = {
  async getStatLibPeriodBooks(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/books.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getStatByAge = {
  async getStatByAge() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/age.php`, 'POST');
  },
};

export const getStatByRoles = {
  async getStatByRoles() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/roles.php`, 'POST');
  },
};

export const getRegRules = {
  async getRegRules() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/reg_rules/get.php`, 'POST');
  },
};

export const getSerRules = {
  async getSerRules() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/srvc_rules/get.php`, 'POST');
  },
};

export const getStatByMW = {
  async getStatByMW() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/mw.php`, 'POST');
  },
};

export const getStatByGenre = {
  async getStatByGenre() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/genre.php`, 'POST');
  },
};

export const getStatByTitle = {
  async getStatByTitle() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/title.php`, 'POST');
  },
};

export const getStatyOtrasli = {
  async getStatyOtrasli() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/otrasli.php`, 'POST');
  },
};
export const getStatyStatuses = {
  async getStatyStatuses() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/statuses.php`, 'POST');
  },
};

export const getStatByLangs = {
  async getStatByLangs() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics/langs.php`, 'POST');
  },
};
export const getGeneralByGivenBooks = {
  async getGeneralByGivenBooks(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/given_books.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralByGivenBooks_Staff = {
  async getGeneralByGivenBooks_Staff(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/given_books_staff.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralByReturnBooks_Staff = {
  async getGeneralByReturnBooks_Staff(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/return_books_staff.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralByReturnBooks = {
  async getGeneralByReturnBooks(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/return_books.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralByGenre = {
  async getGeneralByGenre(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/general_genre.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralByPopular = {
  async getGeneralByPopular(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/popular.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralByOtrasli = {
  async getGeneralByOtrasli(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/otrasliByDate.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralByStatuses = {
  async getGeneralByStatuses(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/statusesByDate.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getGeneralBylangs = {
  async getGeneralBylangs(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/langsByDate.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};
export const getSvodStatByGiven = {
  async getSvodStatByGiven(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/given.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatBygivenGeneral = {
  async getSvodStatBygivenGeneral(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/givenGeneral.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatBygivenPeriodical = {
  async getSvodStatBygivenPeriodical(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/givenPeriodical.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatBygivenGazety = {
  async getSvodStatBygivenGazety(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/givenGazety.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatBygivenMagazines = {
  async getSvodStatBygivenMagazines(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/givenMagazines.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatByReturn = {
  async getSvodStatByReturn(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/return.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatByFond = {
  async getSvodStatByFond() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics_svod/fond.php`, 'POST');
  },
};

export const getSvodStatByReaders = {
  async getSvodStatByReaders() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics_svod/readers.php`, 'POST');
  },
};

export const getSvodStatByVisits = {
  async getSvodStatByVisits(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/visits.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatByAge = {
  async getSvodStatByAge() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics_svod/age.php`, 'POST');
  },
};

export const getSvodStatByGemder = {
  async getSvodStatByGemder() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics_svod/gender.php`, 'POST');
  },
};

export const getSvodStatRegist = {
  async getSvodStatRegist(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/reg.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getKSUdata = {
  async getKSUdata(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics/getKSUdata.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatGenre = {
  async getSvodStatGenre() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/statistics_svod/genre.php`, 'POST');
  },
};

export const getSvodStatFondsBranches = {
  async getSvodStatFondsBranches() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/fondsByBranches.php`,
      'POST',
    );
  },
};

export const getSvodStatFondsLangs = {
  async getSvodStatFondsLangs() {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/fondsByLangs.php`,
      'POST',
    );
  },
};

export const getSvodByPopularity = {
  async getSvodByPopularity(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/SvodByPopularity.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getSvodStatRegEmpl = {
  async getSvodStatRegEmpl(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/statistics_svod/reg_e.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const returnBook = {
  async returnBook(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/books_history/edit.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const editHistoryOfGiven = {
  async editHistoryOfGiven(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/editHistoryOfGiven.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const eventCustomer = {
  async eventCustomer(data, event) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/customer/${event}.php`,
      'POST',
      JSON.stringify(data),
    );
  },
  async getCustomer(page) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/customer/get.php`,
      'POST',
      JSON.stringify({ page }),
    );
  },
};

export const getInventByType = {
  async getInventByType(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/library_books/search.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getMultiInventByType = {
  async getMultiInventByType(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/search.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getInventByType2 = {
  async getInventByType2(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/library_books/search2.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getInventByType3 = {
  async getInventByType3(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/books_history/search.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const searchHistoryOfGiven = {
  async searchHistoryOfGiven(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/multibooks/searchHistoryOfGiven.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};

export const getProvider = {
  async getProvider() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/provider/get.php`);
  },
};
export const getZakazchik = {
  async getZakazchik() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/zakazchik/get.php`);
  },
};
export const getBooksExtensionInfo = {
  async getBooksExtensionInfo() {
    return await commonTryCatch(`https://smart-pvl.kz/Api/api/books_extension/get.php`);
  },
};

export const editBooksExtension = {
  async editBooksExtension(data) {
    return await commonTryCatch(
      `https://smart-pvl.kz/Api/api/books_extension/edit.php`,
      'POST',
      JSON.stringify(data),
    );
  },
};
