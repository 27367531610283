import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import Loader from '../../../components/loader';

import './style.css'

class TotlaStat extends Component {

    async componentDidMount() {
        await this.props.getData('total_stat', 'total_stat')
    }

    render() {
        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>Общая статистика</h1>
                    </div>

                    {this.props.total_stat.length
                        ?
                        <div className='monitor'>
                            <div className='colunm'>
                                <div>
                                    <p>Книг выдали - {this.props.total_stat[0]} раз</p>
                                </div>
                                <div>
                                    <p>Книг возвратили - {this.props.total_stat[1]} раз</p>
                                </div>
                                <div>
                                    <p>Количество жанров: {this.props.total_stat[2]}</p>
                                </div>
                                <div>
                                    <p>Популярные жанры: {this.props.total_stat[3].map(el => <p className='marg'>{el.zhanr}</p>)}</p>
                                </div>
                                <div>
                                    <p>Посещаемость: {this.props.total_stat[4]}</p>
                                </div>
                                <div>
                                    <p>Количество книг: {this.props.total_stat[5]}</p>
                                </div>
                                <div>
                                    <p>По социальному статусу:</p>
                                    <div className='type'>
                                        <p>Госслужащий - {this.props.total_stat[6]}</p>
                                        <p>Гражданский служащий - {this.props.total_stat[7]}</p>
                                        <p>Рабочий - {this.props.total_stat[8]}</p>
                                        <p>Студент - {this.props.total_stat[9]}</p>
                                        <p>Школьник - {this.props.total_stat[10]}</p>
                                        <p>Пенсионер - {this.props.total_stat[11]}</p>
                                        <p>Инвалид - {this.props.total_stat[12]}</p>
                                    </div>
                                </div>
                                <div>
                                    <p>Язык:</p>
                                    <div className='type'>
                                        <p>Русский - {this.props.total_stat[13]}</p>
                                        <p>Казахский - {this.props.total_stat[14]}</p>
                                        <p>Другие - {this.props.total_stat[15]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loader />}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(TotlaStat);