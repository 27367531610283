import React, { Component } from 'react';

import { connect } from 'react-redux';
import history from '../../../components/history';
import { setDate } from '../../../redux/action';

import './style.css';

class Log extends Component {
 mainPage = () => {
  history.push('/');
 };

 render() {
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Мониторинг работы Системы</h1>
     </div>

     <div className='monitor'>
      <div className='colunm'>
       <div>
        <p>Пользовательская версия</p>
        <a href='https://smart-user.doro.kz/'>Переход</a>
       </div>
       <div>
        <p>Админ версия</p>
        <span onClick={this.mainPage}>Переход</span>
       </div>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(Log);
