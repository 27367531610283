import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';
import { GetLogsFirstPage } from '../../../api';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import Button from '../../../components/button';

import toast from 'react-hot-toast';

import './style.css';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

class Obrabotka extends Component {
 state = {
  choiceItem: 'employees',
  date1: '',
  date2: '',
 };

 exLogs = async () => {
  await fetch('https://smart-pvl.kz/Api/ExLogs.php');
  window.location.href = 'https://smart-pvl.kz/Api/ExLogs.php';
 };

 choiseItem = async (item) => {
  await this.props.setDate([], 'obrabotka', 'obrabotka');
  this.setState({ choiceItem: item });
 };

 getDataLog = async (date1, date2) => {
  if (this.state.choiceItem !== 'obrabotka') {
   const type = this.state.choiceItem;
   const res = await GetLogsFirstPage.GetLogsFirstPage(type, date1, date2);
   await this.props.setDate(res, 'obrabotka', 'obrabotka');
  }
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  if (this.state.date1) {
   if (this.state.date2) {
    this.getDataLog(this.state.date1, this.state.date2);
   } else {
    toast.error('Укажите конечную дату');
   }
  } else {
   toast.error('Укажите начальную дату');
  }
 };

 handleTXT = () => {
  var text = '';
  //   console.log(this.props.obrabotka);
  this.state.choiceItem == 'employees'
   ? this.props.obrabotka.map(
      (data) =>
       (text =
        text +
        data.log_timestamp +
        '---' +
        data.libFrom +
        '---' +
        data.executor_name +
        '---' +
        data.action_text +
        '---' +
        data.employee_name +
        ';' +
        '\r\n')
     )
   : this.state.choiceItem == 'customers'
   ? this.props.obrabotka.map(
      (data) =>
       (text =
        text +
        data.log_timestamp +
        '---' +
        data.libFrom +
        '---' +
        data.executor_name +
        '---' +
        data.action_text +
        '---' +
        data.name +
        ';' +
        '\r\n')
     )
   : this.state.choiceItem == 'books_description'
   ? this.props.obrabotka.map(
      (data) =>
       (text =
        text +
        data.log_timestamp +
        '---' +
        data.libFrom +
        '---' +
        data.executor_name +
        '---' +
        data.action_text +
        '---' +
        data.title +
        ';' +
        '\r\n')
     )
   : this.state.choiceItem == 'inventory_number'
   ? this.props.obrabotka.map(
      (data) =>
       (text =
        text +
        data.log_timestamp +
        '---' +
        data.libFrom +
        '---' +
        data.executor_name +
        '---' +
        data.action_text +
        '---' +
        data.title +
        '---' +
        data.inventory_number +
        '---' +
        data.libTo +
        ';' +
        '\r\n')
     )
   : console.log('none');
  //   console.log(text);
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', 'Библиотека. Логи');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
 };

 render() {
  let url = this.props.match.url.slice(1);

  const choiceItem1 = [
   { title: 'Сотрудники', data: 'employees' },
   { title: 'Читатели', data: 'customers' },

   { title: 'Библиографические описания', data: 'books_description' },
   { title: 'Инвентарные номера', data: 'inventory_number' },
  ];

  const choiceItem2 = [{ title: 'Сотрудники', data: 'employees' }];

  var choiceItem = [
   { title: 'Сотрудники', data: 'employees' },
   { title: 'Читатели', data: 'customers' },

   { title: 'Библиографические описания', data: 'books_description' },
   { title: 'Инвентарные номера', data: 'inventory_number' },
  ];

  //   url === 'obrabotka' ? (choiceItem = choiceItem1) : (choiceItem = choiceItem2);

  const headerItemsContent_staff = [
   { title: 'Дата-Время', data: 'log_timestamp' },
   { title: 'Библиотека', data: 'libFrom' },
   { title: 'Сотрудник (субъект)', data: 'executor_name' },
   { title: 'Действие (лог)', data: 'action_text' },
   { title: 'Сотрудник (объект)', data: 'employee_name' },
  ];

  const headerItemsContent_readers = [
   { title: 'Дата-Время', data: 'log_timestamp' },
   { title: 'Библиотека', data: 'libFrom' },
   { title: 'Сотрудник (субъект)', data: 'executor_name' },
   { title: 'Действие (лог)', data: 'action_text' },
   { title: 'Читатель (объект)', data: 'name' },
  ];

  const headerItemsContent_books = [
   { title: 'Дата-Время', data: 'log_timestamp' },
   { title: 'Библиотека', data: 'libFrom' },
   { title: 'Сотрудник (субъект)', data: 'executor_name' },
   { title: 'Действие (лог)', data: 'action_text' },
   { title: 'Библиографическое описание (объект)', data: 'title' },
  ];

  const headerItemsContent_inv = [
   { title: 'Дата-Время', data: 'log_timestamp' },
   { title: 'Библиотека (субъект)', data: 'libFrom' },
   { title: 'Сотрудник (субъект)', data: 'executor_name' },
   { title: 'Действие (лог)', data: 'action_text' },
   { title: 'Библиографическое описание (объект)', data: 'title' },
   { title: 'Инвентраный номер (объект)', data: 'inventory_number' },
   { title: 'Библиотека (объект)', data: 'libTo' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>{url === 'obrabotka' ? 'ВЕДЕНИЕ И ОБРАБОТКА СИСТЕМНЫХ ЖУРНАЛОВ' : 'Работа в системе и с литературой'}</h1>
     </div>
     <div className='choice-type'>
      {choiceItem.map((el, i) => (
       <h3
        style={{ background: el.data == this.state.choiceItem ? '#5b7fbb' : '' }}
        data-set={el.data}
        key={i}
        onClick={(e) => this.choiseItem(e.target.dataset.set)}
       >
        {el.title}
       </h3>
      ))}
     </div>
     <div className='book-items-content'>
      <div style={{ marginBottom: '20px' }}>
       {this.props.obrabotka.length === 0 ? null : (
        <Button text='Выгрузить' className='btn_library_del' onClick={this.handleTXT} />
       )}
      </div>
      {this.props.obrabotka.length === 0 ? (
       <div className='search_output_null'>
        <div style={{ textAlign: 'left', marginBottom: '20px' }}>
         <h3>Укажите отрезок периода для отображения действий</h3>
        </div>
        <SincetoCalendar
         selectDate={this.selectDate}
         state1={this.state.date1}
         state2={this.state.date2}
         handleDate={this.handleDate}
         type='date'
        />
       </div>
      ) : this.state.choiceItem == 'employees' ? (
       <CommonWrapperTable mainArr={this.props.obrabotka} headersArr={headerItemsContent_staff} />
      ) : this.state.choiceItem == 'customers' ? (
       <CommonWrapperTable mainArr={this.props.obrabotka} headersArr={headerItemsContent_readers} />
      ) : this.state.choiceItem == 'books_description' ? (
       <CommonWrapperTable mainArr={this.props.obrabotka} headersArr={headerItemsContent_books} />
      ) : this.state.choiceItem == 'inventory_number' ? (
       <CommonWrapperTable mainArr={this.props.obrabotka} headersArr={headerItemsContent_inv} />
      ) : (
       <p>{this.state.choiceItem}</p>
      )}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(Obrabotka);
