import React from "react";
import "./styles.css";
import { Chart } from "react-charts";

export default function ChartF({ ...props }) {
    const data = React.useMemo(
        () => props.data, []
    );
    const series = React.useMemo(
        () => ({ type: "bar" }), []
    );
    const axes = React.useMemo(
        () => [
            { primary: true, type: "ordinal", position: "bottom" },
            { position: "left", type: "linear", stacked: true }
        ],
        []
    );
    return (
        <div className="chart">
            <div>
                <p className="title-chart">{props.text}</p>
                <Chart data={data} series={series} axes={axes} tooltip />
            </div>
        </div>
    );
}
