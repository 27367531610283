import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from '@material-ui/core';
import pdfMake from 'pdfmake';
import s from './KsuTable.module.css';
import vfs from '../../../assets/fonts/vfs_fonts';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import toast from 'react-hot-toast';
import { getKSUdata } from '../../../api';
import { useEffect, useState } from 'react';

const KsuTable = ({ date }) => {
  const [date1, set_date1] = useState(0);
  const [date2, set_date2] = useState(0);
  const [arr_stat, set_arr_stat] = useState({});

  const writeRotatedText = (text, main) => {
    var ctx,
      canvas = document.createElement('canvas');
    canvas.width = 18;
    ctx = canvas.getContext('2d');
    ctx.font = '8px';
    ctx.save();
    if (main) {
      ctx.translate(7, 80);
    } else {
      ctx.translate(7, 60);
    }
    ctx.textAlign = 'center';
    ctx.rotate(-0.5 * Math.PI);
    ctx.fillStyle = '#000';
    if (text.split(' ').length > 2) {
      let arr = text.split(' ');
      ctx.fillText(arr.splice(0, Math.round(arr.length / 2)).join(' '), 0, 0);
      ctx.fillText(arr.join(' '), 0, 9);
    } else {
      ctx.fillText(text, 0, 0);
    }
    ctx.restore();
    return canvas.toDataURL();
  };

  pdfMake.vfs = vfs;
  pdfMake.fonts = {
    NimbusSans: {
      normal: 'NimbusSanL-Reg.otf',
      bold: 'NimbusSanL-Bol.otf',
      italics: 'NimbusSanL-RegIta.otf',
      bolditalics: 'NimbusSanL-BolIta.otf',
    },
  };
  const listBooks = [
    'книги',
    'брошюры',
    'журналы',
    'газеты',
    'Аудио/видео издание',
    'Кинофотофонодокумент',
    'Электронное издание',
    'другие',
    'на каз',
    'на рус',
    'другие',

    'ОПЛ',
    'ЕНЛ',
    'Техника, сельское и лесное хозяйство',
    'Искусство, физкультура и спорт',
    'Филологические науки, худ. литература',
  ];
  const handleClick = () => {
    var docDefinition = {
      pageMargins: [5, 50, 5, 0],
      info: {
        title: 'Библиотечный фонд',
      },
      content: [
        { text: `Часть 3. Итоги движения библиотечного фонда`, style: 'subheader' },
        {
          style: 'tableExample',
          color: '#444',
          fontSize: 8,
          table: {
            body: [
              [
                {
                  image: writeRotatedText('Период итогов движения библиотечного фонда', true),
                  rowSpan: 2,
                  style: 'tableHeader',
                },
                {
                  image: writeRotatedText('Всего документов', true),
                  rowSpan: 2,
                  style: 'tableHeader',
                },
                {
                  image: writeRotatedText('Общая стоимость документов', true),
                  rowSpan: 2,
                  style: 'tableHeader',
                },
                {
                  text: 'Количество печатных изданий',
                  style: 'tableHeader',
                  colSpan: 2,
                  alignment: 'center',
                },
                {},
                {
                  text: 'Количество периодических печатных изданий',
                  style: 'tableHeader',
                  colSpan: 2,
                  alignment: 'center',
                },
                {},
                {
                  text: 'Количество аудиовизуальных изданий',
                  style: 'tableHeader',
                  colSpan: 4,
                  alignment: 'center',
                },
                {},
                {},
                {},
                { text: 'На языках', style: 'tableHeader', colSpan: 3, alignment: 'center' },
                {},
                {},
                {
                  text: 'Принято на баланс',
                  style: 'tableHeader',
                  colSpan: 2,
                  alignment: 'center',
                },
                {},
                { text: 'По содержанию', style: 'tableHeader', colSpan: 5, alignment: 'center' },
                {},
                {},
                {},
                {},
              ],
              [
                '',
                '',
                '',
                ...listBooks.map((elem, i) => {
                  return { image: writeRotatedText(elem), style: 'tableCell', alignment: 'center' };
                }),
              ],
              [
                { text: 0, style: 'tableCell', alignment: 'center' },
                { text: 0, style: 'tableCell', alignment: 'center' },
                { text: 0, style: 'tableCell', alignment: 'center' },
                ...listBooks.map((elem, i) => {
                  return { text: 0, style: 'tableCell', alignment: 'center' };
                }),
              ],
            ],
          },
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 15, 0, 15],
        },
        subheader: {
          fontSize: 13,
          color: 'black',
          marginLeft: 15,
        },
        tableHeader: {
          bold: true,
          fontSize: 8,
          color: 'black',
        },
        tableHeaderRotate: {
          bold: true,
          fontSize: 5,
          color: 'black',
        },
        tableCell: {
          color: 'black',
          fontSize: 8,
        },
      },
      defaultStyle: {
        font: 'NimbusSans',
      },
    };
    pdfMake.createPdf(docDefinition).download('Библиотечный фонд');
  };

  const selectDate = (e) => {
    e.target.name == 'date1'
      ? set_date1(e.target.value)
      : e.target.name == 'date2'
      ? set_date2(e.target.value)
      : toast.error('Ошибка');
  };

  const checker = (e) => {
    const data = e ? Number(e) : 0;
    return data;
  };

  const search_data_type_of_document = (id) => {
    let res = arr_stat.InvN_by_date_types.find((i) => i.type_of_document == id);
    typeof res == 'undefined' ? (res = 0) : (res = res.by_dt_number);
    return res;
  };

  const summ_data_type_of_document = () => {
    let sum = 0;
    var val = arr_stat.InvN_by_date_types.reduce(function (previousValue, currentValue) {
      return {
        by_dt_number: previousValue.by_dt_number + currentValue.by_dt_number,
      };
    });
    typeof val == 'undefined' ? (val = 0) : (val = val);
    return val.by_dt_number;
  };

  const search_data_periodical_by_date_types = (id) => {
    let res = arr_stat.periodical_by_date_types.find((i) => i.type == id);
    typeof res == 'undefined' ? (res = 0) : (res = res.by_dt_number);
    return res;
  };

  const search_data_InvN_by_branches = (id) => {
    let res = arr_stat.InvN_by_branches.find((i) => i.by_branches_of_knowledge == id);
    typeof res == 'undefined' ? (res = 0) : (res = res.by_bok_number);
    return res;
  };

  const search_data_periodical_by_branches = (id) => {
    let res = arr_stat.periodical_by_branches.find((i) => i.tema == id);
    typeof res == 'undefined' ? (res = 0) : (res = res.by_bc_number);
    return res;
  };

  const search_data_InvN_by_date_langs = (id) => {
    let res = arr_stat.InvN_by_date_langs.find((i) => i.language == id);
    typeof res == 'undefined' ? (res = 0) : (res = res.by_l_number);
    return res;
  };

  const search_data_periodical_by_date_langs = (id) => {
    let res = arr_stat.periodical_by_date_langs.find((i) => i.osn_language == id);
    typeof res == 'undefined' ? (res = 0) : (res = res.by_l_number);
    return res;
  };

  const summ_data_InvN_by_date_langs = () => {
    let sum = 0;
    var val = arr_stat.InvN_by_date_langs.reduce(function (previousValue, currentValue) {
      return {
        by_l_number: previousValue.by_l_number + currentValue.by_l_number,
      };
    });
    typeof val == 'undefined' ? (val = 0) : (val = val);
    return val.by_l_number;
  };

  const summ_data_periodical_by_date_langs = () => {
    let sum = 0;
    var val = arr_stat.periodical_by_date_langs.reduce(function (previousValue, currentValue) {
      return {
        by_l_number: previousValue.by_l_number + currentValue.by_l_number,
      };
    });
    typeof val == 'undefined' ? (val = 0) : (val = val);
    return val.by_l_number;
  };

  const handleDate = async () => {
    if (date1 && date2) {
      try {
        const res = await getKSUdata.getKSUdata({
          start: date1,
          end: date2,
        });
        set_arr_stat(res);
      } catch {
        console.log('err');
      }
    } else {
      toast.error('Некорректно выбранный период');
      //  this.setState({ arr_stat: [], open_1: false });
      set_arr_stat([]);
    }
  };

  useEffect(() => {
    console.log('обновление таблицы');
  }, [arr_stat]);

  return (
    <div className={s.container}>
      <div className={s.downloadContainer}>
        <SincetoCalendar
          selectDate={selectDate}
          state1={date1}
          state2={date2}
          handleDate={handleDate}
          type='date'
        />

        {/* <button onClick={handleClick} className={s.downloadBtn}>
          Скачать в PDF формате
        </button> */}
      </div>
      <div className={s.downloadContainer}>
        {/* <p className={s.readerRecord}>Часть 3. Итоги движения библиотечного фонда</p> */}
      </div>
      <TableContainer className={s.tableContainer} component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={s.rotatedCell} align='center' rowSpan={2}>
                Период итогов движения библиотечного фонда <br /> от {date1} <br /> по {date2}
              </TableCell>
              <TableCell className={s.rotatedCell} align='center' rowSpan={2}>
                Всего документов
              </TableCell>
              <TableCell className={s.rotatedCell} align='center' rowSpan={2}>
                Общая стоимость документов
              </TableCell>
              <TableCell className={s.mainCell} align='center' colSpan={2}>
                Количество печатных изданий
              </TableCell>
              <TableCell className={s.mainCell} align='center' colSpan={2}>
                Количество периодических печатных изданий
              </TableCell>
              <TableCell className={s.mainCell} align='center' colSpan={4}>
                Количество аудиовизуальных изданий
              </TableCell>
              <TableCell className={s.mainCell} align='center' colSpan={3}>
                На языках
              </TableCell>
              <TableCell className={s.mainCell} align='center' colSpan={5}>
                По содержанию
              </TableCell>
            </TableRow>

            <TableRow>
              {listBooks &&
                listBooks.map((elem, i) => (
                  <TableCell key={i} align='center' className={s.rotatedCell}>
                    {elem}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {arr_stat ? (
              Object.keys(arr_stat).length > 0 ? (
                <TableRow>
                  <TableCell align='center' className={s.cell}></TableCell>
                  <TableCell align='center' className={s.cell}>
                    {checker(arr_stat.InvN_by_dates) +
                      checker(arr_stat.periodical_by_date) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {checker(arr_stat.InvN_by_price[0].value_sum) +
                      checker(arr_stat.periodical_by_price[0].value_sum) +
                      ' тнг.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_type_of_document(26) + ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_type_of_document(27) + ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_periodical_by_date_types(1) + ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_periodical_by_date_types(0) + ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_type_of_document(30) + ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_type_of_document(31) + ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_type_of_document(29) + ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {summ_data_type_of_document() -
                      search_data_type_of_document(30) -
                      search_data_type_of_document(31) -
                      search_data_type_of_document(29) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_InvN_by_date_langs(501) +
                      search_data_periodical_by_date_langs(501) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_InvN_by_date_langs(503) +
                      search_data_periodical_by_date_langs(503) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {summ_data_InvN_by_date_langs() +
                      summ_data_periodical_by_date_langs() -
                      search_data_InvN_by_date_langs(501) -
                      search_data_periodical_by_date_langs(501) -
                      search_data_InvN_by_date_langs(503) -
                      search_data_periodical_by_date_langs(503) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_InvN_by_branches(14) +
                      search_data_periodical_by_branches(1) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_InvN_by_branches(15) +
                      search_data_periodical_by_branches(2) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_InvN_by_branches(16) +
                      search_data_periodical_by_branches(3) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_InvN_by_branches(17) +
                      search_data_periodical_by_branches(4) +
                      ' шт.'}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    {search_data_InvN_by_branches(20) +
                      search_data_periodical_by_branches(5) +
                      ' шт.'}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell align='center' className={s.cell}>
                    -
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>{' '}
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>{' '}
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>{' '}
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>{' '}
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>{' '}
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>{' '}
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>{' '}
                  <TableCell align='center' className={s.cell}>
                    -{' '}
                  </TableCell>
                </TableRow>
              )
            ) : (
              console.info()
            )}

            {/* {listBooks &&
                listBooks.map((elem, i) => (
                  <TableCell key={i} align='center' className={s.cell}>
                    {0}
                  </TableCell>
                ))} */}
            {/* </TableRow> */}
          </TableBody>
        </Table>
        {/* <div style={{ display: 'table', margin: 'auto', marginTop: '40px' }}>
          <TableCell className={s.cell} align='center'>
            В системе не достаточно данных
          </TableCell>
        </div> */}
      </TableContainer>
    </div>
  );
};

export default KsuTable;
