import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, bronPolka } from '../../../redux/action';

import { GetBookFiles_marc } from '../../../api';
import Modal from '../../../components/modal';

import Button from '../../../components/button';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import toast from 'react-hot-toast';
import debounce from '../../../utils/debounce';

class BookFilesMarc extends Component {
  state = {
    show: false,
    showAdd: false,
    showRed: false,

    selected: [],
    AddDoc: {
      bf_title: '',
      bf_author_sign: '',
      bf_description: '',
      bf_note: '',
      bf_file: '',
      book_id: '',
    },
    RedDoc: {
      bf_title: '',
      bf_author_sign: '',
      bf_description: '',
      bf_note: '',
      bf_file: '',
      id: '',
    },
    inputType: '',
  };

  handleInputTitle = async (e) => {
    if (e.target.value != '') {
      var value = '';
      e.target.value == 'Отобразить все книги' ? (value = '') : (value = e.target.value);
      this.setState({ inputType: value });

      const obj3 = {
        value: value,
      };
      const res = await GetBookFiles_marc.GetBookFiles_marc(obj3);
      const data = Object.values(res);
      await this.props.setDate(data, 'book_files_marc', 'book_files_marc');
    }
  };

  SelectItem = (e) => {
    this.setState({ selected: e, show: true });
  };

  AddItem = (e) => {
    this.setState({
      ...this.state,
      AddDoc: { ...this.state.AddDoc, book_id: e.book_id },
      showAdd: true,
    });
  };

  EditItem = (e) => {
    this.setState({ selected: e, RedDoc: { ...this.state.RedDoc, id: e.id }, showRed: true });
  };

  DeleteItem = async (e) => {
    const res = await fetch('https://smart-pvl.kz/Api/api/book_files/delete_marc.php', {
      method: 'POST',
      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
      body: JSON.stringify({ id: e.bf_id }),
    });

    const json = await res.json();
    if (json) {
      toast.success('MARC-данные удалена.');
      const obj3 = {
        value: this.state.inputType,
      };
      const resP = await GetBookFiles_marc.GetBookFiles_marc(obj3);
      const data = Object.values(resP);
      await this.props.setDate(data, 'book_files_marc', 'book_files_marc');
    } else {
      toast.error('Ошибка.');
    }
  };

  closeModal2 = () => {
    // this.setState({ showModal6: false });
  };

  closeModal6 = () => {
    this.setState({ selected: [], show: false });
  };

  closeModal21 = () => {
    this.setState({
      ...this.state,
      AddDoc: {
        ...this.state.AddDoc,
        book_id: '',
        bf_title: '',
        bf_description: '',
        bf_note: '',
        bf_author_sign: '',
        bf_file: '',
      },
      showAdd: false,
    });
  };

  closeModal222 = () => {
    this.setState({
      ...this.state,
      RedDoc: {
        ...this.state.RedDoc,
        book_id: '',
        bf_title: '',
        bf_description: '',
        bf_note: '',
        bf_author_sign: '',
      },
      showRed: false,
    });
  };

  SendFile = async () => {
    if (this.state.AddDoc.bf_title) {
      if (this.state.AddDoc.bf_description) {
        if (this.state.AddDoc.bf_author_sign) {
          //  if (this.state.AddDoc.bf_note) {
          if (this.state.AddDoc.bf_file) {
            const formData = await new FormData();
            await formData.append(`bf_title`, this.state.AddDoc.bf_title);
            await formData.append(`bf_description`, this.state.AddDoc.bf_description);
            await formData.append(`bf_author_sign`, this.state.AddDoc.bf_author_sign);
            await formData.append(`bf_note`, this.state.AddDoc.bf_note);
            await formData.append(`bf_file`, this.state.AddDoc.bf_file);
            await formData.append(`book_id`, this.state.AddDoc.book_id);

            const res = await fetch('https://smart-pvl.kz/Api/api/book_files/add_marc.php', {
              method: 'POST',
              headers: {
                AUTH: localStorage.getItem('tokenLibrary'),
              },
              body: formData,
            });

            const json = await res.json();

            if (json) {
              if (json == 124) {
                toast.error('Возникла ошибка. Возможно вы загрузили исполняемый файл');
              } else if (json == 125) {
                toast.error('Возникла ошибка с загрузкой файла');
              } else if (json == 126) {
                toast.error('Возникла ошибка. Возможно недопустимый тип файла');
              } else if (json == 127) {
                toast.error('Возникла ошибка. Возможно недопустимый размер файла');
              } else if (json == 128) {
                toast.error('Возникла ошибка с загрузкой файла');
              } else {
                toast.success('MARC-данные добавлена.');
                this.closeModal21();
                const obj3 = {
                  value: this.state.inputType,
                };
                const resP = await GetBookFiles_marc.GetBookFiles_marc(obj3);
                const data = Object.values(resP);
                await this.props.setDate(data, 'book_files_marc', 'book_files_marc');
              }
            } else {
              toast.error('Ошибка при добавлении.');
              this.closeModal21();
            }
          } else {
            toast.error('Прикрепите файл');
          }
          //  } else {
          //   toast.error('Выберите доступность библиографической записи');
          //  }
        } else {
          toast.error('Заполните Источник библиографической записи');
        }
      } else {
        toast.error('Заполните Описание библиографической записи');
      }
    } else {
      toast.error('Заполните Название библиографической записи');
    }
  };

  RedFile = async () => {
    if (this.state.RedDoc.bf_title) {
      if (this.state.RedDoc.bf_description) {
        if (this.state.RedDoc.bf_author_sign) {
          //  if (this.state.RedDoc.bf_note) {
          //  const formData = await new FormData();
          //  await formData.append(`bf_title`, this.state.RedDoc.bf_title);
          //  await formData.append(`bf_description`, this.state.RedDoc.bf_description);
          //  await formData.append(`bf_author_sign`, this.state.RedDoc.bf_author_sign);
          //  await formData.append(`bf_note`, this.state.RedDoc.bf_note);
          //  await formData.append(`id`, this.state.selected.bf_id);

          const res = await fetch('https://smart-pvl.kz/Api/api/book_files/edit_marc.php', {
            method: 'POST',
            headers: {
              AUTH: localStorage.getItem('tokenLibrary'),
            },
            body: JSON.stringify({
              bf_title: this.state.RedDoc.bf_title,
              bf_description: this.state.RedDoc.bf_description,
              bf_author_sign: this.state.RedDoc.bf_author_sign,
              bf_note: this.state.RedDoc.bf_note,
              id: this.state.selected.bf_id,
            }),
          });

          const json = await res.json();

          if (json) {
            toast.success('MARC-данные отредактирована.');
            this.closeModal222();
            const obj3 = {
              value: this.state.inputType,
            };
            const resP = await GetBookFiles_marc.GetBookFiles_marc(obj3);
            const data = Object.values(resP);
            await this.props.setDate(data, 'book_files_marc', 'book_files_marc');
          } else {
            toast.error('Ошибка при редактировании.');
            this.closeModal222();
          }
          //  } else {
          //   toast.error('Выберите доступность библиографической записи');
          //  }
        } else {
          toast.error('Заполните Источник библиографической записи');
        }
      } else {
        toast.error('Заполните Описание библиографической записи');
      }
    } else {
      toast.error('Заполните Название библиографической записи');
    }
  };

  render() {
    const buttons = [
      { title: 'Подробнее', event: (e) => this.SelectItem(e) },
      { title: 'Добавить', event: (e) => this.AddItem(e) },
      { title: 'Удалить', event: (e) => this.DeleteItem(e) },
    ];

    const headerItemsContent = [
      { title: 'Название', data: 'title' },
      { title: 'Автор', data: 'author' },
      { title: 'Издатель', data: 'publisher' },
      { title: 'Номер части', data: 'publisher' },
      { title: 'Заглавие части', data: 'publisher' },
      { title: 'Подключенная запись', data: 'bf_title' },
      { title: 'Файл', data: 'bf_file' },
      { title: '', data: 'bf_buttons' },
    ];

    return (
      <div className='page-main'>
        <Modal show={this.state.show} closeModalEvent={(e) => this.closeModal2()}>
          <div className='st12'>
            <div className='sideBarInnerHeader'>MARC-данные</div>
            <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
              <div className='search-filter-book__book'>
                <input
                  disabled
                  value={this.state.selected.bf_title}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      selected: { ...this.state.selected, bf_title: e.target.value },
                    })
                  }
                  placeholder='MARC-данные'
                />
              </div>
              <div className='search-filter-book__book'>
                <input
                  disabled
                  value={this.state.selected.bf_description}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      selected: { ...this.state.selected, bf_description: e.target.value },
                    })
                  }
                  placeholder='Описание'
                />
              </div>
              <div className='search-filter-book__book'>
                <input
                  disabled
                  value={this.state.selected.bf_author_sign}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      selected: { ...this.state.selected, bf_author_sign: e.target.value },
                    })
                  }
                  placeholder='Источник'
                />
              </div>
              {/* <div className='search-filter-book__book'>
        <select
         disabled
         value={this.state.selected.bf_note}
         // onChange={(e) => this.getNameAndValue5(e)}
        >
         <option hidden>Доступ</option>
         <option key='1' value='1'>
          Открытый доступ
         </option>
         <option key='2' value='0'>
          Не доступен
         </option>
        </select>
       </div> */}
              <div className='search-filter-book__book'>
                <a
                  href={'https://smart-pvl.kz/marc_files/' + this.state.selected.bf_file}
                  target='_blank'
                >
                  {this.state.selected.bf_file}
                </a>
              </div>
            </div>
            <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
              <Button className='st3' text='Закрыть' onClick={() => this.closeModal6()} />
            </div>
          </div>
        </Modal>
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Modal show={this.state.showAdd} closeModalEvent={(e) => this.closeModal2()}>
          <div className='st12'>
            <div className='sideBarInnerHeader'>MARC-данные</div>
            <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
              <div className='search-filter-book__book'>
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      AddDoc: { ...this.state.AddDoc, bf_title: e.target.value },
                    })
                  }
                  placeholder='MARC-данные'
                />
              </div>
              <div className='search-filter-book__book'>
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      AddDoc: { ...this.state.AddDoc, bf_description: e.target.value },
                    })
                  }
                  placeholder='Описание'
                />
              </div>
              <div className='search-filter-book__book'>
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      AddDoc: { ...this.state.AddDoc, bf_author_sign: e.target.value },
                    })
                  }
                  placeholder='Источник'
                />
              </div>
              {/* <div className='search-filter-book__book'>
        <select
         onChange={(e) => this.setState({ ...this.state, AddDoc: { ...this.state.AddDoc, bf_note: e.target.value } })}
        >
         <option hidden>Доступ</option>
         <option key='1' value='1'>
          Открытый доступ
         </option>
         <option key='2' value='0'>
          Не доступен
         </option>
        </select>
       </div> */}
              <div className='search-filter-book__book'>
                <input
                  accept='.csv'
                  type='file'
                  onChange={(e) => {
                    console.log(e);
                    this.setState({
                      ...this.state,
                      AddDoc: { ...this.state.AddDoc, bf_file: e.target.files[0] },
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
              <Button className='st3' text='Добавить' onClick={() => this.SendFile()} />
              <Button className='st3' text='Закрыть' onClick={() => this.closeModal21()} />
            </div>
          </div>
        </Modal>
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Modal show={this.state.showRed} closeModalEvent={(e) => this.closeModal2()}>
          <div className='st12'>
            <div className='sideBarInnerHeader'>MARC-данные</div>
            <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
              <div className='search-filter-book__book'>
                <input disabled value={this.state.selected.bf_title} />
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      RedDoc: { ...this.state.RedDoc, bf_title: e.target.value },
                    })
                  }
                  placeholder='MARC-данные (новое значение)'
                />
              </div>
              <div className='search-filter-book__book'>
                <input disabled value={this.state.selected.bf_description} />
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      RedDoc: { ...this.state.RedDoc, bf_description: e.target.value },
                    })
                  }
                  placeholder='Описание (новое значение)'
                />
              </div>
              <div className='search-filter-book__book'>
                <input disabled value={this.state.selected.bf_author_sign} />
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      RedDoc: { ...this.state.RedDoc, bf_author_sign: e.target.value },
                    })
                  }
                  placeholder='Источник (новое значение)'
                />
              </div>
              <div className='search-filter-book__book'>
                <select
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      RedDoc: { ...this.state.RedDoc, bf_note: e.target.value },
                    })
                  }
                >
                  <option hidden>Доступ</option>
                  <option key='1' value='1'>
                    Открытый доступ
                  </option>
                  <option key='2' value='0'>
                    Не доступен
                  </option>
                </select>
              </div>
              <div className='search-filter-book__book'>
                <a
                  href={'https://smart-pvl.kz/marc_files/' + this.state.selected.bf_file}
                  target='_blank'
                >
                  {this.state.selected.bf_file}
                </a>
              </div>
            </div>
            <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
              <Button className='st3' text='Редактировать' onClick={() => this.RedFile()} />
              <Button className='st3' text='Закрыть' onClick={() => this.closeModal222()} />
            </div>
          </div>
        </Modal>
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

        <div className='page-out-wrapper'>
          <div className='smart-search' style={{ marginBottom: '20px' }}>
            <input
              type='text'
              placeholder='Введите заглавие'
              onChange={debounce(this.handleInputTitle, 900)}
            />
          </div>

          <div className='book-items-content'>
            <CommonWrapperTable
              mainArr={this.props.book_files_marc}
              headersArr={headerItemsContent}
              buttons={buttons}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, bronPolka };

export default connect(mapStateToProps, mapDispatchToProps)(BookFilesMarc);
