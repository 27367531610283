import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';
import { sortItem } from '../../../api'

import Loader from '../../../components/loader';

import './style.css'

class TotlaStat extends Component {

    state = {
        abc: '',
        kol_book: 0
    }

    abcChange = async (e) => {

        const res = await sortItem.sortBook({ item: 'abc', letter: e.target.value })
        await this.setState({ abc: e.target.value, kol_book: res })
    }

    async componentDidMount() {
        await this.props.getData('svod_otchet', 'svod_otchet')
    }

    render() {

        const abc_ru = ["А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "О", "П", "Р", "С", "Т", "У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ы", "Ь", "Э", "Ю", "Я"];

        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>Формирование и печать стандартных выходных форм</h1>
                    </div>

                    {/* {this.props.svod_otchet.length
                            ?
                            <div className='monitor'>
                                <div className='colunm'>

                                </div>
                            </div>
                            :
                            <Loader />} */}

                    <div className='monitor1'>

                        <div className='formu'>

                            <div className='f1'>
                                <h3>Каталожная карта с сеткой</h3>
                                <div className='f1-wrapper'>
                                    {['ЦБ', 'МЗ', 'М4', 'М10', 'К', 'М', 'Д2', 'Д3', 'Д4', 'Д8', '', ''].map((el, i) => {
                                        return (
                                            <div key={i} className='f1-item'>
                                                <p>{el}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className='f1'>
                                <h3>Книжный формуляр</h3>
                                <div className='f2-wrapper'>
                                    <div className='f2-header'>
                                        <p>каз.яз</p>
                                        <div>
                                            <p>398(574)</p>
                                            <p>159641</p>
                                        </div>
                                        <p>Д 15</p>
                                        <p>Дала фолькорынын антологиясы.</p>
                                        <p>Алматы. 2020</p>
                                        <p>(мук): 2700 т.</p>
                                    </div>
                                    {Array.from({ length: 10 }, (el) => el).map((el, i) => <div className='f2-line' key={i}></div>)}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(TotlaStat);