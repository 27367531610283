import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';

import { Router } from 'react-router';
import history from './components/history';
import ErrorModal from './components/ErrorModal/ErrorModal';
import { ErrorBoundary } from 'react-error-boundary';

// import { oneMin } from './components/common/one-min';

// oneMin();

ReactDOM.render(
 <Router history={history}>
  <Provider store={store}>
   <ErrorBoundary FallbackComponent={ErrorModal}>
       <App />
   </ErrorBoundary>
  </Provider>
 </Router>,
 document.getElementById('root')
);
