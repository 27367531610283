import Button from '../../button';
import s from './SincetoCalendar.module.css';

const SincetoCalendar = ({ selectDate, state1, state2, handleDate, type }) => {
 return (
  <div className={s.searchSved}>
   <div className={s.calendarStatic}>
    <label htmlFor='date1' className={s.sincetoLabel}>
     Период с
    </label>
    <input type={type} name='date1' id='date1' className={s.inputDateStatic} value={state1} onChange={selectDate} />

    <label htmlFor='date2' className={s.sincetoLabel}>
     До
    </label>
    <input type={type} name='date2' id='date2' className={s.inputDateStatic} value={state2} onChange={selectDate} />
   </div>
   <div className={s.buttonsContainer}>
    <Button text='Выбрать промежуток' className={s.btnDate} onClick={handleDate} />
   </div>
  </div>
 );
};

export default SincetoCalendar;
