import React, { Component } from 'react';

import { connect } from 'react-redux';

import MaskedInput from 'react-maskedinput';

import Button from '../button';
import './style.css';
import toast from 'react-hot-toast';
import { eventCustomer } from '../../api';

class FormBookAdd extends Component {
  state = {
    FIO: '',
    phone_number: 'Номер не указан',
    address: '',
    email: '',
    iin: '',
    birthday: '',
    gender: '',
    status: '',
    nation: '',
    education: '',
    profession: '',
    work_place: '',
    students: '',
    education_place: '',
    password: '',
    invalid: 0,
    photo_url: '',
  };

  handleChange = async ({ target }) => {
    if (target.name === 'file') await this.setState({ [target.name]: target.files[0] });
    else
      await this.setState({ [target.name]: target.value === 'Выберите пункт' ? '' : target.value });
  };

  handleChangeModal = ({ target }) => {
    this.setState({
      photo_url: target.files[0],
    });
  };

  handleChangeCheck = ({ target }) => {
    this.setState({ [target.name]: target.checked ? 1 : 0 });
  };
  //
  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.state.password) {
      toast.error('Необходимо заполнить поле "Пароль".');
    } else if (this.state.phone_number.trim().length !== 18) {
      toast.error('Необходимо заполнить поле "Номер телефона".');
    } else if (!this.state.nation) {
      toast.error('Необходимо заполнить поле "Национальность".');
    } else if (!this.state.FIO) {
      toast.error('Необходимо заполнить поле "Ф.И.О.".');
    } else if (!this.state.birthday) {
      toast.error('Необходимо заполнить поле "Дата рождения".');
    } else if (!this.state.address) {
      toast.error('Необходимо заполнить поле "Домашний адрес".');
    } else if (this.state.gender === '') {
      toast.error('Необходимо заполнить поле "Выберите пол".');
    } else if (!this.state.education) {
      toast.error('Необходимо заполнить поле "Образование".');
    } else if (!this.state.status) {
      toast.error('Необходимо заполнить поле "Выберите статус".');
    } else if (!this.state.students) {
      toast.error('Необходимо заполнить поле "Учащиеся".');
    } else {
      if (this.props.type === 'add') {
        const res = await eventCustomer.eventCustomer(this.state, 'add');
        if (res) {
          this.props.type === 'add'
            ? toast.success('Читатель зарегистрирован')
            : toast.success('Данные отредактированны');
          this.props.choiceItem('readers');
          return true;
        } else {
          await toast.error('Данные некоректные');
        }
      }
      if (this.props.type === 'edit') {
        const res = await eventCustomer.eventCustomer(this.state, 'edit');

        const formData = new FormData();
        formData.append(`photo_url`, this.state.photo_url);
        formData.append(`id`, this.state.id);

        const res2 = await fetch('https://smart-pvl.kz/Api/api/customer/readerPhotoUpdate.php', {
          method: 'POST',
          headers: {
            AUTH: localStorage.getItem('tokenLibrary'),
          },
          body: formData,
        });

        const json = await res2.json();

        if (res) {
          this.props.type === 'add'
            ? toast.success('Читатель зарегистрирован')
            : toast.success('Данные отредактированны');
          this.props.choiceItem('readers');
          return true;
        } else {
          toast.error('Данные некоректные');
        }
      }
    }
  };

  componentDidMount() {
    this.setState({ ...this.props.item });
  }

  render() {
    const add = [
      { title: 'Ф.И.О.', name: 'FIO', type: 'text', placeholder: 'Обязательное поле' },
      { title: 'Домашний адрес', name: 'address', type: 'text', placeholder: 'Обязательное поле' },
      { title: 'Электронная почта', name: 'email', type: 'text', placeholder: '-' },
      { title: 'ИИН', name: 'iin', type: 'number', placeholder: '-' },
      { title: 'Пароль', name: 'password', type: 'text', placeholder: 'Обязательное поле' },
      { title: 'Дата рождения', name: 'birthday', type: 'date', placeholder: 'Обязательное поле' },
      {
        title: 'Выберите пол',
        name: 'gender',
        type: 'select',
        option: [
          { id: 0, title: 'Мужской' },
          { id: 1, title: 'Женский' },
        ],
      },
      {
        title: 'Выберите статус',
        name: 'status',
        type: 'select',
        option: this.props.StatusSelectData,
      },
      {
        title: 'Национальность',
        name: 'nation',
        type: 'select',
        option: this.props.NationalitySelectData,
      },
      {
        title: 'Образование',
        name: 'education',
        type: 'select',
        option: this.props.EducationSelectData,
      },
      {
        title: 'Место работы, адрес, телефон',
        name: 'work_place',
        type: 'text',
        placeholder: '-',
      },
      { title: 'Учебное заведение', name: 'education_place', type: 'text', placeholder: '-' },
      {
        title: 'Учащиеся',
        name: 'students',
        type: 'select',
        option: this.props.SchoolSelectData,
      },
      { title: 'Профессия', name: 'profession', type: 'text', placeholder: '-' },
      { title: 'Люди с инвалидностью', name: 'invalid', type: 'checkbox' },
    ];
    return (
      <div className='form-book-add'>
        <div className='form-add'>
          {this.props.textBtn == 'Редактировать' ? (
            <>
              <div className='reader-photo-add'>
                <p>Добавить фото</p>
                <input
                  type='file'
                  accept='image/*'
                  //  value={this.state.markFormDataDop[el.name]}
                  onChange={this.handleChangeModal}
                />
                <div className='reader-photo'>
                  <img
                    src={
                      this.state.photo != null
                        ? 'https://smart-pvl.kz/photos/' + this.state.photo
                        : 'https://smart-pvl.kz/photos/defpic.png'
                    }
                    alt='book'
                  ></img>
                  {/* <button onClick={() => this.showModal(el, 'edit')}>Редактировать</button> */}
                </div>
              </div>
            </>
          ) : null}
          <form
            style={{ display: 'block' }}
            className='form-add-wrapper'
            onSubmit={this.handleSubmit}
          >
            <div className='form-add-book'>
              <div className='search-filter-book'>
                <p>Номер телефона</p>
                <MaskedInput
                  mask='+7(111) 111 - 1111'
                  onChange={(e) => this.setState({ phone_number: e.target.value })}
                  value={this.state.phone_number}
                />
              </div>
              {add.map((el, i) => {
                switch (el.type) {
                  case 'select':
                    return (
                      <div className='search-filter-book' key={i}>
                        <p>{el.title}</p>
                        <select
                          name={el.name}
                          value={this.state[el.name]}
                          onChange={this.handleChange}
                        >
                          <option>Выберите пункт</option>
                          {el.option.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.title || el.name_ru}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  case 'checkbox':
                    return (
                      <div className='search-filter-book' key={i}>
                        <label>
                          {el.title}
                          <input
                            type={el.type}
                            name={el.name}
                            value={this.state[el.name]}
                            autoComplete='off'
                            placeholder={'Введите данные'}
                            onChange={this.handleChangeCheck}
                            checked={this.state[el.name] ? true : false}
                          />
                        </label>
                      </div>
                    );
                  case 'number':
                    return (
                      <div className='search-filter-book' key={i}>
                        <p>{el.title}</p>
                        <MaskedInput
                          mask='111111111111'
                          placeholder={el.placeholder || 'Введите данные'}
                          onChange={(e) => this.setState({ iin: e.target.value })}
                          value={this.state[el.name] ? this.state[el.name] + '' : ''}
                        />
                      </div>
                    );
                  default:
                    return (
                      <div className='search-filter-book' key={i}>
                        <p>{el.title}</p>
                        <input
                          type={el.type}
                          name={el.name}
                          value={this.state[el.name]}
                          autoComplete='off'
                          placeholder={el.placeholder || 'Введите данные'}
                          onChange={this.handleChange}
                        />
                      </div>
                    );
                }
              })}
            </div>

            <div className='form-add-img-btn'>
              <Button className='btn_add_book' text={this.props.textBtn} />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
