import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import { Link } from 'react-router-dom';

// import history from '../components/history'

import Pagination from '../../../components/pagination';
import FormBookAdd from './formBookAdd';
import Loader from '../../../components/loader';
import Button from '../../../components/button';

class BookAdd extends Component {
 state = {
  choiceItemPage: 'book',

  currentPagePag: '',

  sort: false,
  sortType: '',
  sortPag: false,

  item: null,
 };

 getData = async (e) => {
  await this.setState({ choiceItemPage: e.target.dataset.set });
  await this.props.getData('База книг', 'bookAdd');
 };

 showModal = (el) => {
  this.setState({ choiceItemPage: 'add', item: el });
 };

 scanerRequest = async (e) => {
  const res = await fetch('https://smart-pvl.kz/Api/SmartSreach.php', {
   method: 'POST',
   body: JSON.stringify({
    col: '*',
    tab: 'books_stella',
    id_name: 'token',
    id_val: e.target.value,
    shtrih: true,
    searchAll: 'All',
   }),
  });
  const json = await res.json();
  const data = json.filter((el) => typeof el === 'object');
  await this.props.setDate(data, 'red_stela', 'red_stela');
 };

 sortItemBook = async (type) => {
  await this.props.sortItem('База книг', type, this.state.sort, 'bookAdd');
  await this.setState({ sort: !this.state.sort, sortPag: true, sortType: type });
 };

 selectItem = async (tab, id) => await this.props.selectItem(tab, id);

 async componentDidMount() {
  await this.props.getData('red_stela', 'red_stela');
 }

 render() {
  console.log(this.state.item);
  const choiceItem = [
   { title: 'Книги', dataset: 'book' },
   { title: 'Редактировать', dataset: 'add' },
  ];

  const headerItemsContent = [
   { title: 'Автор', data: 'avtor' },
   { title: 'Название книги', data: 'name' },
   { title: 'Год издания', data: 'god' },
   { title: 'Издатель', data: 'izdatel' },
   { title: 'Тираж', data: 'tirazh' },
   { title: 'Место издания', data: 'place_izdn' },
   { title: 'Серия', data: 'seria' },
   { title: 'ISBN', data: 'ISBN' },
   { title: '', data: '' },
  ];
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Редактирование данных о заказе в регистрационную форму</h1>
     </div>

     <div className='smart-search'>
      <input
       type='text'
       placeholder='Введите данные или отсканируйте штрих-код для поиска'
       onChange={(e) => this.scanerRequest(e)}
      />
     </div>

     <div className='choice-type'>
      {choiceItem.map((el, i) =>
       el.title === 'Редактировать' ? (
        <h3 data-set={el.dataset} key={i} onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}>
         {el.title}
        </h3>
       ) : (
        <h3 data-set={el.dataset} key={i} onClick={this.getData}>
         {el.title}
        </h3>
       )
      )}
     </div>

     {this.state.choiceItemPage === 'add' ? (
      <FormBookAdd item={this.state.item} />
     ) : (
      <>
       {/* <FilterPanel /> */}

       <div className='book-items-content'>
        <div className='book-items-header'>
         {headerItemsContent.map((el, i) => {
          return (
           <div key={i}>
            <p>{el.title}</p>
           </div>
          );
         })}
        </div>

        {this.props.loader ? (
         <Loader />
        ) : this.props.red_stela.length === 0 ? (
         <div className='search_output_null'>
          <h1>:</h1>
          <h3>По данному поиску нет совпадений</h3>
         </div>
        ) : (
         this.props.red_stela.map((el, i) => {
          let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
          return (
           <div className={oddEven} key={el.id}>
            <div>
             <p>{el.avtor}</p>
            </div>
            <div>
             <p>{el.name}</p>
            </div>
            <div>
             <p>{el.god}</p>
            </div>
            <div>
             <p>{el.izdatel}</p>
            </div>
            <div>
             <p>{el.tirazh}</p>
            </div>
            <div>
             <p>{el.place_izdn}</p>
            </div>
            <div>
             <p>{el.seria}</p>
            </div>
            <div>
             <p>{el.ISBN}</p>
            </div>
            <div className='del-edit'>
             <Button text='Редактировать' className='btn_library_edit' onClick={() => this.showModal(el)} />
            </div>
           </div>
          );
         })
        )}
       </div>

       {/* <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} /> */}
      </>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(BookAdd);
