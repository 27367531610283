import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import { getInventByTitle } from '../../../api';
import debounce from '../../../utils/debounce';

import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

class BookAdd extends Component {
 state = {};

 handleInputTitle = async (e) => {
  if (e.target.value != '') {
   var value = '';
   e.target.value == 'Отобразить все книги' ? (value = '') : (value = e.target.value);
   this.setState({ inputType: value });

   const obj3 = {
    value: value,
   };
   const res = await getInventByTitle.getInventByTitle(obj3);
   await this.props.setDate(res, 'komplekt_invent', 'komplekt_invent');
  } else {
   await this.props.setDate([], 'komplekt_invent', 'komplekt_invent');
  }
 };

 async componentDidMount() {
  await this.props.setDate([], 'komplekt_invent', 'komplekt_invent');
 }

 render() {
  const headerItemsContent = [
   { title: 'Инвентарный номер', data: 'invN' },
   { title: 'Автор', data: 'author' },
   { title: 'Название книги', data: 'title' },
   { title: 'По отраслям знаний', data: 'by_branches_of_knowledge' },
   { title: 'Год издания', data: 'publication_year' },
   { title: 'Цена', data: 'price' },
   { title: 'Издатель', data: 'publisher' },
   { title: 'Объем', data: 'number_of_pages' },
   { title: 'Статус', data: 'book_status' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>ВЕДЕНИЕ ЭЛЕКТРОННОЙ ИНВЕНТАРНОЙ КНИГИ</h1>
     </div>
     <div className='smart-search' style={{ marginBottom: '20px' }}>
      <input type='text' placeholder='Введите заглавие' onChange={debounce(this.handleInputTitle, 900)} />
     </div>
     <div className='book-items-content'>
      <CommonWrapperTable mainArr={this.props.komplekt_invent} headersArr={headerItemsContent} />

      {/* <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} /> */}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(BookAdd);
