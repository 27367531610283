import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import '../sved_user/style.css';
import {
  getGeneralByGivenBooks,
  getGeneralByReturnBooks,
  getGeneralByGenre,
  getGeneralByPopular,
  getGeneralByOtrasli,
  getGeneralByStatuses,
  getGeneralBylangs,
} from '../../../api';
import toast from 'react-hot-toast';

class StaticRead extends Component {
  state = {
    arr_stat: [],

    open_1: false,
    open_2: false,
    open_3: false,
    open_4: false,
    open_5: false,
    open_6: false,
    open_7: false,
    date1: '',
    date2: '',
  };

  selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

  handleDate = async () => {
    if (this.state.date1 && this.state.date2) {
      toast.success('Можете выбрать тип отчета');
    } else {
      toast.error('Некорректно выбранный период');
    }
  };

  handleGiven = async () => {
    if (this.state.date1 && this.state.date2) {
      const res = await getGeneralByGivenBooks.getGeneralByGivenBooks({
        start: this.state.date1,
        end: this.state.date2,
      });
      this.setState({
        arr_stat: res,
        open_1: true,
        open_2: false,
        open_3: false,
        open_4: false,
        open_5: false,
        open_6: false,
        open_7: false,
      });
    } else {
      toast.error('Выберите период');
    }
  };

  handleReturn = async () => {
    if (this.state.date1 && this.state.date2) {
      const res = await getGeneralByReturnBooks.getGeneralByReturnBooks({
        start: this.state.date1,
        end: this.state.date2,
      });
      this.setState({
        arr_stat: res,
        open_1: false,
        open_2: true,
        open_3: false,
        open_4: false,
        open_5: false,
        open_6: false,
        open_7: false,
      });
    } else {
      toast.error('Выберите период');
    }
  };

  handleGenre = async () => {
    if (this.state.date1 && this.state.date2) {
      const res = await getGeneralByGenre.getGeneralByGenre({
        start: this.state.date1,
        end: this.state.date2,
      });
      this.setState({
        arr_stat: res,
        open_1: false,
        open_2: false,
        open_3: true,
        open_4: false,
        open_5: false,
        open_6: false,
        open_7: false,
      });
    } else {
      toast.error('Выберите период');
    }
  };

  handlePop = async () => {
    if (this.state.date1 && this.state.date2) {
      const res = await getGeneralByPopular.getGeneralByPopular({
        start: this.state.date1,
        end: this.state.date2,
      });
      this.setState({
        arr_stat: res,
        open_1: false,
        open_2: false,
        open_3: false,
        open_4: true,
        open_5: false,
        open_6: false,
        open_7: false,
      });
    } else {
      toast.error('Выберите период');
    }
  };

  handleOtrasli = async () => {
    if (this.state.date1 && this.state.date2) {
      const res = await getGeneralByOtrasli.getGeneralByOtrasli({
        start: this.state.date1,
        end: this.state.date2,
      });
      this.setState({
        arr_stat: res,
        open_1: false,
        open_2: false,
        open_3: false,
        open_4: false,
        open_5: true,
        open_6: false,
        open_7: false,
      });
    } else {
      toast.error('Выберите период');
    }
  };

  handleStatuses = async () => {
    if (this.state.date1 && this.state.date2) {
      const res = await getGeneralByStatuses.getGeneralByStatuses({
        start: this.state.date1,
        end: this.state.date2,
      });
      this.setState({
        arr_stat: res,
        open_1: false,
        open_2: false,
        open_3: false,
        open_4: false,
        open_5: false,
        open_6: true,
        open_7: false,
      });
    } else {
      toast.error('Выберите период');
    }
  };

  handleOLangs = async () => {
    if (this.state.date1 && this.state.date2) {
      const res = await getGeneralBylangs.getGeneralBylangs({
        start: this.state.date1,
        end: this.state.date2,
      });
      this.setState({
        arr_stat: res,
        open_1: false,
        open_2: false,
        open_3: false,
        open_4: false,
        open_5: false,
        open_6: false,
        open_7: true,
      });
    } else {
      toast.error('Выберите период');
    }
  };
  render() {
    const headerItemsContent_1 = [
      { title: 'Заглавие', data: 'title' },
      { title: 'Количество выданных', data: 'by_t_number' },
    ];

    const headerItemsContent_2 = [
      { title: 'Заглавие', data: 'title' },
      { title: 'Количество возвращенных', data: 'by_t_number' },
    ];

    const headerItemsContent_3 = [
      { title: 'Жанр', data: 'title' },
      { title: 'Количество', data: 'by_t_number' },
    ];

    const headerItemsContent_4 = [
      { title: 'Заглавие', data: 'title' },
      { title: 'Количество', data: 'by_t_number' },
    ];

    const headerItemsContent_5 = [
      { title: 'Отрасль', data: 'title' },
      { title: 'Количество', data: 'by_g_number' },
    ];
    const headerItemsContent_6 = [
      { title: 'Социальный статус', data: 'title' },
      { title: 'Количество', data: 'by_g_number' },
    ];
    const headerItemsContent_7 = [
      { title: 'Язык', data: 'title' },
      { title: 'Количество', data: 'by_g_number' },
    ];
    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Формирование общей статистики</h1>
          </div>
          <div>
            <SincetoCalendar
              selectDate={this.selectDate}
              state1={this.state.date1}
              state2={this.state.date2}
              handleDate={this.handleDate}
              type='date'
            />
          </div>
          <div className='searchSved'>
            <div className='buttons-container'>
              <Button
                text='Отчет по выдаче литературы'
                className='btn_library_del'
                onClick={this.handleGiven}
              />
              <Button
                text='Отчет по возвратам литературы'
                className='btn_library_del'
                onClick={this.handleReturn}
              />
              <Button
                text='Отчет по жанрам литературы'
                className='btn_library_del'
                onClick={this.handleGenre}
              />
              <Button
                text='Отчет по популярности литературы'
                className='btn_library_del'
                onClick={this.handlePop}
              />
              <Button
                text='Отчет по отраслям литературы'
                className='btn_library_del'
                onClick={this.handleOtrasli}
              />
              <Button
                text='Отчет по социальному статусу'
                className='btn_library_del'
                onClick={this.handleStatuses}
              />

              <Button
                text='Отчет по языкам литературы'
                className='btn_library_del'
                onClick={this.handleOLangs}
              />
            </div>
          </div>

          <div className='monitor' style={{ marginTop: '40px' }}>
            {this.state.open_1 && (
              <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_1} />
            )}
            {this.state.open_2 && (
              <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_2} />
            )}
            {this.state.open_3 && (
              <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_3} />
            )}
            {this.state.open_4 && (
              <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_4} />
            )}
            {this.state.open_5 && (
              <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_5} />
            )}
            {this.state.open_6 && (
              <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_6} />
            )}
            {this.state.open_7 && (
              <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_7} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(StaticRead);
