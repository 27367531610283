import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, bronPolka } from '../../../redux/action';

import { GetBroned, GetBronedRow } from '../../../api';
import ModalForExtradition from '../../../components/ModalForExtradition/ModalForExtradition';

import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import moment from 'moment';

// import './style.css';

class ZabronBook extends Component {
 state = {
  choiceItem: 'broned',
  selected_book: '',
  inputType: '',
  show: false,
 };

 choiseItem = async (item) => {
  await this.props.setDate([], 'store_bron', 'store_bron');
  this.setState({ choiceItem: item });
  this.getDataBroned();
 };

 getDataBroned = async () => {
  const type = this.state.choiceItem;
  if (type == 'broned') {
   const obj3 = {
    date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
   };
   const res = await GetBroned.GetBroned(obj3);
   const data = Object.values(res);
   await this.props.setDate(data, 'store_bron', 'store_bron');
  }
 };

 CloseModalExtradition = async () => {
  this.setState({ show: false });
 };

 ExtraditionModal = async (item) => {
  this.setState({ show: true, selected_book: item });
 };

 async componentDidMount() {
  this.getDataBroned();
 }

 render() {
  const buttons = [{ title: 'Выдать читателю', event: (e) => this.ExtraditionModal(e) }];

  const choiceItem = [{ title: 'Забронированные книги', data: 'broned' }];

  const headerItemsContent_broned = [
   { title: 'Название', data: 'title' },
   { title: 'Автор', data: 'author' },
   { title: 'Дата бронирования', data: 'date' },
   { title: 'Читатель', data: 'reader' },
   { title: 'Действие', data: 'button' },
  ];

  return (
   <div className='page-main'>
    <ModalForExtradition
     getDataBroned={this.getDataBroned}
     show={this.state.show}
     closeModalProp={this.CloseModalExtradition}
     book={this.state.selected_book}
    />
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <div>
       <div>
        <h1>Выдача забронированных книг</h1>
       </div>
       <div className='choice-type'>
        {choiceItem.map((el, i) => (
         <h3 data-set={el.data} key={i} onClick={(e) => this.choiseItem(e.target.dataset.set)}>
          {el.title}
         </h3>
        ))}
       </div>
      </div>
     </div>

     <div className='book-items-content'>
      {this.props.store_bron.length === 0 ? (
       <div className='search_output_null'>
        <h3>Раздел пуст</h3>
       </div>
      ) : this.state.choiceItem == 'broned' ? (
       <CommonWrapperTable mainArr={this.props.store_bron} headersArr={headerItemsContent_broned} buttons={buttons} />
      ) : null}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, bronPolka };

export default connect(mapStateToProps, mapDispatchToProps)(ZabronBook);
