import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../../redux/action';

import { SmartSreach } from '../../../../api';

import Button from '../../../../components/button';
import Modal from '../../../../components/modal';

import arrow_add from '../../../../assets/icon/arrow-menu.png';
import close from '../../../../assets/icon/modal-genre-close.png';

class FormBookAdd extends Component {
 state = {
  typeItem: 'article',
  selectedItem: true,
  showModal: false,

  zhanr: false,
  zharnInput: '',
  newZhanr: '',

  addbook: {
   avtor: '',
   titul: '',
   dates_life: '',
   avtor_other: '',
   name: '',
   kol_sim: '',
   num_chasti: '',
   name_chasti: '',
   sved_zaglov: '',
   sved_otvets: '',
   par_name_book: '',
   place_izdn: '',
   sved_izdn: '',
   izdatel: '',
   god: '',
   val: '',
   fiz_har: '',
   detskoe_izd: '',
   seria: '',
   podseria: '',
   prim: '',
   ISBN: '',
   price: '',
   kol: '',
   stoit: '',
   tirazh: '',
   zhanr: '',
   kraeved: '',
   kl_slova: '',
   index_bbk: '',
   index_udk: '',
   avtor_znak: '',
   invert_num: '',
   sigla: '',
   date_vvoda: '',
   osn_language: '',
   ost_language: '',
   part_post: '',
   istok_post: '',
   vid_dok: '',
   sluzh_otm: '',
   image: '',
   lib_name: '',
   file: '',
  },
 };

 showModal = () => this.setState({ showModal: true });

 closeModal = () => this.setState({ showModal: false });

 hideModal = async () => {
  await this.setState({ showModal: false });

  const res = await fetch('http://smart.doro.kz/Api/AddZhanr.php', {
   method: 'POST',
   body: JSON.stringify({ zhanr: this.state.newZhanr }),
  });
  const json = await res.json();
  const [role, text] = await json;
  await alert(text);
  await this.props.setDate([role], 'other_data');
  await this.setState({ newZhanr: '' });
  return true;
 };

 handleChange = async ({ target }) => await this.setState({ [target.name]: target.value });

 handleChangeArticle = async ({ target }) => {
  if (target.name === 'file') {
   await this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.files[0] } });
  } else {
   await this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.value } });
  }
 };

 handleSubmit = async (e) => {
  e.preventDefault();

  for (let key in this.state.addbook) {
   this.state.addbook[key] = this.state.addbook[key];
  }

  const formData = await new FormData();
  for (const key in this.state.addbook) {
   await formData.append(`${key}`, this.state.addbook[key]);
  }
  formData.append('staff', localStorage.getItem('idUser'));

  const res = await fetch('http://smart.doro.kz/Api/AddElBook.php', { method: 'POST', body: formData });
  await res.json();

  this.setState({
   addbook: Object.keys(this.state.addbook).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
  });
 };

 //живой поиск Жанра
 searchZhanr = async ({ target }) => {
  await this.setState({ zharnInput: target.value });
  if (this.state.zharnInput.length >= 3) {
   const json = await SmartSreach.SmartSreach({
    col: 'zhanr',
    tab: 'other_data',
    id_name: 'zhanr',
    id_val: target.value,
    searchAll: 'All',
    shtrih: true,
   });
   const zhanr = await json.slice(0, json.length - 2);
   await this.setState({ zhanr });
  }
  if (this.state.zharnInput.length === 0) {
   await this.setState({ zhanr: [] });
  }
 };

 addPos = async (e) => {
  this.setState({ ...this.state, addbook: { ...this.state.addbook, file: e.target.value } });
  console.log(e.target.files[0]);
  const formData = new FormData();
  formData.append('file', e.target.files[0]);
 };

 render() {
  console.log(this.state.addbook.file);
  const typeItem = [
   { title: 'Книга', data: 'book' },
   { title: 'Электронная статья', data: 'article' },
  ];

  const atrFormAddArticle = [
   { title: 'Автор', name: 'avtor' },
   { title: 'Титул 1 автора', name: 'titul' },
   { title: 'Даты жизни 1 автора', name: 'dates_life' },
   { title: 'Другой автор', name: 'avtor_other' },
   { title: 'Заглавление', name: 'name' },
   { title: 'Количество незначащих символов заглавления', name: 'kol_sim' },
   { title: 'Номер части', name: 'num_chasti' },
   { title: 'Название части', name: 'name_chasti' },
   { title: 'Сведение к заглавлению', name: 'sved_zaglov' },
   { title: 'Сведение об ответственности', name: 'sved_otvets' },
   { title: 'Параллельное название', name: 'par_name_book' },
   { title: 'Место издания', name: 'place_izdn' },
   { title: 'Сведение об издании', name: 'sved_izdn' },
   { title: 'Издательство', name: 'izdatel' },
   { title: 'Год издания', name: 'god' },
   { title: 'Объем', name: 'val' },
   { title: 'Физические характеристики, иллюстрации, переплет и т.п.', name: 'fiz_har' },
   { title: 'Детское издание до 15 лет', name: 'detskoe_izd' },
   { title: 'Серия', name: 'seria' },
   { title: 'Подсерия', name: 'podseria' },
   { title: 'Примечание', name: 'prim' },
   { title: 'ISBN', name: 'ISBN' },
   { title: 'Цена', name: 'price' },
   { title: 'Количество', name: 'kol' },
   { title: 'Стоимость', name: 'stoit' },
   { title: 'Тираж', name: 'tirazh' },
   { title: 'Тематическая рубрика', name: 'zhanr' },
   { title: 'Краеведение', name: 'kraeved' },
   { title: 'Ключевые слова', name: 'kl_slova' },
   { title: 'Индекс ББК', name: 'index_bbk' },
   { title: 'Индекс УДК', name: 'index_udk' },
   { title: 'Авторский знак', name: 'avtor_znak' },
   { title: 'Инвентарный номер', name: 'invert_num' },
   { title: 'Сигла, отдел ЦГБ', name: 'sigla' },
   { title: 'Дата ввода', name: 'date_vvoda' },
   { title: 'Основной язык ввода', name: 'osn_language', option: ['Казахский', 'Русский', 'другой'] },
   { title: 'Другие языки издания', name: 'ost_language' },
   { title: 'Партия поступления', name: 'part_post' },
   {
    title: 'Источник поступления',
    name: 'istok_post',
    option: [
     'Бюджет',
     'Госзаказ',
     'Дар читателей',
     'Дар авторов',
     'Дар юр. лиц',
     'Другие источники (замена утерянных)',
     'Другие источники (замена утраченных)',
     'Другие источники ОРФ',
     'Другие источники ВСО',
     'Другие источники (другое)',
    ],
   },
   { title: 'Вид документа', name: 'vid_dok', option: ['Книга', 'Брошюра', 'АВМ', 'CD'] },
   { title: 'Служебные отметки', name: 'sluzh_otm' },
   { title: 'Выбор изображения', name: 'image' },
  ];

  return (
   <div className='form-book-add'>
    <div className='form-add'>
     <form className='form-add-wrapper' onSubmit={this.handleSubmit}>
      {
       <div className='form-add-article'>
        {atrFormAddArticle.map((el, i) => {
         return (
          <div className='search-filter-article' key={i}>
           <p>{el.title}</p>
           <input
            type='text'
            autoComplete='off'
            name={el.name}
            placeholder='Обязательные поля ввода'
            onChange={this.handleChangeArticle}
            value={this.state.addbook[el.name]}
           />
          </div>
         );
        })}
       </div>
      }

      <div className='form-add-img-btn'>
       <div>
        <p>Загрузить файл</p>
        <input type='file' name='file' onChange={this.handleChangeArticle} />
       </div>
       <Button className='btn_add_book' text='Добавить' />
       <div className='unicode'>
        <div>
         <input type='checkbox' />
         <p>Соблюдение авторских прав на электронные материалы и документы</p>
        </div>
       </div>
      </div>
     </form>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
