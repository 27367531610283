import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../button';
import s from './HardSearch.module.css';

const HardSearch = ({ setSearchBooks }) => {
  const [temArrValue, settemArrValue] = useState(0);

  const [avtor, setavtor] = useState('');
  const [title, settitle] = useState('');
  const [part_number, setpart_number] = useState(0);
  const [part_name, setpart_name] = useState('');
  const [title_information, settitle_information] = useState('');
  const [liability_information, setliability_information] = useState('');
  const [parallel_title, setparallel_title] = useState('');
  const [place_of_publication, setplace_of_publication] = useState('');
  const [publication_information, setpublication_information] = useState('');
  const [publication_year, setpublication_year] = useState(0);
  const [number_of_pages, setnumber_of_pages] = useState(0);
  const [kid_fund, setkid_fund] = useState(0);
  const [series, setseries] = useState('');
  const [price, setprice] = useState(0);
  const [ISBN, setISBN] = useState('');
  const [by_content, setby_content] = useState(0);
  const [rare_fund, setrare_fund] = useState(0);
  const [book_circulation, setbook_circulation] = useState(0);
  const [genre_id, setgenre_id] = useState(0);
  const [classifier, setclassifier] = useState(0);
  const [binding, setbinding] = useState(2);
  const [language, setlanguage] = useState(0);
  const [type_of_document, settype_of_document] = useState(0);
  const [by_branches_of_knowledge, setby_branches_of_knowledge] = useState(0);
  const [bibliographic_level, setbibliographic_level] = useState(0);
  const [card_file, setcard_file] = useState(0);

  const PublPlaceDataSelectsData = useSelector((state) => state.PublPlaceDataSelectsData);
  const SoderjSelectData = useSelector((state) => state.SoderjSelectData);
  const GenresSelectData = useSelector((state) => state.GenresSelectData);
  const OtrasliZnaniiSelectData = useSelector((state) => state.OtrasliZnaniiSelectData);
  const LibLevelSelectsDataData = useSelector((state) => state.LibLevelSelectsDataData);
  const StaffingSelectsDataData = useSelector((state) => state.StaffingSelectsDataData);
  const LanguageSelectData = useSelector((state) => state.LanguageSelectData);
  const VidDokSelectData = useSelector((state) => state.VidDokSelectData);

  const UDKSelectsByGeneral = useSelector((state) => state.UDKSelectsByGeneral);
  const UDKSelectsByKazakstanika = useSelector((state) => state.UDKSelectsByKazakstanika);
  const UDKSelectsByKraeved = useSelector((state) => state.UDKSelectsByKraeved);
  const UDKSelectsByBBK = useSelector((state) => state.UDKSelectsByBBK);

  const temArr = [
    {
      value: {
        ru: 'Общий классификатор',
        kz: 'Жалпы жіктеуіш',
      },
      arr: UDKSelectsByGeneral,
    },
    {
      value: {
        ru: 'Классификатор «Казахстаника»',
        kz: '«Қазақстан» жіктеуіші',
      },
      arr: UDKSelectsByKazakstanika,
    },
    {
      value: {
        ru: 'Краеведческий классификатор',
        kz: 'Өлкетану жіктеуіші',
      },
      arr: UDKSelectsByKraeved,
    },
    {
      value: {
        ru: 'ББК',
        kz: 'ББК',
      },
      arr: UDKSelectsByBBK,
    },
  ];

  const search = async () => {
    const data = {};

    avtor ? (data.avtor = avtor) : console.info();
    title ? (data.title = title) : console.info();
    part_number ? (data.part_number = part_number.toString()) : console.info();
    part_name ? (data.part_name = part_name) : console.info();
    title_information ? (data.title_information = title_information) : console.info();
    liability_information ? (data.liability_information = liability_information) : console.info();
    parallel_title ? (data.parallel_title = parallel_title) : console.info();
    place_of_publication
      ? (data.place_of_publication = place_of_publication.toString())
      : console.info();
    publication_information
      ? (data.publication_information = publication_information)
      : console.info();
    publication_year ? (data.publication_year = publication_year.toString()) : console.info();
    number_of_pages ? (data.number_of_pages = number_of_pages.toString()) : console.info();
    kid_fund ? (data.kid_fund = kid_fund.toString()) : console.info();
    series ? (data.series = series) : console.info();
    ISBN ? (data.ISBN = ISBN) : console.info();
    by_content ? (data.by_content = by_content.toString()) : console.info();
    rare_fund ? (data.rare_fund = rare_fund.toString()) : console.info();
    book_circulation ? (data.book_circulation = book_circulation.toString()) : console.info();
    genre_id ? (data.genre_id = genre_id.toString()) : console.info();
    classifier ? (data.classifier = classifier.toString()) : console.info();
    binding !== 2 ? (data.binding = binding.toString()) : console.info();
    language ? (data.language = language.toString()) : console.info();
    type_of_document ? (data.type_of_document = type_of_document.toString()) : console.info();
    by_branches_of_knowledge
      ? (data.by_branches_of_knowledge = by_branches_of_knowledge.toString())
      : console.info();
    bibliographic_level
      ? (data.bibliographic_level = bibliographic_level.toString())
      : console.info();
    card_file ? (data.card_file = card_file.toString()) : console.info();

    console.log(data);

    const res = await fetch(
      `https://smart-pvl.kz/Api/api/bibliographic_descriptions/full_search.php`,
      {
        method: 'POST',
        headers: {
          AUTH: localStorage.getItem('tokenLibrary'),
        },
        body: JSON.stringify(data),
      },
    );

    const jsonres = await res.json();
    console.log(jsonres);
    setSearchBooks(jsonres);
  };

  const handleChangeSelect = ({ target }) => {
    console.log(target);
    settemArrValue(target.value);
  };

  const handleChangeModal = ({ target }) => {
    console.log(target);
    setclassifier(target.value);
  };
  return (
    <>
      <div className={s.searchWrapper}>
        <div className={s.leftBlock}>
          <div className='filter-serach'>
            <div className='smart-search'>
              {/* <input
        type='text'
        placeholder='Автор'
        onChange={(e) => setavtor(e.target.value)}
        //  value={this.state.tempSelectAvtor}
       />
       <div style={{ marginTop: '15px' }}></div> */}
              <input
                type='text'
                placeholder='Заглавие'
                onChange={(e) => settitle(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div className={s.number}>
                <input
                  type='number'
                  placeholder='Номер части'
                  onChange={(e) => setpart_number(e.target.value)}

                  //  value={this.state.tempSelectAvtor}
                />
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <input
                type='text'
                placeholder='Название части'
                onChange={(e) => setpart_name(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>
              <input
                type='text'
                placeholder='Сведения, относящиеся к заглавию'
                onChange={(e) => settitle_information(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>
              <input
                type='text'
                placeholder='Сведения об ответственности'
                onChange={(e) => setliability_information(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>
              <input
                type='text'
                placeholder='Параллельное название'
                onChange={(e) => setparallel_title(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>

              <input
                type='text'
                placeholder='Сведения об издании'
                onChange={(e) => setpublication_information(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div className={s.numberWrapper}>
                <div className={s.number}>
                  <input
                    type='number'
                    placeholder='Год издания (г.)'
                    onChange={(e) => setpublication_year(e.target.value)}

                    //  value={this.state.tempSelectAvtor}
                  />
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className={s.number}>
                  <input
                    type='number'
                    placeholder='Объем (стр.)'
                    onChange={(e) => setnumber_of_pages(e.target.value)}

                    //  value={this.state.tempSelectAvtor}
                  />
                </div>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <input
                type='text'
                placeholder='Серия'
                onChange={(e) => setseries(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>
              <input
                type='text'
                placeholder='ISBN'
                onChange={(e) => setISBN(e.target.value)}

                //  value={this.state.tempSelectAvtor}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div className={s.number}>
                <input
                  type='number'
                  placeholder='Цена (тнг.)'
                  onChange={(e) => setprice(e.target.value)}

                  //  value={this.state.tempSelectAvtor}
                />
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className={s.number}>
                <input
                  type='number'
                  placeholder='Тираж (шт.)'
                  onChange={(e) => setbook_circulation(e.target.value)}

                  //  value={this.state.tempSelectAvtor}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={s.rightBlock}>
          <div className='filter-serach'>
            <div className='smart-search'>
              <div className={s.selectOptions}>
                <select
                  name='Место издания'
                  onChange={(e) => setplace_of_publication(e.target.value)}
                  //   key={this.state.addbook.osn_language_lat}
                  //   value={this.state.addbook[el.name]}
                >
                  <option hidden>Место издания</option>
                  {PublPlaceDataSelectsData.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name_ru}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className={s.selectOptions}>
                <select
                  name='По содержанию'
                  onChange={(e) => setby_content(e.target.value)}
                  //   key={this.state.addbook.osn_language_lat}
                  //   value={this.state.addbook[el.name]}
                >
                  <option hidden>По содержанию</option>
                  {SoderjSelectData.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name_ru}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className={s.selectOptionsWrapperBack}>
                <div className={s.selectOptionsRadioWrapper}>
                  <label style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    <div
                      className={s.selectOptionsRadio}
                      style={{ background: binding == 1 ? '#125ad140' : '#a5c3f540' }}
                    >
                      Твердый переплет
                      <input
                        type='radio'
                        //    name={el.name}
                        //    id='binding'
                        onClick={(e) => setbinding(1)}
                        checked={binding == 1 ? true : false}
                        style={{ marginLeft: '10px' }}
                      />
                    </div>
                  </label>
                  <label style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '10px' }}>
                    <div
                      className={s.selectOptionsRadio}
                      style={{ background: binding == 0 ? '#125ad140' : '#a5c3f540' }}
                    >
                      Мягкий переплет
                      <input
                        type='radio'
                        //    name={el.name}
                        //    id='binding'
                        onClick={(e) => setbinding(0)}
                        checked={binding == 0 ? true : false}
                        style={{ marginLeft: '10px' }}
                      />
                      {console.log(binding)}
                    </div>
                  </label>
                </div>
              </div>
              <div className={s.selectOptionsRadioWrapperSecond}>
                <label style={{ fontSize: '18px', fontWeight: 'bold' }}>
                  <div className={s.selectOptionsRadioSecond}>
                    Детская литература
                    <input
                      type='radio'
                      //    name={el.name}
                      //    id='binding'
                      onChange={(e) => setkid_fund(kid_fund == 1 ? 0 : 1)}
                      checked={kid_fund}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </label>
                <label style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '10px' }}>
                  <div className={s.selectOptionsRadioSecond}>
                    Редкий фонд
                    <input
                      type='radio'
                      //    name={el.name}
                      //    id='binding'
                      onChange={(e) => setrare_fund(rare_fund == 1 ? 0 : 1)}
                      checked={rare_fund}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </label>
                <div style={{ marginTop: '15px' }}></div>

                <div className={s.selectOptionsWrapperBack}>
                  <div className={s.selectOptions}>
                    <select onChange={handleChangeSelect}>
                      <option hidden>Выберите классификатор</option>
                      <option disabled style={{ color: 'blue', fontWeight: 'bold' }}>
                        Выберите классификатор
                      </option>
                      {temArr.map((item, i) => {
                        return (
                          <option key={i} value={i}>
                            {item.value['ru']}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className={s.selectOptions}>
                    <select onChange={handleChangeModal}>
                      <option hidden>Выберите рубрику</option>
                      {/* <option disabled style={{ color: 'blue', fontWeight: 'bold' }}>
                {temArr[temArrValue].value[lang]}
               </option> */}
                      {temArr[temArrValue]?.arr.map((item, i) => {
                        return (
                          <option key={i} data-udk={item.index_udk_bbk} value={item.id}>
                            {item.name_ru}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className={s.selectOptions}>
                  <select
                    name='Жанры'
                    onChange={(e) => setgenre_id(e.target.value)}
                    //   key={this.state.addbook.osn_language_lat}
                    //   value={this.state.addbook[el.name]}
                  >
                    <option hidden>Жанры</option>
                    {GenresSelectData.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name_ru}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className={s.selectOptions}>
                  <select
                    name='По общим отраслям'
                    onChange={(e) => setby_branches_of_knowledge(e.target.value)}
                    //   key={this.state.addbook.osn_language_lat}
                    //   value={this.state.addbook[el.name]}
                  >
                    <option hidden>По общим отраслям</option>
                    {OtrasliZnaniiSelectData.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name_ru}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className={s.selectOptions}>
                  <select
                    name='Библиографический уровень'
                    onChange={(e) => setbibliographic_level(e.target.value)}
                    //   key={this.state.addbook.osn_language_lat}
                    //   value={this.state.addbook[el.name]}
                  >
                    <option hidden>Библиографический уровень</option>
                    {LibLevelSelectsDataData.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name_ru}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className={s.selectOptions}>
                  <select
                    name='Картотека'
                    onChange={(e) => setcard_file(e.target.value)}
                    //   key={this.state.addbook.osn_language_lat}
                    //   value={this.state.addbook[el.name]}
                  >
                    <option hidden>Картотека</option>
                    {StaffingSelectsDataData.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name_ru}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className={s.selectOptions}>
                  <select
                    name='Язык издания'
                    onChange={(e) => setlanguage(e.target.value)}
                    //   key={this.state.addbook.osn_language_lat}
                    //   value={this.state.addbook[el.name]}
                  >
                    <option hidden>Язык издания</option>
                    {LanguageSelectData.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className={s.selectOptions}>
                  <select
                    name='Вид документа'
                    onChange={(e) => settype_of_document(e.target.value)}
                    //   key={this.state.addbook.osn_language_lat}
                    //   value={this.state.addbook[el.name]}
                  >
                    <option hidden>Вид документа</option>
                    {VidDokSelectData.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name_ru}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ marginTop: '15px' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.searchButton} onClick={search}>
        <h3>Найти</h3>
      </div>
    </>
  );
};

export default HardSearch;
