import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, bronPolka } from '../../../redux/action';
import Modal from '../../../components/modal';
import { getRegRules, GetBronedRow } from '../../../api';
import Button from '../../../components/button';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import toast from 'react-hot-toast';

class RegRules extends Component {
 state = {
  choiceItem: 'broned',
  showModal6: false,
  rules: {
   title: '',
   description: '',
   note: '',
  },
 };

 getDataRules = async () => {
  const type = this.state.choiceItem;
  if (type == 'broned') {
   const res = await getRegRules.getRegRules();
   //    const data = Object.values(res);
   await this.props.setDate(res, 'reg_rules', 'reg_rules');
  }
 };

 deleteRule = async (item) => {
  const SendObj = {
   id: item.id,
  };

  const res = await fetch('https://smart-pvl.kz/Api/api/reg_rules/delete.php', {
   method: 'POST',
   headers: {
    AUTH: localStorage.getItem('tokenLibrary'),
   },
   body: JSON.stringify(SendObj),
  });
  const json = await res.json();
  if (json) {
   toast.success('Правило успешно удалено!');
   this.getDataRules();
  } else {
   toast.error('Возникли неполадки');
  }
 };

 closeModal6 = () => {
  this.setState({ showModal6: false });
 };

 closeModal2 = () => {
  // this.setState({ showModal6: false });
 };

 RuleAddSend = async () => {
  if (this.state.rules.title) {
   if (this.state.rules.description) {
    if (this.state.rules.note) {
     const SendObj = {
      title: this.state.rules.title,
      description: this.state.rules.description,
      note: this.state.rules.note,
     };
     const res = await fetch('https://smart-pvl.kz/Api/api/reg_rules/add.php', {
      method: 'POST',
      headers: {
       AUTH: localStorage.getItem('tokenLibrary'),
      },
      body: JSON.stringify(SendObj),
     });
     const json = await res.json();
     console.log(json);
     if (json) {
      this.setState({ showModal6: false, rules: { ...this.state.rules, title: '', description: '', note: '' } });
      toast.success('Правило успешно сформировано!');
      this.getDataRules();
     } else {
      toast.error('Возникли неполадки');
     }
    } else {
     toast.error('Заполните все поля');
    }
   } else {
    toast.error('Заполните все поля');
   }
  } else {
   toast.error('Заполните все поля');
  }
 };

 async componentDidMount() {
  this.getDataRules();
 }

 render() {
  const buttons = [{ title: 'Удалить', event: (e) => this.deleteRule(e) }];

  const headerItemsContent_broned = [
   { title: 'Правило', data: 'title' },
   { title: 'Содержание', data: 'description' },
   { title: 'Примечание', data: 'note' },
   { title: '', data: 'button' },
  ];

  return (
   <div className='page-main'>
    <Modal show={this.state.showModal6} closeModalEvent={(e) => this.closeModal2()}>
     <div className='st12'>
      Окно формирования правила
      <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
       <div className='search-filter-book__book'>
        <input
         onChange={(e) => this.setState({ ...this.state, rules: { ...this.state.rules, title: e.target.value } })}
         placeholder='Правило'
        />
       </div>
       <div className='search-filter-book__book'>
        <input
         onChange={(e) => this.setState({ ...this.state, rules: { ...this.state.rules, description: e.target.value } })}
         placeholder='Содержание'
        />
       </div>
       <div className='search-filter-book__book'>
        <input
         onChange={(e) => this.setState({ ...this.state, rules: { ...this.state.rules, note: e.target.value } })}
         placeholder='Примечание'
        />
       </div>
      </div>
      <div style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}>
       <Button className='st3' text='Добавить' onClick={() => this.RuleAddSend()} />
       <Button className='st3' text='Закрыть' onClick={() => this.closeModal6()} />
      </div>
     </div>
    </Modal>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <div>
       <div>
        <h1>Правила автоматизированного обслуживания пользователей</h1>
       </div>
       <div className='choice-type'>
        <h3 onClick={(e) => this.setState({ showModal6: true })}>Сформировать правило</h3>
       </div>
      </div>
     </div>

     <div className='book-items-content'>
      <CommonWrapperTable mainArr={this.props.reg_rules} headersArr={headerItemsContent_broned} buttons={buttons} />
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, bronPolka };

export default connect(mapStateToProps, mapDispatchToProps)(RegRules);
