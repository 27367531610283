import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import toast from 'react-hot-toast';
import { getSvodStatGenre } from '../../../api';
import Button from '../../../components/button';

import '../otchet_set/style.css';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CSVDownload } from 'react-csv';

class OtchetSet9 extends Component {
  state = {
    arr_stat: [],

    open_1: false,
    open_2: false,
    date1: '',
    date2: '',

    csv: [],
  };

  handleDate = async () => {
    try {
      const res = await getSvodStatGenre.getSvodStatGenre({});
      console.log(res);
      const data = Object.values(res);
      this.setState({ arr_stat: data, open_1: true });
    } catch {
      console.log('err');
    }
  };

  handleTXT = () => {
    var text = '';
    this.state.arr_stat.map(
      (data) =>
        (text =
          text +
          data.lib +
          ':' +
          '\r\n' +
          'Проза: ' +
          data.g1 +
          '\r\n' +
          'Поэзия: ' +
          data.g2 +
          '\r\n' +
          'Мемуары: ' +
          data.g3 +
          '\r\n' +
          'Очерки: ' +
          data.g4 +
          '\r\n' +
          'Детектив: ' +
          data.g5 +
          '\r\n' +
          'Фантастика: ' +
          data.g6 +
          '\r\n' +
          'Ужасы: ' +
          data.g7 +
          '\r\n' +
          'Приключения: ' +
          data.g8 +
          '\r\n' +
          'Женский роман: ' +
          data.g9 +
          '\r\n' +
          'Комикс: ' +
          data.g10 +
          '\r\n' +
          'Пьеса: ' +
          data.g11 +
          '\r\n' +
          'Эссе: ' +
          data.g12 +
          '\r\n' +
          'Фольклор: ' +
          data.g13 +
          '\r\n' +
          'Другое: ' +
          data.g14 +
          '\r\n'),
    );

    console.log(text);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'Отчет по жанрам литературы');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handlePDF = () => {
    try {
      const input = document.getElementById('divDownload');
      html2canvas(input).then((canvas) => {
        const ImageData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(ImageData, 'JPEG', 0, 0);
        pdf.save('Отчет по жанрам литературы.pdf');
      });
    } catch {
      console.log('err');
    }
  };

  handleCSV = () => {
    try {
      var majorArr = [];
      var headerArr = [];

      headerArr.push('Библиотека');
      headerArr.push('Проза');
      headerArr.push('Поэзия');
      headerArr.push('Мемуары');
      headerArr.push('Очерки');
      headerArr.push('Детектив');
      headerArr.push('Фантастика');
      headerArr.push('Ужасы');
      headerArr.push('Приключения');
      headerArr.push('Женский роман');
      headerArr.push('Комикс');
      headerArr.push('Пьеса');
      headerArr.push('Эссе');
      headerArr.push('Фольклор');
      headerArr.push('Другое');

      majorArr.push(headerArr);

      this.state.arr_stat.map((data) => {
        var minorArr = [];
        minorArr.push(data.lib);
        minorArr.push(data.g1);
        minorArr.push(data.g2);
        minorArr.push(data.g3);
        minorArr.push(data.g4);
        minorArr.push(data.g5);
        minorArr.push(data.g6);
        minorArr.push(data.g7);
        minorArr.push(data.g8);
        minorArr.push(data.g9);
        minorArr.push(data.g10);
        minorArr.push(data.g11);
        minorArr.push(data.g12);
        minorArr.push(data.g13);
        minorArr.push(data.g14);

        majorArr.push(minorArr);
      });

      console.log(majorArr);
      this.setState({ csv: majorArr, open_2: true });
    } catch {
      console.log('err');
    }
  };

  render() {
    const headerItemsContent_1 = [
      { title: 'Библиотека', data: 'lib' },
      { title: 'П\nр\nо\nз\nа', data: 'g1' },
      { title: 'П\nо\nэ\nз\nи\nя', data: 'g2' },
      { title: 'М\nе\nм\nу\nа\nр\nы', data: 'g3' },
      { title: 'О\nч\nе\nр\nк\nи', data: 'g4' },
      { title: 'Д\nе\nт\nе\nк\nт\nи\nв', data: 'g5' },
      { title: 'Ф\nа\nн\nт\nа\nс\nт\nи\nк\nа', data: 'g6' },
      { title: 'У\nж\nа\nс\nы', data: 'g7' },
      { title: 'П\nр\nи\nк\nл\nю\nч\nе\nн\nи\nя', data: 'g8' },
      { title: 'Ж\nе\nн\nс\nк\nи\nй р\nо\nм\nа\nн', data: 'g9' },
      { title: 'К\nо\nм\nи\nк\nс', data: 'g10' },
      { title: 'П\nь\nе\nс\nа', data: 'g11' },
      { title: 'Э\nс\nс\nе', data: 'g12' },
      { title: 'Ф\nо\nл\nь\nк\nл\nо\nр', data: 'g13' },
      { title: 'Д\nр\nу\nг\nо\nе', data: 'g14' },
    ];

    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Формирование сводной отчетности по жанрам литературы</h1>
          </div>
          <div>
            {!this.state.open_1 && (
              <Button
                text='Сформировать отчет'
                className='btn_library_del'
                onClick={this.handleDate}
              />
            )}

            {this.state.open_1 && (
              <div className='searchSved'>
                <div className='buttons-container'>
                  <Button text='TXT' className='btn_library_del' onClick={this.handleTXT} />
                  <Button text='PDF' className='btn_library_del' onClick={this.handlePDF} />
                  <Button text='CSV' className='btn_library_del' onClick={this.handleCSV} />
                  {this.state.open_2 && <CSVDownload data={this.state.csv} target='_blank' />}
                </div>
              </div>
            )}

            {this.state.open_1 && (
              <div id='divDownload' style={{ width: '210mm', padding: '20px', margin: 'auto' }}>
                <CommonWrapperTable
                  mainArr={this.state.arr_stat}
                  headersArr={headerItemsContent_1}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(OtchetSet9);
