import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import StatRead from './stat_read'
import StatTotal from './stat_total';

import './style.css'

class Analit extends Component {

    state = {
        choiceItemPage: 'stat_read'
    }

    render() {

        const choiceItem = [{ title: 'Статистика читателей', dataset: 'stat_read' },
        { title: 'Общая статистика', dataset: 'stat_total' },
        { title: 'Отчетность', dataset: 'stat_otchet' },
        { title: 'Сотрудники', dataset: 'stat_staff' }
        ];

        const analitTable = [{ component: <StatRead />, dataset: 'stat_read' },
        { component: <StatTotal />, dataset: 'stat_total' },
        { component: [], dataset: 'stat_otchet' },
        { component: [], dataset: 'stat_staff' }
        ];
        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>Аналитика</h1>
                    </div>

                    <div className='choice-type'>
                        {choiceItem.map((el, i) => <h3 data-set={el.dataset}
                            key={i} onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}>{el.title}</h3>)}
                    </div>

                    <div className='block-chart'>
                        {analitTable.filter(el => el.dataset === this.state.choiceItemPage).map(el => el.component)}
                    </div>

                </div>

            </div >
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(Analit);