import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../redux/action';

import { SmartSreach, AddOther } from '../../api';

import Button from '../button';
import Modal from '../modal';

import arrow_add from '../../assets/icon/arrow-menu.png';
import close from '../../assets/icon/modal-genre-close.png';

import './style.css';

class FormBookAdd extends Component {
 state = {
  typeItem: 'book',
  selectedItem: true,

  showModal: false,
  modalTitle: '',
  modalItemName: '',
  newItem: '',
  index_bbk: '',
  index_udk: '',

  addItems: {
   zhanr: '',
   zhanrItems: [],

   osn_language: '',
   osn_languageItems: [],

   istok_post: '',
   istok_postItems: [],

   vid_dok: '',
   vid_dokItems: [],

   otrasli: '',
   otrasliItems: [],
  },

  addbook: {
   avtor: '',
   dates_life: '',
   avtor_other: '',
   avtor_other_dates_life: '',
   kollekt_avtor: '',
   place_avtor: '',
   pod_zveno: '',
   time_kollekt_avtor: '',
   plice_time_avtor: '',
   pod_zveno_time_avtor: '',
   name: '',
   kol_sim: '',
   num_chasti: '',
   name_chasti: '',
   sved_zaglov: '',
   sved_otvets: '',
   par_name_book: '',
   zagl_istoch: '',
   sved_istoch: '',
   sved_otvets_istoch: '',
   avtor_istoch: '',
   sved_izdan_istoch: '',
   vyhod_istoch: '',
   smesh_info: '',
   ISBN: '',
   prim: '',
   type_prim: '',
   ref: '',
   link_dop_card: '',
   zhanr: '',
   istoch_katalog: '',
   ob_podraz_rubrik: '',
   istok_rubrik: '',
   nez_symbol_theme: '',
   key_word: '',
   nez_symbol_key_word: '',
   index_udk: '',
   index_bbk_nauch: '',
   index_bbk_mass: '',
   avtor_znak: '',
   rasstanovka: '',
   dop_link: '',
   type_link: '',
   lang_main_text: '',
   other_lang_izdan: '',
   sluzh_otm: '',
   text: '',
   prim_text: '',
   sigla: '',
   kol_ikz: '',
  },
 };

 showModal = (el) => this.setState({ showModal: true, modalItemName: el.name, modalTitle: el.title });

 closeModal = () => this.setState({ showModal: false });

 handleChange = ({ target }) => {
  this.setState({ [target.name]: target.value });
 };

 handleChangeModal = ({ target }) => {
  if (target.name === 'detskoe_izd')
   this.setState({
    ...this.state,
    addbook: { ...this.state.addbook, [target.name]: !this.state.addbook[target.name] },
   });
  else this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.value } });
 };

 handleSubmit = async (type) => {
  if (this.state.addbook.avtor.trim().length && this.state.addbook.name.trim().length) {
   for (let key in this.state.addbook) {
    this.state.addbook[key] = this.state.addbook[key];
   }

   const formData = await new FormData();
   for (const key in this.state.addbook) {
    await formData.append(`${key}`, this.state.addbook[key]);
   }
   await formData.append('stoit', this.state.addbook.price * this.state.addbook.kol);
   await formData.append('staff', localStorage.getItem('idUser'));

   const res = await fetch('https://smart-pvl.kz/Api/AddElStat.php', {
    method: 'POST',
    headers: {
     AUTH: localStorage.getItem('tokenLibrary'),
    },
    body: formData,
   });
   await res.json();

   this.setState({
    addbook: Object.keys(this.state.addbook).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
    addItems: { zhanr: '', osn_language: '', istok_post: '', vid_dok: '', otrasli: '', zhanrItems: [] },
   });
  } else {
   alert('Заполните поля!');
  }
 };

 closeModalEvent = (e) => {
  if (e === 'modal') this.closeModal();
 };

 render() {
  const atrFormAddBook = [
   { title: 'Автор', name: 'avtor', type: 'text' },
   { title: 'Даты жизни 1 автора', name: 'dates_life', type: 'text' },
   { title: 'Другой автор', name: 'avtor_other', type: 'text' },
   { title: 'Даты жизни др. автора', name: 'avtor_other_dates_life', type: 'text' },
   { title: 'Постоянный коллективный автор', name: 'kollekt_avtor', type: 'text' },
   { title: 'Место постоянного автора', name: 'place_avtor', type: 'text' },
   { title: 'Подчиненное звено', name: 'pod_zveno', type: 'text' },
   { title: 'Временный коллективный автор', name: 'time_kollekt_avtor', type: 'text' },
   { title: 'Место временного автора', name: 'plice_time_avtor', type: 'text' },
   { title: 'Подчиненное звено временного автора', name: 'pod_zveno_time_avtor', type: 'text' },
   { title: 'Заглавие', name: 'name', type: 'text' },
   { title: 'Количество незначащих символов', name: 'kol_sim', type: 'text' },
   { title: 'Номер части', name: 'num_chasti', type: 'text' },
   { title: 'Название части', name: 'name_chasti', type: 'text' },
   { title: 'Сведение, относящиеся к заглавию', name: 'sved_zaglov', type: 'text' },
   { title: 'Сведение об ответственности', name: 'sved_otvets', type: 'text' },
   { title: 'Параллельное название', name: 'par_name_book', type: 'text' },
   { title: 'Заглавие источника', name: 'zagl_istoch', type: 'text' },
   { title: 'Сведения, относящиеся к источнику', name: 'sved_istoch', type: 'text' },
   { title: 'Сведения об ответственности источника', name: 'sved_otvets_istoch', type: 'text' },
   { title: 'Авторы источника', name: 'avtor_istoch', type: 'text' },
   { title: 'Сведения об издании источника', name: 'sved_izdan_istoch', type: 'text' },
   { title: 'Выходные данные источника', name: 'vyhod_istoch', type: 'text' },
   { title: 'Смешанная информация', name: 'smesh_info', type: 'text' },
   { title: 'ISBN источника', name: 'ISBN', type: 'text' },
   { title: 'Примечание', name: 'prim', type: 'text' },
   { title: 'Тип примечания', name: 'type_prim', type: 'text' },
   { title: 'Реферат', name: 'ref', type: 'text' },
   { title: 'Ссылка на добавочную карточку', name: 'link_dop_card', type: 'text' },
   { title: 'Тематическая рубрика', name: 'zhanr', type: 'text' },
   { title: 'Источник каталогизатора', name: 'istoch_katalog', type: 'text' },
   { title: 'Общее подразделение рубрики', name: 'ob_podraz_rubrik', type: 'text' },
   { title: 'Источник рубрики', name: 'istok_rubrik', type: 'text' },
   { title: 'Незначащие символы тем', name: 'nez_symbol_theme', type: 'text' },
   { title: 'Ключенвые слова', name: 'key_word', type: 'text' },
   { title: 'Незначащие символы ключевых слов', name: 'nez_symbol_key_word', type: 'text' },
   { title: 'Индекс УДК', name: 'index_udk', type: 'text' },
   { title: 'Индекс ББК научных библиотек', name: 'index_bbk_nauch', type: 'text' },
   { title: 'Индекс ББК массовых библиотек', name: 'index_bbk_mass', type: 'text' },
   { title: 'Авторский знак', name: 'avtor_znak', type: 'text' },
   { title: 'Расстановка', name: 'rasstanovka', type: 'text' },
   { title: 'Дополнительные ссылки', name: 'dop_link', type: 'text' },
   { title: 'Тип ссылки', name: 'type_link', type: 'text' },
   { title: 'Язык основного текста', name: 'lang_main_text', type: 'text' },
   { title: 'Другой язык издания', name: 'other_lang_izdan', type: 'text' },
   { title: 'Служебные отметки', name: 'sluzh_otm', type: 'text' },
   { title: 'Текст', name: 'text', type: 'text' },
   { title: 'Примечание к тексту', name: 'prim_text', type: 'text' },
   { title: 'Сигла', name: 'sigla', type: 'text' },
   { title: 'Количество экземпляров', name: 'kol_ikz', type: 'text' },
  ];
  return (
   <div className='form-book-add'>
    <Modal
     show={this.state.showModal}
     handleClose={this.addNewItemModal}
     btn={'Добавить'}
     classBtn='btn_add_genre'
     closeModalEvent={this.closeModalEvent}
    >
     <div className='modal-content1'>
      <div className='modal-title-icon'>
       <h1>Добавить: {this.state.modalTitle}</h1>
       <img className='modal-close-icon' onClick={this.closeModal} alt='close' src={close} />
      </div>
      <form onSubmit={(e) => e.preventDefault()} className='product-modal-inner addItem-form'>
       <input
        type='text'
        autoComplete='off'
        name='newItem'
        placeholder='Обязательные поля ввода'
        onChange={this.handleChange}
        value={this.state.newItem}
       />
       {this.state.modalItemName === 'zhanr'
        ? [
           { title: 'Индекс ББК', name: 'index_bbk', type: 'text' },
           { title: 'Индекс УДК', name: 'index_udk', type: 'text' },
          ].map((el, i) => {
           return (
            <input
             type='text'
             name={el.name}
             autoComplete='off'
             value={this.state.addItems[el.name]}
             placeholder={el.title}
             onChange={this.handleChange}
            />
           );
          })
        : null}
      </form>
     </div>
    </Modal>

    <div div className='form-add'>
     <form className='form-add-wrapper' onSubmit={(e) => e.preventDefault()}>
      <div className='form-add-book__book'>
       {atrFormAddBook.map((el, i) => {
        switch (el.type) {
         case 'select': {
          return (
           <div className='search-filter-book_book' key={i}>
            <p>{el.title}</p>
            <div className='search-filter-book-rubrik'>
             <select name={el.name} onChange={this.handleChangeModal}>
              <option hidden>Выберите данные</option>
              {el.option.map((item, i) => {
               // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
               return <option key={i}>{item}</option>;
              })}
             </select>
             <span className='plus' onClick={() => this.modalAddItem(el)}>
              +
             </span>
            </div>
           </div>
          );
         }
         case 'zhanr': {
          return (
           <div className='search-filter-book__book' key={i}>
            <p>{el.title}</p>
            <div className='rubrika'>
             <input
              type='text'
              name={el.name}
              className='input-rubrika'
              autoComplete='off'
              value={this.state.addItems[el.name]}
              placeholder='Обязательные поля ввода'
              onChange={(e) => this.searchItem(e, el.plus)}
             />
             <div className='searchZhanr'>
              {this.state.addItems[el.name + 'Items']?.slice(0, 5).map((el, i) => {
               return (
                <div>
                 <p key={i} onClick={() => this.selectedItem(el)}>
                  {el.сolumn}
                 </p>
                </div>
               );
              })}
              {/* {this.state.zharnInput.length >= 3 && this.state.zhanr.length > 5 ? <p>.....</p> : null} */}
             </div>
             {el.plus ? (
              <p title={`Добавить ${el.title}`} data-type='book' onClick={() => this.showModal(el)}>
               +
              </p>
             ) : null}
            </div>
           </div>
          );
         }
         case 'other': {
          return (
           <div className='search-filter-book__book' key={i}>
            <p>{el.title}</p>
            <p value={this.state.addbook[el.name]}>{el.name}</p>
           </div>
          );
         }
         case 'checkbox': {
          return (
           <div className='search-filter-book__book' key={i}>
            <p>{el.title}</p>
            <input
             type={el.type}
             name={el.name}
             checked={this.state.addbook[el.name]}
             onChange={this.handleChangeModal}
            />
           </div>
          );
         }
         default: {
          return (
           <div className='search-filter-book__book' key={i}>
            <p>{el.title}</p>
            <input
             type='text'
             name={el.name}
             autoComplete='off'
             value={this.state.addbook[el.name]}
             placeholder='Обязательные поля ввода'
             onChange={this.handleChangeModal}
            />
           </div>
          );
         }
        }
       })}
      </div>

      <div className='form-add-img-btn'>
       <Button className='btn_add_book' text='Добавить' onClick={this.handleSubmit} />
      </div>
     </form>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
