export default function chooseSpravList(value) {
 const staff = [
  { name: 'Национальность', id: 1, url: 'api/nation', list: 'staff' },
  { name: 'Образование', id: 2, url: 'api/employees_education', list: 'staff' },
  { name: 'Должность', id: 3, url: 'api/positions', list: 'staff' },
  { name: 'Семейное положение', id: 4, url: 'api/marital_status', list: 'staff' },
 ];

 const reader = [
  { name: 'Национальность', id: 1, url: 'api/nation', list: 'reader' },
  { name: 'Статус', id: 2, url: 'api/statuses', list: 'reader' },
  { name: 'Учащиеся', id: 3, url: 'api/customer_education_list', list: 'reader' },
  { name: 'Образование', id: 4, url: 'api/customer_education_list', list: 'reader' },
 ];

 const library = [
  { name: 'Место издания', id: 1, url: 'api/places_of_publication', list: 'library' },
  { name: 'По содержанию', id: 2, url: 'api/by_content', list: 'library' },
  { name: 'Общий классификатор', id: 3, url: 'api/classifiers', list: 'library', type: 'general' },
  { name: 'Классификатор казахстаника', id: 4, url: 'api/classifiers', list: 'library', type: 'kazakhstanika' },
  { name: 'Краеведческий классификатор', id: 5, url: 'api/classifiers', list: 'library', type: 'kraeved' },
  { name: 'По жанрам', id: 6, url: 'api/genres', list: 'library' },
  { name: 'По общим отраслям', id: 7, url: 'api/branches_of_knowledge', list: 'library' },
  { name: 'Библиографический уровень', id: 8, url: 'api/bibliographic_level', list: 'library' },
  { name: 'Картотека', id: 9, url: 'api/cabinet', list: 'library' },
  { name: 'Вид документа', id: 10, url: 'api/document_types', list: 'library' },
 ];

 const main = [
  { name: 'Список библиотек', id: 1, url: 'api/libraries', list: 'main' },
  { name: 'Пункты книговыдачи', id: 2, url: 'api/provider', list: 'main' },
  { name: 'Заказчики и поставщики', id: 3, url: 'api/zakazchik', list: 'main' },
  { name: 'Издательства', id: 4, url: 'api/publisher', list: 'main' },
  { name: 'Источники поступления', id: 5, url: 'api/sources_of_income', list: 'main' },
 ];

 const objArrs = { main, library, reader, staff };
 return objArrs[value];
}
