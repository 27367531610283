import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import { Link } from 'react-router-dom';

// import history from '../components/history'

// import FilterPanel from '../../components/filter-panel';
import Pagination from '../../../components/pagination';
import FormBookAdd from './formBookAdd';
import Loader from '../../../components/loader';

import './style.css';

import arrow_sort from '../../../assets/icon/arrow-sort.png';
import Button from '../../../components/button';

class CreateSrav extends Component {
 state = {
  choiceItemPage: 'book',

  currentPagePag: '',

  sort: false,
  sortType: '',
  sortPag: false,

  scanerCode: null,
 };

 getData = async (e) => {
  await this.setState({ choiceItemPage: e.target.dataset.set });
  await this.props.getData('komplekt_act', 'komplekt_act');
 };

 pagination = async (pageNum) => {
  await this.props.paginationPages({ item: 'komplekt_act', skip: pageNum, page: 'komplekt_act' });
  await this.setState({ currentPagePag: pageNum });
 };

 delDoc = async (id) => {
  const res = await fetch('https://smart-pvl.kz/Api/DelDoc.php', {
   method: 'POST',
   body: JSON.stringify({ id, staff: localStorage.getItem('idUser') }),
  });
  const json = await res.json();
  await this.props.setDate(json, 'komplekt_act');
 };

 sortItemBook = async (type) => {
  await this.props.sortItem('База книг', type, this.state.sort, 'bookAdd');
  await this.setState({ sort: !this.state.sort, sortPag: true, sortType: type });
 };

 selectItem = async (tab, id) => await this.props.selectItem(tab, id);

 exLogs = async (file) => (window.location.href = file);

 async componentDidMount() {
  await this.props.getData('komplekt_act', 'komplekt_act');
 }

 render() {
  const choiceItem = [
   { title: 'Документы', dataset: 'book' },
   { title: 'Добавить', dataset: 'add' },
  ];

  const headerItemsContent = [{ title: 'Название документа', data: 'name' }, { title: '' }];
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>сопроводительные документы</h1>
     </div>

     <div className='choice-type'>
      {choiceItem.map((el, i) =>
       el === 'Добавить' ? (
        <h3 data-set={el.dataset} key={i} onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}>
         {el.title}
        </h3>
       ) : (
        <h3 data-set={el.dataset} key={i} onClick={this.getData}>
         {el.title}
        </h3>
       )
      )}
     </div>

     {this.state.choiceItemPage === 'add' ? (
      <FormBookAdd />
     ) : (
      <>
       <div className='book-items-content'>
        <div className='book-items-header'>
         {headerItemsContent.map((el, i) => {
          return (
           <div key={i}>
            <p>{el.title}</p>
            {/* <img src={arrow_sort} alt="profile" data-type={el.data} onClick={(e) => this.sortItemBook(e.target.dataset.type)} /> */}
           </div>
          );
         })}
        </div>

        {this.props.loader ? (
         <Loader />
        ) : this.props.komplekt_act.length === 0 ? (
         <div className='search_output_null'>
          <h3>Раздел пуст</h3>
         </div>
        ) : (
         this.props.komplekt_act.map((el, i) => {
          let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
          return (
           <div className={oddEven} key={el.id}>
            <div>
             <p>{el.name}</p>
            </div>
            <div>
             <Button text='Скачать' onClick={() => this.exLogs(el.url)} />
             <Button text='Удалить документ' onClick={() => this.delDoc(el.id)} />
            </div>
           </div>
          );
         })
        )}
       </div>
       <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} />
      </>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(CreateSrav);
