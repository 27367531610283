import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';
import { SearchBooks } from '../../../api'

import Button from '../../../components/button';
import Loader from '../../../components/loader';

import './style.css'

class TotlaStat extends Component {

    state = {
        showFilterModal: false,

        choiceItemPage: 'search',

        checkArray: [],

        addbook: {
            avtor: '',
            titul: '',
            dates_life: '',
            avtor_other: '',
            name: '',
            kol_sim: '',
            num_chasti: '',
            name_chasti: '',
            sved_zaglov: '',
            sved_otvets: '',
            par_name_book: '',
            place_izdn: '',
            sved_izdn: '',
            izdatel: '',
            god: '',
            val: '',
            fiz_har: '',
            detskoe_izd: '',
            seria: '',
            podseria: '',
            prim: '',
            ISBN: '',
            price: '',
            kol: '',
            tirazh: '',
            zhanr: '',
            kraeved: '',
            kl_slova: '',
            index_bbk: '',
            index_udk: '',
            avtor_znak: '',
            invert_num: '',
            sigla: '',
            date_vvoda: '',
            osn_language: '',
            ost_language: '',
            part_post: '',
            istok_post: '',
            vid_dok: '',
            sluzh_otm: '',
            image: '',
            lib_name: '',
            otrasli: '',
        }
    }

    handleChangeModal = ({ target }) => {
        if (target.name === 'detskoe_izd') this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: !this.state.addbook[target.name] } })
        else this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.value } });
    }

    filterBook = async (e) => {
        e.preventDefault();
        const json = await SearchBooks.SearchBooks(this.state.addbook)
        await this.props.setDate(json, 'komplekt_otchet')
        await this.setState({ choiceItemPage: 'result' })
    }

    clearFilterBook = () => {

    }

    selectItem = async (tab, id) => await this.props.selectItem(tab, id)

    clearIntup = () => {
        this.setState({
            addbook: Object.keys(this.state.addbook)
                .reduce((acc, key) => ({ ...acc, [key]: "" }), {}),
            checkArray: []
        });
    }

    handleCheck = (e) => {
        let checkId = this.state.checkArray.some(el => el === e.target.name)
        if (checkId) this.setState({ ...this.state, checkArray: this.state.checkArray.filter(el => el !== e.target.name) })
        else this.setState({ ...this.state, checkArray: [...this.state.checkArray, e.target.name] })
    }



    render() {
        console.log(this.state.addbook.detskoe_izd);
        const atrFormAddBook = [
            { id: '1', title: 'Автор', name: 'avtor', type: 'text' },
            { id: '2', title: 'Титул 1 автора', name: 'titul', type: 'text' },
            { id: '3', title: 'Даты жизни 1 автора', name: 'dates_life', type: 'text' },
            { id: '4', title: 'Заглавие', name: 'name', type: 'text' },
            { id: '5', title: 'Количество незначащих символов заглавления', name: 'kol_sim', type: 'text' },
            { id: '6', title: 'Номер части', name: 'num_chasti', type: 'text' },
            { id: '7', title: 'Название части', name: 'name_chasti', type: 'text' },
            { id: '8', title: 'Сведение, относящиеся к заглавию', name: 'sved_zaglov', type: 'text' },
            { id: '9', title: 'Сведение об ответственности', name: 'sved_otvets', type: 'text' },
            { id: '10', title: 'Параллельное название', name: 'par_name_book', type: 'text' },
            { id: '11', title: 'Место издания', name: 'place_izdn', type: 'text' },
            { id: '12', title: 'Сведение об издании', name: 'sved_izdn', type: 'text' },
            { id: '13', title: 'Издательство', name: 'izdatel', type: 'text' },
            { id: '14', title: 'Год издания', name: 'god', type: 'text' },
            { id: '15', title: 'Объем', name: 'val', type: 'text' },
            { id: '16', title: 'Физические характеристики, иллюстрации, переплет и т.п.', name: 'fiz_har', type: 'text' },
            { id: '17', title: 'Детское издание до 15 лет', name: 'detskoe_izd', type: 'checkbox' },
            { id: '18', title: 'Серия', name: 'seria', type: 'text' },
            { id: '19', title: 'Подсерия', name: 'podseria', type: 'text' },
            { id: '20', title: 'Примечание', name: 'prim', type: 'text' },
            { id: '21', title: 'ISBN', name: 'ISBN', type: 'text' },
            { id: '22', title: 'Цена', name: 'price', type: 'text' },
            { id: '23', title: 'Количество', name: 'kol', type: 'text' },
            { id: '24', title: 'Тираж', name: 'tirazh', type: 'text' },
            { id: '25', title: 'Тематическая рубрика', name: 'zhanr', type: 'text' },
            { id: '26', title: 'Краеведение', name: 'kraeved', type: 'text' },
            { id: '27', title: 'Ключевые слова', name: 'kl_slova', type: 'text' },
            { id: '28', title: 'Индекс ББК', name: 'index_bbk', type: 'text' },
            { id: '29', title: 'Индекс УДК', name: 'index_udk', type: 'text' },
            { id: '30', title: 'Авторский знак', name: 'avtor_znak', type: 'text' },
            { id: '31', title: 'Инвентарный номер', name: 'invert_num', type: 'text' },
            { id: '32', title: 'Сигла', name: 'sigla', type: 'text' },
            { id: '33', title: 'Дата ввода', name: 'date_vvoda', type: 'text' },
            { id: '34', title: 'Основной язык ввода', name: 'osn_language', type: 'text' },
            { id: '35', title: 'Другие языки издания', name: 'ost_language', type: 'text' },
            { id: '36', title: 'Партия поступления', name: 'part_post', type: 'text' },
            { id: '37', title: 'Источник поступления', name: 'istok_post', type: 'text' },
            { id: '38', title: 'Вид документа', name: 'vid_dok', type: 'text' },
            { id: '39', title: 'Служебные отметки', name: 'sluzh_otm', type: 'text' },
            { id: '40', title: 'Библиотека', name: 'lib_name', type: 'select', option: this.props.library_id.map(el => el.name) },
            { id: '41', title: 'Отрасли', name: 'otrasli', type: 'text' }
        ];

        const choiceItem = [{ title: 'Поиск', dataset: 'search' }, { title: 'Результат поиска', dataset: 'result' }];
        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>Формирование и получение отчетных форм</h1>
                    </div>

                    <div className='choice-type'>
                        {choiceItem.map((el, i) => <h3 data-set={el.dataset}
                            key={i} onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}>{el.title}</h3>)}
                    </div>

                    {this.state.choiceItemPage === 'search'
                        ?
                        <div div className='form-add' >
                            <form className='form-add-wrapper' onSubmit={(e) => e.preventDefault()}>
                                <div className='form-add-book__book'>
                                    {atrFormAddBook.map((el, i) => {
                                        switch (el.type) {
                                            case 'select': {
                                                return (
                                                    <div className='search-filter-book_book' key={el.id}>
                                                        <p>{el.title}</p>
                                                        <div className='search-filter-book-rubrik'>
                                                            <select name={el.name} onChange={this.handleChangeModal}>
                                                                <option hidden>Выберите данные</option>
                                                                {el.option.map((item, i) => {
                                                                    // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
                                                                    return <option key={i}>{item}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            case 'checkbox': {
                                                return (
                                                    <div className='search-filter-book__book' key={el.id}>
                                                        <p>{el.title}</p>
                                                        <input type={el.type} name={el.name} checked={this.state.addbook[el.name]} onChange={this.handleChangeModal}
                                                        />
                                                    </div>
                                                )
                                            }
                                            default: {
                                                let disable = this.state.checkArray.some(elem => elem === el.id)
                                                return (
                                                    <div className='search-filter-book__book df' key={el.id}>
                                                        <div>
                                                            <input type='checkbox' name={el.id} checked={disable} onChange={this.handleCheck} />
                                                        </div>
                                                        <p className='search-filter-book__book-p'>{el.title}</p>
                                                        <input type='text' name={el.name} disabled={!disable}
                                                            autoComplete='off' value={this.state.addbook[el.name]}
                                                            placeholder='Обязательные поля ввода' onChange={this.handleChangeModal} />
                                                    </div>
                                                )
                                            }
                                        }
                                    })}
                                </div>

                                <div className='form-add-img-btn'>
                                    <Button className='btn_add_book' text='Сформировать поиск' onClick={this.filterBook} />
                                    <Button className='btn_add_book' text='Сбросить поиск' onClick={this.clearFilterBook} />
                                </div>
                            </form >
                        </div >
                        :
                        this.props.loader
                            ? <Loader />
                            :
                            this.props.komplekt_otchet.length
                                ?
                                this.props.komplekt_otchet.map((el, i) => {
                                    let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
                                    let url = `book_add/${el.id}`;
                                    // let newUrl = `/${el.id}`;
                                    return (
                                        <div className={oddEven} key={el.id}>
                                            <Link to={url} key={el.id}>
                                                <div onClick={() => this.selectItem('books', el.id)}><p>{el.avtor}</p></div>
                                            </Link>
                                            <div><p>{el.name}</p></div>
                                            <div><p>{el.god}</p></div>
                                            <div><p>{el.izdatel}</p></div>
                                            <div><p>{el.tirazh}</p></div>
                                            <div><p>{el.place_izdn}</p></div>
                                            <div><p>{el.seria}</p></div>
                                            <div><p>{el.ISBN}</p></div>
                                        </div>
                                    )
                                })
                                :
                                <div className='search_output_null'>
                                    <h1>:</h1>
                                    <h3 >По данному поиску нет совпадений</h3>
                                </div>
                    }



                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(TotlaStat);