import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import toast from 'react-hot-toast';
import { getSvodStatFondsLangs } from '../../../api';
import Button from '../../../components/button';

import '../otchet_set/style.css';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CSVDownload } from 'react-csv';

class OtchetSetDop6 extends Component {
  state = {
    arr_stat: [],

    open_1: false,
    open_2: false,
    date1: '',
    date2: '',

    csv: [],
  };

  handleDate = async () => {
    try {
      const res = await getSvodStatFondsLangs.getSvodStatFondsLangs({});
      const data = Object.values(res);
      this.setState({ arr_stat: data, open_1: true });
    } catch {
      console.log('err');
    }
  };

  handleTXT = () => {
    var text = '';
    this.state.arr_stat.map(
      (data) =>
        (text =
          text +
          data.lib +
          ':' +
          '\r\n' +
          'На казахском языке: ' +
          data.kaz +
          '\r\n' +
          'На русском языке: ' +
          data.rus +
          '\r\n' +
          'На английском языке: ' +
          data.eng +
          '\r\n' +
          'Другое: ' +
          data.g_other +
          '\r\n'),
    );

    console.log(text);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'Отчет по языкам');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handlePDF = () => {
    try {
      const input = document.getElementById('divDownload');
      html2canvas(input).then((canvas) => {
        const ImageData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(ImageData, 'JPEG', 0, 0);
        pdf.save('Отчет по языкам.pdf');
      });
    } catch {
      console.log('err');
    }
  };

  handleCSV = () => {
    try {
      var majorArr = [];
      var headerArr = [];

      headerArr.push('Библиотека');
      headerArr.push('На казахском языке');
      headerArr.push('На русском языке');
      headerArr.push('На английском языке');
      headerArr.push('Другое');

      majorArr.push(headerArr);

      this.state.arr_stat.map((data) => {
        var minorArr = [];
        minorArr.push(data.lib);
        minorArr.push(data.kaz);
        minorArr.push(data.rus);
        minorArr.push(data.eng);
        minorArr.push(data.g_other);
        majorArr.push(minorArr);
      });

      console.log(majorArr);
      this.setState({ csv: majorArr, open_2: true });
    } catch {
      console.log('err');
    }
  };

  render() {
    const headerItemsContent_1 = [
      { title: 'Библиотека', data: 'lib' },
      { title: 'На казахском языке', data: 'kaz' },
      { title: 'На русском языке', data: 'rus' },
      { title: 'На английском языке', data: 'eng' },
      { title: 'Другое', data: 'g_other' },
    ];

    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Формирование сводной отчетности по фонду литературы по языкам</h1>
          </div>
          <div>
            {!this.state.open_1 && (
              <Button
                text='Сформировать отчет'
                className='btn_library_del'
                onClick={this.handleDate}
              />
            )}

            {this.state.open_1 && (
              <div className='searchSved'>
                <div className='buttons-container'>
                  <Button text='TXT' className='btn_library_del' onClick={this.handleTXT} />
                  <Button text='PDF' className='btn_library_del' onClick={this.handlePDF} />
                  <Button text='CSV' className='btn_library_del' onClick={this.handleCSV} />
                  {this.state.open_2 && <CSVDownload data={this.state.csv} target='_blank' />}
                </div>
              </div>
            )}

            {this.state.open_1 && (
              <div id='divDownload' style={{ width: '210mm', padding: '20px', margin: 'auto' }}>
                <CommonWrapperTable
                  mainArr={this.state.arr_stat}
                  headersArr={headerItemsContent_1}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(OtchetSetDop6);
