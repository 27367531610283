import React, { Component } from 'react';

import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

class Documents extends Component {
 render() {
  const data = [
   { doc: 'Внедрение и разработка в 10 учреждениях', href: 'https://yandex.com' },
   { doc: 'Приобретение серверного оборудования', href: 'https://google.com' },
   { doc: 'Приобретение ноутбуков', href: 'https://google.com' },
   { doc: 'Приобретение интерактивной стеллы', href: 'https://google.com' },
   { doc: 'Приобретение термопринтеров', href: 'https://google.com' },
   { doc: 'Приобретение сканер штрих-кодов', href: 'https://google.com' },
   { doc: 'Прохождение информационной безопасности', href: 'https://google.com' },
   { doc: 'Приложения в Play Market, Appstore', href: 'https://google.com' },
   { doc: 'Разработка электронной библиотеки для пользователей', href: 'https://google.com' },
   { doc: 'Электронная библиотека для смартфонов (мобильная версия)', href: 'https://google.com' },
   { doc: 'Устав проекта', href: 'https://google.com' },
   { doc: 'Техническое задание', href: 'https://google.com' },
   { doc: 'Требования к аппаратному обеспечению', href: 'https://google.com' },
   { doc: 'Реестр выполненых настроек и разработок', href: 'https://google.com' },
   { doc: 'Сценарий тестирования', href: 'https://google.com' },
   { doc: 'Протокол предварительных испытаний', href: 'https://google.com' },
   { doc: 'Протокол интеграционного тестирования', href: 'https://google.com' },
   { doc: 'Протокол нагрузочного тестирования', href: 'https://google.com' },
   { doc: 'План устранения замечаний', href: 'https://google.com' },
   { doc: 'Видео уроки', href: 'https://google.com' },
   { doc: 'Руководство пользователя', href: 'https://google.com' },
   { doc: 'Отчет о проведенном обучении', href: 'https://google.com' },
   { doc: 'Матрица доступов', href: 'https://google.com' },
   { doc: 'Техническая документация для администрирования системы', href: 'https://google.com' },
   { doc: 'Протокол ввода в эксплуатацию', href: 'https://google.com' },
   { doc: 'Документы по информационной безопасности', href: 'https://google.com' },
   { doc: 'Получение авторского права', href: 'https://google.com' },
  ];
  const headerItemsContent = [
   { title: '', data: 'doc' },
   { title: '', data: 'button_href' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Документы</h1>
     </div>

     <div className='book-items-content'>
      <CommonWrapperTable mainArr={data} headersArr={headerItemsContent} />
     </div>
    </div>
   </div>
  );
 }
}

export default Documents;
