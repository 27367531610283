import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import './style.css'

class History extends Component {
    render() {
        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>ПРОСМОТР И ВЫГРУЗКА ИСТОРИИ</h1>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(History);