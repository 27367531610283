import React, { Component, useRef } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../redux/action';
import {
  addInventNumberMultiBook,
  delMultiBookApi,
  delInventNumber,
  getInventNumberMultiBook,
  eventCustomer,
  getMultiInventByType,
  getMultiBookInventByType,
  searchHistoryOfGiven,
  editHistoryOfGiven,
  getMultiBookData,
} from '../../api';

import ReactToPrint from 'react-to-print';
import debounce from '../../utils/debounce';

import Modal from '../../components/modal';
import Button from '../../components/button';
import Alert from '../../components/alert';
import history from '../../components/history';
import BarCode from '../../components/bar-code';
import ReitStar from '../../components/reiting-star';
import BookCard from '../../components/book-card';

import '../bookItem/BookItem.css';
import toast from 'react-hot-toast';
import moment from 'moment';

import close from '../../assets/icon/modal-genre-close.png';
// import startY from '../../assets/icon/star-y.png'
import startG from '../../assets/icon/star-g.png';
import BookItemLoader from '../bookItem/BookItemLoader.js';
import commonTryCatch from '../../helpers/commonTryCatch';

class MultiBookItem extends React.PureComponent {
  state = {
    showModalGive: false,
    showModalAlert: false,
    showAlertSpisan: false,
    showAlertSigla: false,
    showAlertInvNumber: false,
    showAlertCatCardInvNumber: false,
    typeBtnFromAlert: '',

    inputSpisAlert: '',
    spisElem: '',
    typeAlert: null,
    book: null,
    giveItem: {
      kol_day: '',
      id_customers: '',
      id_invent_number: '',
    },
    return_book: {
      bh_id: '',
      lb_id: '',
    },
    SpisanieItem: {
      act: '',
      id_invent_number: '',
    },
    btn: 'Выдать',
    btnAlert: 'Закрыть',
    days: '',
    inv_number_vydacha: '',
    inv_number_spis: '',
    choiceUser: '',
    readerInput: '',
    userBookOrder: false,
    InventNumbersBookOrder: false,
    moreInfo: false,
    loadPage: true,
    siglLibrary: localStorage.getItem('library_id'),
    changeSigla: '',
    inv_number: '',
    inv_number_book: [],
    status_vyd: 0,
    reload_book_item: [],
    inv_num_sigl: '',

    clicked_inv_num: '',
    clicked_inv_num_barcode: '',

    god: '',
    id: '',
    izdatel: '',
    kol: '',
    num_chasti: '',
    osn_language: '',
    ost_language1: '',
    ost_language2: '',
    place_izdn: '',
    place_izdn2: '',
    price: '',
    prim: '',
    seria: '',
    status: '',
    sved_izdn: '',
    sved_otvets: '',
    sved_zaglov: '',
    tema: '',
    title: '',
    type: '',
  };

  //Списание книги
  delBookAlert = async (id) => {
    await this.setState({
      typeAlert: 'списать',
      showAlertSpisan: true,
      btn: 'Cписать',
      spisElem: id,
    });
    await document.querySelector('#inv_number_spis').focus();
  };

  showModal = async (data, text) => {
    await this.setState({
      showModalGive: true,
      giveItem: data,
      typeBtnFromAlert: text,
      typeAlert: 'выдать',
      btn: 'Выдать',
    });
    document.querySelector('#inv_number_vydacha').focus();
  };

  //confirm при возврате книги
  confirmReturnBook = async (book, text) =>
    this.setState({ showModalAlert: true, typeBtnFromAlert: text, typeAlert: 'вернуть', book });

  //отмена confirm
  cancelReturnBook = async () => this.setState({ showModalAlert: false });

  addIvnNumber = async (id) => {
    this.getInveNumberByLib(id);
    this.setState({
      typeAlert: 'Добавить инвентарный номер',
      showAlertInvNumber: true,
      btn: 'Добавить',
      spisElem: id,
    });
  };

  getInveNumberByLib = async (lib) => {
    const obj = {
      book_id: this.state.id,
    };

    const invNumber = getInventNumberMultiBook.getInventNumberMultiBook(obj);
    invNumber.then((res) => {
      this.setState({ inv_number_book: res });
    });
  };

  addInv = async () => {
    if (this.state.inv_num_sigl) {
      if (this.state.inv_number.length) {
        const obj = {
          book: this.state.id,
          library_id: this.state.inv_num_sigl,
          invent_num: this.state.inv_number,
          status_vyd: this.state.status_vyd,
        };
        const add = addInventNumberMultiBook.addInventNumberMultiBook(obj);
        add.then((res) => {
          this.getInveNumber();
          this.setState({ inv_number: '', inv_num_sigl: '' });
          document.querySelector('#input_add_inv_number').focus();
          document.querySelector('.select_alert_inv_number').selectedIndex = 0;
          if (res) {
            toast.success('Инвентарный номер успешно добавлен!');
          } else {
            toast.error('Инвентарный номер не добавлен');
          }
        });
      } else {
        document.querySelector('#input_add_inv_number').focus();
        toast.error('Необходимо указать инв. номер!');
      }
    } else {
      document.querySelector('.select_alert_inv_number').focus();
      toast.error('Необходимо указать библиотеку!');
    }
  };

  //Получение инвентарных номеров
  getInveNumber = async () => {
    const obj = {
      book_id: this.state.id,
      // sigl: localStorage.getItem('lib_ID'),
      library_id: this.state.inv_num_sigl,
    };
    if (this.state.inv_num_sigl) {
      const invNumber = getInventNumberMultiBook.getInventNumberMultiBook(obj);
      invNumber.then((res) => {
        this.setState({ inv_number_book: res });
      });
    } else {
      this.setState({ inv_number_book: [] });
    }
  };

  closeModal = () =>
    // this.state.typeAlert === 'Книжный формуляр'
    //  ? this.addIvnNumber()
    //  :
    this.setState({
      showModalGive: false,
      userBookOrder: false,
      InventNumbersBookOrder: false,
      readerInput: '',
      showAlertSpisan: false,
      inputSpisAlert: '',
      showAlertSigla: false,
      showAlertInvNumber: false,
      showModalAlert: false,
      showAlertCatCardInvNumber: false,
      days: '',
      inv_number_vydacha: '',
    });

  closeModalEvent = (e) => {
    if (e === 'modal') this.closeModal();
  };

  prompt_modal = () => {
    document.querySelector('.modal_prompt').style.display = 'block';
  };
  prompt_modal_close = () => {
    document.querySelector('.modal_prompt').style.display = 'none';
  };

  // Удаление инвентарного номера
  delInvNumberBook = async (id, id_sigl) => {
    // const obj = {
    //  id: id,
    //  id_sigl: id_sigl,
    // };
    // const res = delInventNumber.delInventNumber(obj);
    // res.then((res) => {
    //  this.getInveNumber();
    // });
    // toast.success('Инвентарный номер успешно удален!');
  };

  hideModal = async () => {
    const day = 1000 * 60 * 60 * 24;

    const date1 = this.state.days;
    const date2 = moment().format('YYYY-MM-DD');
    const date3 = new Date(date1);
    const date4 = new Date(date2);

    const difference = date3 - date4;
    const dayNumber = difference / day;

    const data = {
      ...this.state.giveItem,
      date_vydachi: date2,
      date_sdachi: date1,
      day_number: dayNumber,
    };

    if (this.state.days) {
      if (this.state.giveItem.id_customers) {
        if (this.state.giveItem.id_invent_number) {
          if (dayNumber > 0) {
            const res = await commonTryCatch(
              'https://smart-pvl.kz/Api/api/multibooks/addHistoryOfGiven.php',
              'POST',
              JSON.stringify(data),
            );

            this.closeModal();

            if (res) {
              toast.success('Книга успешно выдана!');
            } else {
              toast.error('Книга не выдана');
            }
          } else {
            toast.error('Некорректно указаны даты!');
          }
        } else {
          toast.error('Необходимо указать инв. номер!');
        }
      } else {
        toast.error('Необходимо указать читателя!');
      }
    } else {
      toast.error('Необходимо указать дату возврата!');
    }

    // (await this.props.successBook) && this.setState({ showModalGive: false });
    // await history.push('/book_out');
  };

  handleChange = async ({ target }) => {
    if (target.name == 'inputSpisAlert') {
      await this.setState({
        SpisanieItem: {
          ...this.state.SpisanieItem,
          act: target.value,
        },
      });
    } else {
      await this.setState({ [target.name]: target.value });
    }
  };

  handleChangeLib = async (id) => {
    console.log(id);
    this.setState({ inv_num_sigl: id });
    this.getInveNumberByLib(id);
  };

  filterValues = async (e) => {
    await this.setState({ readerInput: e.target.value });

    const userBookOrder = await eventCustomer.eventCustomer({ value: e.target.value }, 'search');
    await this.setState({ userBookOrder });
  };

  // выдача
  filterValues22 = async (e) => {
    await this.setState({ inv_number_vydacha: e.target.value });

    if (e.target.value !== '') {
      const InventNumbersBookOrder = await getMultiBookInventByType.getMultiBookInventByType({
        invent_number: e.target.value,
        book_id: this.state.id,
      });
      await this.setState({ InventNumbersBookOrder });
    } else {
      await this.setState({ InventNumbersBookOrder: false });
    }
  };

  // возвращение
  filterValues23 = async (e) => {
    await this.setState({ inv_number_vydacha: e.target.value });
    if (e.target.value !== '') {
      const InventNumbersBookOrder = await searchHistoryOfGiven.searchHistoryOfGiven({
        invent_number: e.target.value,
        book_id: this.state.id,
      });

      await this.setState({ InventNumbersBookOrder });
    } else {
      await this.setState({ InventNumbersBookOrder: false });
    }
  };

  // списание
  filterValues2 = async (e) => {
    await this.setState({ inv_number_vydacha: e.target.value });

    if (e.target.value !== '') {
      const InventNumbersBookOrder = await getMultiInventByType.getMultiInventByType({
        invent_number: e.target.value,
        book_id: this.state.id,
      });

      await this.setState({ InventNumbersBookOrder });
    } else {
      await this.setState({ InventNumbersBookOrder: false });
    }
  };

  //Alert при выдачи и возврате книги
  hideModalAlert = async () => {
    if (this.state.typeAlert === 'вернуть') {
      const returnBooks = await editHistoryOfGiven.editHistoryOfGiven(
        { id_invent_number: this.state.return_book.lb_id, id: this.state.return_book.bh_id },
        'search',
      );
      console.log(returnBooks);
      await this.setState({ showModalAlert: false });
      //  await history.push('/book_out');
      toast.success('Книга успешно возвращена!');
    } else {
      // if (this.props.successBook) {
      //   this.setState({ showModalAlert: true });
      // }
      // await this.setState({ showModalAlert: false });
      //  history.push('/book_out');
    }
  };

  delBook = async () => {
    const obj = {
      id_invent_number: this.state.SpisanieItem.id_invent_number,
      act: this.state.SpisanieItem.act,
    };

    await delMultiBookApi.delMultiBookApi(obj);
    this.setState({
      typeAlert: '',
      showAlertSpisan: false,
      btn: '',
      spisElem: '',
      inputSpisAlert: '',
    });

    toast.success('Книга успешно списана!');
  };

  async componentDidMount() {
    window.scrollBy(0, 0);
    const url = window.location.pathname.slice(11);

    console.log(url);

    const data = await getMultiBookData.getMultiBookData(url);

    this.setState({
      god: data[0].god,
      id: data[0].id,
      izdatel: data[0].izdatel,
      kol: data[0].kol,
      num_chasti: data[0].num_chasti,
      osn_language: data[0].osn_language,
      ost_language1: data[0].ost_language1,
      ost_language2: data[0].ost_language2,
      place_izdn: data[0].place_izdn,
      place_izdn2: data[0].place_izdn2,
      price: data[0].price,
      prim: data[0].prim,
      seria: data[0].seria,
      status: data[0].status,
      sved_izdn: data[0].sved_izdn,
      sved_otvets: data[0].sved_otvets,
      sved_zaglov: data[0].sved_zaglov,
      tema: data[0].tema,
      title: data[0].title,
      type: data[0].type,
    });
  }

  render() {
    return (
      <div className='page-out-wrapper'>
        {this.state.typeAlert === 'списать' && (
          <Alert
            show={this.state.showAlertSpisan}
            handleClose={this.delBook}
            data={this.state}
            btn={this.state.btn}
            classBtn='btn-close'
            closeModalEvent={this.closeModalEvent}
            btnTwo='Отменить'
            cancelClose={this.closeModal}
          >
            <div className='modal-content'>
              <div className='alert-title-icon'>
                <div className='alert-spisan'>
                  <h1>Списание книги</h1>
                  <input
                    type='text'
                    onChange={this.handleChange}
                    name='inputSpisAlert'
                    placeholder='Введите номер Акта списания'
                  />
                  <div
                    className='search-filter-book-item'
                    style={{ width: '100%', maxWidth: '100%' }}
                  >
                    <p>Инвентарный номер : {this.state.inv_number_spis}</p>
                    <input
                      type='number'
                      autoComplete='off'
                      name='inv_number_spis'
                      id='inv_number_spis'
                      style={{ width: '100%' }}
                      //  value={this.state.}
                      placeholder='Отсканируйте штрих-код'
                      onChange={debounce(this.filterValues2)}
                    />
                    <div className='searchReader'>
                      {this.state.InventNumbersBookOrder
                        ? this.state.InventNumbersBookOrder.map((el, i) => {
                            return (
                              <div>
                                <p
                                  key={i}
                                  onClick={() =>
                                    this.setState({
                                      InventNumbersBookOrder: [],
                                      inv_number_spis: el.inventory_number,
                                      SpisanieItem: {
                                        ...this.state.SpisanieItem,
                                        id_invent_number: el.id,
                                      },
                                    })
                                  }
                                >
                                  {el.inventory_number}
                                </p>
                              </div>
                            );
                          })
                        : []}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Alert>
        )}

        {this.state.typeAlert === 'выдать' ? (
          <Alert
            show={this.state.showModalAlert}
            handleClose={this.hideModalAlert}
            data={this.state}
            btn={this.state.btn}
            classBtn='btn-close'
          >
            <div className='modal-content'>
              <div className='alert-title-icon'>
                <h1>{this.state.typeBtnFromAlert}</h1>
              </div>
            </div>
          </Alert>
        ) : (
          <Alert
            show={this.state.showModalAlert}
            handleClose={this.hideModalAlert}
            data={this.state}
            btn='Вернуть'
            btnTwo='Отменить'
            cancelClose={this.cancelReturnBook}
            classBtn='btn-close'
          >
            <div className='modal-content'>
              <div className='modal-title-icon'>
                <h1>Возвращение книги</h1>
                <img
                  className='modal-close-icon'
                  onClick={this.closeModal}
                  alt='close'
                  src={close}
                />
              </div>
              <form
                onSubmit={(e) => e.preventDefault()}
                className='product-modal-inner'
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div style={{ display: 'flex' }}></div>
                <div
                  className='search-filter-book-item'
                  style={{ width: '100%', maxWidth: '100%' }}
                >
                  <p>Инвентарный номер : {this.state.inv_number_vydacha}</p>
                  <input
                    type='number'
                    autoComplete='off'
                    name='inv_number_vydacha'
                    id='inv_number_vydacha'
                    style={{ width: '100%' }}
                    //  value={this.state.}
                    placeholder='Отсканируйте штрих-код'
                    onChange={debounce(this.filterValues23)}
                  />
                  <div className='searchReader'>
                    {this.state.InventNumbersBookOrder
                      ? this.state.InventNumbersBookOrder.map((el, i) => {
                          return (
                            <div>
                              <p
                                key={i}
                                onClick={() =>
                                  this.setState({
                                    InventNumbersBookOrder: [],
                                    inv_number_vydacha: el.inventory_number,
                                    return_book: {
                                      ...this.state.return_book,
                                      bh_id: el.bh_id,
                                      lb_id: el.lb_id,
                                    },
                                  })
                                }
                              >
                                {el.inventory_number}
                              </p>
                            </div>
                          );
                        })
                      : []}
                  </div>
                </div>
              </form>
            </div>
          </Alert>
        )}

        <Modal
          show={this.state.showModalGive}
          handleClose={this.hideModal}
          data={this.state}
          btn={this.state.btn}
          classBtn='btn-close'
          closeModalEvent={this.closeModalEvent}
        >
          <div className='modal-content'>
            <div className='modal-title-icon'>
              <h1>Информация о выдаче книги</h1>
              <img className='modal-close-icon' onClick={this.closeModal} alt='close' src={close} />
            </div>
            <form
              onSubmit={(e) => e.preventDefault()}
              className='product-modal-inner'
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div style={{ display: 'flex' }}>
                <div className='search-filter-book-item'>
                  <p>День возврата</p>
                  <input
                    type='date'
                    autoComplete='off'
                    name='days'
                    value={this.state.days}
                    placeholder='Обязательные поля ввода'
                    onChange={this.handleChange}
                  />
                </div>
                <div className='search-filter-book-item'>
                  <p>Читатель: {this.state.readerInput}</p>
                  <input
                    type='text'
                    name='reader'
                    autoComplete='off'
                    placeholder='Введите фамилию читателя'
                    // value={this.state.readerInput}
                    onChange={debounce(this.filterValues)}
                  />
                  <div className='searchReader'>
                    {this.state.userBookOrder
                      ? this.state.userBookOrder.map((el, i) => {
                          return (
                            <div>
                              <p
                                key={i}
                                onClick={() =>
                                  this.setState({
                                    userBookOrder: [],
                                    readerInput: el.FIO,
                                    giveItem: {
                                      ...this.state.giveItem,
                                      id_customers: el.id,
                                    },
                                  })
                                }
                              >
                                {el.FIO}
                              </p>
                            </div>
                          );
                        })
                      : []}
                  </div>
                  {/* {this.state.readerInput.length >= 3 && this.state.userBookOrder.length > 5 ? <p>.....</p> : null} */}
                </div>
              </div>
              <div className='search-filter-book-item' style={{ width: '100%', maxWidth: '100%' }}>
                <p>Инвентарный номер : {this.state.inv_number_vydacha}</p>
                <input
                  type='number'
                  autoComplete='off'
                  name='inv_number_vydacha'
                  id='inv_number_vydacha'
                  style={{ width: '100%' }}
                  //  value={this.state.}
                  placeholder='Отсканируйте штрих-код'
                  onChange={debounce(this.filterValues22)}
                />
                <div className='searchReader'>
                  {this.state.InventNumbersBookOrder
                    ? this.state.InventNumbersBookOrder.map((el, i) => {
                        return (
                          <div>
                            <p
                              key={i}
                              onClick={() =>
                                this.setState({
                                  InventNumbersBookOrder: [],
                                  inv_number_vydacha: el.inventory_number,
                                  giveItem: {
                                    ...this.state.giveItem,
                                    id_invent_number: el.id,
                                  },
                                })
                              }
                            >
                              {el.inventory_number}
                            </p>
                          </div>
                        );
                      })
                    : []}
                </div>
              </div>
            </form>
          </div>
        </Modal>

        {this.state.typeAlert === 'Добавить инвентарный номер' && (
          <Alert
            show={this.state.showAlertInvNumber}
            handleClose={this.addInv}
            data={this.state}
            btn={this.state.btn}
            classBtn='btn-close'
            closeModalEvent={this.closeModalEvent}
            btnTwo='Закрыть'
            cancelClose={this.closeModal}
          >
            <div className='modal-content'>
              <div className='alert-title-icon'>
                <div className='alert-spisan'>
                  <h1>Инвентарные номера</h1>
                  <div className='wrap_input_inv_number'>
                    <span className='input_inv_number_marker'>&#9998;</span>
                    <input
                      type='text'
                      className=''
                      id='input_add_inv_number'
                      value={this.state.inv_number}
                      placeholder='Введите инвентарный номер'
                      onChange={(e) => this.setState({ inv_number: e.target.value })}
                    />
                    <label>
                      <input
                        type={'checkbox'}
                        onChange={(e) => this.setState({ status_vyd: e.target.checked ? 1 : 0 })}
                        style={{ marginRight: '5px', marginLeft: '10px' }}
                      />
                      Выдаваемая
                    </label>
                  </div>
                  <select
                    className='select_alert_inv_number'
                    onChange={(e) => {
                      this.handleChangeLib(e.target.value);
                    }}
                  >
                    <option hidden>
                      {this.state.inv_num_sigl == ''
                        ? 'Выберите сиглу перед тем как добавить инвентарный номер'
                        : this.props.SiglsDate.find((i) => i.id === this.state.inv_num_sigl)?.name}
                    </option>
                    {this.props.SiglsDate.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='inv_number_title'>
                <h3>Инвентарные номера:</h3>
                <div onMouseMove={this.prompt_modal} onMouseLeave={this.prompt_modal_close}>
                  ❓
                </div>
                <div className={'modal_prompt '}>
                  <div>
                    <span style={{ color: 'yellow' }}>Желтый - забронирована</span>
                  </div>
                  <div>
                    <span style={{ color: 'green' }}>Зеленый - выдана</span>
                  </div>
                  <div>
                    <span style={{ color: 'black' }}>Черный - свободна</span>
                  </div>
                  <div>
                    <span style={{ color: 'red' }}>Красный - невыдаваемая</span>
                  </div>
                  <br />
                  <div>При нажатии на ❌ инвентарный номер удалиться.</div>
                  <div>
                    При нажатии на сам инвентарный номер, откроется книжный формуляр и его штрих-код
                  </div>
                </div>
              </div>
              <div className='inv_number_wrapper'>
                {this.state.inv_number_book.length
                  ? this.state.inv_number_book.map((item, i) => {
                      return (
                        <div>
                          <div
                            className='item_inv_number'
                            key={i}
                            //  onClick={(e) => this.getCatalogCardForInvNumber(item.id, item.inventory_number, item.barcode)}
                            style={
                              item.status == '0'
                                ? { color: 'red' }
                                : item.status == '1'
                                ? { color: 'black' }
                                : item.status == '2'
                                ? { color: 'yellow' }
                                : { color: 'green' }
                            }
                          >
                            {item.inventory_number}
                          </div>
                          <span onClick={(e) => this.delInvNumberBook(item.id, item.id_sigl, e)}>
                            ❌
                          </span>
                        </div>
                      );
                    })
                  : 'Ваша библиотека еще не добавила инвентарные номера для данной книги'}
              </div>
            </div>
          </Alert>
        )}
        <div className='base-books-wrapper'>
          <div className='book-info'>
            <div className='book-info-left'>
              <img
                src={'https://smart-pvl.kz/covers/defpic3.png'}
                alt='book'
                className='book-info-image-news'
              ></img>
            </div>

            <div className='book-info-right-book'>
              <div className='base-books-title'>
                <div className='title-btn'>
                  <div>
                    <h1>{this.state.title} </h1>
                    {/* <p>{el.avtor}</p> */}
                  </div>
                  <div style={{ display: 'inline-grid' }}>
                    <Button
                      onClick={() => this.delBookAlert(this.state.id)}
                      text={'Списать'}
                      className={'btn_archive_book'}
                      typeBtn={'button'}
                    />

                    {/* {!el.date_vydachi ? ( */}
                    <Button
                      onClick={() =>
                        this.showModal(
                          {
                            //  id_customers: 7,
                            id_book: this.state.id,
                          },
                          'Книга успешно выдана',
                        )
                      }
                      text={'Выдать'}
                      className={'btn_order_book'}
                      typeBtn={'button'}
                    />
                    {/* ) : ( */}
                    <Button
                      onClick={() =>
                        this.confirmReturnBook(
                          {
                            id_customers: 7,
                            id_book: this.state.id,
                          },
                          'Вы точно хотите вернуть книгу?',
                        )
                      }
                      text={'Вернуть'}
                      className={'btn_return_book'}
                      typeBtn={'button'}
                    />
                    {/* )} */}
                    <br />
                    <Button
                      onClick={() => this.addIvnNumber(this.state.id)}
                      text={'Инвентарные номера'}
                      className={'btn_inv_number_book'}
                      typeBtn={'button'}
                    />
                  </div>
                </div>

                <div className='book-info-main'>
                  <div className='l'>
                    <div className='book-info-rigth-item'>
                      <p>
                        Сведения об ответственности: <span>{this.state.sved_otvets}</span>
                      </p>
                    </div>
                    <div className='book-info-rigth-item'>
                      <p>
                        Сведения к заглавию: <span>{this.state.sved_zaglov}</span>
                      </p>
                    </div>
                    <div className='book-info-rigth-item'>
                      <p>
                        Сведение об издании: <span>{this.state.sved_izdn}</span>
                      </p>
                    </div>

                    <div className='book-info-rigth-item'>
                      <p>
                        Область места издания: <span>{this.state.place_izdn2}</span>
                      </p>
                    </div>
                    <div className='book-info-rigth-item'>
                      <p>
                        Серия: <span>{this.state.seria}</span>
                      </p>
                    </div>
                    <div className='book-info-rigth-item'>
                      <p>
                        Примечание: <span>{this.state.prim}</span>
                      </p>
                    </div>
                    {/* <div className='book-info-rigth-item'>
             <p>
              Библиотека: <span>{this.state.id}</span>
             </p>
            </div> */}
                  </div>
                </div>
              </div>
              {/* <div className='book-info-right1'>
                                         <Bar barcode={this.state.id} code={this.state.id} />
                                     </div> */}
            </div>
          </div>
        </div>
        {/* );
      })} */}
      </div>
    );
  }
}
const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(MultiBookItem);
