import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, cleanAnyState, setBOPartiaPart } from '../redux/action';

import { GetBatchesByDate } from '../api';

import CommonWrapperTable from '../components/tables/CommonWrapperTable/CommonWrapperTable';

import FormPartAdd from '../components/FormPartAdd/FormPartAdd';

import './style.css';

import moment from 'moment';

class PartAdd extends Component {
  state = {
    choiceItemPage: 'book',
  };

  returnOnFirstPage = async () => {
    this.setState({ choiceItemPage: 'book' });
    const obj = {
      date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    };
    const json = await GetBatchesByDate.GetBatchesByDate(obj);
    console.log(json);
    await this.props.setBOPartiaPart(json);
  };

  async componentDidMount() {
    const obj = {
      date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    };
    const json = await GetBatchesByDate.GetBatchesByDate(obj);
    console.log(json);
    await this.props.setBOPartiaPart(json);
  }

  render() {
    const url = this.props.match.path.slice(1);
    const choiceItem = [
      { title: 'Заявки', dataset: 'book' },
      { title: 'Формирование документов для заказа литературы', dataset: 'add' },
    ];

    const headerItemsContent = [
      { title: 'Дата поступления', data: 'entrance_date' },
      { title: 'Дата по документу', data: 'document_date' },

      { title: 'Номер документа', data: 'document_number' },
      { title: 'Примечание', data: 'note' },
      { title: 'Сопроводительный документ', data: 'soprov_doc' },

      { title: 'Поставщик', data: 'supplier' },
      { title: 'Тип источника', data: 'type_of_source' },
      { title: 'Количество БО', data: 'bo_number' },
      { title: 'Общая сумма', data: 'summa' },

      { title: 'Количество экземпляров', data: 'exemplar_number' },
      { title: 'Дата', data: 'dateCreate' },
      { title: 'Библиотека', data: 'lib' },

      { title: 'Отметка о поступлении', data: 'status_type' },
    ];

    const btnTypeCatalog = [
      { title: 'қазақша' },
      { title: 'русский' },
      { title: 'другие' },
      { title: 'все' },
    ];
    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>Формирование и обработка заказов на комплектование фонда библиотеки</h1>
          </div>

          <div className='choice-type'>
            {choiceItem.map((el, i) => (
              <h3
                data-set={el.dataset}
                key={i}
                onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}
              >
                {el.title}
              </h3>
            ))}
          </div>
          {this.state.choiceItemPage === 'add' ? (
            <FormPartAdd returnOnFirstPage={this.returnOnFirstPage} />
          ) : (
            <>
              <div
                className='book-items-content'
                style={{ width: 'fit-content', paddingRight: '50px', marginRight: '-60px' }}
              >
                <CommonWrapperTable
                  mainArr={this.props.BOPartiaPart}
                  headersArr={headerItemsContent}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, cleanAnyState, setBOPartiaPart };

export default connect(mapStateToProps, mapDispatchToProps)(PartAdd);
