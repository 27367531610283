import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';
import { getDataStat3 } from '../../../api';

import Loader from '../../../components/loader';

import '../otrasl_read/style.css';

class ZhanrRead extends Component {
 state = {
  statData: [],
 };

 async componentDidMount() {
  // const stat = await getDataStat3.getDataStat3('qwe');
  // this.setState({ statData: stat });
 }

 checkData = (arr, name) => {
  //   console.log(arr, name);
  let res = arr.find((i) => i.name_ru === name);
  typeof res == 'undefined' ? (res = '0') : console.info();
  return res;
 };

 render() {
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Книговыдача по жанрам литературы</h1>
     </div>
     {this.state.statData ? (
      <div className='monitor'>
       <div className='colunm'>
        <div>
         <div className='headerRead'>
          <p>Жанры</p>
         </div>
         <div className='type'>
          <p>
           Проза (Романы. Повести. Рассказы) -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Проза (Романы. Повести. Рассказы)').amount
              ? this.checkData(this.state.statData, 'Проза (Романы. Повести. Рассказы)').amount
              : '0'
            : '0'}
          </p>
          <p>
           Поэзия (Поэмы. Стихи. Оды. Сонеты. Баллады. и др.) -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Поэзия (Поэмы. Стихи. Оды. Сонеты. Баллады. и др.)').amount
              ? this.checkData(this.state.statData, 'Поэзия (Поэмы. Стихи. Оды. Сонеты. Баллады. и др.)').amount
              : '0'
            : '0'}
          </p>
          <p>
           Мемуары. Автобиографии -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Мемуары. Автобиографии').amount
              ? this.checkData(this.state.statData, 'Мемуары. Автобиографии').amount
              : '0'
            : '0'}
          </p>
          <p>
           Очерки -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Очерки').amount
              ? this.checkData(this.state.statData, 'Очерки').amount
              : '0'
            : '0'}{' '}
          </p>
          <p>
           Детективы -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Детективы').amount
              ? this.checkData(this.state.statData, 'Детективы').amount
              : '0'
            : '0'}
          </p>
          <p>
           Фантастика. Фэнтэзи -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Фантастика. Фэнтэзи').amount
              ? this.checkData(this.state.statData, 'Фантастика. Фэнтэзи').amount
              : '0'
            : '0'}
          </p>
          <p>
           Ужасы. Триллеры -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Ужасы. Триллеры').amount
              ? this.checkData(this.state.statData, 'Ужасы. Триллеры').amount
              : '0'
            : '0'}
          </p>
          <p>
           Приключения -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Приключения').amount
              ? this.checkData(this.state.statData, 'Приключения').amount
              : '0'
            : '0'}
          </p>
          <p>
           Женский роман -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Женский роман').amount
              ? this.checkData(this.state.statData, 'Женский роман').amount
              : '0'
            : '0'}
          </p>
          <p>
           Комиксы -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Комиксы').amount
              ? this.checkData(this.state.statData, 'Комиксы').amount
              : '0'
            : '0'}
          </p>
          <p>
           Пьесы -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Пьесы').amount
              ? this.checkData(this.state.statData, 'Пьесы').amount
              : '0'
            : '0'}
          </p>
          <p>
           Эссе -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Эссе').amount
              ? this.checkData(this.state.statData, 'Эссе').amount
              : '0'
            : '0'}
          </p>
          <p>
           Фольклор (Притчи. Эпиграммы. Мифы. Сказки. Эпосы) -{' '}
           {this.state.statData
            ? this.checkData(this.state.statData, 'Фольклор (Притчи. Эпиграммы. Мифы. Сказки. Эпосы)').amount
              ? this.checkData(this.state.statData, 'Фольклор (Притчи. Эпиграммы. Мифы. Сказки. Эпосы)').amount
              : '0'
            : '0'}
          </p>
         </div>
        </div>
        <div></div>
       </div>
      </div>
     ) : (
      <Loader />
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(ZhanrRead);
