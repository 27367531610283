import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import toast from 'react-hot-toast';
import { getSvodStatByGiven } from '../../../api';
import Button from '../../../components/button';

import './style.css';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CSVLink, CSVDownload } from 'react-csv';

class OtchetSet extends Component {
 state = {
  arr_stat: [],

  open_1: false,
  open_2: false,
  date1: '',
  date2: '',

  csv: [],
 };

 handleDate = async () => {
  if (this.state.date1 && this.state.date2) {
   try {
    const res = await getSvodStatByGiven.getSvodStatByGiven({
     start: this.state.date1,
     end: this.state.date2,
    });
    console.log(res);
    this.setState({ arr_stat: res, open_1: true });
   } catch {
    console.log('err');
   }
  } else {
   toast.error('Некорректно выбранный период');
   this.setState({ arr_stat: [], open_1: false });
  }
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleTXT = () => {
  var text = '';
  this.state.arr_stat.map((data) => (text = text + data.name + '-' + data.booksN + '\r\n'));

  console.log(text);
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', 'Отчет по выдаче');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
 };

 handlePDF = () => {
  try {
   const input = document.getElementById('divDownload');
   html2canvas(input).then((canvas) => {
    const ImageData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.addImage(ImageData, 'JPEG', 0, 0);
    pdf.save('Отчет по выдаче.pdf');
   });
  } catch {
   console.log('err');
  }
 };

 handleCSV = () => {
  try {
   var majorArr = [];
   var headerArr = [];

   headerArr.push('Библиотека');
   headerArr.push('Количество выданных книг');

   majorArr.push(headerArr);

   this.state.arr_stat.map((data) => {
    var minorArr = [];
    minorArr.push(data.name);
    minorArr.push(data.booksN);
    majorArr.push(minorArr);
   });

   console.log(majorArr);
   this.setState({ csv: majorArr, open_2: true });
  } catch {
   console.log('err');
  }
 };

 render() {
  const headerItemsContent_1 = [
   { title: 'Библиотека', data: 'name' },
   { title: 'Количество выданных книг', data: 'booksN' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Формирование сводной отчетности по выдаче литературы</h1>
     </div>

     <div>
      <SincetoCalendar
       selectDate={this.selectDate}
       state1={this.state.date1}
       state2={this.state.date2}
       handleDate={this.handleDate}
       type='date'
      />

      {this.state.open_1 && (
       <div className='searchSved'>
        <div className='buttons-container'>
         <Button text='TXT' className='btn_library_del' onClick={this.handleTXT} />
         <Button text='PDF' className='btn_library_del' onClick={this.handlePDF} />
         <Button text='CSV' className='btn_library_del' onClick={this.handleCSV} />
         {this.state.open_2 && <CSVDownload data={this.state.csv} target='_blank' />}
        </div>
       </div>
      )}

      {this.state.open_1 && (
       <div id='divDownload' style={{ width: '210mm', padding: '20px' }}>
        <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_1} />
       </div>
      )}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(OtchetSet);
