import React, { Component } from 'react';

import { connect } from 'react-redux';

import './style.css';

import icon1 from '../assets/icon/main-page1.png';
import icon2 from '../assets/icon/main-page2.png';
import icon3 from '../assets/icon/main-page3.png';
import icon4 from '../assets/icon/main-page4.png';

import moment from 'moment';
import { GetStaffFullData, GetStaffFullDataLog } from '../api';
import history from '../components/history';

class MainPage extends Component {
 state = {
  dateTo: moment().format('YYYY-MM-DD'),
  data: '',
  data2: '',
 };
 componentDidMount() {
  this.getData();
  if (localStorage.getItem('lib_ID')) {
   const lib_ID = localStorage.getItem('lib_ID');
   lib_ID !== '0' ? console.info() : localStorage.setItem('libcheck', 'true');
   history.push('/');
  }
 }

 getData = async () => {
  const data = {
   id: localStorage.getItem('idUser'),
  };
  const json = await GetStaffFullData.GetStaffFullData(data);
  console.log(json);
  this.setState({ data: json });

  const data2 = {
   id: localStorage.getItem('idUser'),
  };
  const json2 = await GetStaffFullDataLog.GetStaffFullDataLog(data2);
  this.setState({ data2: json2 });
 };

 render() {
  const mainPageCon = [
   { title: 'Библиографические описания', icon: icon2, num: this.props.totalAllItem },
   { title: 'Инвентарные номера', icon: icon4, num: 1320 },

   { title: 'Количество читателей', icon: icon1, num: 1256 },
   { title: 'Выдано книг', icon: icon3, num: 1084 },
   //    { title: 'Книги на русском языке', icon: icon4, num: 1683 },
   //    { title: 'Книги на английском языке', icon: icon4, num: 321 },
  ];

  return (
   <div className='page-main'>
    <div className='MainPageTemp'>
     <div className='MainPageTemp2'>
      {/* {mainPageCon.map((el, i) => {
       return (
        <div className='page-main-item' key={i}>
         <div>
          <p>{el.title}</p>
         </div>
         <img src={el.icon} alt='icon' />
         <div>
          <p>{el.num}</p>
         </div>
        </div>
       );
      })} */}
     </div>
     <div className='leftSide'>
      <div style={{ display: 'flex' }}>
       <div>
        {this.state.data.gender_id === 0 ? (
         <img src='https://smart-pvl.kz/avatar_m.png' alt='icon' className='user-info-image' />
        ) : (
         <img src='https://smart-pvl.kz/avatar_w.png' alt='icon' className='user-info-image' />
        )}
       </div>
       <div className='userName'>
        <div className='dataField'>{this.state.data.last_name}</div>
        <div className='dataField'>{this.state.data.first_name}</div>
        <div className='dataField'>{this.state.data.middle_name}</div>
        <div className='dataField'>{this.state.data.date_of_birth}</div>
       </div>
      </div>
      <div className='leftSideBottom' style={{ display: 'flex' }}>
       <div className='userName'>
        <div className='dataFieldBottom'>Памятка 1</div>
        <div className='dataFieldBottom'>Памятка 2</div>
        <div className='dataFieldBottom'>Памятка 3</div>
        <div className='dataFieldBottom'>Памятка 4</div>
       </div>
       <div className='userName'>
        <div className='dataFieldBottom'>Памятка 5 </div>
        <div className='dataFieldBottom'>Памятка 6</div>
        <div className='dataFieldBottom'>Памятка 7 </div>
        <div className='dataFieldBottom'>Памятка 8</div>
       </div>
      </div>
     </div>
     <div className='sideBar'>
      <div className='sideBarInner'>
       <div className='sideBarInnerHeader'>Последние действия</div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[0]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[0]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[1]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[1]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[2]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[2]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[3]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[3]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[4]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[4]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[5]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[5]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[6]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[6]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[7]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[7]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[8]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[8]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[9]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[9]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[10]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[10]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[11]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[11]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[12]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[12]?.action_text : 'Нет данных'}
       </div>
       <div className='sideBarInnerBody'>
        {this.state.data2 ? this.state.data2[13]?.log_timestamp : 'Нет данных'}---
        {this.state.data2 ? this.state.data2[13]?.action_text : 'Нет данных'}
       </div>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
