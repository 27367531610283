import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import '../sved_user/style.css';
import { getStatLibPeriodVisit, getStatLibPeriodBooks } from '../../../api';
import toast from 'react-hot-toast';
import Chart from '../stad_spad/chart';

class StadSpadCat extends Component {
 state = {
  arr_stat: [],

  open_2: false,

  date1: '',
  date2: '',
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 sortData = (arr) => {
  var fr = {};

  arr.forEach(function (value) {
   fr[value] = 0;
  });
  var uniq = arr.filter(function (value) {
   return ++fr[value] == 1;
  });

  return fr;
 };

 handleBooks = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getStatLibPeriodBooks.getStatLibPeriodBooks({
    start: this.state.date1,
    end: this.state.date2,
   });

   console.log(res);

   try {
    var FirstArr = [];
    res.map((data) => {
     var date = data.created_date.slice(0, 10);
     FirstArr.push(date);
    });

    const sorted = this.sortData(FirstArr);
    const sortedEntr = Object.entries(sorted);

    var SecondHalfArr = [];
    sortedEntr.map((data) => {
     var date = data[0];
     var num = data[1];
     var objMin = { x: date, y: num };
     SecondHalfArr.push(objMin);
    });
    const sortedByDate = SecondHalfArr.sort(function (a, b) {
     return new Date(b.x) - new Date(a.x);
    });
    console.log(sortedByDate);

    var SecondArr = [];

    sortedByDate.map((data) => {
     var date = new Date(data.x);
     var num = data.y;
     var objMin = { x: date, y: num };
     SecondArr.push(objMin);
    });

    var ThirdArr = { datums: SecondArr, label: 'Количество инвентарных номеров' };

    var ForthdArr = [];
    ForthdArr.push(ThirdArr);

    console.log(ForthdArr);
    this.setState({ arr_stat: ForthdArr, open_1: false, open_2: true });
   } catch {
    console.log('err');
   }
  } else {
   toast.error('Выберите период');
  }
 };

 render() {
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Формирование аналитической информации</h1>
     </div>
     <div>
      <SincetoCalendar
       selectDate={this.selectDate}
       state1={this.state.date1}
       state2={this.state.date2}
       handleDate={this.handleBooks}
       type='date'
      />
     </div>
     <div className='searchSved'>
      <div className='buttons-container'></div>
     </div>

     <div className='monitor' style={{ marginTop: '40px' }}>
      {this.state.open_2 && <Chart data={this.state.arr_stat} />}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(StadSpadCat);
