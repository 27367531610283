import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';
import './style.css';
import StaticSvedInfo from '../../../components/tables/StaticSvedInfo/StaticSvedInfo';
import { getStatLibPeriod } from '../../../api';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import toast from 'react-hot-toast';

class StaticSved extends Component {
 state = {
  date1: '',
  date2: '',
  svedStaticArr: false,
 };

 async componentDidMount() {
  //   const res = await getStatLibPeriod.getStatLibPeriod({
  //    id_sigl: localStorage.lib_ID,
  //    start: this.state.date1 || '2020-01',
  //    end: this.state.date2 || '2030-01',
  //    lib_name: localStorage.lib_name,
  //   });
  //   this.setState({ svedStaticArr: res });
 }

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getStatLibPeriod.getStatLibPeriod({
    start: this.state.date1,
    end: this.state.date2,
   });

   this.setState({ svedStaticArr: res });
  } else {
   toast.error('Выберите период');
  }
 };
 render() {
  const url = this.props.match.path.slice(1);
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      {url === 'stat_komplekt' ? (
       <h1>Формирование отчетно-статистических данных</h1>
      ) : (
       <h1>Статистические сведения по состоянию, движению и использованию фонда библиотеки</h1>
      )}
     </div>
     <SincetoCalendar
      selectDate={this.selectDate}
      state1={this.state.date1}
      state2={this.state.date2}
      handleDate={this.handleDate}
      type='date'
     />

     {this.state.svedStaticArr ? (
      <div className='monitor static-tables'>
       <StaticSvedInfo
        title={'Количество фонда (БО)'}
        static_sved={this.state.svedStaticArr.BO}
        types={this.state.svedStaticArr.BO_types}
        langs={this.state.svedStaticArr.BO_langs}
       />
       <StaticSvedInfo
        title={'Количество фонда (ИН)'}
        static_sved={this.state.svedStaticArr.InvN}
        types={this.state.svedStaticArr.InvN_types}
        langs={this.state.svedStaticArr.InvN_langs}
       />

       <StaticSvedInfo
        title={'Выбыло'}
        static_sved={this.state.svedStaticArr.droped}
        types={this.state.svedStaticArr.droped_types}
        langs={this.state.svedStaticArr.droped_langs}
       />
       <StaticSvedInfo
        title={'Поступило'}
        static_sved={this.state.svedStaticArr.InvN_by_date}
        types={this.state.svedStaticArr.InvN_by_date_types}
        langs={this.state.svedStaticArr.InvN_by_date_langs}
       />
       <StaticSvedInfo
        title={'Выдано'}
        static_sved={this.state.svedStaticArr.history}
        types={this.state.svedStaticArr.history_types}
        langs={this.state.svedStaticArr.history_langs}
       />
      </div>
     ) : (
      <div className='page-win'>...</div>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(StaticSved);
