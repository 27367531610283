import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import '../sved_user/style.css';
import { getGeneralByGenre, getGeneralByPopular } from '../../../api';
import toast from 'react-hot-toast';

class StaticRead2 extends Component {
 state = {
  arr_stat: [],

  open_1: false,
  open_2: false,
  open_2: false,
  open_2: false,

  date1: '',
  date2: '',
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  if (this.state.date1 && this.state.date2) {
   toast.success('Можете выбрать тип отчета');
  } else {
   toast.error('Некорректно выбранный период');
  }
 };

 handleGenre = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByGenre.getGeneralByGenre({
    start: this.state.date1,
    end: this.state.date2,
   });
   this.setState({ arr_stat: res, open_1: false, open_2: false, open_3: true, open_4: false });
  } else {
   toast.error('Выберите период');
  }
 };

 handlePop = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getGeneralByPopular.getGeneralByPopular({
    start: this.state.date1,
    end: this.state.date2,
   });
   this.setState({ arr_stat: res, open_1: false, open_2: false, open_3: false, open_4: true });
  } else {
   toast.error('Выберите период');
  }
 };

 render() {
  const headerItemsContent_3 = [
   { title: 'Жанр', data: 'title' },
   { title: 'Количество', data: 'by_t_number' },
  ];

  const headerItemsContent_4 = [
   { title: 'Заглавие', data: 'title' },
   { title: 'Количество', data: 'by_t_number' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1> Отчетно-статистические данные</h1>
     </div>
     <div>
      <SincetoCalendar
       selectDate={this.selectDate}
       state1={this.state.date1}
       state2={this.state.date2}
       handleDate={this.handleDate}
       type='date'
      />
     </div>
     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Отчет по жанрам литературы' className='btn_library_del' onClick={this.handleGenre} />
       <Button text='Отчет по популярности литературы' className='btn_library_del' onClick={this.handlePop} />
      </div>
     </div>

     <div className='monitor' style={{ marginTop: '40px' }}>
      {this.state.open_3 && <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_3} />}
      {this.state.open_4 && <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_4} />}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(StaticRead2);
