import Modal from '../modal';
import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import debounce from '../../utils/debounce';
import { getInventByType2, editInventNumStatus, editBookedBookStatus } from '../../api';
import toast from 'react-hot-toast';
import commonTryCatch from '../../helpers/commonTryCatch';
import moment from 'moment';

const ModalForExtradition = ({ show, closeModalProp, book, getDataBroned }) => {
 const [inv_number_bron, set_inv_number_bron] = useState('');
 const [inv_number_bron_id, set_inv_number_bron_id] = useState('');
 const [daysType, set_daysType] = useState('');

 const [InventNumbersBookBronArray, set_InventNumbersBookBronArray] = useState([]);

 const filterValues22 = async (e) => {
  await set_inv_number_bron(e.target.value);

  if (e.target.value !== '') {
   const InventNumbersBookBronArrayTemp = await getInventByType2.getInventByType2({
    invent_number: e.target.value,
    book_id: book.book_id,
   });
   await set_InventNumbersBookBronArray(InventNumbersBookBronArrayTemp);
  } else {
   await set_InventNumbersBookBronArray([]);
  }
 };

 const closeModal = async (e) => {
  if (e === 'modal') {
   closeModalProp();
   await set_InventNumbersBookBronArray([]);
   await set_inv_number_bron('');
   await set_inv_number_bron_id('');
  }
 };

 const handleChange = (e) => {
  set_daysType(e.target.value);
 };

 const SendBron = async () => {
  const day = 1000 * 60 * 60 * 24;

  const date1 = daysType;
  const date2 = moment().format('YYYY-MM-DD');
  const date3 = new Date(date1);
  const date4 = new Date(date2);

  const difference = date3 - date4;
  const dayNumber = difference / day;

  const data = {
   date_vydachi: date2,
   date_sdachi: date1,
   day_number: dayNumber,
   id_customers: book.reader_id,
   id_invent_number: inv_number_bron_id,
   id_book: book.book_id,
  };

  if (dayNumber > 0) {
   if (inv_number_bron_id) {
    const res = await commonTryCatch(
     'https://smart-pvl.kz/Api/api/books_history/add.php',
     'POST',
     JSON.stringify(data)
    );
    if (res) {
     closeModal('modal');
     toast.success('Книга успешно выдана!');
     getDataBroned();
    } else {
     closeModal('modal');
     toast.error('Возникла ошибка');
    }
   } else {
    toast.error('Укажите инвентарный номер');
   }
  } else {
   toast.error('Некорректно указаны даты!');
  }
 };

 const handleSelect = async (data) => {
  await set_InventNumbersBookBronArray([]);
  await set_inv_number_bron(data.inventory_number);
  await set_inv_number_bron_id(data.id);
 };

 return (
  <Modal
   show={show}
   handleClose={SendBron}
   // data={this.state}
   btn='Выдать'
   classBtn='btn-close'
   closeModalEvent={closeModal}
  >
   <div className='modal-content'>
    <div className='modal-title-icon' style={{ flexDirection: 'column' }}>
     <h1>{book.title}</h1>
     <h1>Выдача читателю {book.reader}</h1>
     {/* <img className='modal-close-icon' onClick={this.closeModal} alt='close' src={close} /> */}
    </div>
    <form
     // onSubmit={(e) => e.preventDefault()}
     className='product-modal-inner'
     style={{ display: 'flex', flexDirection: 'column' }}
    >
     <div className='search-filter-book-item' style={{ width: '100%', maxWidth: '100%' }}>
      <div className='search-filter-book-item'>
       <p>День возврата</p>
       <input
        type='date'
        autoComplete='off'
        name='days'
        value={daysType}
        placeholder='Обязательные поля ввода'
        onChange={handleChange}
       />
      </div>
      <p>Инвентарный номер : {inv_number_bron}</p>
      <input
       type='number'
       autoComplete='off'
       name='inv_number_bron'
       id='inv_number_bron'
       style={{ width: '100%' }}
       //  value={}
       placeholder='Отсканируйте штрих-код'
       onChange={debounce(filterValues22)}
      />
      <div className='searchReader'>
       {InventNumbersBookBronArray
        ? InventNumbersBookBronArray.map((el, i) => {
           return (
            <div>
             <p key={i} onClick={() => handleSelect(el)}>
              {el.inventory_number}
             </p>
            </div>
           );
          })
        : []}
      </div>
     </div>
    </form>
   </div>
  </Modal>
 );
};

export default ModalForExtradition;
