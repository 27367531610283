import React, { Component, useRef } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../redux/action';
import {
  addInventNumber,
  delBookApi,
  delInventNumber,
  getInventNumberBook,
  eventCustomer,
  getInventByType,
  getInventByType2,
  getInventByType3,
  returnBook,
} from '../../api';

import ReactToPrint from 'react-to-print';
import debounce from '../../utils/debounce';

import Modal from '../../components/modal';
import Button from '../../components/button';
import Alert from '../../components/alert';
import history from '../../components/history';
import BarCode from '../../components/bar-code';
import ReitStar from '../../components/reiting-star';
import BookCard from '../../components/book-card';

import './BookItem.css';
import toast from 'react-hot-toast';
import moment from 'moment';

import close from '../../assets/icon/modal-genre-close.png';
// import startY from '../../assets/icon/star-y.png'
import startG from '../../assets/icon/star-g.png';
import BookItemLoader from './BookItemLoader.js';

class BookItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.prinShtrixInvNumber = React.createRef();
  }

  state = {
    showModalGive: false,
    showModalAlert: false,
    showAlertSpisan: false,
    showAlertSigla: false,
    showAlertInvNumber: false,
    showAlertCatCardInvNumber: false,
    typeBtnFromAlert: '',

    inputSpisAlert: '',
    spisElem: '',

    typeAlert: null,
    book: null,

    giveItem: {
      kol_day: '',
      id_customers: '',
      id_invent_number: '',
    },
    return_book: {
      bh_id: '',
      lb_id: '',
    },
    SpisanieItem: {
      act: '',
      id_invent_number: '',
    },
    btn: 'Выдать',
    btnAlert: 'Закрыть',
    days: '',
    inv_number_vydacha: '',
    inv_number_spis: '',
    choiceUser: '',

    readerInput: '',
    userBookOrder: false,
    InventNumbersBookOrder: false,
    moreInfo: false,

    loadPage: true,

    siglLibrary: localStorage.getItem('library_id'),

    changeSigla: '',

    inv_number: '',

    inv_number_book: [],

    status_vyd: 0,

    reload_book_item: [],

    inv_num_sigl: '',

    clicked_inv_num: '',
    clicked_inv_num_barcode: '',
  };

  showModal = async (data, text) => {
    await this.setState({
      showModalGive: true,
      giveItem: data,
      typeBtnFromAlert: text,
      typeAlert: 'выдать',
      btn: 'Выдать',
    });
    document.querySelector('#inv_number_vydacha').focus();
  };

  closeModal = () =>
    // this.state.typeAlert === 'Книжный формуляр'
    //  ? this.addIvnNumber()
    //  :
    this.setState({
      showModalGive: false,
      userBookOrder: false,
      InventNumbersBookOrder: false,
      readerInput: '',
      showAlertSpisan: false,
      inputSpisAlert: '',
      showAlertSigla: false,
      showAlertInvNumber: false,
      showModalAlert: false,
      showAlertCatCardInvNumber: false,
      days: '',
      inv_number_vydacha: '',
    });

  closeModalEvent = (e) => {
    if (e === 'modal') this.closeModal();
  };

  handleChange = async ({ target }) => {
    if (target.name == 'inputSpisAlert') {
      await this.setState({
        SpisanieItem: {
          ...this.state.SpisanieItem,
          act: target.value,
        },
      });
    } else {
      await this.setState({ [target.name]: target.value });
    }
  };

  hideModal = async () => {
    const day = 1000 * 60 * 60 * 24;

    const date1 = this.state.days;
    const date2 = moment().format('YYYY-MM-DD');
    const date3 = new Date(date1);
    const date4 = new Date(date2);

    const difference = date3 - date4;
    const dayNumber = difference / day;

    const data = {
      ...this.state.giveItem,
      date_vydachi: date2,
      date_sdachi: date1,
      day_number: dayNumber,
    };

    if (this.state.days) {
      if (this.state.giveItem.id_customers) {
        if (this.state.giveItem.id_invent_number) {
          if (dayNumber > 0) {
            await this.props.giveBook(data);
            this.closeModal();
            toast.success('Книга успешно выдана!');
          } else {
            toast.error('Некорректно указаны даты!');
          }
        } else {
          toast.error('Необходимо указать инв. номер!');
        }
      } else {
        toast.error('Необходимо указать читателя!');
      }
    } else {
      toast.error('Необходимо указать дату возврата!');
    }

    // (await this.props.successBook) && this.setState({ showModalGive: false });
    // await history.push('/book_out');
  };
  //Alert при выдачи и возврате книги
  hideModalAlert = async () => {
    if (this.state.typeAlert === 'вернуть') {
      const returnBooks = await returnBook.returnBook(
        { id_invent_number: this.state.return_book.lb_id, id: this.state.return_book.bh_id },
        'search',
      );
      console.log(returnBooks);
      await this.setState({ showModalAlert: false });
      //  await history.push('/book_out');
      toast.success('Книга успешно возвращена!');
    } else {
      if (this.props.successBook) {
        this.setState({ showModalAlert: true });
      }
      await this.setState({ showModalAlert: false });
      //  history.push('/book_out');
    }
  };

  //confirm при возврате книги
  confirmReturnBook = async (book, text) =>
    this.setState({ showModalAlert: true, typeBtnFromAlert: text, typeAlert: 'вернуть', book });

  //отмена confirm
  cancelReturnBook = async () => this.setState({ showModalAlert: false });

  filterValues = async (e) => {
    await this.setState({ readerInput: e.target.value });

    const userBookOrder = await eventCustomer.eventCustomer({ value: e.target.value }, 'search');
    await this.setState({ userBookOrder });
  };

  // списание
  filterValues2 = async (e) => {
    await this.setState({ inv_number_vydacha: e.target.value });

    if (e.target.value !== '') {
      const InventNumbersBookOrder = await getInventByType.getInventByType({
        invent_number: e.target.value,
        book_id: this.props.bookItem.id,
      });

      await this.setState({ InventNumbersBookOrder });
    } else {
      await this.setState({ InventNumbersBookOrder: false });
    }
  };

  // выдача
  filterValues22 = async (e) => {
    await this.setState({ inv_number_vydacha: e.target.value });

    if (e.target.value !== '') {
      const InventNumbersBookOrder = await getInventByType2.getInventByType2({
        invent_number: e.target.value,
        book_id: this.props.bookItem.id,
      });
      await this.setState({ InventNumbersBookOrder });
    } else {
      await this.setState({ InventNumbersBookOrder: false });
    }
  };

  // возвращение
  filterValues23 = async (e) => {
    await this.setState({ inv_number_vydacha: e.target.value });
    if (e.target.value !== '') {
      const InventNumbersBookOrder = await getInventByType3.getInventByType3({
        invent_number: e.target.value,
        book_id: this.props.bookItem.id,
      });

      await this.setState({ InventNumbersBookOrder });
    } else {
      await this.setState({ InventNumbersBookOrder: false });
    }
  };
  //  searchReader = async ({ target }) => {
  //   await this.setState({ readerInput: target.value });
  //   if (this.state.readerInput.length >= 2) {
  //    const res = await fetch('https://smart-pvl.kz/Api/SmartSreachUser.php', {
  //     method: 'POST',
  //     headers: {
  //      AUTH: localStorage.getItem('tokenLibrary'),
  //     },
  //     body: JSON.stringify({
  //      col: '*',
  //      tab: 'users',
  //      searchUser: true,
  //      id_val: this.state.readerInput,
  //      sreachUser: true,
  //     }),
  //    });
  //    const json = await res.json();
  //    const userBookOrder = await json.slice(0, json.length - 2);
  //    await this.setState({ userBookOrder });
  //   }
  //   if (this.state.readerInput.length === 0) {
  //    await this.setState({ userBookOrder: [] });
  //   }
  //  };

  findInMassive1 = (e) => {
    //   console.log(e);

    let res = this.props.LanguageSelectData.find((i) => i.id == e);
    typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.name);
    //   console.log(res);
    return res;
  };

  //для поиска названия по айди
  findInMassive2 = (e) => {
    let res = this.props.UDKSelectsByGeneral.find((i) => i.id == e);
    typeof res == 'undefined' ? this.findInMassive3(e) : (res = res.name_ru);
    return res;
  };

  findInMassive3 = (e) => {
    let res = this.props.UDKSelectsByKazakstanika.find((i) => i.id == e);
    typeof res == 'undefined' ? this.findInMassive4(e) : (res = res.name_ru);
    return res;
  };

  findInMassive4 = (e) => {
    let res = this.props.UDKSelectsByKraeved.find((i) => i.id == e);
    typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.name_ru);
    return res;
  };

  //для поиска индекса по айди
  findInMassive22 = (e) => {
    let res = this.props.UDKSelectsByGeneral.find((i) => i.id == e);
    typeof res == 'undefined' ? this.findInMassive3(e) : (res = res.index_udk_bbk);
    return res;
  };

  findInMassive33 = (e) => {
    let res = this.props.UDKSelectsByKazakstanika.find((i) => i.id == e);
    typeof res == 'undefined' ? this.findInMassive4(e) : (res = res.index_udk_bbk);
    return res;
  };

  findInMassive44 = (e) => {
    let res = this.props.UDKSelectsByKraeved.find((i) => i.id == e);
    typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.index_udk_bbk);
    return res;
  };

  findInMassive12 = (e) => {
    let res = this.props.VidDokSelectData.find((i) => i.id == e);
    typeof res == 'undefined' ? (res = 'Не корректно введенное поле') : (res = res.name_ru);
    return res;
  };

  //Печать формуляра
  setComponentRefForm = (ref) => {
    this.componentRefForm = ref;
  };
  reactToPrintContentForm = () => {
    return this.componentRefForm;
  };
  //Печать штрих-кода
  setComponentRef = (ref) => {
    this.getInveNumber();
    this.componentRef = ref;
  };
  reactToPrintContent = () => {
    return this.componentRef;
  };
  //Печать каталожной карточки
  setComponentRefCard = (ref) => {
    this.componentRefCard = ref;
  };
  reactToPrintContentCard = () => {
    return this.componentRefCard;
  };

  //Списание книги
  delBookAlert = async (id) => {
    await this.setState({
      typeAlert: 'списать',
      showAlertSpisan: true,
      btn: 'Cписать',
      spisElem: id,
    });
    await document.querySelector('#inv_number_spis').focus();
  };

  //Получение инвентарных номеров
  getInveNumber = async () => {
    const obj = {
      book_id: this.props.bookItem.id,
      // sigl: localStorage.getItem('lib_ID'),
      library_id: this.state.inv_num_sigl,
    };
    if (this.state.inv_num_sigl) {
      const invNumber = getInventNumberBook.getInventNumberBook(obj);
      invNumber.then((res) => {
        this.setState({ inv_number_book: res });
      });
    } else {
      this.setState({ inv_number_book: [] });
    }
  };

  getInveNumberByLib = async (lib) => {
    const obj = {
      book_id: this.props.bookItem.id,
      // sigl: localStorage.getItem('lib_ID'),
      library_id: lib,
    };

    const invNumber = getInventNumberBook.getInventNumberBook(obj);
    invNumber.then((res) => {
      this.setState({ inv_number_book: res });
    });
  };

  addIvnNumber = async (id) => {
    this.getInveNumberByLib(id);
    this.setState({
      typeAlert: 'Добавить инвентарный номер',
      showAlertInvNumber: true,
      btn: 'Добавить',
      spisElem: id,
    });
  };

  handleChangeLib = async (id) => {
    console.log(id);
    this.setState({ inv_num_sigl: id });
    this.getInveNumberByLib(id);
  };
  // Добавление инвентарного номера
  addInv = async () => {
    if (this.state.inv_num_sigl) {
      if (this.state.inv_number.length) {
        const obj = {
          book: this.props.bookItem.id,
          sigl: this.state.inv_num_sigl,
          invent_num: this.state.inv_number,
          status_vyd: this.state.status_vyd,
        };
        console.log(obj);
        const add = addInventNumber.addInventNumber(obj);
        add.then((res) => {
          this.getInveNumber();
          this.setState({ inv_number: '', inv_num_sigl: '' });
          document.querySelector('#input_add_inv_number').focus();
          document.querySelector('.select_alert_inv_number').selectedIndex = 0;
        });

        toast.success('Инвентарный номер успешно добавлен!');
      } else {
        document.querySelector('#input_add_inv_number').focus();
        toast.error('Необходимо указать инв. номер!');
      }
    } else {
      document.querySelector('.select_alert_inv_number').focus();
      toast.error('Необходимо указать библиотеку!');
    }
  };
  // Удаление инвентарного номера
  delInvNumberBook = async (id, id_sigl) => {
    const obj = {
      id: id,
      id_sigl: id_sigl,
    };
    const res = delInventNumber.delInventNumber(obj);
    res.then((res) => {
      this.getInveNumber();
    });

    toast.success('Инвентарный номер успешно удален!');
  };

  delBook = async () => {
    const obj = {
      id_invent_number: this.state.SpisanieItem.id_invent_number,
      act: this.state.SpisanieItem.act,
    };

    await delBookApi.delBookApi(obj);
    this.setState({
      typeAlert: '',
      showAlertSpisan: false,
      btn: '',
      spisElem: '',
      inputSpisAlert: '',
    });

    toast.success('Книга успешно списана!');
  };

  prompt_modal = () => {
    document.querySelector('.modal_prompt').style.display = 'block';
  };
  prompt_modal_close = () => {
    document.querySelector('.modal_prompt').style.display = 'none';
  };

  //Показать каталожную карточку по инвентарному номеру
  getCatalogCardForInvNumber = async (id, invent_num, barcode) => {
    this.setState({
      typeAlert: 'Книжный формуляр',
      showAlertCatCardInvNumber: true,
      clicked_inv_num: invent_num,
      clicked_inv_num_barcode: barcode,
    });
  };

  async componentDidMount() {
    window.scrollBy(0, 0);

    // const resProps = await [];
    // if (this.props.bookItem.length) {
    //  this.props.bookItem.map((el) => {
    //   for (const key in el) resProps.push({ [key]: el[key] });
    //  });
    //  let res = [];
    //  const collum = Math.ceil(resProps.length / 2);
    //  while (resProps.length > 0) {
    //   const chunk = await resProps.splice(0, collum);
    //   await res.push(chunk);
    //  }

    //  await this.setState({ data: res });
    //  return res;
    // } else {
    //  history.push('/book_add');
    // }
  }

  render() {
    console.log(this.props.bookItem);

    setTimeout(() => {
      this.setState({ loadPage: false });
    }, 500);

    return (
      <>
        {!this.state.loadPage ? (
          <div className='page-main'>
            <Modal
              show={this.state.showModalGive}
              handleClose={this.hideModal}
              data={this.state}
              btn={this.state.btn}
              classBtn='btn-close'
              closeModalEvent={this.closeModalEvent}
            >
              <div className='modal-content'>
                <div className='modal-title-icon'>
                  <h1>Информация о выдаче книги</h1>
                  <img
                    className='modal-close-icon'
                    onClick={this.closeModal}
                    alt='close'
                    src={close}
                  />
                </div>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className='product-modal-inner'
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div style={{ display: 'flex' }}>
                    <div className='search-filter-book-item'>
                      <p>День возврата</p>
                      <input
                        type='date'
                        autoComplete='off'
                        name='days'
                        value={this.state.days}
                        placeholder='Обязательные поля ввода'
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='search-filter-book-item'>
                      <p>Читатель: {this.state.readerInput}</p>
                      <input
                        type='text'
                        name='reader'
                        autoComplete='off'
                        placeholder='Введите фамилию читателя'
                        // value={this.state.readerInput}
                        onChange={debounce(this.filterValues)}
                      />
                      <div className='searchReader'>
                        {this.state.userBookOrder
                          ? this.state.userBookOrder.map((el, i) => {
                              return (
                                <div>
                                  <p
                                    key={i}
                                    onClick={() =>
                                      this.setState({
                                        userBookOrder: [],
                                        readerInput: el.FIO,
                                        giveItem: {
                                          ...this.state.giveItem,
                                          id_customers: el.id,
                                        },
                                      })
                                    }
                                  >
                                    {el.FIO}
                                  </p>
                                </div>
                              );
                            })
                          : []}
                      </div>
                      {/* {this.state.readerInput.length >= 3 && this.state.userBookOrder.length > 5 ? <p>.....</p> : null} */}
                    </div>
                  </div>
                  <div
                    className='search-filter-book-item'
                    style={{ width: '100%', maxWidth: '100%' }}
                  >
                    <p>Инвентарный номер : {this.state.inv_number_vydacha}</p>
                    <input
                      type='number'
                      autoComplete='off'
                      name='inv_number_vydacha'
                      id='inv_number_vydacha'
                      style={{ width: '100%' }}
                      //  value={this.state.}
                      placeholder='Отсканируйте штрих-код'
                      onChange={debounce(this.filterValues22)}
                    />
                    <div className='searchReader'>
                      {this.state.InventNumbersBookOrder
                        ? this.state.InventNumbersBookOrder.map((el, i) => {
                            return (
                              <div>
                                <p
                                  key={i}
                                  onClick={() =>
                                    this.setState({
                                      InventNumbersBookOrder: [],
                                      inv_number_vydacha: el.inventory_number,
                                      giveItem: {
                                        ...this.state.giveItem,
                                        id_invent_number: el.id,
                                      },
                                    })
                                  }
                                >
                                  {el.inventory_number}
                                </p>
                              </div>
                            );
                          })
                        : []}
                    </div>
                  </div>
                </form>
              </div>
            </Modal>

            {this.state.typeAlert === 'выдать' ? (
              <Alert
                show={this.state.showModalAlert}
                handleClose={this.hideModalAlert}
                data={this.state}
                btn={this.state.btn}
                classBtn='btn-close'
              >
                <div className='modal-content'>
                  <div className='alert-title-icon'>
                    <h1>{this.state.typeBtnFromAlert}</h1>
                  </div>
                </div>
              </Alert>
            ) : (
              <Alert
                show={this.state.showModalAlert}
                handleClose={this.hideModalAlert}
                data={this.state}
                btn='Вернуть'
                btnTwo='Отменить'
                cancelClose={this.cancelReturnBook}
                classBtn='btn-close'
              >
                <div className='modal-content'>
                  <div className='modal-title-icon'>
                    <h1>Возвращение книги</h1>
                    <img
                      className='modal-close-icon'
                      onClick={this.closeModal}
                      alt='close'
                      src={close}
                    />
                  </div>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    className='product-modal-inner'
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div style={{ display: 'flex' }}></div>
                    <div
                      className='search-filter-book-item'
                      style={{ width: '100%', maxWidth: '100%' }}
                    >
                      <p>Инвентарный номер : {this.state.inv_number_vydacha}</p>
                      <input
                        type='number'
                        autoComplete='off'
                        name='inv_number_vydacha'
                        id='inv_number_vydacha'
                        style={{ width: '100%' }}
                        //  value={this.state.}
                        placeholder='Отсканируйте штрих-код'
                        onChange={debounce(this.filterValues23)}
                      />
                      <div className='searchReader'>
                        {this.state.InventNumbersBookOrder
                          ? this.state.InventNumbersBookOrder.map((el, i) => {
                              return (
                                <div>
                                  <p
                                    key={i}
                                    onClick={() =>
                                      this.setState({
                                        InventNumbersBookOrder: [],
                                        inv_number_vydacha: el.inventory_number,
                                        return_book: {
                                          ...this.state.return_book,
                                          bh_id: el.bh_id,
                                          lb_id: el.lb_id,
                                        },
                                      })
                                    }
                                  >
                                    {el.inventory_number}
                                  </p>
                                </div>
                              );
                            })
                          : []}
                      </div>
                    </div>
                  </form>
                </div>
              </Alert>
            )}

            {this.state.typeAlert === 'Добавить инвентарный номер' && (
              <Alert
                show={this.state.showAlertInvNumber}
                handleClose={this.addInv}
                data={this.state}
                btn={this.state.btn}
                classBtn='btn-close'
                closeModalEvent={this.closeModalEvent}
                btnTwo='Закрыть'
                cancelClose={this.closeModal}
              >
                <div className='modal-content'>
                  <div className='alert-title-icon'>
                    <div className='alert-spisan'>
                      <h1>Инвентарные номера</h1>
                      <div className='wrap_input_inv_number'>
                        <span className='input_inv_number_marker'>&#9998;</span>
                        <input
                          type='text'
                          className=''
                          id='input_add_inv_number'
                          value={this.state.inv_number}
                          placeholder='Введите инвентарный номер'
                          onChange={(e) => this.setState({ inv_number: e.target.value })}
                        />
                        <label>
                          <input
                            type={'checkbox'}
                            onChange={(e) =>
                              this.setState({ status_vyd: e.target.checked ? 1 : 0 })
                            }
                            style={{ marginRight: '5px', marginLeft: '10px' }}
                          />
                          Выдаваемая
                        </label>
                      </div>
                      <select
                        className='select_alert_inv_number'
                        onChange={(e) => {
                          this.handleChangeLib(e.target.value);
                        }}
                      >
                        <option hidden>
                          {this.state.inv_num_sigl == ''
                            ? 'Выберите сиглу перед тем как добавить инвентарный номер'
                            : this.props.SiglsDate.find((i) => i.id === this.state.inv_num_sigl)
                                ?.name}
                        </option>
                        {this.props.SiglsDate.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='inv_number_title'>
                    <h3>Инвентарные номера:</h3>
                    <div onMouseMove={this.prompt_modal} onMouseLeave={this.prompt_modal_close}>
                      ❓
                    </div>
                    <div className={'modal_prompt '}>
                      <div>
                        <span style={{ color: 'yellow' }}>Желтый - забронирована</span>
                      </div>
                      <div>
                        <span style={{ color: 'green' }}>Зеленый - выдана</span>
                      </div>
                      <div>
                        <span style={{ color: 'black' }}>Черный - свободна</span>
                      </div>
                      <div>
                        <span style={{ color: 'red' }}>Красный - невыдаваемая</span>
                      </div>
                      <br />
                      <div>При нажатии на ❌ инвентарный номер удалиться.</div>
                      <div>
                        При нажатии на сам инвентарный номер, откроется книжный формуляр и его
                        штрих-код
                      </div>
                    </div>
                  </div>
                  <div className='inv_number_wrapper'>
                    {this.state.inv_number_book.length
                      ? this.state.inv_number_book.map((item, i) => {
                          return (
                            <div>
                              <div
                                className='item_inv_number'
                                key={i}
                                onClick={(e) =>
                                  this.getCatalogCardForInvNumber(
                                    item.id,
                                    item.inventory_number,
                                    item.barcode,
                                  )
                                }
                                style={
                                  item.status == '0'
                                    ? { color: 'red' }
                                    : item.status == '1'
                                    ? { color: 'black' }
                                    : item.status == '2'
                                    ? { color: 'yellow' }
                                    : { color: 'green' }
                                }
                              >
                                {item.inventory_number}
                              </div>
                              <span
                                onClick={(e) => this.delInvNumberBook(item.id, item.id_sigl, e)}
                              >
                                ❌
                              </span>
                            </div>
                          );
                        })
                      : 'Ваша библиотека еще не добавила инвентарные номера для данной книги'}
                  </div>
                </div>
              </Alert>
            )}

            {this.state.typeAlert === 'Книжный формуляр' && (
              <Alert
                show={this.state.showAlertCatCardInvNumber}
                handleClose={this.addInv}
                data={this.state}
                classBtn='book_formular_btn_close'
                closeModalEvent={this.closeModalEvent}
                btnTwo='Закрыть'
                cancelClose={this.closeModal}
              >
                <div className='modal-content'>
                  <div>
                    <div className='alert-spisan'>
                      <h1>Книжный формуляр</h1>
                    </div>
                    <div
                      ref={this.setComponentRefForm}
                      style={{ width: '246px', height: '398px', margin: 'auto' }}
                    >
                      <div className='alert-title-icon'></div>
                      <div></div>
                      <div>{this.findInMassive1(this.props.bookItem.language)}</div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{this.findInMassive22(this.props.bookItem.classifier)}</div>
                        <div>{this.state.clicked_inv_num}</div>
                      </div>
                      <div>{this.props.bookItem?.author_sign}</div>
                      <div>{this.props.bookItem?.title}</div>
                      <div>
                        {this.props.bookItem?.place_of_publication +
                          '. ' +
                          this.props.bookItem?.publication_year +
                          ' г.'}
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>
                          {this.props.bookItem?.binding == 0 ? '(мягк. пер.): ' : '(тверд. пер.): '}
                        </div>
                        <div>{this.props.bookItem?.price + ' тнг.'}</div>
                      </div>
                      <div
                        style={{ width: '100%', height: '30px', borderBottom: '1px solid' }}
                      ></div>
                      <div
                        style={{ width: '100%', height: '30px', borderBottom: '1px solid' }}
                      ></div>
                      <div
                        style={{ width: '100%', height: '30px', borderBottom: '1px solid' }}
                      ></div>
                      <div
                        style={{ width: '100%', height: '30px', borderBottom: '1px solid' }}
                      ></div>
                      <div
                        style={{ width: '100%', height: '30px', borderBottom: '1px solid' }}
                      ></div>
                      <div
                        style={{ width: '100%', height: '30px', borderBottom: '1px solid' }}
                      ></div>
                      <div
                        style={{ width: '100%', height: '30px', borderBottom: '1px solid' }}
                      ></div>
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                      <ReactToPrint
                        trigger={() => <a href='#'>Распечатать формуляр</a>} // здесь добавляется ссылка, при нажатии компонент уходит на печать
                        content={this.reactToPrintContentForm}
                        documentTitle='AwesomeFileName'
                        removeAfterPrint
                      />
                    </div>
                    <div className='alert-title-icon'>
                      <div style={{ textAlign: 'center' }}>
                        <div className='alert-spisan'>
                          <h1>Штрих-код</h1>
                        </div>
                        <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                          <div
                          //  className='bar_code_bookitem'
                          >
                            <BarCode
                              barcode={this.state.clicked_inv_num_barcode}
                              inv_number={this.state.clicked_inv_num}
                              ref={this.setComponentRef}
                            />
                          </div>
                          <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                            <ReactToPrint
                              trigger={() => <a href='#'>Распечатать штрих-код</a>} // здесь добавляется ссылка, при нажатии компонент уходит на печать
                              content={this.reactToPrintContent}
                              documentTitle='AwesomeFileName'
                              removeAfterPrint
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Alert>
            )}
            {this.state.typeAlert === 'списать' && (
              <Alert
                show={this.state.showAlertSpisan}
                handleClose={this.delBook}
                data={this.state}
                btn={this.state.btn}
                classBtn='btn-close'
                closeModalEvent={this.closeModalEvent}
                btnTwo='Отменить'
                cancelClose={this.closeModal}
              >
                <div className='modal-content'>
                  <div className='alert-title-icon'>
                    <div className='alert-spisan'>
                      <h1>Списание книги</h1>
                      <input
                        type='text'
                        onChange={this.handleChange}
                        name='inputSpisAlert'
                        placeholder='Введите номер Акта списания'
                      />
                      <div
                        className='search-filter-book-item'
                        style={{ width: '100%', maxWidth: '100%' }}
                      >
                        <p>Инвентарный номер : {this.state.inv_number_spis}</p>
                        <input
                          type='number'
                          autoComplete='off'
                          name='inv_number_spis'
                          id='inv_number_spis'
                          style={{ width: '100%' }}
                          //  value={this.state.}
                          placeholder='Отсканируйте штрих-код'
                          onChange={debounce(this.filterValues2)}
                        />
                        <div className='searchReader'>
                          {this.state.InventNumbersBookOrder
                            ? this.state.InventNumbersBookOrder.map((el, i) => {
                                return (
                                  <div>
                                    <p
                                      key={i}
                                      onClick={() =>
                                        this.setState({
                                          InventNumbersBookOrder: [],
                                          inv_number_spis: el.inventory_number,
                                          SpisanieItem: {
                                            ...this.state.SpisanieItem,
                                            id_invent_number: el.id,
                                          },
                                        })
                                      }
                                    >
                                      {el.inventory_number}
                                    </p>
                                  </div>
                                );
                              })
                            : []}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Alert>
            )}

            <div className='page-out-wrapper'>
              {/* {this.props.bookItem.map((el) => {
        let objStatus = { style: '', text: '' };

        if (el.busy === '0') {
         objStatus.style = 'status-book free';
         objStatus.text = 'В НАЛИЧИИ';
        } else if (el.busy === '1') {
         objStatus.style = 'status-book order';
         objStatus.text = 'ЗАКАЗ';
        } else if (el.busy === '2') {
         objStatus.style = 'status-book hand';
         objStatus.text = 'НА РУКАХ';
        }
        return ( */}
              <div className='base-books-wrapper' key={this.props.bookItem.part_name}>
                <div className='book-info'>
                  <div className='book-info-left'>
                    <img
                      src={
                        this.props.bookItem.cover_url
                          ? 'https://smart-pvl.kz/covers/' + this.props.bookItem.cover_url
                          : 'https://smart-pvl.kz/covers/defpic.jpg'
                      }
                      alt='book'
                      className='book-info-image'
                    ></img>
                    {/* <button onClick={() => this.showModal(el, 'edit')}>Редактировать</button> */}
                  </div>

                  <div className='book-info-right-book'>
                    <div className='base-books-title'>
                      <div className='title-btn'>
                        <div>
                          <h1>{this.props.bookItem.title} </h1>
                          {/* <p>{el.avtor}</p> */}
                        </div>
                        <div style={{ display: 'inline-grid' }}>
                          <Button
                            onClick={() => this.delBookAlert(this.props.bookItem.id)}
                            text={'Списать'}
                            className={'btn_archive_book'}
                            typeBtn={'button'}
                          />

                          {/* {!el.date_vydachi ? ( */}
                          <Button
                            onClick={() =>
                              this.showModal(
                                {
                                  //  id_customers: 7,
                                  id_book: this.props.bookItem.id,
                                },
                                'Книга успешно выдана',
                              )
                            }
                            text={'Выдать'}
                            className={'btn_order_book'}
                            typeBtn={'button'}
                          />
                          {/* ) : ( */}
                          <Button
                            onClick={() =>
                              this.confirmReturnBook(
                                {
                                  id_customers: 7,
                                  id_book: this.props.bookItem.id,
                                },
                                'Вы точно хотите вернуть книгу?',
                              )
                            }
                            text={'Вернуть'}
                            className={'btn_return_book'}
                            typeBtn={'button'}
                          />
                          {/* )} */}
                          <br />
                          <Button
                            onClick={() => this.addIvnNumber(this.props.bookItem.id)}
                            text={'Инвентарные номера'}
                            className={'btn_inv_number_book'}
                            typeBtn={'button'}
                          />
                        </div>
                      </div>

                      {/* <div className={objStatus.style}>
              <p>{objStatus.text}</p>
             </div>

             <div className='book-irem-reit'>
              <ReitStar countReit={el.kol_vyd} />
             </div> */}

                      <div className='tags-items'>
                        <span>Ключевые слова:</span>
                        {this.props.bookItem.keywords.map((el, i) =>
                          el == 'undefined' ? null : (
                            <p
                              key={i}
                              // onClick={() => this.searchBookInTags(el)}
                            >
                              #{el.keyword}
                            </p>
                          ),
                        )}
                      </div>

                      <div className='book-info-main'>
                        <div className='l'>
                          <div className='book-info-rigth-item'>
                            <p>
                              Автор: <span>{this.props.bookItem.authors[0].name}</span>
                            </p>
                          </div>
                          {/* <div className="book-info-rigth-item">
                          <p>
                            Титул автора: <span>{el.titul}</span>
                          </p>
                        </div> */}
                          {/* <div className="book-info-rigth-item">
                          <p>
                            Дата жизни автора: <span>{el.dates_life}</span>
                          </p>
                        </div> */}
                          <div className='book-info-rigth-item'>
                            <p>
                              Другой автор: <span>{this.props.bookItem.authors[1]?.name}</span>
                            </p>
                          </div>
                          <div className='book-info-rigth-item'>
                            <p>
                              Сведение к заглавию:{' '}
                              <span>{this.props.bookItem.title_information}</span>
                            </p>
                          </div>
                          <div className='book-info-rigth-item'>
                            <p>
                              Сведение об ответственности:{' '}
                              <span>{this.props.bookItem.liability_information}</span>
                            </p>
                          </div>
                          <div className='book-info-rigth-item'>
                            <p>
                              Краткое содержание: <span>{this.props.bookItem.desctiption}</span>
                            </p>
                          </div>
                          {/* <div className='book-info-rigth-item'>
              <p>
               Библиотека: <span>{this.props.bookItem.id}</span>
              </p>
             </div> */}
                        </div>

                        <div className='r'>
                          <div className='book-info-rigth-item'>
                            <p>
                              Заглавие: <span>{this.props.bookItem.title}</span>
                            </p>
                          </div>
                          <div className='book-info-rigth-item'>
                            <p>
                              Номер части: <span>{this.props.bookItem.part_number}</span>
                            </p>
                          </div>
                          <div className='book-info-rigth-item'>
                            <p>
                              Название части: <span>{this.props.bookItem.part_name}</span>
                            </p>
                          </div>
                          <div className='book-info-rigth-item'>
                            <p>
                              Параллельное название:{' '}
                              <span>{this.props.bookItem.parallel_title}</span>
                            </p>
                          </div>

                          {/*ПЕЧАТЬ ИНВЕНТАРНЫХ НОМЕРОВ */}
                          <div className='block_inv_number_shtrih'>
                            <div ref={this.prinShtrixInvNumber}>
                              {this.state.inv_number_book.map((item) => {
                                return (
                                  <BarCode
                                    inv_number={item.inventory_number}
                                    barcode={item.barcode}
                                  />
                                );
                              })}
                            </div>
                          </div>

                          <div className='bar_code_bookitem'>
                            <BarCode
                              barcode={this.props.bookItem.id}
                              code={this.props.bookItem.id}
                              ref={this.setComponentRef}
                            />
                          </div>
                          {this.state.inv_number_book.length > 0 ? (
                            <ReactToPrint
                              onClick={() => this.getInveNumber}
                              trigger={() => <a href='#'>Распечатать штрих-коды</a>} // здесь добавляется ссылка, при нажатии компонент уходит на печать
                              content={() => this.prinShtrixInvNumber.current}
                              documentTitle='AwesomeFileName'
                              removeAfterPrint
                            />
                          ) : null}

                          <div>
                            <div
                              ref={this.setComponentRefCard}
                              style={{ width: '475px', height: '285px' }}
                            >
                              <BookCard book={this.props.bookItem} />
                            </div>
                            <ReactToPrint
                              trigger={() => <a href='#'>Распечатать каталожную карточку</a>} // здесь добавляется ссылка, при нажатии компонент уходит на печать
                              content={this.reactToPrintContentCard}
                              documentTitle='AwesomeFileName'
                              removeAfterPrint
                            />
                          </div>
                        </div>
                      </div>

                      {!this.state.moreInfo ? (
                        <div
                          className='more'
                          onClick={() => this.setState({ moreInfo: !this.state.moreInfo })}
                        >
                          <p>Показать больше информации &gt;&gt;&gt;</p>
                        </div>
                      ) : (
                        <>
                          <div
                            className='more'
                            onClick={() => this.setState({ moreInfo: !this.state.moreInfo })}
                          >
                            <p>Скрыть информацию &lt;&lt;&lt;</p>
                          </div>
                          <div className='book-info-main'>
                            <div className='l'>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Место издания:{' '}
                                  <span>{this.props.bookItem.place_of_publication}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Сведение об издании:{' '}
                                  <span>{this.props.bookItem.publishing_information}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Издательство: <span>{this.props.bookItem.publisher}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Год издания:{' '}
                                  <span>{this.props.bookItem.publication_year + ' г.'}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Объем:{' '}
                                  <span>{this.props.bookItem.number_of_pages + ' cтр.'}</span>
                                </p>
                              </div>
                              {/* <div className="book-info-rigth-item">
                              <p>
                                Физические характеристики, иллюстрации, переплет и т.п.:{' '}
                                <span>{el.fiz_har}</span>
                              </p>
                            </div> */}
                              <div className='book-info-rigth-item'>
                                <p>
                                  Детское издание до 15 лет:
                                  {this.props.bookItem.kid_fund && (
                                    <span>{this.props.bookItem.kid_fund ? 'Да' : 'Нет'}</span>
                                  )}
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Серия: <span>{this.props.bookItem.series}</span>
                                </p>
                              </div>
                              {/* <div className="book-info-rigth-item">
                              <p>
                                Подсерия: <span>{el.podseria}</span>
                              </p>
                            </div> */}
                              <div className='book-info-rigth-item'>
                                <p>
                                  Примечание: <span>{this.props.bookItem.note}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  ISBN: <span>{this.props.bookItem.ISBN}</span>
                                </p>
                              </div>
                              {/* <div className='book-info-rigth-item'>
                <p>
                 Инвентарный номер: <span>{this.props.bookItem.part_name}</span>
                </p>
               </div> */}
                              {/* <div className='book-info-rigth-item'>
                <p>
                 Сигла, отдел ЦГБ: <span>{this.props.bookItem.part_name}</span>
                </p>
               </div> */}
                              {/* <div className='book-info-rigth-item'>
                <p>
                 Дата ввода: <span>{this.props.bookItem.part_name}</span>
                </p>
               </div> */}
                              <div className='book-info-rigth-item'>
                                <p>
                                  Язык издания:{' '}
                                  <span>{this.findInMassive1(this.props.bookItem.language)}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Переплет:{' '}
                                  <span>
                                    {this.props.bookItem.binding
                                      ? 'Твердый переплет'
                                      : 'Мягкий переплет'}
                                  </span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Дополнительные ссылки:{' '}
                                  <span>{this.props.bookItem.dop_links}</span>
                                </p>
                              </div>
                            </div>

                            <div className='r'>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Цена: <span>{this.props.bookItem.price + ' тнг.'}</span>
                                </p>
                              </div>
                              {/* <div className="book-info-rigth-item">
                              <p>
                                Количество: <span>{el.kol}</span>
                              </p>
                            </div> */}
                              {/* <div className="book-info-rigth-item">
                              <p>
                                Стоимость: <span>{el.stoit}</span>
                              </p>
                            </div> */}
                              <div className='book-info-rigth-item'>
                                <p>
                                  Тираж:{' '}
                                  <span>{this.props.bookItem.book_circulation + ' шт.'}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Тематическая рубрика:{' '}
                                  <span>{this.findInMassive2(this.props.bookItem.classifier)}</span>
                                </p>
                              </div>
                              {/* <div className="book-info-rigth-item">
                              <p>
                                Краеведение: <span>{el.kraeved}</span>
                              </p>
                            </div> */}
                              {/* <div className='book-info-rigth-item'>
                  <p>
                   Ключевые слова: <span>{el.kl_slova}</span>
                  </p>
                 </div> */}
                              <div className='book-info-rigth-item'>
                                <p>
                                  Индекс УДК/ББК:{' '}
                                  <span>
                                    {this.findInMassive22(this.props.bookItem.classifier)}
                                  </span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Авторский знак: <span>{this.props.bookItem.author_sign}</span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Другие языки издания:{' '}
                                  <span>{this.props.bookItem.language_notes}</span>
                                </p>
                              </div>
                              {/* <div className='book-info-rigth-item'>
                <p>
                 Партия поступления: <span>{this.props.bookItem.part_name}</span>
                </p>
               </div> */}
                              {/* <div className='book-info-rigth-item'>
                <p>
                 Источник поступления: <span>{this.props.bookItem.part_name}</span>
                </p>
               </div> */}
                              <div className='book-info-rigth-item'>
                                <p>
                                  Вид документа:{' '}
                                  <span>
                                    {this.findInMassive12(this.props.bookItem.type_of_document)}
                                  </span>
                                </p>
                              </div>
                              <div className='book-info-rigth-item'>
                                <p>
                                  Служебные отметки:{' '}
                                  <span>{this.props.bookItem.employees_notes}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {/* <div className='book-info-right1'>
                                          <Bar barcode={this.props.bookItem.id} code={this.props.bookItem.id} />
                                      </div> */}
                  </div>
                </div>
              </div>
              {/* );
       })} */}
            </div>
          </div>
        ) : (
          <div className='loaderBook'>
            <BookItemLoader />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(BookItem);
