// import './styles.css';
import ReactApexChart from 'react-apexcharts';
import React from 'react';

class Chart extends React.Component {
 render() {
  const series = [
   {
    data: this.props.numbersArr,
   },
  ];

  const options = {
   chart: {
    type: 'bar',
    height: '999',
   },
   dataLabels: {
    enabled: false,
   },
   legend: {
    show: false,
   },
   plotOptions: {
    bar: {
     borderRadius: 4,
     horizontal: true,
    },
   },
   dataLabels: {
    enabled: false,
   },
   xaxis: {
    categories: this.props.titlesArr,
   },
  };
  return (
   <div className='chart'>
    {console.log(series[0].data.length * 60)}
    {/* <div style={{ width: '800px', overflow: 'auto', height: 'auto', marginBottom: '0px' }}> */}
    <div style={{ width: '800px', height: 'auto', marginBottom: '0px' }}>
     <ReactApexChart options={options} series={series} type='bar' height={series[0].data.length * 60 + 60} />
    </div>
   </div>
  );
 }
}
export default Chart;
