import React from "react";
import Chart from "react-google-charts";
import '../style.css'

class cartG extends React.Component {
    render() {
        return (
            <div style={this.props.style}>
                <p className="title-chart">{this.props.text}</p>
                <Chart
                    chartType={this.props.type}
                    data={this.props.dataPie}
                    options={this.props.pieOptions}
                    graph_id="PieChart"
                    width={"300px"}
                    height={"300px"}
                    legend_toggle
                />
            </div>
        );
    }
}

export default cartG

