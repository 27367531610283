import Modal from '../modal';
import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import debounce from '../../utils/debounce';
import { getInventByType2, editInventNumStatus, editBookedBookStatus } from '../../api';
import toast from 'react-hot-toast';

const ModalForBrone = ({ show, closeModalProp, book, getDataBroned }) => {
 const [inv_number_bron, set_inv_number_bron] = useState('');
 const [inv_number_bron_id, set_inv_number_bron_id] = useState('');

 const [InventNumbersBookBronArray, set_InventNumbersBookBronArray] = useState([]);

 const filterValues22 = async (e) => {
  await set_inv_number_bron(e.target.value);

  if (e.target.value !== '') {
   const InventNumbersBookBronArrayTemp = await getInventByType2.getInventByType2({
    invent_number: e.target.value,
    book_id: book.book_id,
   });
   await set_InventNumbersBookBronArray(InventNumbersBookBronArrayTemp);
  } else {
   await set_InventNumbersBookBronArray([]);
  }
 };

 const closeModal = async (e) => {
  if (e === 'modal') {
   closeModalProp();
   await set_InventNumbersBookBronArray([]);
   await set_inv_number_bron('');
   await set_inv_number_bron_id('');
  }

  //   await set_inv_number_bron('');
  //   await set_inv_number_bron_id('');
  //   this.setState({
  //    showModalGive: false,
  //    userBookOrder: false,
  //    InventNumbersBookOrder: false,
  //    readerInput: '',
  //    showAlertSpisan: false,
  //    inputSpisAlert: '',
  //    showAlertSigla: false,
  //    showAlertInvNumber: false,
  //    showModalAlert: false,
  //    showAlertCatCardInvNumber: false,
  //    days: '',
  //    inv_number_vydacha: '',
  //   });
 };

 const SendBron = async () => {
  const data = {
   inv_number_bron_id: inv_number_bron_id,
   bron_id: book.id,
  };

  if (inv_number_bron_id) {
   const res = await editInventNumStatus.editInventNumStatus({
    status: 2,
    id: inv_number_bron_id,
   });

   const res2 = await editBookedBookStatus.editBookedBookStatus({
    status: 1,
    id: book.id,
   });

   if (res) {
    if (res2) {
     closeModal('modal');
     toast.success('Книга успешно отправлена на бронеполку!');
     getDataBroned();
    } else {
     closeModal('modal');
     toast.error('Возникла ошибка');
    }
   } else {
    closeModal('modal');
    toast.error('Возникла ошибка');
   }
  } else {
   toast.error('Укажите инвентарный номер');
  }
 };

 const handleSelect = async (data) => {
  await set_InventNumbersBookBronArray([]);
  await set_inv_number_bron(data.inventory_number);
  await set_inv_number_bron_id(data.id);
 };

 return (
  <Modal
   show={show}
   handleClose={SendBron}
   // data={this.state}
   btn='Отправить на бронеполку'
   classBtn='btn-close'
   closeModalEvent={closeModal}
  >
   <div className='modal-content'>
    <div className='modal-title-icon'>
     <h1>Отправка на бронеполку</h1>
     {/* <img className='modal-close-icon' onClick={this.closeModal} alt='close' src={close} /> */}
    </div>
    <form
     // onSubmit={(e) => e.preventDefault()}
     className='product-modal-inner'
     style={{ display: 'flex', flexDirection: 'column' }}
    >
     <div className='search-filter-book-item' style={{ width: '100%', maxWidth: '100%' }}>
      <p>Инвентарный номер : {inv_number_bron}</p>
      <input
       type='number'
       autoComplete='off'
       name='inv_number_bron'
       id='inv_number_bron'
       style={{ width: '100%' }}
       //  value={}
       placeholder='Отсканируйте штрих-код'
       onChange={debounce(filterValues22)}
      />
      <div className='searchReader'>
       {InventNumbersBookBronArray
        ? InventNumbersBookBronArray.map((el, i) => {
           return (
            <div>
             <p key={i} onClick={() => handleSelect(el)}>
              {el.inventory_number}
             </p>
            </div>
           );
          })
        : []}
      </div>
     </div>
    </form>
   </div>
  </Modal>
 );
};

export default ModalForBrone;
