import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import './style.css'

class ElectronRes extends Component {
    render() {
        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>ЭЛЕКТРОННЫЕ РЕСУРСЫ С УКАЗАНИЕМ ДОСТУПА К НИМ</h1>
                    </div>

                    <div className='electron-res-opis'>
                        <h3>Библиотека онлайн</h3>
                        <p>Объединим городские библиотеки в единую базу для удобства наших читателей.</p>

                        <ul>Основные функции приложения:
                            <li>Пользователь может выбрать любую подходящую для него библиотеку</li>
                            <li>При помощи поиска найти интересующую книгу в одной из библиотек города</li>
                            <li>Читатель может поставить бронь на нужную книгу, а так же добавить её в раздел «избранных» книг</li>
                        </ul>

                        <p>В приложении доступен личный кабинет пользователя где видна история брони книг, весь прочитанный материал а
                            так же книги которые находятся у читателя в пользовании на данный момент.</p>

                        <p>В приложение предусмотрена система уведомлений. Пользователю придёт напоминание о истекающем сроке сдачи книги в библиотеку.</p>

                        <h3>Сайт «Библиотека онлайн»</h3>

                        <p> Сайт имеет одинаковую базу с мобильным приложением и так же объединяет городские библиотеки в одном месте для удобства наших читателей.</p>

                        <ul>Основные функции сайта:
                            <li>Пользователь может выбрать любую подходящую для него библиотеку</li>
                            <li>При помощи поиска найти интересующую книгу в одной из библиотек города</li>
                            <li>Читатель может поставить бронь на нужную книгу, а так же добавить её в раздел «избранных» книг</li>
                        </ul>

                        <p>На сайте доступен личный кабинет пользователя где видна история брони книг, весь прочитанный материал, а
                            так же книги которые находятся у читателя в пользовании на данный момент. В личном кабинете пользователю придёт
                            напоминание о истекающем сроке сдачи книги в библиотеку.</p>

                    </div>
                </div>

            </div >
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(ElectronRes);