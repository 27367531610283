export default function hiddenText(str) {
    const bodyArr = (str + '').split(' ');
    let hidden = false;
    return {
        str: bodyArr
            .map((elem) => {
                if (elem.length > 14) {
                    hidden = true;
                    return `${elem.slice(0, 13)}...`;
                } else {
                    return elem;
                }
            })
            .join(' '),
        hidden,
    };
};