import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Loader from '../../../components/loader';

import './style.css';
import { getStaticFond } from '../../../api';

class StaticRead extends Component {
  state = {
    dateFrom: '',
    dateTo: '',
    dataPeriod: [],
  };
  async componentDidMount() {
    await this.props.getData('komplekt_popular', 'komplekt_popular');
  }

  render() {
    let url = this.props.match.url.slice(1);

    const headerItemsContent = [
      { title: 'Автор', data: 'avtor' },
      { title: 'Название книги', data: 'name' },
      { title: 'Год издания', data: 'god' },
      { title: 'Издатель', data: 'izdatel' },
      { title: 'Взято', data: 'vzyato' },
      { title: 'Тираж', data: 'tirazh' },
      { title: 'Место издания', data: 'place_izdn' },
      { title: 'Серия', data: 'seria' },
      { title: 'ISBN', data: 'ISBN' },
    ];

    const getStatistics = () => {
      const obj = { start: this.state.dateFrom, end: this.state.dateTo };
      const res = getStaticFond.getStaticFond(obj);
      res.then((res) => this.setState({ dataPeriod: res }));
    };

    const resetStatistics = () => {
      this.setState({ dateFrom: '', dateTo: '', dataPeriod: [] });
    };
    return (
      <div className="page-main">
        <div className="page-out-wrapper">
          <div className="title-total">
            {url === 'komplekt_popular' ? (
              <h1>Формирование документов для заказа литературы</h1>
            ) : (
              <h1>Формирование статистики по использованию и востребованности фондов</h1>
            )}
          </div>

          <h3>Популярные книги:</h3>

          <div className="wrapper-filter">
            <div className="filter-readers">
              <label htmlFor="dataFrom">Период с</label>
              <input
                type="date"
                name="dataFrom"
                id="dataFrom"
                value={this.state.dateFrom}
                onChange={(e) => this.setState({ dateFrom: e.target.value })}
              />
            </div>
            <div className="filter-readers">
              <label htmlFor="dataTo">по</label>
              <input
                type="date"
                name="dataTo"
                id="dataTo"
                value={this.state.dateTo}
                onChange={(e) => this.setState({ dateTo: e.target.value })}
              />
            </div>
            <div>
              <button className="filter-button filter-button-green" onClick={getStatistics}>
                Сформировать
              </button>
              <button className="filter-button filter-button-red" onClick={resetStatistics}>
                Сбросить фильтр
              </button>
            </div>
          </div>
          {this.state.dataPeriod.length ? (
            <div className="book-items-content">
              <div className="book-items-header">
                {headerItemsContent.map((el, i) => {
                  return (
                    <div key={i}>
                      <p>{el.title}</p>
                    </div>
                  );
                })}
              </div>

              <div>
                {this.state.dataPeriod.map((el, i) => {
                  let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
                  return (
                    <div className={oddEven} key={el.id}>
                      <div>
                        <p>{el.avtor}</p>
                      </div>
                      <div>
                        <p>{el.name}</p>
                      </div>
                      <div>
                        <p>{el.god}</p>
                      </div>
                      <div>
                        <p>{el.izdatel}</p>
                      </div>
                      <div>
                        <p>{el.CNT}</p>
                      </div>
                      <div>
                        <p>{el.tirazh}</p>
                      </div>
                      <div>
                        <p>{el.place_izdn}</p>
                      </div>
                      <div>
                        <p>{el.seria}</p>
                      </div>
                      <div>
                        <p>{el.lib_name}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : this.props.komplekt_popular.length ? (
            <div className="book-items-content">
              <div className="book-items-header">
                {headerItemsContent.map((el, i) => {
                  return (
                    <div key={i}>
                      <p>{el.title}</p>
                    </div>
                  );
                })}
              </div>

              <div>
                {this.props.komplekt_popular.map((el, i) => {
                  let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
                  return (
                    <div className={oddEven} key={el.id}>
                      <div>
                        <p>{el.avtor}</p>
                      </div>
                      <div>
                        <p>{el.name}</p>
                      </div>
                      <div>
                        <p>{el.god}</p>
                      </div>
                      <div>
                        <p>{el.izdatel}</p>
                      </div>
                      <div>
                        <p>{el.kol_vyd}</p>
                      </div>
                      <div>
                        <p>{el.tirazh}</p>
                      </div>
                      <div>
                        <p>{el.place_izdn}</p>
                      </div>
                      <div>
                        <p>{el.seria}</p>
                      </div>
                      <div>
                        <p>{el.lib_name}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(StaticRead);
