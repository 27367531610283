import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../redux/action';

import Button from '../button';
import Modal from '../modal';

import './style.css';
import moment from 'moment';

import toast from 'react-hot-toast';

class FormBookAdd extends Component {
  state = {
    temArrValue: 0,
    publisher: '',
    status: true,

    showModal: false,
    showModal2: false,
    showModal3: false,
    showModal4: false,
    showModal5: false,

    lang: 'ru',
    publishersGetMassive: [],
    publisherSelectedN: -1,
    publisherSelectedItem: '',
    avtorsGetMassive: [],
    avtorSelectedN: -1,
    avtorSelectedItem: '',
    avtorSelectedN2: -1,
    avtorSelectedItem2: '',
    kl_slova_dop: 1,
    dopRows: false,
    addAvtor: {
      name: '',
    },
    markFormData: {
      Metka_001: '',
      Metka_010: '',
      Metka_011: '',
      Metka_013: '',
      Metka_014: '',
      Metka_020: '',
      Metka_021: '',
      Metka_100: '',
      Metka_101: '',
      Metka_102: '',
      Metka_200: '',
      Metka_205: '',
      Metka_300: '',
      Metka_324: '',
      Metka_325: '',
      Metka_509: '',
      Metka_700: '',
      Metka_701: '',
      Metka_702: '',
    },
    markFormDataDop: {
      Metka_010_a: '',
      Metka_010_d: '',
      Metka_010_9: '',

      Metka_013_a: '',
      Metka_013_b: '',
      Metka_013_d: '',
      Metka_013_z: '',

      Metka_020_a: '',
      Metka_020_b: '',

      Metka_021_a: '',
      Metka_021_b: '',

      Metka_101_a1: '',
      Metka_101_a2: '',
      Metka_101_a3: '',
      Metka_101_a4: '',
      Metka_101_a5: '',

      Metka_200_a: '',
      Metka_200_d: '',
      Metka_200_e: '',
      Metka_200_h: '',
      Metka_200_i: '',
      Metka_200_v: '',
      Metka_200_z: '',

      Metka_205_a: '',
      Metka_205_b: '',

      Metka_509_a: '',
      Metka_509_b: '',
      Metka_509_c: '',
      Metka_509_f: '',
      Metka_509_h: '',
      Metka_509_n: '',
    },
    addbook: {
      avtor: '',
      avtor_other: '',
      title: '',
      part_number: '',
      part_name: '',
      title_information: '',
      liability_information: '',
      parallel_title: '',
      place_of_publication: '',
      publication_information: '',
      publisher_id: '1',
      publication_year: '',
      number_of_pages: '',
      kid_fund: 0,
      series: '',
      employees_notes: '',
      ISBN: '',
      price: '',
      by_content: '',
      rare_fund: 0,
      book_circulation: '',
      genre_id: '',
      classifier: '',
      index: '',
      kl_slova: '',
      kl_slova_1: '',
      kl_slova_2: '',
      kl_slova_3: '',
      kl_slova_4: '',
      kl_slova_5: '',
      kl_slova_6: '',
      kl_slova_7: '',
      kl_slova_8: '',
      kl_slova_9: '',
      kl_slova_10: '',
      binding: '',
      author_sign: '',
      date_vvoda: moment().format('YYYY-MM-DD'),
      language: '',
      osn_language_lat: '',
      language_notes: '',
      type_of_document: '',
      note: '',
      cover_url: '',
      desctiption: '',
      by_branches_of_knowledge: '',
      bibliographic_level: '',
      card_file: '',
      dop_links: '',
      file_link: '',
    },
  };

  kl_slova_dop_change = (num) => {
    this.setState({ kl_slova_dop: num });
  };

  closeModalWithoutSelect = () =>
    this.setState({
      showModal: false,
      showModal2: false,

      showModal3: false,
      showModal5: false,
      //  avtorSelectedItem: '',
      //  avtorSelectedN: -1,
      //  avtorSelectedItem2: '',
      //  avtorSelectedN2: -1,
      //  publisherSelectedItem: '',
      //  publisherSelectedN: -1,
    });

  closeModalWithSelect = () => this.setState({ showModal: false });
  closeModalWithSelect2 = () => this.setState({ showModal3: false });
  closeModalWithSelect3 = () => this.setState({ showModal5: false });

  openAddAvtorModal = () => {
    this.setState({
      showModal: false,
      avtorSelectedItem: '',
      avtorSelectedN: -1,
      showModal2: true,
    });
  };
  openAddAvtorModal2 = () => {
    this.setState({
      showModal3: false,
      avtorSelectedItem2: '',
      avtorSelectedN2: -1,
      showModal4: true,
      showModal2: true,
    });
  };
  AvtorAddSend = async () => {
    const SendObj = {
      name: this.state.addAvtor.name,
    };

    const res = await fetch('https://smart-pvl.kz/Api/api/author/add.php', {
      method: 'POST',
      headers: {
        AUTH: localStorage.getItem('tokenLibrary'),
      },
      body: JSON.stringify(SendObj),
    });
    const json = await res.json();
    console.log(json);
    if (json) {
      !this.state.showModal4
        ? this.setState({ showModal2: false, avtorSelectedItem: json, avtorSelectedN: 0 })
        : this.setState({ showModal2: false, avtorSelectedItem2: json, avtorSelectedN2: 0 });
      toast.success('Автор успешно создан!');
    }
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleChangeSelect = ({ target }) => {
    this.setState({ temArrValue: target.value });
  };

  PublisherChangeHandle = async () => {
    if (this.state.publisher.trim().length !== 0) {
      const objForm = { keyword: this.state.publisher };
      const formData = new FormData();
      formData.append(`keyword`, this.state.publisher);

      const res = await fetch('https://smart-pvl.kz/Api/api/publisher/get.php', {
        method: 'POST',
        headers: {
          AUTH: localStorage.getItem('tokenLibrary'),
        },
        body: JSON.stringify(objForm),
      });
      const json = await res.json();
      if (json.length === 0) {
        this.setState({ showModal5: true, publishersGetMassive: [] });
      } else {
        this.setState({ publishersGetMassive: json, showModal5: true });
      }
    }
  };

  AvtorChangeHandle = async () => {
    if (this.state.addbook.avtor.trim().length !== 0) {
      const objForm = { keyword: this.state.addbook.avtor };
      const formData = new FormData();
      formData.append(`keyword`, this.state.addbook.avtor);

      const res = await fetch('https://smart-pvl.kz/Api/api/author/get.php', {
        method: 'POST',
        headers: {
          AUTH: localStorage.getItem('tokenLibrary'),
        },
        body: JSON.stringify(objForm),
      });
      const json = await res.json();
      if (json.length === 0) {
        this.setState({ showModal: true, avtorsGetMassive: [] });
      } else {
        this.setState({ avtorsGetMassive: json, showModal: true });
      }
    }
  };
  AvtorChangeHandle2 = async () => {
    if (this.state.addbook.avtor_other.trim().length !== 0) {
      const objForm = { keyword: this.state.addbook.avtor_other };
      const formData = new FormData();
      formData.append(`keyword`, this.state.addbook.avtor_other);

      const res = await fetch('https://smart-pvl.kz/Api/api/author/get.php', {
        method: 'POST',
        headers: {
          AUTH: localStorage.getItem('tokenLibrary'),
        },
        body: JSON.stringify(objForm),
      });
      const json = await res.json();
      if (json.length === 0) {
        this.setState({ showModal3: true, avtorsGetMassive: [] });
      } else {
        this.setState({ avtorsGetMassive: json, showModal3: true });
      }
    }
  };
  handleChangeModalWithCheck = ({ target }) => {
    if (target.value.indexOf(',') > -1) {
      toast.error('Нельзя использовать символ запятой ","');
    } else if (target.value.indexOf('&') > -1) {
      toast.error('Нельзя использовать символ "&"');
    } else {
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
      });
    }
  };

  handleInputMainMarc = ({ target }) => {
    this.setState({
      ...this.state,
      markFormData: { ...this.state.markFormData, [target.name]: target.value },
    });
  };

  handleInputDopMarc = ({ target }) => {
    this.setState({
      ...this.state,
      markFormDataDop: { ...this.state.markFormDataDop, [target.name]: target.value },
    });
  };

  handleChangeModal = ({ target }) => {
    if (target.name === 'kid_fund') {
      this.setState({
        ...this.state,
        addbook: {
          ...this.state.addbook,
          [target.name]: this.state.addbook[target.name] === 0 ? 1 : 0,
        },
      });
    } else if (target.name === 'rare_fund') {
      this.setState({
        ...this.state,
        addbook: {
          ...this.state.addbook,
          [target.name]: this.state.addbook[target.name] === 0 ? 1 : 0,
        },
      });
    } else if (target.name === 'classifier') {
      //  console.log(target[target.selectedIndex].dataset.udk);
      this.setState({
        ...this.state,
        addbook: {
          ...this.state.addbook,
          classifier: target.value,
          index: target[target.selectedIndex].dataset.udk,
        },
      });
    } else if (target.name === 'language') {
      this.setState({
        ...this.state,
        addbook: {
          ...this.state.addbook,
          language: target.value,
          osn_language_lat: target[target.selectedIndex].dataset.lat,
        },
      });
    } else if (target.name === 'cover_url' || target.name === 'file_link') {
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.files[0] },
      });
    } else if (target.name === 'publisher') {
      this.setState({ publisher: target.value });
    } else if (target.name === 'ISBN') {
      const isbn = '$a' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_010_a: isbn },
      });
    } else if (target.name === 'price') {
      const price = '$d' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_010_d: price },
      });
    } else if (target.name === 'book_circulation') {
      const book_circulation = '$9' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_010_9: book_circulation },
      });
    } else if (target.name === 'title') {
      const title = '$a' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_200_a: title },
      });
    } else if (target.name === 'parallel_title') {
      const parallel_title = '$d' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_200_d: parallel_title },
      });
    } else if (target.name === 'title_information') {
      const title_information = '$e' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_200_e: title_information },
      });
    } else if (target.name === 'part_name') {
      const part_name = '$i' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_200_i: part_name },
      });
    } else if (target.name === 'publication_information') {
      const publication_information = '$a' + target.value;
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
        markFormDataDop: { ...this.state.markFormDataDop, Metka_205_a: publication_information },
      });
    } else {
      this.setState({
        ...this.state,
        addbook: { ...this.state.addbook, [target.name]: target.value },
      });
    }
  };

  handleSubmit = async (type) => {
    this.setState({ status: false });

    const formData = await new FormData();
    var massive2 = [];
    var massive3 = [];

    var kl_slovaGeneral = '';

    for (const key in this.state.markFormData) {
      console.log(key);

      if (key == 'Metka_010') {
        const part1 = this.state.markFormDataDop.Metka_010_a;
        const part2 = this.state.markFormDataDop.Metka_010_d;
        const part3 = this.state.markFormDataDop.Metka_010_9;

        const concat = '010 ##' + part1 + part2 + part3;
        await formData.append(`Metka_010`, JSON.stringify(concat));
      } else if (key == 'Metka_001') {
        const part1 = this.state.markFormData.Metka_001;

        const concat = '001 ' + part1;
        await formData.append(`Metka_001`, JSON.stringify(concat));
      } else if (key == 'Metka_011') {
        const part1 = this.state.markFormData.Metka_011;

        const concat = '011 ##$a' + part1;
        await formData.append(`Metka_011`, JSON.stringify(concat));
      } else if (key == 'Metka_013') {
        const part1 = '$a' + this.state.markFormDataDop.Metka_013_a;
        const part2 = '$b' + this.state.markFormDataDop.Metka_013_b;
        const part3 = '$d' + this.state.markFormDataDop.Metka_013_d;
        const part4 = '$z' + this.state.markFormDataDop.Metka_013_z;

        const concat = '013 ##' + part1 + part2 + part3 + part4;
        await formData.append(`Metka_013`, JSON.stringify(concat));
      } else if (key == 'Metka_014') {
        const part1 = this.state.markFormData.Metka_014;

        const concat = '014 ##$a' + part1;
        await formData.append(`Metka_014`, JSON.stringify(concat));
      } else if (key == 'Metka_020') {
        const part1 = '$a' + this.state.markFormDataDop.Metka_020_a;
        const part2 = '$b' + this.state.markFormDataDop.Metka_020_b;

        const concat = '020 ##' + part1 + part2;
        await formData.append(`Metka_020`, JSON.stringify(concat));
      } else if (key == 'Metka_021') {
        const part1 = '$a' + this.state.markFormDataDop.Metka_021_a;
        const part2 = '$b' + this.state.markFormDataDop.Metka_021_b;

        const concat = '021 ##' + part1 + part2;
        await formData.append(`Metka_021`, JSON.stringify(concat));
      } else if (key == 'Metka_100') {
        const part1 = this.state.markFormData.Metka_100;

        const concat = '100 ##$a' + part1;
        await formData.append(`Metka_100`, JSON.stringify(concat));
      } else if (key == 'Metka_101') {
        const part1 = '$a' + this.state.markFormDataDop.Metka_101_a1;
        const part2 = '$a' + this.state.markFormDataDop.Metka_101_a2;
        const part3 = '$a' + this.state.markFormDataDop.Metka_101_a3;
        const part4 = '$a' + this.state.markFormDataDop.Metka_101_a4;
        const part5 = '$a' + this.state.markFormDataDop.Metka_101_a5;

        const concat = '101 #' + part1 + part2 + part3 + part4 + part5;
        await formData.append(`Metka_101`, JSON.stringify(concat));
      } else if (key == 'Metka_102') {
        const part1 = this.state.markFormData.Metka_102;

        const concat = '102 ##$a' + part1;
        await formData.append(`Metka_102`, JSON.stringify(concat));
      } else if (key == 'Metka_200') {
        const part1 = this.state.markFormDataDop.Metka_200_a;
        const part2 = this.state.markFormDataDop.Metka_200_d;
        const part3 = this.state.markFormDataDop.Metka_200_e;
        const part4 = '$h' + this.state.markFormDataDop.Metka_200_h;
        const part5 = this.state.markFormDataDop.Metka_200_i;
        const part6 = '$v' + this.state.markFormDataDop.Metka_200_v;
        const part7 = '$z' + this.state.markFormDataDop.Metka_200_z;

        const concat = '200 1#' + part1 + part2 + part3 + part4 + part5 + part6 + part7;
        await formData.append(`Metka_200`, JSON.stringify(concat));
      } else if (key == 'Metka_205') {
        const part1 = this.state.markFormDataDop.Metka_205_a;
        const part2 = '$b' + this.state.markFormDataDop.Metka_205_b;

        const concat = '205 ##' + part1 + part2;
        await formData.append(`Metka_205`, JSON.stringify(concat));
      } else if (key == 'Metka_300') {
        const part1 = this.state.markFormData.Metka_300;

        const concat = '300 ##$a' + part1;
        await formData.append(`Metka_300`, JSON.stringify(concat));
      } else if (key == 'Metka_324') {
        const part1 = this.state.markFormData.Metka_324;

        const concat = '324 ##$a' + part1;
        await formData.append(`Metka_324`, JSON.stringify(concat));
      } else if (key == 'Metka_325') {
        const part1 = this.state.markFormData.Metka_325;

        const concat = '325 ##$a' + part1;
        await formData.append(`Metka_325`, JSON.stringify(concat));
      } else if (key == 'Metka_509') {
        const part1 = '$a' + this.state.markFormDataDop.Metka_509_a;
        const part2 = '$b' + this.state.markFormDataDop.Metka_509_b;
        const part3 = '$c' + this.state.markFormDataDop.Metka_509_c;
        const part4 = '$f' + this.state.markFormDataDop.Metka_509_f;
        const part5 = '$h' + this.state.markFormDataDop.Metka_509_h;
        const part6 = '$n' + this.state.markFormDataDop.Metka_509_n;

        const concat = '509 01' + part1 + part2 + part3 + part4 + part5 + part6;
        await formData.append(`Metka_509`, JSON.stringify(concat));
      } else if (key == 'Metka_700') {
        const part1 = this.state.markFormData.Metka_700;

        const concat = '700 ##$a' + part1;
        await formData.append(`Metka_700`, JSON.stringify(concat));
      } else if (key == 'Metka_701') {
        const part1 = this.state.markFormData.Metka_701;

        const concat = '701 ##$a' + part1;
        await formData.append(`Metka_701`, JSON.stringify(concat));
      } else if (key == 'Metka_702') {
        const part1 = this.state.markFormData.Metka_702;

        const concat = '702 ##$a' + part1;
        await formData.append(`Metka_702`, JSON.stringify(concat));
      }
    }
    for (const key in this.state.addbook) {
      if (key === 'kl_slova') {
        var massive = [];

        for (var i = 1; i <= 10; i++) {
          const kl_slovaGeneralPart = 'kl_slova_' + String(i);
          if (this.state.addbook[kl_slovaGeneralPart] !== '') {
            massive.push(this.state.addbook[kl_slovaGeneralPart]);
          }
        }
        await formData.append(`keywords`, JSON.stringify(massive));
      } else if (key === 'publisher_id') {
        console.log(this.state.publisherSelectedItem);
        this.state.publisherSelectedItem
          ? await formData.append(`publisher_id`, this.state.publisherSelectedItem.id)
          : console.info();
      } else if (key === 'avtor') {
        var authorObj = {
          author_id: this.state.avtorSelectedItem ? this.state.avtorSelectedItem.id : 1,
          type: 1,
        };
        massive2.push(authorObj);
        console.log(massive2);
      } else if (key === 'avtor_other') {
        var authorObj = {
          author_id: this.state.avtorSelectedItem2 ? this.state.avtorSelectedItem2.id : 1,
          type: 2,
        };
        massive2.push(authorObj);
      } else if (key === 'osn_language_lat') {
      } else {
        if (key !== 'kl_slova_1') {
          if (key !== 'kl_slova_2') {
            if (key !== 'kl_slova_3') {
              if (key !== 'kl_slova_4') {
                if (key !== 'kl_slova_5') {
                  if (key !== 'kl_slova_6') {
                    if (key !== 'kl_slova_7') {
                      if (key !== 'kl_slova_8') {
                        if (key !== 'kl_slova_9') {
                          if (key !== 'kl_slova_10') {
                            formData.append(`${key}`, this.state.addbook[key]);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    await formData.append('authors', JSON.stringify(massive2));

    if (this.state.addbook.title.trim().length) {
      if (this.state.addbook.part_number.trim().length) {
        if (this.state.addbook.part_name.trim().length) {
          if (this.state.addbook.title_information.trim().length) {
            if (this.state.addbook.liability_information.trim().length) {
              if (this.state.addbook.place_of_publication.trim().length) {
                if (this.state.publisherSelectedN !== -1) {
                  if (this.state.addbook.publication_year.trim().length) {
                    if (this.state.addbook.number_of_pages.trim().length) {
                      if (this.state.addbook.binding !== '') {
                        if (this.state.addbook.series.trim().length) {
                          if (this.state.addbook.ISBN.trim().length) {
                            if (this.state.addbook.price.trim().length) {
                              if (this.state.addbook.book_circulation.trim().length) {
                                if (this.state.addbook.classifier.trim().length) {
                                  if (this.state.addbook.genre_id.trim().length) {
                                    if (this.state.addbook.kl_slova_1.trim().length) {
                                      if (this.state.addbook.author_sign.trim().length) {
                                        // if (this.state.addbook.part_post.trim().length) {
                                        if (this.state.addbook.language.trim().length) {
                                          // if (this.state.addbook.istok_post.trim().length) {
                                          if (this.state.addbook.type_of_document.trim().length) {
                                            if (
                                              this.state.addbook.by_branches_of_knowledge.trim()
                                                .length
                                            ) {
                                              if (this.state.addbook.desctiption.trim().length) {
                                                if (this.state.addbook.by_content.trim().length) {
                                                  const res = await fetch(
                                                    'https://smart-pvl.kz/Api/api/bibliographic_descriptions/add.php',
                                                    {
                                                      method: 'POST',
                                                      headers: {
                                                        AUTH: localStorage.getItem('tokenLibrary'),
                                                      },
                                                      body: formData,
                                                    },
                                                  );

                                                  // console.log(res);
                                                  const json = await res.json();
                                                  //
                                                  if (json) {
                                                    if (json == 124) {
                                                      toast.error(
                                                        'Возникла ошибка. Возможно вы загрузили исполняемый файл',
                                                      );
                                                    } else if (json == 125) {
                                                      toast.error(
                                                        'Возникла ошибка с загрузкой файла',
                                                      );
                                                    } else if (json == 126) {
                                                      toast.error(
                                                        'Возникла ошибка. Возможно недопустимый тип файла',
                                                      );
                                                    } else if (json == 127) {
                                                      toast.error(
                                                        'Возникла ошибка. Возможно недопустимый размер файла',
                                                      );
                                                    } else if (json == 128) {
                                                      toast.error(
                                                        'Возникла ошибка с загрузкой файла',
                                                      );
                                                    } else {
                                                      toast.success('Книга добавлена.');
                                                      this.props.handleChangeChoice();
                                                    }
                                                  } else {
                                                    toast.error('Ошибка при добавлении.');
                                                  }
                                                } else {
                                                  toast.error(
                                                    'Необходимо заполнить поле "По содержанию".',
                                                  );
                                                }
                                              } else {
                                                toast.error(
                                                  'Необходимо заполнить поле "Краткая аннотация".',
                                                );
                                              }
                                            } else {
                                              toast.error(
                                                'Необходимо отметить поле "По общим отраслям".',
                                              );
                                            }
                                          } else {
                                            toast.error(
                                              'Необходимо заполнить поле "Вид документа".',
                                            );
                                          }
                                          // } else {
                                          //  toast.error('Необходимо заполнить поле "Источник поступления".');
                                          // }
                                        } else {
                                          toast.error('Необходимо заполнить поле "Язык издания".');
                                        }
                                        // } else {
                                        //  toast.error('Необходимо заполнить поле "Партия поступления".');
                                        // }
                                      } else {
                                        toast.error('Необходимо заполнить поле "Авторский знак".');
                                      }
                                    } else {
                                      toast.error(
                                        'Необходимо заполнить как минимум одно поле "Ключевое слово".',
                                      );
                                    }
                                  } else {
                                    toast.error('Необходимо выбрать пункт "Жанры".');
                                  }
                                } else {
                                  toast.error('Необходимо выбрать пункт "Тематическая рубрика".');
                                }
                              } else {
                                toast.error('Необходимо заполнить поле "Тираж".');
                              }
                            } else {
                              toast.error('Необходимо заполнить поле "Цена".');
                            }
                          } else {
                            toast.error('Необходимо заполнить поле "ISBN".');
                          }
                        } else {
                          toast.error('Необходимо заполнить поле "Серия".');
                        }
                      } else {
                        toast.error('Необходимо определить "Переплет".');
                      }
                    } else {
                      toast.error('Необходимо заполнить поле "Объем".');
                    }
                  } else {
                    toast.error('Необходимо заполнить поле "Год издания".');
                  }
                } else {
                  toast.error('Необходимо заполнить поле "Издательство".');
                }
              } else {
                toast.error('Необходимо заполнить поле "Место издания".');
              }
            } else {
              toast.error('Необходимо заполнить поле "Сведения об ответственности".');
            }
          } else {
            toast.error('Необходимо заполнить поле "Сведения, относящиеся к заглавию".');
          }
        } else {
          toast.error('Необходимо заполнить поле "Название части".');
        }
      } else {
        toast.error('Необходимо заполнить поле "Номер части".');
      }
    } else {
      toast.error('Необходимо заполнить поле "Заглавие".');
    }
    this.setState({ status: true });
  };

  closeModalEvent = (e, state) => {
    if (e === 'modal') this.setState({ [state]: false });
  };

  PublisherSelectHandle = (e, i) => {
    this.setState({ publisherSelectedN: i, publisherSelectedItem: e });
  };

  AvtorSelectHandle = (e, i) => {
    this.setState({ avtorSelectedN: i, avtorSelectedItem: e });
  };

  AvtorSelectHandle2 = (e, i) => {
    this.setState({ avtorSelectedN2: i, avtorSelectedItem2: e });
  };

  render() {
    const temArr = [
      {
        value: {
          ru: 'Общий классификатор',
          kz: 'Жалпы жіктеуіш',
        },
        arr: this.props.UDKSelectsByGeneral,
      },
      {
        value: {
          ru: 'Классификатор «Казахстаника»',
          kz: '«Қазақстан» жіктеуіші',
        },
        arr: this.props.UDKSelectsByKazakstanika,
      },
      {
        value: {
          ru: 'Краеведческий классификатор',
          kz: 'Өлкетану жіктеуіші',
        },
        arr: this.props.UDKSelectsByKraeved,
      },
      {
        value: {
          ru: 'ББК',
          kz: 'ББК',
        },
        arr: this.props.UDKSelectsByBBK,
      },
    ];

    const atrFormAddBook = [
      { title: 'Автор', name: 'avtor', type: 'avtor', placeholder: 'Введите фамилию автора' },
      {
        title: 'Другой автор',
        name: 'avtor_other',
        type: 'avtor2',
        placeholder: 'Введите фамилию автора',
      },
      { title: 'Заглавие', name: 'title', type: 'text' },
      { title: 'Номер части', name: 'part_number', type: 'number' },
      { title: 'Название части', name: 'part_name', type: 'text' },
      { title: 'Сведения, относящиеся к заглавию', name: 'title_information', type: 'text' },
      { title: 'Сведения об ответственности', name: 'liability_information', type: 'text' },
      { title: 'Параллельное название', name: 'parallel_title', type: 'text', placeholder: '-' },
      {
        title: 'Место издания',
        name: 'place_of_publication',
        type: 'selectFromBD',
        option: this.props.PublPlaceDataSelectsData,
      },
      {
        title: 'Сведения об издании',
        name: 'publication_information',
        type: 'text',
        placeholder: '-',
      },
      {
        title: 'Издательство',
        name: 'publisher',
        type: 'publisher',
        placeholder: 'Введите название издательства',
      },
      { title: 'Год издания (г.)', name: 'publication_year', type: 'number' },
      { title: 'Объем (стр.)', name: 'number_of_pages', type: 'number' },
      {
        title: 'По содержанию',
        name: 'by_content',
        type: 'selectFromBD',
        option: this.props.SoderjSelectData,
      },
      { title: 'Твердый переплет', name: 'binding', value: '1', type: 'radio' },
      { title: 'Мягкий переплет', name: 'binding', value: '0', type: 'radio' },

      { title: 'Детская литература', name: 'kid_fund', type: 'checkbox' },
      { title: 'Редкий фонд', name: 'rare_fund', type: 'checkbox' },
      { title: 'Серия', name: 'series', type: 'text' },
      { title: 'Примечание', name: 'note', type: 'text', placeholder: '-' },
      { title: 'ISBN', name: 'ISBN', type: 'text' },
      { title: 'Цена (тнг.)', name: 'price', type: 'number' },
      { title: 'Тираж (шт.)', name: 'book_circulation', type: 'number' },
      {
        title: 'Тематическая рубрика',
        name: 'classifier',
        type: 'tema',
      },
      { title: 'Индекс', name: 'index', type: 'other' },

      {
        title: 'Жанры',
        name: 'genre_id',
        type: 'selectFromBD',
        option: this.props.GenresSelectData,
      },
      {
        title: 'По общим отраслям',
        name: 'by_branches_of_knowledge',
        type: 'selectFromBD',
        option: this.props.OtrasliZnaniiSelectData,
      },
      {
        title: 'Библиографический уровень',
        name: 'bibliographic_level',
        type: 'selectFromBD',
        option: this.props.LibLevelSelectsDataData,
      },
      {
        title: 'Картотека',
        name: 'card_file',
        type: 'selectFromBD',
        option: this.props.StaffingSelectsDataData,
      },

      { title: 'Ключевое слово № 1', name: 'kl_slova_1', type: 'kl_slova', plus: true, num: 1 },
      {
        title: 'Ключевое слово № 2',
        name: 'kl_slova_2',
        type: 'kl_slova',
        plus: true,
        num: 2,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 3',
        name: 'kl_slova_3',
        type: 'kl_slova',
        plus: true,
        num: 3,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 4',
        name: 'kl_slova_4',
        type: 'kl_slova',
        plus: true,
        num: 4,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 5',
        name: 'kl_slova_5',
        type: 'kl_slova',
        plus: true,
        num: 5,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 6',
        name: 'kl_slova_6',
        type: 'kl_slova',
        plus: true,
        num: 6,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 7',
        name: 'kl_slova_7',
        type: 'kl_slova',
        plus: true,
        num: 7,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 8',
        name: 'kl_slova_8',
        type: 'kl_slova',
        plus: true,
        num: 8,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 9',
        name: 'kl_slova_9',
        type: 'kl_slova',
        plus: true,
        num: 9,
        placeholder: '-',
      },
      {
        title: 'Ключевое слово № 10',
        name: 'kl_slova_10',
        type: 'kl_slova',
        num: 10,
        placeholder: '-',
      },

      { title: 'Авторский знак', name: 'author_sign', type: 'text' },
      //  { title: 'Партия поступления', name: 'part_post', type: 'text' },
      {
        title: 'Дата ввода',
        name: 'date_vvoda',
        type: 'text',
        value: moment().format('YYYY-MM-DD'),
      },
      {
        title: 'Язык издания',
        name: 'language',
        type: 'selectLang',
        option: this.props.LanguageSelectData,
      },
      {
        title: 'Другие языки издания',
        name: 'language_notes',
        type: 'text',
        plus: false,
        placeholder: '-',
      },
      //  {
      //   title: 'Источник поступления',
      //   name: 'istok_post',
      //   type: 'selectFromBD',
      //   option: this.props.IstPosSelectData,
      //   plus: false,
      //  },
      {
        title: 'Вид документа',
        name: 'type_of_document',
        type: 'selectFromBD',
        option: this.props.VidDokSelectData,
        plus: false,
      },
      { title: 'Служебные отметки', name: 'employees_notes', type: 'text', placeholder: '-' },

      { title: 'Краткая аннотация', name: 'desctiption', type: 'text' },

      { title: 'Дополнительные ссылки', name: 'dop_links', type: 'text', placeholder: '-' },
      //  { title: 'Прикрепить файл в PDF', name: 'file_link', type: 'file', accept: '.pdf' },
      { title: 'Обложка книги', name: 'cover_url', type: 'file', accept: 'image/*' },
    ];

    const atrFormAddBookDop = [
      { title: '001: Идентификатор записи', name: 'Metka_001', type: 'textMain', placeholder: '-' },

      {
        title: '010: ## ISBN ($a - Номер)',
        name: 'Metka_010_a',
        type: 'disableDop',
        placeholder: '-',
      },
      {
        title: '010: ## ISBN ($d - Цена)',
        name: 'Metka_010_d',
        type: 'disableDop',
        placeholder: '-',
      },
      {
        title: '010: ## ISBN ($9 - Тираж)',
        name: 'Metka_010_9',
        type: 'disableDop',
        placeholder: '-',
      },

      { title: '011: ## ISSN ($a - Номер)', name: 'Metka_011', type: 'text', placeholder: '-' },

      { title: '013: ## ISMN ($a - Номер)', name: 'Metka_013_a', type: 'text', placeholder: '-' },
      {
        title: '013: ## ISMN ($b - Утонения)',
        name: 'Metka_013_b',
        type: 'text',
        placeholder: '-',
      },
      { title: '013: ## ISMN ($d - Цена)', name: 'Metka_013_d', type: 'text', placeholder: '-' },
      {
        title: '013: ## ISMN ($z - Ошибочный ISMN)',
        name: 'Metka_013_z',
        type: 'text',
        placeholder: '-',
      },

      {
        title: '014: ## Код статьи сериального ресурса ($a - Идентификатор статьи)',
        name: 'Metka_014',
        type: 'textMain',
        placeholder: '-',
      },

      {
        title: '020: ## Номер документа в нац. библиографии ($a - Код страны)',
        name: 'Metka_020_a',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '020: ## Номер документа в нац. библиографии ($b - Номер)',
        name: 'Metka_020_b',
        type: 'text',
        placeholder: '-',
      },

      {
        title: '021: ## Номер гос.регистрации ($a - Код страны)',
        name: 'Metka_021_a',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '021: ## Номер гос.регистрации ($b - Номер)',
        name: 'Metka_021_b',
        type: 'text',
        placeholder: '-',
      },

      {
        title: '100: ## Данные общей обработки ($a - Дата ввода записи в файл)',
        name: 'Metka_100',
        type: 'textMain',
        placeholder: '-',
      },

      {
        title: '101: # Язык ресурса ($a - Язык текста)',
        name: 'Metka_101_a1',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '101: # Язык ресурса ($a - Язык промежуточного перевода)',
        name: 'Metka_101_a2',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '101: # Язык ресурса ($a - Язык оригинала)',
        name: 'Metka_101_a3',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '101: # Язык ресурса ($a - Язык резюме)',
        name: 'Metka_101_a4',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '101: # Язык ресурса ($a - Язык оглавления)',
        name: 'Metka_101_a5',
        type: 'text',
        placeholder: '-',
      },

      {
        title: '102 : ## Страна публикации или производства ($a - Страна публикации)',
        name: 'Metka_102',
        type: 'textMain',
        placeholder: '-',
      },

      {
        title: '200 : 1# Заглавие и сведения об ответсвенности ($a - Основное заглавие)',
        name: 'Metka_200_a',
        type: 'disableDop',
        placeholder: '-',
      },
      {
        title: '200 : 1# Заглавие и сведения об ответсвенности ($d - Параллельное заглавие)',
        name: 'Metka_200_d',
        type: 'disableDop',
        placeholder: '-',
      },
      {
        title:
          '200 : 1# Заглавие и сведения об ответсвенности ($e - Сведения относящиеся к заглавию)',
        name: 'Metka_200_e',
        type: 'disableDop',
        placeholder: '-',
      },
      {
        title: '200 : 1# Заглавие и сведения об ответсвенности ($h - Обозначение и номер части)',
        name: 'Metka_200_h',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '200 : 1# Заглавие и сведения об ответсвенности ($i - Наименование части)',
        name: 'Metka_200_i',
        type: 'disableDop',
        placeholder: '-',
      },
      {
        title: '200 : 1# Заглавие и сведения об ответсвенности ($v - Обозначение тома)',
        name: 'Metka_200_v',
        type: 'text',
        placeholder: '-',
      },
      {
        title: '200 : 1# Заглавие и сведения об ответсвенности ($z - Язык параллельного заглавия)',
        name: 'Metka_200_z',
        type: 'text',
        placeholder: '-',
      },

      {
        title: '205: ## Сведения об издании ($a - Сведения об издании)',
        name: 'Metka_205_a',
        type: 'disableDop',
        placeholder: '-',
      },
      {
        title: '205: ## Сведения об издании ($b - Дополнительные сведения об издании)',
        name: 'Metka_205_b',
        type: 'text',
        placeholder: '-',
      },

      {
        title: '300: ## Общие примечания ($a - Текст примечания)',
        name: 'Metka_300',
        type: 'textMain',
        placeholder: '-',
      },

      {
        title: '324: ## Примечание о первоначальной (оригинальной) версии ($a - Текст примечания)',
        name: 'Metka_324',
        type: 'textMain',
        placeholder: '-',
      },
      {
        title: '325: ## Примечание о копии ($a - Текст примечания)',
        name: 'Metka_325',
        type: 'textMain',
        placeholder: '-',
      },

      {
        title:
          '509: 01 Заголовок - структурированное географическое или тематическое наименование ($a - Географическое/тематическое наименование - начальный элемент ввода)',
        name: 'Metka_509_a',
        type: 'text',
        placeholder: '-',
      },
      {
        title:
          '509: 01 Заголовок - структурированное географическое или тематическое наименование ($b - Географическое/тематическое наименование - структурное подразделение',
        name: 'Metka_509_b',
        type: 'text',
        placeholder: '-',
      },
      {
        title:
          '509: 01 Заголовок - структурированное географическое или тематическое наименование ($c - Идентифицирующий признак)',
        name: 'Metka_509_c',
        type: 'text',
        placeholder: '-',
      },
      {
        title:
          '509: 01 Заголовок - структурированное географическое или тематическое наименование ($f - Даты)',
        name: 'Metka_509_f',
        type: 'text',
        placeholder: '-',
      },
      {
        title:
          '509: 01 Заголовок - структурированное географическое или тематическое наименование ($h - Географичексий термин)',
        name: 'Metka_509_h',
        type: 'text',
        placeholder: '-',
      },
      {
        title:
          '509: 01 Заголовок - структурированное географическое или тематическое наименование ($n - Масштаб)',
        name: 'Metka_509_n',
        type: 'text',
        placeholder: '-',
      },

      {
        title: '700: #1 Имя лица - первичная ответственность ($a - Элемент ввода)',
        name: 'Metka_700',
        type: 'textMain',
        placeholder: '-',
      },

      {
        title: '701: #1 Имя лица - альтернативная ответственность ($a - Элемент ввода)',
        name: 'Metka_701',
        type: 'textMain',
        placeholder: '-',
      },

      {
        title: '702: #1 Имя лица - вторичная ответственность ($a - Элемент ввода)',
        name: 'Metka_702',
        type: 'textMain',
        placeholder: '-',
      },
    ];

    let btnAddAndClear = this.state.status ? 'btn_add_book' : 'btn_disab_book';

    return (
      <div className='form-book-add'>
        <div div className='form-add'>
          <Modal
            show={this.state.showModal}
            closeModalEvent={(e) => this.closeModalEvent(e, 'showModal')}
          >
            <div className='st12'>
              Количество совпадений: {this.state.avtorsGetMassive.length}
              <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {this.state.avtorsGetMassive.length !== 0
                  ? this.state.avtorsGetMassive.map((i, j) => (
                      <div
                        className={j === this.state.avtorSelectedN ? 'fmba2' : 'fmba1'}
                        onClick={() => this.AvtorSelectHandle(i, j)}
                      >
                        {i.name}
                      </div>
                    ))
                  : null}
              </div>
              <div
                style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}
              >
                <Button
                  className='st3'
                  text='Выбрать'
                  onClick={() => this.closeModalWithSelect()}
                />
                <Button className='st3' text='Добавить' onClick={() => this.openAddAvtorModal()} />

                <Button
                  className='st3'
                  text='Закрыть'
                  onClick={() => this.closeModalWithoutSelect()}
                />
              </div>
            </div>
          </Modal>

          <Modal
            show={this.state.showModal2}
            closeModalEvent={(e) => this.closeModalEvent(e, 'showModal2')}
          >
            <div className='st12'>
              Окно добавления автора
              <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <div className='search-filter-book__book'>
                  <input
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        addAvtor: { ...this.state.addAvtor, name: e.target.value },
                      })
                    }
                    placeholder='Имя'
                  />
                </div>
              </div>
              <div
                style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}
              >
                <Button className='st3' text='Добавить' onClick={() => this.AvtorAddSend()} />
                <Button
                  className='st3'
                  text='Закрыть'
                  onClick={() => this.closeModalWithoutSelect()}
                />
              </div>
            </div>
          </Modal>

          <Modal
            show={this.state.showModal3}
            closeModalEvent={(e) => this.closeModalEvent(e, 'showModal3')}
          >
            <div className='st12'>
              Количество совпадений: {this.state.avtorsGetMassive.length}
              <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {this.state.avtorsGetMassive.length !== 0
                  ? this.state.avtorsGetMassive.map((i, j) => (
                      <div
                        className={j === this.state.avtorSelectedN2 ? 'fmba2' : 'fmba1'}
                        onClick={() => this.AvtorSelectHandle2(i, j)}
                      >
                        {i.name}
                      </div>
                    ))
                  : null}
              </div>
              <div
                style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}
              >
                <Button
                  className='st3'
                  text='Выбрать'
                  onClick={() => this.closeModalWithSelect2()}
                />
                <Button className='st3' text='Добавить' onClick={() => this.openAddAvtorModal2()} />

                <Button
                  className='st3'
                  text='Закрыть'
                  onClick={() => this.closeModalWithoutSelect()}
                />
              </div>
            </div>
          </Modal>

          <Modal
            show={this.state.showModal5}
            closeModalEvent={(e) => this.closeModalEvent(e, 'showModal5')}
          >
            <div className='st12'>
              Количество совпадений: {this.state.publishersGetMassive.length}
              <div className='st9' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {this.state.publishersGetMassive.length !== 0
                  ? this.state.publishersGetMassive.map((i, j) => (
                      <div
                        className={j === this.state.publisherSelectedN ? 'fmba2' : 'fmba1'}
                        onClick={() => this.PublisherSelectHandle(i, j)}
                      >
                        {i.name}
                      </div>
                    ))
                  : null}
              </div>
              <div
                style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex' }}
              >
                <Button
                  className='st3'
                  text='Выбрать'
                  onClick={() => this.closeModalWithSelect3()}
                />

                <Button
                  className='st3'
                  text='Закрыть'
                  onClick={() => this.closeModalWithoutSelect()}
                />
              </div>
            </div>
          </Modal>
          <form className='form-add-wrapper' onSubmit={(e) => e.preventDefault()}>
            <div className='form-add-book__book'>
              {atrFormAddBook.map((el, i) => {
                switch (el.type) {
                  case 'select': {
                    return (
                      <div className='search-filter-book_book' key={i}>
                        <p style={{ margin: '10px 0px', fontWeight: 'bold' }}>{el.title}</p>
                        <div className='search-filter-book-rubrik'>
                          <select
                            name={el.name}
                            onChange={this.handleChangeModal}
                            value={this.state.addbook[el.name]}
                          >
                            <option hidden>Выберите данные</option>
                            {el.option.map((item, i) => {
                              // const newEl = item.length > 40 ? `${item.slice(0, 40)}...` : item;
                              return <option key={i}>{item}</option>;
                            })}
                          </select>
                          {/* <span className='plus' onClick={() => this.modalAddItem(el)}>
              +
             </span> */}
                        </div>
                      </div>
                    );
                  }
                  case 'selectLang': {
                    return (
                      <div className='search-filter-book_book' key={i}>
                        <p style={{ margin: '10px 0px', fontWeight: 'bold' }}>{el.title}</p>
                        <div className='search-filter-book-rubrik'>
                          <select
                            name={el.name}
                            onChange={this.handleChangeModal}
                            key={this.state.addbook.osn_language_lat}
                            value={this.state.addbook[el.name]}
                          >
                            <option hidden>Выберите язык</option>
                            {el.option.map((item, i) => {
                              return (
                                <option data-lat={item.lat} key={i} value={item.id}>
                                  {item.code}:{item.name}
                                </option>
                              );
                            })}
                          </select>
                          {/* <span className='plus' onClick={() => this.modalAddItem(el)}>
              +
             </span> */}
                        </div>
                      </div>
                    );
                  }
                  case 'publication_year': {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <p style={{ margin: '10px 0px', fontWeight: 'bold' }}>{el.title}</p>
                        <input
                          type='number'
                          value={this.state.addbook[el.name]}
                          onChange={this.handleChangeModal}
                        />
                      </div>
                    );
                  }
                  case 'tema': {
                    return (
                      <div className='search-filter-book_book temaWrapper' key={i}>
                        <p className='temaTitile'>{el.title}</p>
                        <div>
                          <p>Выберите язык</p>
                          <label>
                            Русский
                            <input
                              type='radio'
                              name='lang'
                              id=''
                              value='ru'
                              onChange={this.handleChange}
                            />
                          </label>
                          <label>
                            Казахский
                            <input
                              type='radio'
                              name='lang'
                              id=''
                              value='kz'
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>
                        <div className='select-together'>
                          <div className='search-filter-book-rubrik tema'>
                            <select onChange={this.handleChangeSelect}>
                              <option hidden>Выберите классификатор</option>
                              <option disabled style={{ color: 'blue', fontWeight: 'bold' }}>
                                Выберите классификатор
                              </option>
                              {temArr.map((item, i) => {
                                return (
                                  <option key={i} value={i}>
                                    {item.value[this.state.lang]}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className='search-filter-book-rubrik tema'>
                            <select
                              name={el.name}
                              onChange={this.handleChangeModal}
                              value={this.state.addbook[el.name]}
                            >
                              <option hidden>Выберите рубрику</option>
                              {/* <option disabled style={{ color: 'blue', fontWeight: 'bold' }}>
                {temArr[this.state.temArrValue].value[this.state.lang]}
               </option> */}
                              {temArr[this.state.temArrValue]?.arr.map((item, i) => {
                                return (
                                  <option key={i} data-udk={item.index_udk_bbk} value={item.id}>
                                    {this.state.lang === 'ru' ? item.name_ru : item.name_kz}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {/* <span className='plus' onClick={() => this.modalAddItem(el)}>+</span> */}
                      </div>
                    );
                  }
                  case 'selectFromBD': {
                    return (
                      <div className='search-filter-book_book' key={i}>
                        <p style={{ margin: '10px 0px', fontWeight: 'bold' }}>{el.title}</p>
                        <div className='search-filter-book-rubrik'>
                          <select
                            name={el.name}
                            onChange={this.handleChangeModal}
                            key={this.state.addbook.osn_language_lat}
                            value={this.state.addbook[el.name]}
                          >
                            <option hidden>Выберите один из пунктов списка</option>
                            {el.option.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {el.name === 'genre_id'
                                    ? this.state.lang === 'ru'
                                      ? item.name_ru
                                      : item.name_kz
                                    : item.name_ru}
                                </option>
                              );
                            })}
                          </select>
                          {/* <span className='plus' onClick={() => this.modalAddItem(el)}>
               +
              </span> */}
                        </div>
                      </div>
                    );
                  }
                  case 'selectHard': {
                    return (
                      <div className='search-filter-book_book' key={i}>
                        <p>{el.title}</p>
                        <div className='search-filter-book-rubrik'>
                          <select
                            name={el.name}
                            onChange={this.handleChangeModal}
                            value={this.state.addbook[el.name]}
                          >
                            <option hidden>Выберите данные</option>
                            {el.option.map((item, i) => {
                              return (
                                <option key={i} data-udk={item.udk}>
                                  {item.genre_id[this.state.lang]}
                                </option>
                              );
                            })}
                          </select>
                          {/* <span className='plus' onClick={() => this.modalAddItem(el)}>+</span> */}
                        </div>
                      </div>
                    );
                  }
                  case 'radio': {
                    return (
                      <div
                        className='search-filter-book_book '
                        style={{ marginTop: '10px' }}
                        key={i}
                      >
                        <div>
                          <label style={{ fontSize: '18px', fontWeight: 'bold' }}>
                            {el.title}
                            <input
                              type={el.type}
                              name={el.name}
                              id='binding'
                              onChange={this.handleChangeModal}
                              value={el.value}
                              style={{ marginLeft: '10px' }}
                            />
                          </label>
                        </div>
                      </div>
                    );
                  }
                  case 'other': {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <p>{el.title}</p>
                        <p value={this.state.addbook[el.name]}>{this.state.addbook[el.name]}</p>
                      </div>
                    );
                  }
                  case 'checkbox': {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <label>
                          {el.title}
                          <input
                            type={el.type}
                            name={el.name}
                            checked={this.state.addbook[el.name]}
                            onChange={this.handleChangeModal}
                          />
                        </label>
                      </div>
                    );
                  }
                  case 'file': {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <p>{el.title}</p>
                        <input
                          accept={el.accept}
                          type={el.type}
                          name={el.name}
                          checked={this.state.addbook[el.name]}
                          onChange={this.handleChangeModal}
                        />
                      </div>
                    );
                  }
                  //  case 'coast': {
                  //   return (
                  //    <div className='search-filter-book__book' key={i}>
                  //     <p>{el.title}</p>
                  //     <p value={+this.state.addbook.kol * +this.state.addbook.price}>
                  //      {+this.state.addbook.kol * +this.state.addbook.price}
                  //     </p>
                  //    </div>
                  //   );
                  //  }
                  case 'publisher': {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <p>{el.title}</p>

                        <input
                          type={el.type}
                          name={el.name}
                          value={this.state.addbook[el.name]}
                          placeholder={
                            el.placeholder === '-'
                              ? ''
                              : el.placeholder || 'Обязательные поля ввода'
                          }
                          onChange={this.handleChangeModal}
                          onBlur={this.PublisherChangeHandle}
                        />

                        <div style={{ marginTop: '10px' }}></div>

                        <input
                          placeholder={
                            this.state.publisherSelectedN === -1
                              ? 'Издательство не выбрано'
                              : this.state.publisherSelectedItem.name
                          }
                          disabled
                        />
                      </div>
                    );
                  }
                  case 'avtor': {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <p>{el.title}</p>

                        <input
                          type={el.type}
                          name={el.name}
                          value={this.state.addbook[el.name]}
                          placeholder={
                            el.placeholder === '-'
                              ? ''
                              : el.placeholder || 'Обязательные поля ввода'
                          }
                          onChange={this.handleChangeModal}
                          onBlur={this.AvtorChangeHandle}
                        />

                        <div style={{ marginTop: '10px' }}></div>

                        <input
                          placeholder={
                            this.state.avtorSelectedN === -1
                              ? 'Автор не выбран'
                              : this.state.avtorSelectedItem.name
                          }
                          disabled
                        />
                      </div>
                    );
                  }
                  case 'avtor2': {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <p>{el.title}</p>

                        <input
                          type={el.type}
                          name={el.name}
                          value={this.state.addbook[el.name]}
                          placeholder={
                            el.placeholder === '-'
                              ? ''
                              : el.placeholder || 'Обязательные поля ввода'
                          }
                          onChange={this.handleChangeModal}
                          onBlur={this.AvtorChangeHandle2}
                        />

                        <div style={{ marginTop: '10px' }}></div>

                        <input
                          placeholder={
                            this.state.avtorSelectedN2 === -1
                              ? 'Автор не выбран'
                              : this.state.avtorSelectedItem2.name
                          }
                          disabled
                        />
                      </div>
                    );
                  }
                  case 'kl_slova': {
                    return (
                      <>
                        {el.num <= this.state.kl_slova_dop ? (
                          <div className='search-filter-book__book' key={i}>
                            <p>{el.title}</p>
                            <input
                              type='text'
                              name={el.name}
                              value={this.state.addbook[el.name]}
                              placeholder={el.placeholder || 'Обязательные поля ввода'}
                              onChange={this.handleChangeModalWithCheck}
                            />
                            <span
                              className='plus'
                              onClick={() => this.kl_slova_dop_change(el.num + 1)}
                            >
                              {' '}
                              +
                            </span>
                          </div>
                        ) : null}
                      </>
                    );
                  }

                  default: {
                    return (
                      <div className='search-filter-book__book' key={i}>
                        <p>{el.title}</p>
                        {el.name === 'date_vvoda' ? (
                          <input
                            type='date'
                            name={el.name}
                            value={el.value}
                            placeholder='Обязательные поля ввода'
                            onChange={this.handleChangeModal}
                          />
                        ) : (
                          <input
                            type={el.type}
                            name={el.name}
                            value={this.state.addbook[el.name]}
                            placeholder={
                              el.placeholder === '-'
                                ? ''
                                : el.placeholder || 'Обязательные поля ввода'
                            }
                            onChange={this.handleChangeModal}
                          />
                        )}
                      </div>
                    );
                  }
                }
              })}
            </div>

            <div>
              <Button
                className={btnAddAndClear}
                text='Дополнительные поля'
                onClick={() => this.setState({ dopRows: !this.state.dopRows })}
              />
            </div>

            {this.state.dopRows && (
              <div>
                <div className='form-add-book__book'>
                  {atrFormAddBookDop.map((el, i) => {
                    switch (el.type) {
                      case 'disableDop': {
                        return (
                          <>
                            <div className='search-filter-book__book' key={i}>
                              <p>{el.title}</p>
                              <input
                                disabled
                                type='text'
                                name={el.name}
                                value={this.state.markFormDataDop[el.name]}
                              />
                            </div>
                          </>
                        );
                      }
                      case 'textMain': {
                        return (
                          <>
                            <div className='search-filter-book__book' key={i}>
                              <p>{el.title}</p>
                              <input
                                type='text'
                                name={el.name}
                                //  value={this.state.markFormData[el.name]}
                                onChange={this.handleInputMainMarc}
                              />
                            </div>
                          </>
                        );
                      }

                      default: {
                        return (
                          <div className='search-filter-book__book' key={i}>
                            <p>{el.title}</p>
                            <input
                              type={el.type}
                              name={el.name}
                              //  value={this.state.markFormDataDop[el.name]}
                              onChange={this.handleInputDopMarc}
                            />
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>
            )}
            <div style={{ marginTop: '20px' }} className='form-add-img-btn'>
              {/* <div className='form-add-img'></div> */}
              {/* <Button className={btnAddAndClear} text='Добавить и очистить' onClick={() => this.handleSubmit('clean')} /> */}
              <Button className={btnAddAndClear} text='Добавить' onClick={this.handleSubmit} />
              <div className='unicode'></div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
