import { useState } from 'react';
import s from './ErrorModal.module.css';

const ErrorModal = ({ button, auth = true }) => {
 const [isOpen, setIsOpen] = useState(true);
 let authLocal = true;
 if (isOpen) {
  // document.body.style.overflow = 'hidden'
  document.body.style.paddingRight = '20px';
 } else {
  document.body.style.overflow = 'visible';
  document.body.style.paddingRight = '0';
 }
 if (localStorage.getItem('modal401')) {
  localStorage.clear();
  authLocal = false;
 }

 if (localStorage.getItem('tryCatch')) {
  localStorage.removeItem('tryCatch');
 }
 let classWrapper = isOpen ? 'modalWrapper' : 'display-none';
 let message = button ? 'закрыть это сообщение' : 'обновить страницу';
 return (
  <div className={s[classWrapper]} onClick={() => setIsOpen(false)}>
   <div className={s.modal}>
    {auth && authLocal && (
     <div className={s.modalContainer}>
      <h1 className={s.modalTitle}>Что то пошло не так...</h1>
      <p className={s.modalText}>Сообщите команде разработчиков о возникшей ошибке</p>
      <p className={s.modalText}>Далее можете просто {message} и продолжить пользоваться другим функционалом!</p>
     </div>
    )}
    {button && (
     <button type='button' className={s.buttonClose} onClick={() => setIsOpen(false)}>
      Закрыть
     </button>
    )}

    {(!auth || !authLocal) && (
     <div className={s.modalContainer}>
      <h1 className={s.modalTitle}>Предупреждение!</h1>
      <p className={s.modalText}>
       Похоже, что кто-то зашел на ваш аккаунт с новой учетной записи или произошла ошибка с вашей авторизацией!
      </p>
      <p className={s.modalText}>Вам нужно просто обновить страницу и заново войти в свой аккаунт!</p>
     </div>
    )}
   </div>
  </div>
 );
};

export default ErrorModal;
