import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getZakazchik } from '../../../api';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import { setDate } from '../../../redux/action';

import './style.css';

class LibraryAdr extends Component {
 state = {
  libArr: [],
 };

 async componentDidMount() {
  const arr = await getZakazchik.getZakazchik();
  this.setState({ libArr: arr });
 }

 render() {
  const headerItemsContent = [
   { title: 'Название', data: 'name' },
   { title: 'Контакты', data: 'contact' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>База данных заказчиков и поставщиков</h1>
     </div>

     <div className='book-items-content lib-address-wrapper-table'>
      <CommonWrapperTable mainArr={this.state.libArr} headersArr={headerItemsContent} />
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(LibraryAdr);
