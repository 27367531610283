import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import Loader from '../../loader';

import './style.css'

class InfoTech extends Component {

    async componentDidMount() {
        // await this.props.getData('svod_otchet', 'svod_otchet')
    }

    render() {

        const lib = [
            { name: 'Центральная городская библиотека г.Павлодара', book: 80038, square: '972,9', adr: 'Торайгырова 44/1 т.53-44-05' },
            { name: 'Массовая  библиотека  № 3', book: 9737, square: '160,0', adr: 'П.Ленинский Сарыарка 53А т. 62-52-64' },
            { name: 'Массовая  библиотека  № 4', book: 19792, square: '77,3', adr: 'Сибирская  56   т. 51-59-31' },
            { name: 'Массовая  библиотека  им. Дихана Абилева', book: 18766, square: '312,7', adr: 'Богенбай батыра 38/2 т.20-44-01' },
            { name: 'Массовая  библиотека с. Жетекши', book: 7640, square: '17,4', adr: 'Ауэзова 15   т.35-67-85  акимат' },
            { name: 'Массовая  библиотека  с.Кенжеколь', book: 19745, square: '80,0', adr: 'Кенжекольская  5 т.35-27-36' },
            { name: 'Массовая  библиотека  с.Мойылды', book: 7080, square: '37', adr: 'Улы -Дала 5/2 т.35-66-48' },
            { name: 'Массовая  библиотека  с.Павлодарское', book: 12294, square: '120', adr: 'Молодежная 15/1 т.31-87 - 07' },
            { name: 'Центральная детская библиотека им. Мубарака Жаманбалинова', book: 41540, square: '466', adr: 'Малайсары батыра 2 т. 54-93-04' },
            { name: 'Детская библиотека  № 2', book: 16721, square: '178,3', adr: 'Майры 47 т. 52-68-08' },
            { name: 'Детская библиотека  № 3', book: 12128, square: '61,3', adr: 'Пр.Назарбаева 285-121  т.57-75-06' },
            { name: 'Детская библиотека  № 4', book: 23372, square: '144,2', adr: 'Пр.Назарбаева 20-286 т.55-34-49' },
            { name: 'Детская библиотека  № 8', book: 14191, square: '184,5', adr: 'Катаева 36/1  т-21-58-00' },
        ]

        const header = [{ title: 'Наименование' }, { title: 'Объем электронного каталога', sub: ['Всего', 'С/М'] }, { title: 'Число док-ов, переведенных в цифровой формат', sub: ['Всего', 'С/М'] },
        { title: 'Число библиотек с доступом в сеть ИНТЕРНЕТ', sub: ['Всего', 'С/М'] }, { title: 'Из них с доступов в Wi-Fi', sub: ['Всего', 'С/М'] },
        { title: 'Число  компьютеров', sub: ['Всего', 'С/М'] }, { title: 'Число компьютеров для пользователей', sub: ['Всего', 'С/М'] },
        { title: 'Число копировально-множительной техники (факс, принтер, сканер)', sub: ['Всего', 'С/М'] }, { title: 'Из них число профес. книжных сканеров', sub: ['Всего', 'С/М'] },]

        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    {this.props.svod_otchet.length
                        ?
                        <>
                            <h2>ВЫБЫЛО В ТЕЧЕНИЕ ГОДА</h2>
                            <div className='table'>
                                <div className='row dviz_lib_fond h9_150'>
                                    {header.map((el, i) => {
                                        return (
                                            el.hasOwnProperty('sub')
                                                ?
                                                <div className='row-item9 flex border'>
                                                    <div>
                                                        <p>{el.title}</p>
                                                    </div>
                                                    <div className='row-subitem flex__sub'>
                                                        <div className='border-r h d'>
                                                            <p>Всего</p>
                                                        </div>
                                                        <div>
                                                            <p>С/М</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='row-item9'>
                                                    <p>{el.title}</p>
                                                </div>
                                        )
                                    })}
                                </div>

                                {lib.map((el, i) => {
                                    return (
                                        <div className='row h11' key={i}>
                                            <div className='row-item9'>
                                                <div className='row-subitem__data w'>
                                                    <p>{el.name}</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                    <p>1_3</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                            <div className='row-item9 h11'>
                                                <div className='row-subitem__data w'>
                                                    <p>1_1</p>
                                                    <p>1_2</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <Loader />}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(InfoTech);