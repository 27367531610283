import React, { Component } from 'react';

import starG from '../../assets/icon/star-g.png'
import starY from '../../assets/icon/star-y.png'

class ReitStar extends Component {

    state = {
        maxNumReit: 100,

        starG: '/static/media/star-g.f1e549b9.png',
        starY: '/static/media/star-y.a1bd4d38.png',

        grayStar: 5,
        reitStar: 0
    }


    async componentDidMount() {
        const reitStar = await Math.ceil(this.props.countReit / (this.state.maxNumReit / 5));
        const grayStar = await this.state.grayStar - reitStar;
        this.setState({ reitStar, grayStar });
    }


    render() {

        return (
            <>
                <div className='reit'>
                    <span>Рейтинг: ({this.props.countReit})</span>
                    <div>
                        {[new Array(this.state.reitStar).fill(this.state.starY).map((el, i) => <img src={el} alt='starG' key={i} />)]}
                        {[new Array(this.state.grayStar).fill(this.state.starG).map((el, i) => <img src={el} alt='starG' key={i} />)]}
                    </div>
                </div>
                <p>Книга была взята: {this.props.countReit} раз</p>
            </>
        );
    }
}

export default ReitStar;