import React, { Component } from 'react';

import { connect } from 'react-redux';
import { logIn } from '../../redux/action';

import { Auth } from '../../api';

import toast from 'react-hot-toast';

import Button from '../../components/button';
import history from '../../components/history';

import MaskedInput from 'react-maskedinput';

import logo from '../../assets/icon/logo.png';

import './style.css';

class LogIn extends Component {
 state = {
  phone: '',
  pass: '',
  // lib_name: ''
 };

 handleChange = async ({ target }) => await this.setState({ [target.name]: target.value.trim() });

 //Вход
 logIn = async (event) => {
  await event.preventDefault();
  const data = await { login: this.state.phone, password: this.state.pass };
  const json = await Auth.Auth(data);
  const name = json.first_name + ' ' + json.last_name + ' ' + json.middle_name;
  var localRoles = '';
  if (json.token) {
   json.roles.map((i) => (localRoles = localRoles + i.title + ','));

   toast(`Добро пожаловать, ${name}!`);
   localStorage.setItem('tokenLibrary', json.token);
   localStorage.setItem('idUser', json.id);
   localStorage.setItem('roles', localRoles);
   localStorage.setItem('library_id', json.library_id);
   localStorage.setItem('library_name', json.name);

   localStorage.setItem('nameUser', name);

   console.log(json);
   json.library_id == null ? localStorage.setItem('libcheck', 'true') : console.info();
   await this.props.logIn(true);

   history.push('/');
  } else {
   toast(`Ошибка аутентификации`);
  }
 };

 Автовход;
 async componentDidMount() {
  try {
   if (localStorage.getItem('tokenLibrary')) {
    //  const json = await Auth.AutoAuth();
    //  await this.props.logIn(
    //   true,
    //   localStorage.getItem('nameUser'),
    //   localStorage.getItem('lib_name'),
    //   localStorage.getItem('role'),
    //   'new api!'
    //  );
    //  // history.push('/');

    await this.props.logIn(true);

    history.push('/');
   }
  } catch (error) {}
 }

 render() {
  return (
   <div className='login-wrapper'>
    <div className='login-logo'>
     <img src={logo} alt='logo' />
    </div>

    <div className='log'>
     <div className='log-wrapper'>
      <h1>Вход</h1>

      <form onSubmit={this.logIn}>
       {/* <select name='lib_name' onChange={this.handleChange}>
                                <option>Выберите библиотеку</option>
                                {this.props.library_id.map(el => {
                                    // const newEl = el.name.length > 30 ? `${el.name.slice(0, 30)}...` : el.name;
                                    return <option key={el.id}>{el.name}</option>
                                })}
                            </select> */}

       <div className='login-input'>
        {/* <input type='text' name='phone' autoComplete='off' placeholder='Номер телефона' onChange={this.handleChange} /> */}
        <MaskedInput
         mask='+7(***) *** - ****'
         onChange={(e) => this.setState({ phone: e.target.value })}
         value={this.state.phone}
         className='loginPhone'
        />
       </div>

       <div className='pass-input'>
        <input type='text' name='pass' autoComplete='off' placeholder='Пароль' onChange={this.handleChange} />
       </div>

       <Button text='ВОЙТИ' className='btn_enter' />
      </form>
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { logIn };

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
