import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Loader from '../../../components/loader';
import Button from '../../../components/button';

import './style.css';

class KomplentFond extends Component {
 state = {
  inputSearch: '',

  searchBook: [],

  selectedBook: [],

  lib_name: '',
 };

 handleChangeModal = (e) => this.setState({ lib_name: e.target.value });

 scanerRequest = async (e) => {
  this.setState({ inputSearch: e.target.value });
  const res = await fetch('https://smart-pvl.kz/Api/SmartSreach.php', {
   method: 'POST',
   body: JSON.stringify({
    col: '*',
    tab: 'books',
    id_name: 'token',
    id_val: e.target.value,
    shtrih: true,
    searchAll: 'All',
    NoPag: true,
   }),
  });
  const json = await res.json();
  await this.setState({ searchBook: json });
  if (e.target.value.length === 0) await this.setState({ searchBook: [] });
 };

 selectedBook = (el) => {
  this.setState({ searchBook: [], inputSearch: '', selectedBook: [...this.state.selectedBook, el] });
 };

 removeBook = (el) => {
  this.setState({
   searchBook: [],
   inputSearch: '',
   selectedBook: this.state.selectedBook.filter((book) => book.id !== el.id),
  });
 };

 perenap = async () => {
  const bookId = await this.state.selectedBook.map((el) => el.id);
  const res = await fetch('https://smart-pvl.kz/Api/Perenap.php', {
   method: 'POST',
   body: JSON.stringify([this.state.lib_name, ...bookId]),
  });
  const json = await res.json();
  if (json) alert(`Книги перенаправленны в ${this.state.lib_name}`);
  this.setState({ searchBook: [], selectedBook: [], lib_name: '' });
 };

 render() {
  console.log(this.state);
  const headerItemsContent = [
   { title: 'Автор', data: 'avtor' },
   { title: 'Название книги', data: 'name' },
   { title: 'Год издания', data: 'god' },
   { title: 'Издатель', data: 'izdatel' },
   { title: 'Тираж', data: 'tirazh' },
   { title: 'Место издания', data: 'place_izdn' },
   { title: 'Серия', data: 'seria' },
   { title: 'ISBN', data: 'ISBN' },
  ];

  let url = this.props.match.url.slice(1);

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Формирование и обработка заявок на комплектование фондов библиотеки</h1>
     </div>

     <div className='smart-search por'>
      <input
       type='text'
       value={this.state.inputSearch}
       placeholder='Введите данные или отсканируйте штрих-код для поиска'
       onChange={(e) => this.scanerRequest(e)}
      />
      <div className='searchZhanr'>
       {this.state.searchBook.slice(0, 5).map((el, i) => {
        return (
         <div className='res_searchZhanr'>
          <p key={i} onClick={() => this.selectedBook(el)}>
           {el.name}
          </p>
         </div>
        );
       })}
      </div>
     </div>

     {this.state.selectedBook.length ? (
      <div className='search-filter-book-rubrik'>
       <select name='' onChange={this.handleChangeModal} className='mr20'>
        <option hidden>Выберите библиотеку</option>
        {this.props.library_id.map((el) => (
         <option>{el.name}</option>
        ))}
       </select>

       {this.state.lib_name ? (
        <Button text='Перенаправить' className='btn_library_create' onClick={this.perenap} />
       ) : null}
      </div>
     ) : null}

     <div className='book-items-content'>
      <div className='book-items-header'>
       {headerItemsContent.map((el, i) => {
        return (
         <div key={i}>
          <p>{el.title}</p>
         </div>
        );
       })}
      </div>

      {this.state.selectedBook.map((el, i) => {
       let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
       return (
        <div className={oddEven} key={el.id}>
         <div>
          <p>{el.avtor}</p>
         </div>
         <div>
          <p>{el.name}</p>
         </div>
         <div>
          <p>{el.god}</p>
         </div>
         <div>
          <p>{el.izdatel}</p>
         </div>
         <div>
          <p>{el.tirazh}</p>
         </div>
         <div>
          <p>{el.place_izdn}</p>
         </div>
         <div>
          <p>{el.seria}</p>
         </div>
         <div>
          <p>{el.ISBN}</p>
         </div>
         <Button text='Удалить' className='btn_library_edit' onClick={() => this.removeBook(el)} />
        </div>
       );
      })}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(KomplentFond);
