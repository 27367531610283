import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import toast from 'react-hot-toast';
import { getSvodStatByAge } from '../../../api';
import Button from '../../../components/button';

import '../otchet_set/style.css';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CSVDownload } from 'react-csv';

class OtchetSet6 extends Component {
 state = {
  arr_stat: [],

  open_1: false,
  open_2: false,
  date1: '',
  date2: '',

  csv: [],
 };

 handleDate = async () => {
  try {
   const res = await getSvodStatByAge.getSvodStatByAge({});
   const data = Object.values(res);
   this.setState({ arr_stat: data, open_1: true });
  } catch {
   console.log('err');
  }
 };

 handleTXT = () => {
  var text = '';
  this.state.arr_stat.map(
   (data) =>
    (text =
     text +
     data.lib +
     ':' +
     '\r\n' +
     'до 5 лет: ' +
     data.to_5 +
     '\r\n' +
     'от 5 до 15: ' +
     data.f_5_15 +
     '\r\n' +
     'от 15 до 21: ' +
     data.f_15_21 +
     '\r\n' +
     'от 21 до 35: ' +
     data.f_21_35 +
     '\r\n' +
     'от 35 до 60: ' +
     data.f_35_60 +
     '\r\n' +
     'более 60 лет: ' +
     data.f_60 +
     '\r\n')
  );

  console.log(text);
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', 'Отчет по возрасту читателей');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
 };

 handlePDF = () => {
  try {
   const input = document.getElementById('divDownload');
   html2canvas(input).then((canvas) => {
    const ImageData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.addImage(ImageData, 'JPEG', 0, 0);
    pdf.save('Отчет по возрасту читателей.pdf');
   });
  } catch {
   console.log('err');
  }
 };

 handleCSV = () => {
  try {
   var majorArr = [];
   var headerArr = [];

   headerArr.push('Библиотека');
   headerArr.push('до 5 лет');
   headerArr.push('от 5 до 15');
   headerArr.push('от 15 до 21');
   headerArr.push('от 21 до 35');
   headerArr.push('от 35 до 60');
   headerArr.push('более 60 лет');

   majorArr.push(headerArr);

   this.state.arr_stat.map((data) => {
    var minorArr = [];
    minorArr.push(data.lib);
    minorArr.push(data.to_5);
    minorArr.push(data.f_5_15);
    minorArr.push(data.f_15_21);
    minorArr.push(data.f_21_35);
    minorArr.push(data.f_35_60);
    minorArr.push(data.f_60);

    majorArr.push(minorArr);
   });

   console.log(majorArr);
   this.setState({ csv: majorArr, open_2: true });
  } catch {
   console.log('err');
  }
 };

 render() {
  const headerItemsContent_1 = [
   { title: 'Библиотека', data: 'lib' },
   { title: 'до 5 лет', data: 'to_5' },
   { title: 'от 5 до 15', data: 'f_5_15' },
   { title: 'от 15 до 21', data: 'f_15_21' },
   { title: 'от 21 до 35', data: 'f_21_35' },
   { title: 'от 35 до 60', data: 'f_35_60' },
   { title: 'более 60 лет', data: 'f_60' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Формирование сводной отчетности по возрасту читателей</h1>
     </div>

     <div>
      {!this.state.open_1 && <Button text='Сформировать отчет' className='btn_library_del' onClick={this.handleDate} />}

      {this.state.open_1 && (
       <div className='searchSved'>
        <div className='buttons-container'>
         <Button text='TXT' className='btn_library_del' onClick={this.handleTXT} />
         <Button text='PDF' className='btn_library_del' onClick={this.handlePDF} />
         <Button text='CSV' className='btn_library_del' onClick={this.handleCSV} />
         {this.state.open_2 && <CSVDownload data={this.state.csv} target='_blank' />}
        </div>
       </div>
      )}

      {this.state.open_1 && (
       <div id='divDownload' style={{ width: '210mm', padding: '20px', margin: 'auto' }}>
        <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_1} />
       </div>
      )}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(OtchetSet6);
