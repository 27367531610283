export default function getSpravFormValues(form, objOfValues) {
    const arr = Object.keys(objOfValues)
    let obj = {}

    for (let i = 0; i < arr.length; i++) {
        if(arr[i] === 'type'){
            obj[arr[i]] = objOfValues.type
        } else if (arr[i] !== 'id' && arr[i] !== 'code') {
            if (form.get(arr[i]) && form.get(arr[i]).trim() === '') return false
            obj[arr[i]] = form.get(arr[i])
        } else if (arr[i] === 'code') {
            obj.code = form.get('name')
        }
    }
    return obj
}