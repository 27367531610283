import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, delItem } from '../../redux/action';

import FormReaderAdd from '../../components/formReaderAdd';
import Pagination from '../../components/pagination';
import { eventCustomer } from '../../api';
import CommonWrapperTable from '../../components/tables/CommonWrapperTable/CommonWrapperTable';
import debounce from '../../utils/debounce';
import Button from '../../components/button';
import MaskedInput from 'react-maskedinput';
import toast from 'react-hot-toast';

class Reader extends Component {
  state = {
    currentPagePag: '1',

    choiceItem: 'readers',
    textBtn: '',

    scanerCode: null,

    selectedItem: null,

    readersArr: [],
    isPhoneFilter: false,
  };

  pagination = async (pageNum) => {
    const res = await eventCustomer.getCustomer(pageNum);
    this.setState({ currentPagePag: pageNum, readersArr: res });
  };

  VisitUser = async (el) => {
    const res = await eventCustomer.eventCustomer({ customer_id: el.id }, 'visit');
    if (res) {
      toast.success('Посещение отмечено');
    } else {
      toast.error('Что-то пошло не так..');
    }
  };

  editUser = (el) => {
    this.setState({ selectedItem: el, choiceItem: 'edit', textBtn: 'Редактировать' });
  };

  delUser = async (id) => {
    await eventCustomer.eventCustomer({ id }, 'delete');
    const res = await eventCustomer.getCustomer(this.state.currentPagePag);
    if (res) {
      this.setState({ readersArr: res });
    } else {
      toast.error('Что-то пошло не так..');
    }
  };

  //выбор раздела Читатели, Регистрация, Редактирование
  choiceItem = async (e) => {
    if (e === 'readers' && this.state.choiceItem !== 'readers') {
      const res = await eventCustomer.getCustomer(this.state.currentPagePag);
      this.setState({ readersArr: res });
    }
    this.setState({ choiceItem: e, selectedItem: null, textBtn: 'Добавить' });
  };

  async componentDidMount() {
    const res = await eventCustomer.getCustomer(this.state.currentPagePag);
    this.setState({ readersArr: res });
  }

  filterValues = async (e) => {
    const res = await eventCustomer.eventCustomer({ value: e.target.value }, 'search');
    this.setState({ readersArr: ['fake', ...res] });
  };

  render() {
    const choiceItem = [
      { title: 'Читатели', data: 'readers' },
      { title: 'Регистрация читателя', data: 'add' },
    ];

    const headerItemsContent = [
      { title: 'Ф.И.О.', data: 'FIO' },
      { title: 'Номер телефона', data: 'phone_number' },
      { title: 'Электронная почта', data: 'email' },
      { title: 'ИИН', data: 'iin' },
      { title: 'Читательский билет', data: 'library_ticket' },
      { title: 'Дата рождения', data: 'birthday' },
      { title: 'Пол', data: 'gender' },
      { title: 'Действие', data: 'button' },
    ];

    const buttons = [
      { title: 'Отметить посещение', event: (e) => this.VisitUser(e) },
      { title: 'Редактировать', event: (e) => this.editUser(e) },
      { title: 'Удалить', event: (e) => this.delUser(e.id), style: 'buttonDel' },
    ];
    return (
      <div className='page-main'>
        <div className='page-out-wrapper'>
          <div className='title-total'>
            <h1>ЧИТАТЕЛИ</h1>
          </div>

          <div className='choice-type'>
            {choiceItem.map((el, i) => (
              <h3 key={i} onClick={() => this.choiceItem(el.data)}>
                {el.title}
              </h3>
            ))}
          </div>

          {this.state.choiceItem !== 'readers' ? (
            <FormReaderAdd
              choiceItem={this.choiceItem}
              type={this.state.choiceItem}
              textBtn={this.state.textBtn}
              item={this.state.selectedItem}
            />
          ) : null}

          {this.state.choiceItem === 'readers' ? (
            <>
              <div className='smart-search'>
                <div className='wrapper-reader-button'>
                  <Button
                    text={
                      this.state.isPhoneFilter
                        ? 'Переключить на поиск по ФИО/чит.билету'
                        : 'Переключить на поиск по телефону'
                    }
                    className='btn_library_del'
                    onClick={() => this.setState({ isPhoneFilter: !this.state.isPhoneFilter })}
                  />
                </div>
                {this.state.isPhoneFilter ? (
                  <MaskedInput mask='+7(111) 111 - 1111' onChange={debounce(this.filterValues)} />
                ) : (
                  <input
                    type='text'
                    placeholder='Введите ФИО/чит.билет'
                    onChange={debounce(this.filterValues)}
                  />
                )}
              </div>
              <div className='book-items-content'>
                {this.state.readersArr.length < 2 ? (
                  <div className='search_output_null'>
                    <h1>:(</h1>
                    <h3>По данному поиску нет совпадений, или раздел пуст</h3>
                  </div>
                ) : (
                  <CommonWrapperTable
                    buttons={buttons}
                    mainArr={this.state.readersArr.slice(1)}
                    headersArr={headerItemsContent}
                  />
                )}
              </div>
              {this.state.readersArr[0]?.count / 20 + 1 > 1 && (
                <Pagination
                  pageCount={Math.floor(this.state.readersArr[0]?.count / 20 + 1)}
                  paginationPages={(pageNum) => this.pagination(pageNum)}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, delItem };

export default connect(mapStateToProps, mapDispatchToProps)(Reader);
