const setPublPlaceDataSelects = (json) => {
 return {
  type: 'SET_PublPlaceDataSelects_SELECT_DATA',
  payload: json,
 };
};

const setSupliersDataSelects = (json) => {
 return {
  type: 'SET_SupliersDataSelects_SELECT_DATA',
  payload: json,
 };
};
const setEducation2DataSelects = (json) => {
 return {
  type: 'SET_Education2DataSelects_SELECT_DATA',
  payload: json,
 };
};
const setStaffingSelectsData = (json) => {
 return {
  type: 'SET_StaffingSelectsData_SELECT_DATA',
  payload: json,
 };
};
const setLibLevelSelectsData = (json) => {
 return {
  type: 'SET_LibLevelSelectsData_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeGenres = (json) => {
 return {
  type: 'SET_Genres_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeOtrasliZnanii = (json) => {
 return {
  type: 'SET_OtrasliZnanii_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeSoderj = (json) => {
 return {
  type: 'SET_Soderj_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeVidDok = (json) => {
 return {
  type: 'SET_VidDok_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeIstPos = (json) => {
 return {
  type: 'SET_IstPos_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeStatus = (json) => {
 return {
  type: 'SET_Status_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeSchool = (json) => {
 return {
  type: 'SET_School_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeEducation = (json) => {
 return {
  type: 'SET_Education_SELECT_DATA',
  payload: json,
 };
};

const setUDKSelectsByGeneral = (json) => {
 return {
  type: 'SET_UDKSelectsByGeneral_SELECT_DATA',
  payload: json,
 };
};

const setUDKSelectsByKazakstanika = (json) => {
 return {
  type: 'SET_UDKSelectsByKazakstanika_SELECT_DATA',
  payload: json,
 };
};

const setUDKSelectsByKraeved = (json) => {
 return {
  type: 'SET_UDKSelectsByKraeved_SELECT_DATA',
  payload: json,
 };
};

const setUDKSelectsByBBK = (json) => {
 return {
  type: 'SET_UDKSelectsByBBK_SELECT_DATA',
  payload: json,
 };
};

const setSelectsAllByTypeNationality = (json) => {
 return {
  type: 'SET_Nationality_SELECT_DATA',
  payload: json,
 };
};
// отображаемая часть поступившей партии
const setBOPartiaPart = (json) => {
 return {
  type: 'SET_BOPartiaPart_SELECT_DATA',
  payload: json,
 };
};

const setDate = (json, page, item) => {
 return {
  type: 'SET_DATE',
  payload: json,
  page,
  item,
 };
};

const setRolesData = (json) => {
 return {
  type: 'SET_ROLES_DATA',
  payload: json,
 };
};

const setMaritalStatusData = (json) => {
 return {
  type: 'SET_MARITAL_DATA',
  payload: json,
 };
};

const setPositionsData = (json) => {
 return {
  type: 'SET_POSITIONS_DATA',
  payload: json,
 };
};
const setAllByTypeEducationStaff = (json) => {
 return {
  type: 'SET_STAFF_EDUCATION_DATA',
  payload: json,
 };
};

const setSiglsDate = (json) => {
 return {
  type: 'SET_SIGLS_DATE',
  payload: json,
 };
};

const setLanguageSelectData = (json) => {
 return {
  type: 'SET_LanguageSelectData_DATA',
  payload: json,
 };
};

const logIn = (data, user, libname, role, numAllBook) => {
 return {
  type: 'LOG_IN',
  payload: data,
  user,
  libname,
  role,
  numAllBook,
 };
};

const logOut = () => {
 return {
  type: 'LOG_OUT',
 };
};

const delItem = (state, id) => {
 return {
  type: 'DEL_ITEM',
  state,
  id,
 };
};

const editUserAC = (user) => {
 return {
  type: 'EDIT_USER',
  user,
 };
};

const cleanAnyState = (state) => {
 return {
  type: 'CLEAN_ANY_STATE',
  payload: state,
 };
};

// ------- Бронеполка
const bronPolka = (id, value) => ({ type: 'BRONE_POLKA', id, value });

const returnBook = (id, value) => ({ type: 'RETURN_BOOK', id });

const add_period_calendar = (item) => ({ type: 'ADD_PERIOD_CALENDAR', item });

export {
 setUDKSelectsByGeneral,
 setUDKSelectsByKazakstanika,
 setUDKSelectsByKraeved,
 setUDKSelectsByBBK,
 setLanguageSelectData,
 setBOPartiaPart,
 setSelectsAllByTypeEducation,
 setSelectsAllByTypeGenres,
 setSelectsAllByTypeIstPos,
 setSelectsAllByTypeNationality,
 setSelectsAllByTypeOtrasliZnanii,
 setSelectsAllByTypeSchool,
 setSelectsAllByTypeSoderj,
 setSelectsAllByTypeStatus,
 setSelectsAllByTypeVidDok,
 setSiglsDate,
 setRolesData,
 setMaritalStatusData,
 setPositionsData,
 setAllByTypeEducationStaff,
 setDate,
 logIn,
 logOut,
 bronPolka,
 returnBook,
 add_period_calendar,
 delItem,
 editUserAC,
 cleanAnyState,
 setPublPlaceDataSelects,
 setSupliersDataSelects,
 setEducation2DataSelects,
 setStaffingSelectsData,
 setLibLevelSelectsData,
};
