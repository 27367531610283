import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

// import '../sved_user/style.css';
import { getStatByAge, getStatByMW, getStatByGenre, getStatByTitle } from '../../../api';
import toast from 'react-hot-toast';
import Chart from '../../../components/charts/horizontal_bar';

class AnalitReaders extends Component {
 state = {
  arr_stat_age: [],
  arr_stat_gender: [],
  arr_stat_genre: [],
  arr_stat_title: [],

  titlesArr: [],
  numbersArr: [],

  open_1: false,
  open_2: false,
  open_3: false,
  open_4: false,
 };

 handleAge = async () => {
  const res = await getStatByAge.getStatByAge();

  var to_5 = 0;
  var from_5_to_15 = 0;
  var from_15_to_21 = 0;
  var from_21_to_35 = 0;
  var from_35_to_60 = 0;
  var from_60 = 0;

  res.map((data) => {
   var date = new Date(data.birthday);
   var dateMilli = date.getTime();
   var year = Math.floor(dateMilli / 31536000000);
   year > from_60
    ? (from_60 = from_60 + 1)
    : year > from_35_to_60
    ? (from_35_to_60 = from_35_to_60 + 1)
    : year > from_21_to_35
    ? (from_21_to_35 = from_21_to_35 + 1)
    : year > from_15_to_21
    ? (from_15_to_21 = from_15_to_21 + 1)
    : year > from_5_to_15
    ? (from_5_to_15 = from_5_to_15 + 1)
    : (to_5 = to_5 + 1);
  });

  const obj_to_5 = { age: to_5, title: 'до 5 лет' };
  const obj_from_5_to_15 = { age: from_5_to_15, title: 'от 5 до 15' };
  const obj_from_15_to_21 = { age: from_15_to_21, title: 'от 15 до 21' };
  const obj_from_21_to_35 = { age: from_21_to_35, title: 'от 21 до 35' };
  const obj_from_35_to_60 = { age: from_35_to_60, title: 'от 35 до 60' };
  const obj_from_60 = { age: from_60, title: 'более 60 лет' };

  var yearsArr = [];

  yearsArr.push(obj_to_5);
  yearsArr.push(obj_from_5_to_15);
  yearsArr.push(obj_from_15_to_21);
  yearsArr.push(obj_from_21_to_35);
  yearsArr.push(obj_from_35_to_60);
  yearsArr.push(obj_from_60);

  var titlesArr = [];
  var numbersArr = [];
  yearsArr.map((data) => {
   titlesArr.push(data.title);
   numbersArr.push(data.age);
  });

  this.setState({
   titlesArr: titlesArr,
   numbersArr: numbersArr,
   open_1: true,
   open_2: false,
   open_3: false,
   open_4: false,
  });
 };

 handlePol = async () => {
  const res = await getStatByMW.getStatByMW();

  var men = 0;
  var women = 0;

  res.map((data) => {
   data.gender == 0 ? (men = men + 1) : (women = women + 1);
  });

  const data = { men: men, women: women };

  var genderArr = [];

  genderArr.push(data);

  var titlesArr = [];
  var numbersArr = [];

  titlesArr.push('Мужчины');
  titlesArr.push('Женщины');

  numbersArr.push(genderArr[0].men);
  numbersArr.push(genderArr[0].women);

  this.setState({
   titlesArr: titlesArr,
   numbersArr: numbersArr,
   open_1: false,
   open_2: true,
   open_3: false,
   open_4: false,
  });
 };

 handleGenre = async () => {
  const res = await getStatByGenre.getStatByGenre();

  var titlesArr = [];
  var numbersArr = [];
  res.map((data) => {
   titlesArr.push(data.title);
   numbersArr.push(data.by_g_number);
  });

  this.setState({
   titlesArr: titlesArr,
   numbersArr: numbersArr,
   open_1: false,
   open_2: false,
   open_3: true,
   open_4: false,
  });
 };

 handleTitle = async () => {
  const res = await getStatByTitle.getStatByTitle();

  var titlesArr = [];
  var numbersArr = [];
  res.map((data) => {
   titlesArr.push(data.title);
   numbersArr.push(data.by_t_number);
  });

  this.setState({
   titlesArr: titlesArr,
   numbersArr: numbersArr,
   open_1: false,
   open_2: false,
   open_3: false,
   open_4: true,
  });
 };

 render() {
  const headerItemsContent_1 = [
   { title: 'Возраст', data: 'title' },
   { title: 'Количество', data: 'age' },
  ];

  const headerItemsContent_2 = [
   { title: 'Мужчины', data: 'men' },
   { title: 'Женщины', data: 'women' },
  ];

  const headerItemsContent_3 = [
   { title: 'Жанр', data: 'title' },
   { title: 'Количество', data: 'by_g_number' },
  ];

  const headerItemsContent_4 = [
   { title: 'Заглавие', data: 'title' },
   { title: 'Количество', data: 'by_t_number' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Статистика читателей</h1>
     </div>

     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Отчет по возрасту' className='btn_library_del' onClick={this.handleAge} />
       <Button text='Отчет по половому признаку' className='btn_library_del' onClick={this.handlePol} />
       <Button text='Отчет по жанрам литературы' className='btn_library_del' onClick={this.handleGenre} />
       <Button text='Отчет по названиям литературы' className='btn_library_del' onClick={this.handleTitle} />
      </div>
     </div>
     <div className='monitor' style={{ marginTop: '40px' }}>
      {this.state.open_1 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_2 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_3 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
      {this.state.open_4 && <Chart titlesArr={this.state.titlesArr} numbersArr={this.state.numbersArr} />}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(AnalitReaders);
