import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { useEffect, useState } from 'react';

import hiddenText from '../../../helpers/hiddenText';
import Button from '../../button';
import s from './CommonWrapperTable.module.css';

const CommonWrapperTable = ({ headersArr, mainArr, buttons }) => {
  const [libsOpen, set_libsOpen] = useState(0);

  const StaffName = (last_name, first_name, middle_name) => {
    const last_name_check = last_name == null ? '' : last_name;
    const first_name_check = first_name == null ? '' : first_name;
    const middle_name_check = middle_name == null ? '' : middle_name;
    const FIO = last_name_check + ' ' + first_name_check + ' ' + middle_name_check;
    return FIO;
  };

  return (
    <TableContainer className={s.tableContainer}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            {headersArr.map((elem, i) => (
              <TableCell className={s.cellMain} key={i} align='center'>
                {elem.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mainArr.map((elem, j) => (
            <TableRow key={elem.id || j}>
              {headersArr.map((value, i) => {
                if (
                  value.data !== 'button' &&
                  value.data !== 'gender' &&
                  value.data !== 'employee_name' &&
                  value.data !== 'StaffFIO' &&
                  value.data !== 'StaffLibName' &&
                  value.data !== 'inventory_numbers_bookAdd' &&
                  value.data !== 'StaffRoles' &&
                  value.data !== 'price' &&
                  value.data !== 'number_of_pages' &&
                  value.data !== 'publication_year' &&
                  value.data !== 'libraries' &&
                  value.data !== 'book_status' &&
                  value.data !== 'button_href' &&
                  value.data !== 'buttons_by_accept' &&
                  value.data !== 'buttons_by_accept_serv' &&
                  value.data !== 'bf_title' &&
                  value.data !== 'bf_file' &&
                  value.data !== 'bf_buttons' &&
                  value.data !== 'type_of_gazet' &&
                  value.data !== 'libFrom' &&
                  value.data !== 'authorCheck' &&
                  value.data !== 'booksNDop_1' &&
                  value.data !== 'authorForBroned'
                ) {
                  const popupClass =
                    hiddenText(elem[value.data]).hidden && value.data !== 'phone_number'
                      ? s.cellHover
                      : s.cell;
                  return (
                    <TableCell
                      data-title={elem[value.data]}
                      key={i}
                      className={popupClass}
                      align='center'
                    >
                      {hiddenText(elem[value.data] ? elem[value.data] : 'Нет данных').str ||
                        'Не указано'}
                    </TableCell>
                  );
                } else if (value.data === 'gender') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem[value.data] ? 'жен' : 'муж'}
                    </TableCell>
                  );
                } else if (value.data === 'employee_name') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem[value.data] == null ? '-' : elem[value.data]}
                    </TableCell>
                  );
                } else if (value.data == 'authorForBroned') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.author[0]}
                    </TableCell>
                  );
                } else if (value.data == 'StaffFIO') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {StaffName(elem.first_name, elem.last_name, elem.middle_name)}
                    </TableCell>
                  );
                } else if (value.data == 'StaffLibName') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.name == null ? 'Сотрудник не привязан к библиотеке' : elem.name}
                    </TableCell>
                  );
                } else if (value.data == 'authorCheck') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.author == null ? 'Нет автора' : elem.author}
                    </TableCell>
                  );
                } else if (value.data == 'StaffRoles') {
                  return (
                    <TableCell
                      key={i}
                      data-title={elem.roles}
                      className={hiddenText(elem.roles).hidden ? s.cellHover : s.cell}
                      align='center'
                    >
                      {elem.roles == null ? 'Сотрудник не имеет прав' : hiddenText(elem.roles).str}
                    </TableCell>
                  );
                } else if (value.data == 'bf_title') {
                  return (
                    <TableCell
                      key={i}
                      data-title={elem.roles}
                      className={hiddenText(elem.roles).hidden ? s.cellHover : s.cell}
                      align='center'
                    >
                      {elem.bf_title == null ? 'Отсутствует' : hiddenText(elem.bf_title).str}
                    </TableCell>
                  );
                } else if (value.data == 'libFrom') {
                  return (
                    <TableCell
                      key={i}
                      data-title={elem.libFrom}
                      className={hiddenText(elem.libFrom).hidden ? s.cellHover : s.cell}
                      align='center'
                    >
                      {elem.libFrom == null
                        ? 'Сотрудник не закреплен'
                        : hiddenText(elem.libFrom).str}
                    </TableCell>
                  );
                } else if (value.data == 'bf_file') {
                  return (
                    <TableCell
                      key={i}
                      data-title={elem.roles}
                      className={hiddenText(elem.roles).hidden ? s.cellHover : s.cell}
                      align='center'
                    >
                      {elem.bf_file == null ? '-' : hiddenText(elem.bf_file).str}
                    </TableCell>
                  );
                } else if (value.data == 'publication_year') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.publication_year + ' г.'}
                    </TableCell>
                  );
                } else if (value.data == 'price') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.price + ' тнг.'}
                    </TableCell>
                  );
                } else if (value.data == 'number_of_pages') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.number_of_pages + ' стр.'}
                    </TableCell>
                  );
                } else if (value.data == 'type_of_gazet') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.type == 0
                        ? 'Газета'
                        : elem.type == 1
                        ? 'Журнал'
                        : 'Периодическое издание'}
                    </TableCell>
                  );
                } else if (value.data == 'booksNDop_1') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.booksN
                        ? elem.booksN2
                          ? elem.booksN + elem.booksN2
                          : elem.booksN
                        : elem.booksN2
                        ? elem.booksN2
                        : 0}
                      {/* {elem.booksN ? elem.booksN : 0 + elem.booksN2 ? elem.booksN2 : 0} */}
                    </TableCell>
                  );
                } else if (value.data == 'inventory_numbers_bookAdd') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.inventory_numbers == null
                        ? 'Нет инвентарных номеров'
                        : elem.inventory_numbers.split(',').map((item, i) => (
                            <p key={i} className={s.inventory_numbers_bookAdd}>
                              {item}
                              <br />
                              <br />
                            </p>
                          ))}
                    </TableCell>
                  );
                } else if (value.data == 'libraries') {
                  return (
                    <TableCell key={i} className={s.libs} align='center'>
                      {elem.libraries == null ? (
                        'Нет библиотек'
                      ) : (
                        <>
                          <div className={s.libsWrapper}>
                            <div
                              style={{ display: libsOpen == j ? 'none' : 'block' }}
                              className={s.libs1}
                              onClick={() => set_libsOpen(j)}
                            >
                              Раскрыть
                            </div>
                            <div
                              style={{ display: libsOpen == j ? 'block' : 'none' }}
                              onClick={() => set_libsOpen(0)}
                            >
                              {elem.libraries.split('&').map((item, i) => (
                                <p key={i} className={s.libs2}>
                                  {item}
                                  <br />
                                  <br />
                                </p>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </TableCell>
                  );
                } else if (value.data == 'book_status') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      {elem.book_status == 0
                        ? 'Не выдаваемая'
                        : elem.book_status == 1
                        ? 'Свободная'
                        : elem.book_status == 2
                        ? 'На бронеполке'
                        : elem.book_status == 3
                        ? 'Выдана'
                        : elem.book_status == 4
                        ? 'Списана'
                        : 'Не определено'}
                    </TableCell>
                  );
                }
                // дальше идут видоизмененные кнопки
                else if (value.data == 'buttons_by_accept') {
                  return elem.publisher ? (
                    <TableCell key={i} className={s.cell} align='center'>
                      {buttons.map((value, i) => (
                        <Button
                          key={i}
                          text={value.title}
                          className={value.style ? s[value.style] : s.button}
                          onClick={() => value.event(elem)}
                        />
                      ))}
                    </TableCell>
                  ) : (
                    <TableCell key={i} className={s.notAccepted} align='center'>
                      Необходимо дополнить информацию
                      <Button
                        key={i}
                        text={buttons[1].title}
                        className={buttons[1].style ? s[buttons[1].style] : s.button}
                        onClick={() => buttons[1].event(elem)}
                      />
                    </TableCell>
                  );
                } else if (value.data == 'buttons_by_accept_serv') {
                  return elem.publisher ? (
                    <TableCell key={i} className={s.cell} align='center'>
                      <Button
                        key={i}
                        text={buttons[0].title}
                        className={buttons[0].style ? s[buttons[0].style] : s.button}
                        onClick={() => buttons[0].event(elem)}
                      />
                    </TableCell>
                  ) : (
                    <TableCell key={i} className={s.notAccepted} align='center'>
                      Необходимо дополнить информацию
                    </TableCell>
                  );
                } else if (value.data == 'bf_buttons') {
                  return elem.bf_file ? (
                    <TableCell key={i} className={s.cell} align='center'>
                      {buttons.map((value, i) =>
                        value.title == 'Добавить' ? null : (
                          <Button
                            key={i}
                            text={value.title}
                            className={value.style ? s[value.style] : s.button}
                            onClick={() => value.event(elem)}
                          />
                        ),
                      )}
                    </TableCell>
                  ) : (
                    <TableCell key={i} className={s.cell} align='center'>
                      {buttons.map((value, i) =>
                        value.title == 'Добавить' ? (
                          <Button
                            key={i}
                            text={value.title}
                            className={value.style ? s[value.style] : s.button}
                            onClick={() => value.event(elem)}
                          />
                        ) : null,
                      )}
                    </TableCell>
                  );
                } else if (value.data == 'button_href') {
                  return (
                    <TableCell key={i} className={s.cell} align='center'>
                      <a href={elem.href} target='_blank'>
                        Открыть документ
                      </a>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={i} className={s.cellButton} align='center'>
                      {buttons.map((value, i) => (
                        <Button
                          key={i}
                          text={value.title}
                          className={value.style ? s[value.style] : s.button}
                          onClick={() => value.event(elem)}
                        />
                      ))}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonWrapperTable;
