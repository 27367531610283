import React, { Component } from 'react';

import { connect } from 'react-redux';
import { logOut } from '../../redux/action';

import {
 setSiglsDate,
 setRolesData,
 setMaritalStatusData,
 setPositionsData,
 setLanguageSelectData,
 setSelectsAllByTypeGenres,
 setAllByTypeEducationStaff,
 setSelectsAllByTypeOtrasliZnanii,
 setSelectsAllByTypeSoderj,
 setSelectsAllByTypeVidDok,
 setSelectsAllByTypeIstPos,
 setSelectsAllByTypeStatus,
 setSelectsAllByTypeSchool,
 setSelectsAllByTypeEducation,
 setSelectsAllByTypeNationality,
 setUDKSelectsByGeneral,
 setUDKSelectsByKazakstanika,
 setUDKSelectsByKraeved,
 setUDKSelectsByBBK,
 setPublPlaceDataSelects,
 setSupliersDataSelects,
 setEducation2DataSelects,
 setStaffingSelectsData,
 setLibLevelSelectsData,
} from '../../redux/action';

import history from '../history';

import './style.css';

import {
 LibSigls,
 RolesGetData,
 MaritalStatusData,
 PositionsData,
 SelectsAllByTypeGenres,
 SelectsAllByTypeOtrasliZnanii,
 SelectsAllByTypeSoderj,
 SelectsAllByTypeVidDok,
 SelectsAllByTypeIstPos,
 SelectsAllByTypeStatus,
 SelectsAllByTypeSchool,
 SelectsAllByTypeEducationReader,
 SelectsAllByTypeEducationStaff,
 SelectsAllByTypeNationality,
 LanguageDataSelects,
 UDKSelectsByGeneral,
 UDKSelectsByKazakstanika,
 UDKSelectsByBBK,
 UDKSelectsByKraeved,
 PublPlaceDataSelects,
 SupliersDataSelects,
 Education2DataSelects,
 StaffingSelectsData,
 LibLevelSelectsData,
} from '../../api';

import out from '../../assets/icon/out.png';

class Header extends Component {
 out = async () => {
  delete localStorage.tokenLibrary;
  delete localStorage.lib_name;
  delete localStorage.role;
  delete localStorage.nameUser;
  delete localStorage.lib_ID;
  delete localStorage.idUser;
  delete localStorage.zabron_book;
  delete localStorage.zabron_length;
  delete localStorage.zabron_notification;

  await this.props.logOut;
  //   history.push('/');
  window.location.reload();
 };

 getSiglsData = async () => {
  const json = await LibSigls.LibSigls();

  await this.props.setSiglsDate(json);
 };

 getUDKData = async () => {
  const json1 = await UDKSelectsByGeneral.UDKSelectsByGeneral();
  const json2 = await UDKSelectsByKazakstanika.UDKSelectsByKazakstanika();
  const json3 = await UDKSelectsByKraeved.UDKSelectsByKraeved();
  const json4 = await UDKSelectsByBBK.UDKSelectsByBBK();

  await this.props.setUDKSelectsByGeneral(json1);
  await this.props.setUDKSelectsByKazakstanika(json2);
  await this.props.setUDKSelectsByKraeved(json3);
  await this.props.setUDKSelectsByBBK(json4);
 };

 getLanguageData = async () => {
  const json1 = await LanguageDataSelects.LanguageDataSelects();

  await this.props.setLanguageSelectData(json1);
 };

 // получаем и записываем в редакс селекты
 SelectsByType = async () => {
  const json1 = await SelectsAllByTypeGenres.SelectsAllByTypeGenres();
  const json2 = await SelectsAllByTypeOtrasliZnanii.SelectsAllByTypeOtrasliZnanii();
  const json3 = await SelectsAllByTypeSoderj.SelectsAllByTypeSoderj();
  const json4 = await SelectsAllByTypeVidDok.SelectsAllByTypeVidDok();
  const json5 = await SelectsAllByTypeIstPos.SelectsAllByTypeIstPos();
  const json6 = await SelectsAllByTypeStatus.SelectsAllByTypeStatus();
  const json7 = await SelectsAllByTypeSchool.SelectsAllByTypeSchool();
  const json8 = await SelectsAllByTypeEducationReader.SelectsAllByTypeEducationReader();
  const json9 = await SelectsAllByTypeNationality.SelectsAllByTypeNationality();
  const json10 = await PublPlaceDataSelects.PublPlaceDataSelects();
  const json11 = await SupliersDataSelects.SupliersDataSelects();
  //   const json12 = await Education2DataSelects.Education2DataSelects();
  const json13 = await StaffingSelectsData.StaffingSelectsData();
  const json14 = await LibLevelSelectsData.LibLevelSelectsData();
  const json15 = await SelectsAllByTypeEducationStaff.SelectsAllByTypeEducationStaff();
  const json16 = await RolesGetData.RolesGetData();
  const json17 = await MaritalStatusData.MaritalStatusData();
  const json18 = await PositionsData.PositionsData();

  await this.props.setSelectsAllByTypeGenres(json1);
  await this.props.setSelectsAllByTypeOtrasliZnanii(json2);
  await this.props.setSelectsAllByTypeSoderj(json3);
  await this.props.setSelectsAllByTypeVidDok(json4);
  await this.props.setSelectsAllByTypeIstPos(json5);
  await this.props.setSelectsAllByTypeStatus(json6);
  await this.props.setSelectsAllByTypeSchool(json7);
  await this.props.setSelectsAllByTypeEducation(json8);
  await this.props.setSelectsAllByTypeNationality(json9);
  await this.props.setPublPlaceDataSelects(json10);
  await this.props.setSupliersDataSelects(json11);
  //   await this.props.setEducation2DataSelects(json12);
  await this.props.setStaffingSelectsData(json13);
  await this.props.setLibLevelSelectsData(json14);
  await this.props.setAllByTypeEducationStaff(json15);
  await this.props.setRolesData(json16);
  await this.props.setMaritalStatusData(json17);
  await this.props.setPositionsData(json18);
 };

 async componentDidMount() {
  await this.getSiglsData();
  const lib_ID = localStorage.getItem('lib_ID');
  lib_ID === '0' && localStorage.setItem('libcheck', 'true');
  await this.SelectsByType();
  await this.getLanguageData();
  await this.getUDKData();
 }

 render() {
  // let fio;
  // if (+this.props.name.split(' ').length) {
  //  let [a, b, c] = this.props.name.split(' ');
  //  fio = [a, b?.slice(0, 1), c?.slice(0, 1)].join(' ');
  // }

  return (
   <div className='header'>
    <div className='header-wrapper'>
     <div className='header-content'>
      <div className='header-profile-link'>
       <p>
        <div className='dataFieldBottom' style={{ width: '100%' }}>
         {localStorage.getItem('library_name')}
        </div>
       </p>
      </div>
      <div className='header-out-link' onClick={this.out}>
       <img src={out} alt='profile' />
       <p>ВЫЙТИ </p>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = {
 logOut,
 setSiglsDate,
 setRolesData,
 setMaritalStatusData,
 setPositionsData,
 setLanguageSelectData,
 setSelectsAllByTypeGenres,
 setAllByTypeEducationStaff,
 setSelectsAllByTypeOtrasliZnanii,
 setSelectsAllByTypeSoderj,
 setSelectsAllByTypeVidDok,
 setSelectsAllByTypeIstPos,
 setSelectsAllByTypeStatus,
 setSelectsAllByTypeSchool,
 setSelectsAllByTypeEducation,
 setSelectsAllByTypeNationality,
 setUDKSelectsByGeneral,
 setUDKSelectsByKazakstanika,
 setUDKSelectsByKraeved,
 setUDKSelectsByBBK,
 setPublPlaceDataSelects,
 setSupliersDataSelects,
 setEducation2DataSelects,
 setStaffingSelectsData,
 setLibLevelSelectsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
