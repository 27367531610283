import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import '../sved_user/style.css';
import { getStatByRoles } from '../../../api';
import toast from 'react-hot-toast';

class UsersRoles extends Component {
 state = {
  arr_stat: [],

  open_1: false,
 };

 handleAge = async () => {
  const res = await getStatByRoles.getStatByRoles();

  console.log(res);

  this.setState({ arr_stat: res, open_1: true });
 };

 render() {
  const headerItemsContent_1 = [
   { title: 'Роль', data: 'title' },
   { title: 'Количество сотрудников', data: 'by_dt_number' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Права доступа/Роли сотрудников</h1>
     </div>

     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Сформировать отчет' className='btn_library_del' onClick={this.handleAge} />
      </div>
     </div>

     <div className='monitor' style={{ marginTop: '40px' }}>
      {this.state.open_1 && <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_1} />}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(UsersRoles);
