import s from './StaticSvedInfo.module.css';

const StaticSvedInfo = ({ static_sved, title, types, langs }) => {
 return (
  <div className={s.colunm}>
   <div className={s.headerRead}>
    <p>
     {title}: {static_sved || 0}
    </p>
   </div>
   <div>
    <div className={s.headerRead}>
     <p>По типу документа</p>
    </div>
    <div className={s.type}>
     {types
      ? types.map((type) => (
         <p>
          {type.title} - {type.by_dt_number}
         </p>
        ))
      : null}
    </div>
   </div>
   <div>
    <div className={s.headerRead}>
     <p>По языкам</p>
    </div>
    <div className={s.type}>
     {langs
      ? langs.map((lang) => (
         <p>
          {lang.title} - {lang.by_l_number}
         </p>
        ))
      : null}
    </div>
   </div>
  </div>
 );
};

export default StaticSvedInfo;
