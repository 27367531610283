import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, add_period_calendar } from '../../../../redux/action';

import { SmartSreach } from '../../../../api';

import Button from '../../../../components/button';
import Modal from '../../../../components/modal';

import arrow_add from '../../../../assets/icon/arrow-menu.png';
import close from '../../../../assets/icon/modal-genre-close.png';

class FormBookAdd extends Component {
 state = {
  typeItem: 'article',

  addbook: {
   name: '',
   date: '',
  },
 };

 handleChangeModal = async ({ target }) => {
  await this.setState({ ...this.state, addbook: { ...this.state.addbook, [target.name]: target.value } });
 };

 handleSubmit = async (e) => {
  e.preventDefault();
  if (this.state.addbook.name.trim().length && this.state.addbook.name.length) {
   for (let key in this.state.addbook) {
    this.state.addbook[key] = this.state.addbook[key].trim();
   }

   // this.props.add_period_calendar(this.state.addbook)
   const res = await fetch('https://smart-pvl.kz/Api/AddPeriodDate.php', {
    method: 'POST',
    body: JSON.stringify({ ...this.state.addbook, staff: localStorage.getItem('idUser') }),
   });
   const json = await res.json();
   await this.props.setDate(json, 'subcr_calendar');

   this.setState({
    addbook: Object.keys(this.state.addbook).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
   });
  } else {
   alert('Заполните поля');
  }
 };

 render() {
  const atrFormAddArticle = [
   { title: 'Название', name: 'name', type: 'text' },
   { title: 'Описание', name: 'date', type: 'date' },
  ];
  return (
   <div className='form-book-add'>
    <div className='form-add'>
     <form className='form-add-wrapper' onSubmit={this.handleSubmit}>
      <div className='form-add-sprav'>
       {atrFormAddArticle.map((el, i) => {
        return (
         <div className='search-filter-article' key={i}>
          <p>{el.title}</p>
          <input
           type={el.type}
           autoComplete='off'
           name={el.name}
           placeholder='Обязательные поля ввода'
           value={this.state.addbook[el.name]}
           onChange={this.handleChangeModal}
          />
         </div>
        );
       })}
      </div>

      <div className='form-add-img-btn'>
       <Button className='btn_add_book' text='Добавить' />
      </div>
     </form>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, add_period_calendar };

export default connect(mapStateToProps, mapDispatchToProps)(FormBookAdd);
