import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';

import Button from '../../../components/button';
import SincetoCalendar from '../../../components/calendars/SincetoCalendar/SincetoCalendar';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import '../sved_user/style.css';
import { getStatLibPeriodReg, getStatLibPeriodVisit } from '../../../api';
import toast from 'react-hot-toast';

class SvedCustomers extends Component {
 state = {
  arr_stat: [],
  arrBooks: [],
  open_1: false,
  open_2: false,
  date1: '',
  date2: '',
 };

 selectDate = (e) => this.setState({ [e.target.name]: e.target.value });

 handleDate = async () => {
  if (this.state.date1 && this.state.date2) {
   toast.success('Можете выбрать тип отчета');
  } else {
   toast.error('Выберите период');
  }
 };

 handleReg = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getStatLibPeriodReg.getStatLibPeriodReg({
    start: this.state.date1,
    end: this.state.date2,
   });

   console.log(res);
   this.setState({ arr_stat: res, open_1: true, open_2: false });
  } else {
   toast.error('Выберите период');
  }
 };

 handleVisit = async () => {
  if (this.state.date1 && this.state.date2) {
   const res = await getStatLibPeriodVisit.getStatLibPeriodVisit({
    start: this.state.date1,
    end: this.state.date2,
   });

   console.log(res);
   this.setState({ arr_stat: res, open_1: false, open_2: true });
  } else {
   toast.error('Выберите период');
  }
 };

 render() {
  const headerItemsContent_1 = [
   { title: 'Читатель', data: 'FIO' },
   { title: 'Дата регистрации', data: 'date' },
  ];

  const headerItemsContent_2 = [
   { title: 'Читатель', data: 'FIO' },
   { title: 'Дата посещения', data: 'visit_timestamp' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>Формирование и получение отчетных выходных форм</h1>
     </div>
     <div>
      <SincetoCalendar
       selectDate={this.selectDate}
       state1={this.state.date1}
       state2={this.state.date2}
       handleDate={this.handleDate}
       type='date'
      />
     </div>
     <div className='searchSved'>
      <div className='buttons-container'>
       <Button text='Отчет по регистрации' className='btn_library_del' onClick={this.handleReg} />
       <Button text='Отчет по посещаемости' className='btn_library_del' onClick={this.handleVisit} />
      </div>
     </div>

     <div className='monitor' style={{ marginTop: '40px' }}>
      {this.state.open_1 && <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_1} />}
      {this.state.open_2 && <CommonWrapperTable mainArr={this.state.arr_stat} headersArr={headerItemsContent_2} />}
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(SvedCustomers);
