import React from 'react';
import JsBarcode from 'jsbarcode';

const styles = {
 root: {
  // display: 'flex',
  // flexDirection: 'column',
  // justifyContent: 'space-between'

  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridGap: '10px',
 },
 row: {
  display: 'flex',
  flexDirection: 'row',
 },
 // display: "grid",
 // gridTemplateColumns: 'repeat(4, 1fr)',
 // gridGap: '10px'
};

class BarcodeRenderer extends React.Component {
 componentDidMount() {
  JsBarcode(this.props.name).init();
 }

 render() {
  return null;
 }
}

class Barcode extends React.Component {
 render() {
  return (
   <>
    <div style={{ margin: 'auto', textAlign: 'center' }}>
     <div style={{ margin: 'auto', textAlign: 'center' }}>
      {this.props.invent_num ? 'Инв. номер: ' + this.props.invent_num : ''}
     </div>
     <img
      className='barcode'
      jsbarcode-format='CODE128'
      jsbarcode-value={this.props.value}
      jsbarcode-textmargin='0'
      jsbarcode-fontoptions='bold'
     />
    </div>
   </>
  );
 }
}

class Bar extends React.Component {
 render() {
  return (
   <div>
    <Barcode value={this.props.barcode} invent_num={this.props.inv_number} />
    <BarcodeRenderer name='.barcode' />
   </div>
  );
 }
}

export default Bar;
