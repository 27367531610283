import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate } from '../../../redux/action';
import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';
import './style.css';
import chooseSpravList from '../../../helpers/chooseSpravList';
import Modal from '../../../components/modal';
import chooseHeadersTitle from '../../../helpers/chooseHeadersTitle';
import getSpravFormValues from '../../../helpers/getSpravFormValues';
import tryCatchAddAfterEvent from '../../../helpers/tryCatchAddAfterEvent';
import commonTryCatch from '../../../helpers/commonTryCatch';
import toast from 'react-hot-toast';

class CreateSrav extends Component {
 state = {
  choiceItem: 'staff',
  modalOpen: false,
  modalArr: false,
  modalHeaders: [],
  modalButtons: [],
  modalTitle: '',
  url: '',
  editModal: false,
  editObj: {},
 };

 openModal = async (e) => {
  let url = e.url;

  if (e.name === 'Образование' && this.state.choiceItem === 'reader') {
   url = url + '/get_fin.php';
  } else {
   url = url + '/get.php';
  }

  const buttons = [
   { title: 'Редактировать', event: (e) => this.openEditModal(e) },
   { title: 'Удалить', event: (e) => this.delUser(e), style: 'buttonDel' },
  ];
  let json;
  if (e.url === 'api/classifiers') {
   json = await commonTryCatch(`https://smart-pvl.kz/Api/${url}`, 'POST', JSON.stringify({ type: e.type }));
  } else {
   json = await commonTryCatch(`https://smart-pvl.kz/Api/${url}`);
  }
  this.setState({
   modalArr: json,
   modalHeaders: chooseHeadersTitle(e),
   modalButtons: buttons,
   modalTitle: e.name,
   url: e.url,
  });
 };

 delUser = async (elem) => {
  let arr = await tryCatchAddAfterEvent(
   this.state.url,
   { id: elem.id, type: elem.type },
   'delete',
   this.state.modalTitle,
   this.state.choiceItem
  );
  if (arr) {
   this.setState({ modalArr: arr });
  }
 };

 openEditModal = (e) => {
  this.setState({ editModal: true, editObj: e });
 };

 editData = async (e) => {
  e.preventDefault();
  let obj = JSON.parse(JSON.stringify(this.state.editObj));
  e.currentTarget.querySelectorAll('input').forEach(function (elem) {
   obj[elem.name] = elem.value;
  });
  let arr = await tryCatchAddAfterEvent(this.state.url, obj, 'edit', this.state.modalTitle, this.state.choiceItem);
  this.setState({ editModal: false });
  if (arr) {
   this.setState({ modalArr: arr });
  }
 };

 addData = async (e) => {
  e.preventDefault();
  const form = document.querySelector('#add');
  const formData = new FormData(form);
  console.log(form);
  console.log(this.state.modalArr);
  console.log(this.state.modalArr[0]);

  const data = getSpravFormValues(formData, this.state.modalArr[0]);
  console.log(data);

  if (!data) {
   form.reset();
   return toast('Недопустимое значение');
  }
  form.reset();
  let arr = await tryCatchAddAfterEvent(this.state.url, data, 'add', this.state.modalTitle, this.state.choiceItem);
  if (arr) {
   this.setState({ modalArr: arr });
  }
 };

 render() {
  const choiceItem = [
   { title: 'Сотрудники', value: 'staff' },
   { title: 'Читатели', value: 'reader' },
   { title: 'Библиографические описания', value: 'library' },
   { title: 'Общее', value: 'main' },
  ];

  const headersArr = [
   { title: 'Название', data: 'name' },
   { title: 'Действие', data: 'button' },
  ];

  const buttons = [{ title: 'Открыть', event: (e) => this.openModal(e) }];
  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     <div className='title-total'>
      <h1>СОЗДАНИЕ И ПОПОЛНЕНИЕ СПРАВОЧНИКОВ</h1>
     </div>

     <div className='choice-type'>
      {choiceItem.map((el) => (
       <h3
        className={el.value === this.state.choiceItem ? 'sprav-active' : ''}
        key={el.value}
        onClick={() => this.setState({ choiceItem: el.value })}
       >
        {el.title}
       </h3>
      ))}
     </div>

     <CommonWrapperTable mainArr={chooseSpravList(this.state.choiceItem)} headersArr={headersArr} buttons={buttons} />

     <Modal
      show={!!this.state.modalArr}
      handleClose={() => this.setState({ modalArr: false })}
      btn='Закрыть'
      classBtn='btn-close mt-10'
      closeModalEvent={(e) => e === 'modal' && this.setState({ modalArr: false })}
     >
      <p className='sprav-title-modal'>{this.state.modalTitle}</p>

      <form className='sprav-adding-container' id='add' onSubmit={this.addData}>
       <div className='sprav-adding-container'>
        {this.state.modalHeaders?.map((elem, i) => {
         if (elem.data === 'button') return false;
         return (
          <input
           className='sprav-adding-inputs'
           type='text'
           name={elem.data}
           key={i}
           placeholder={elem.title}
           required
          />
         );
        })}
       </div>
       <button className='sprav-button-modal'>Добавить</button>
      </form>

      {this.state.modalArr.length > 0 && (
       <CommonWrapperTable
        mainArr={this.state.modalArr}
        headersArr={this.state.modalHeaders}
        buttons={this.state.modalButtons}
       />
      )}
     </Modal>

     {this.state.editModal && (
      <Modal
       show={this.state.editModal}
       handleClose={() => this.setState({ editModal: false })}
       btn='Закрыть'
       classBtn='btn-close mt-10'
       closeModalEvent={(e) => e === 'modal' && this.setState({ editModal: false })}
      >
       <p className='sprav-title-modal'>{this.state.modalTitle}</p>

       <form className='sprav-adding-container' id='edit' onSubmit={this.editData}>
        <div className='sprav-adding-container'>
         {this.state.modalHeaders?.map((elem, i) => {
          if (elem.data === 'button') return false;
          return (
           <input
            className='sprav-adding-inputs'
            defaultValue={this.state.editObj[elem.data]}
            type='text'
            name={elem.data}
            key={i}
            placeholder={elem.title}
            required
           />
          );
         })}
        </div>
        <button className='sprav-button-modal'>Редактировать</button>
       </form>
      </Modal>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(CreateSrav);
