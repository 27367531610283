import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setDate } from '../../../redux/action';

import { Link } from 'react-router-dom';

// import history from '../components/history'

import Pagination from '../../../components/pagination';
import FormStatiAdd from '../../../components/formStatiAdd';
import Loader from '../../../components/loader'

import '../../style.css'

import arrow_sort from '../../../assets/icon/arrow-sort.png'


class Stati extends Component {

    state = {
        choiceItemPage: 'book',

        currentPagePag: '',

    }

    pagination = async (pageNum) => {
        await this.props.paginationPages({ item: "ElStat", skip: pageNum, page: 'ElStat' });
        await this.setState({ currentPagePag: pageNum });
    }

    changeSection = async (e) => {
        this.setState({ choiceItemPage: e.target.dataset.set })
        if (e.target.dataset.set === 'book') {
            await this.props.getData('ElStat', 'ElStat')
        }
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    }

    async componentDidMount() {
    }

    render() {
        const url = this.props.match.path.slice(1);

        const choiceItem = [{ title: 'Электронные статьи', dataset: 'book' }, { title: 'Добавить', dataset: 'add' }];

        const headerItemsContent = [
            { title: 'Автор', data: 'avtor' }, { title: 'Заглавие', data: 'name' }, { title: 'Другой автор', data: 'avtor_other' },
            { title: 'Тематическая рубрика', data: 'zhanr' }, { title: 'ISBN источника', data: 'ISBN' }];
        return (
            <div className='page-main'>
                <div className='page-out-wrapper'>

                    <div className='title-total'>
                        <h1>Электронные статьи</h1>
                    </div>

                    <div className='choice-type'>
                        {choiceItem.map((el, i) => <h3 data-set={el.dataset}
                            key={i} onClick={(e) => this.changeSection(e)}>{el.title}</h3>)}
                    </div>

                    {this.state.choiceItemPage === 'add'
                        ?
                        <FormStatiAdd />
                        :
                        <>
                            <div className='book-items-content'>
                                <div className='book-items-header'>
                                    {headerItemsContent.map((el, i) => {
                                        return (
                                            <div key={i}>
                                                <p>{el.title}</p>
                                                <img src={arrow_sort} alt="profile" data-type={el.data} onClick={(e) => this.sortItemBook(e.target.dataset.type)} />
                                            </div>
                                        )
                                    })}
                                </div>

                                {this.props.loader ?
                                    <Loader />
                                    :
                                    this.props.ElStat.length === 0
                                        ?
                                        <div className='search_output_null'>
                                            <h1>:</h1>
                                            <h3 >По данному поиску нет совпадений</h3>
                                        </div>
                                        :
                                        this.props.ElStat.map((el, i) => {
                                            let oddEven = (i + 1) % 2 === 0 ? 'book-items-data' : 'book-items-data gray';
                                            // let url = `book_add/${el.id}`;
                                            // let newUrl = `/${el.id}`;
                                            return (
                                                <div className={oddEven} key={el.id}>
                                                    {/* <Link to={url} key={el.id}> */}
                                                    <div><p>{el.avtor}</p></div>
                                                    {/* </Link> */}
                                                    <div><p>{el.name}</p></div>
                                                    <div><p>{el.avtor_other}</p></div>
                                                    <div><p>{el.zhanr}</p></div>
                                                    <div><p>{el.ISBN}</p></div>
                                                </div>
                                            )
                                        })
                                }
                            </div>

                            <Pagination pageCount={this.props.numbersBtnPag} paginationPages={(pageNum) => this.pagination(pageNum)} />

                        </>}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setDate }

export default connect(mapStateToProps, mapDispatchToProps)(Stati);