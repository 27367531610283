import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setDate, cleanAnyState, setBOPartiaPart } from '../../../redux/action';

import { GetBatchesByDate } from '../../../api';

import CommonWrapperTable from '../../../components/tables/CommonWrapperTable/CommonWrapperTable';

import FormPartEdit from '../../../components/FormPartEdit/FormPartEdit';

import moment from 'moment';

class PartEdit extends Component {
 state = {
  choiceItemPage: 'book',
  choiseItemPart: [],
 };

 returnOnFirstPage = async () => {
  this.setState({ choiceItemPage: 'book' });
  const obj = {
   date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  };
  const json = await GetBatchesByDate.GetBatchesByDate(obj);
  console.log(json);
  await this.props.setBOPartiaPart(json);
 };

 HandleEditClick = async (e) => {
  console.log(e);
  this.setState({ choiseItemPart: e, choiceItemPage: 'edit' });
 };

 async componentDidMount() {
  const obj = {
   date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  };
  const json = await GetBatchesByDate.GetBatchesByDate(obj);
  console.log(json);
  await this.props.setBOPartiaPart(json);
 }

 render() {
  //   const url = this.props.match.path.slice(1);
  const choiceItem = [{ title: 'Заявки', dataset: 'book' }];

  const buttons = [{ title: 'Редактировать', event: (e) => this.HandleEditClick(e) }];

  const headerItemsContent = [
   { title: 'Дата поступления', data: 'entrance_date' },
   { title: 'Дата по документу', data: 'document_date' },

   { title: 'Номер документа', data: 'document_number' },
   { title: 'Примечание', data: 'note' },
   { title: 'Сопроводительный документ', data: 'soprov_doc' },

   { title: 'Поставщик', data: 'supplier' },
   { title: 'Тип источника', data: 'type_of_source' },
   { title: 'Количество БО', data: 'bo_number' },
   { title: 'Общая сумма', data: 'summa' },

   { title: 'Количество экземпляров', data: 'exemplar_number' },
   { title: 'Дата', data: 'dateCreate' },
   { title: 'Отметка о поступлении', data: 'status_type' },
   { title: 'Библиотека', data: 'lib' },
   { title: '', data: 'button' },
  ];

  return (
   <div className='page-main'>
    <div className='page-out-wrapper'>
     {this.state.choiceItemPage !== 'book' ? (
      <FormPartEdit returnOnFirstPage={this.returnOnFirstPage} choiseItemPart={this.state.choiseItemPart} />
     ) : (
      <>
       <div className='title-total'>
        <h1>Заявки на комплектование фондов библиотеки</h1>
       </div>

       <div className='choice-type'>
        {choiceItem.map((el, i) => (
         <h3 data-set={el.dataset} key={i} onClick={(e) => this.setState({ choiceItemPage: e.target.dataset.set })}>
          {el.title}
         </h3>
        ))}
       </div>

       <div className='book-items-content' style={{ width: 'fit-content', paddingRight: '50px', marginRight: '-60px' }}>
        <CommonWrapperTable mainArr={this.props.BOPartiaPart} headersArr={headerItemsContent} buttons={buttons} />
       </div>
      </>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setDate, cleanAnyState, setBOPartiaPart };

export default connect(mapStateToProps, mapDispatchToProps)(PartEdit);
